import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Shipment } from '@qwyk/shared-stores/shipments';

import * as ShipmentTrackingEventsActions from './shipment-tracking-events.actions';
import * as ShipmentTrackingEventsSelectors from './shipment-tracking-events.selectors';

@Injectable()
export class ShipmentTrackingEventsFacade {
    /**
     * Combine pieces of state using createSelector,
     * and expose them as observables through the facade.
     */
    loading$ = this.store.pipe(
        select(ShipmentTrackingEventsSelectors.getShipmentTrackingEventsLoading)
    );
    allShipmentTrackingEvents$ = this.store.pipe(
        select(ShipmentTrackingEventsSelectors.getAllShipmentTrackingEvents)
    );
    selectedShipmentTrackingEvents$ = this.store.pipe(
        select(ShipmentTrackingEventsSelectors.getSelected)
    );
    shipment$ = this.store.pipe(
        select(ShipmentTrackingEventsSelectors.getShipment)
    );

    constructor(private store: Store) {}

    /**
     * Use the initialization action to perform one
     * or more tasks in your Effects.
     */
    init(shipment: Shipment) {
        this.store.dispatch(ShipmentTrackingEventsActions.init({ shipment }));
    }
}
