import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationFacade } from './../state/authentication.facade';

@Injectable({
    providedIn: 'root',
})
export class VerifyAllowedRoleGuard implements CanActivate, CanActivateChild {
    constructor(
        private authentication: AuthenticationFacade,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        // eslint-disable-next-line no-prototype-builtins
        if (!next.data || !next.data.hasOwnProperty('allowedRoles')) {
            return true;
        }
        return this.authentication.user$.pipe(
            map(user => (!user ? null : user.role)),
            switchMap(role => {
                if (role === null) {
                    return of(this.router.createUrlTree(['/']));
                }
                if (!next.data.allowedRoles.includes(role)) {
                    return of(
                        this.router.createUrlTree(['/error/403'], {
                            queryParams: {
                                source: '/' + next.url.toString(),
                            },
                        })
                    );
                }

                return of(true);
            })
        );
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }
}
