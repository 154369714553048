import { Component } from '@angular/core';
import { ShipmentActivitiesListItemComponent } from '../shipment-activities-list-item/shipment-activities-list-item.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-activities-milestone-change]',
    templateUrl: './shipment-activities-milestone-change.component.html',
    styleUrls: ['./shipment-activities-milestone-change.component.scss'],
})
export class ShipmentActivitiesMilestoneChangeComponent extends ShipmentActivitiesListItemComponent {
    constructor() {
        super();
    }
}
