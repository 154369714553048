/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { EnvironmentConfig } from '../environmentConfig';
import { AmplitudeEvents, AmplitudeEvent } from '../enums/AmplitudeEvent';

@Injectable({
    providedIn: 'root',
})
export class AmplitudeService {
    private instance = null;

    constructor(private environment: EnvironmentConfig) {}

    public get isAmplitudeEnabled(): boolean {
        return this.environment.utils.Amplitude.enabled;
    }

    public get isAmplitudeInitialized(): boolean {
        return null !== this.instance && this.instance._isInitialized;
    }

    public initialize(): void {
        if (this.isAmplitudeEnabled && !this.isAmplitudeInitialized) {
            this.instance = amplitude.getInstance();
            this.instance.init(this.environment.utils.Amplitude.apiKey);
            console.debug('Amplitude: initialized');
        }
    }

    public logEvent(event: AmplitudeEvents, properties?: any): void {
        if (this.isAmplitudeInitialized) {
            this.instance.logEvent(event, properties);
            console.debug('Amplitude: event sent');
        }
    }

    public logEventV2(event: AmplitudeEvent): void {
        if (this.isAmplitudeInitialized) {
            event.log(this.instance);
            console.debug('Amplitude: event sent');
        }
    }

    public identify(
        id: string,
        username: string,
        email: string,
        organization_id: number,
        site: string
    ): void {
        if (this.isAmplitudeInitialized) {
            this.instance.setUserId(id);
            this.instance.setUserProperties({
                username,
                email,
                organization_id,
                site,
            });
            console.debug('Amplitude: user identified');
        }
    }
}
