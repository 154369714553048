import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TranslateConfigChild } from '@qwyk/portals/translation';

import {
    LoginFormComponent,
    LoginModalComponent,
    ResetPasswordFormComponent,
    RequestPasswordResetFormComponent,
} from './components';
import * as fromAuthentication from './state/authentication.reducer';
import { AuthenticationFacade } from './state/authentication.facade';
import { AuthenticationEffects } from './state/authentication.effects';
import { ResendConfirmationEmailComponent } from './components/resend-confirmation-email/resend-confirmation-email.component';
import {DropdownModule} from 'primeng/dropdown';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        StoreModule.forFeature(
            fromAuthentication.AUTHENTICATION_FEATURE_KEY,
            fromAuthentication.reducer
        ),
        EffectsModule.forFeature([AuthenticationEffects]),
        TranslateModule.forChild(TranslateConfigChild),
        DropdownModule
    ],
    providers: [AuthenticationFacade],
    declarations: [
        LoginModalComponent,
        LoginFormComponent,
        ResetPasswordFormComponent,
        RequestPasswordResetFormComponent,
        ResendConfirmationEmailComponent,
    ],
    exports: [
        ResendConfirmationEmailComponent,
    ],
})
export class PortalsAuthenticationModule {}
