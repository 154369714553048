import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ui } from '@qwyk/models';

import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { EnvironmentConfig } from '../environmentConfig';

@Injectable({
    providedIn: 'root',
})
export class GooglePlacesService {
    // autocompleteService: google.maps.places.AutocompleteService;
    // placesService: google.maps.places.PlacesService;
    private environment;

    constructor(env: EnvironmentConfig, private http: HttpClient) {
        this.environment = env;
        // this.autocompleteService = new google.maps.places.AutocompleteService();
        // this.placesService = new google.maps.places.PlacesService(document.getElementById('places-service') as HTMLDivElement);
    }

    private static getFormattedAddress(place: any): ui.GooglePlacesAddress {
        const location: ui.GooglePlacesAddress = {
            formattedAddress: '',
            streetNumber: '',
            route: '',
            locality: '',
            postalCode: '',
            state: '',
            countryName: '',
            countryCode: '',
            position: { lat: 0, lng: 0 },
            types: [],
            code: '',
            placeId: '',
            plusCode: '',
        };
        for (const i in place.address_components) {
            // eslint-disable-next-line no-prototype-builtins
            if (!place.address_components.hasOwnProperty(i)) {
                continue;
            }

            const item = place.address_components[i];

            location.formattedAddress = place.formatted_address;
            location.types = place.types;

            location.position = {
                lat: place.geometry.location.lat,
                lng: place.geometry.location.lng,
            };

            if (item.types.indexOf('locality') > -1) {
                // eslint-disable-next-line no-prototype-builtins
                if (!location.hasOwnProperty('code')) {
                    location.code = item.short_name;
                }
                location.locality = item.long_name;
            } else if (
                item.types.indexOf('sublocality') > -1 &&
                !location.locality
            ) {
                location.locality = item.long_name;
            } else if (item.types.indexOf('administrative_area_level_1') > -1) {
                location.state = item.short_name;
            } else if (item.types.indexOf('street_number') > -1) {
                location.streetNumber = item.short_name;
            } else if (item.types.indexOf('route') > -1) {
                location.route = item.long_name;
            } else if (item.types.indexOf('country') > -1) {
                location.countryName = item.long_name;
                location.countryCode = item.short_name;
            } else if (item.types.indexOf('postal_code') > -1) {
                location.code = item.short_name;
                location.postalCode = item.short_name;
            }
        }

        location.placeId = place.place_id;
        if (place.plus_code) {
            location.plusCode = place.plus_code.global_code;
        }

        return location;
    }

    public getSuggestions(
        term: string,
        types?: string
    ): Observable<google.maps.places.AutocompletePrediction[] | null> {
        if (!term) {
            return of(null);
        }
        const params: { types: string } = { types: '' };
        if (types) {
            params['types'] = types;
        }

        return this.http
            .post<google.maps.places.AutocompletePrediction[]>(
                `${this.environment.backendServer}/api/masters/gplaces/autocomplete?term=${term}`,
                { term, params }
            )
            .pipe(
                take(1),
                catchError(() => of([]))
            );
    }

    public getPlaceDetails(
        placeId: string
    ): Observable<google.maps.places.PlaceResult> {
        return this.http
            .get<google.maps.places.PlaceResult>(
                `${this.environment.backendServer}/api/masters/gplaces/place/${placeId}`
            )
            .pipe(
                take(1),
                catchError(() => of({}))
            );
    }

    public getParsedPlaceDetails(
        placeId: string
    ): Observable<ui.GooglePlacesAddress | null> {
        return this.getPlaceDetails(placeId).pipe(
            take(1),
            map(result => {
                if (!result) {
                    return null;
                } else {
                    return GooglePlacesService.getFormattedAddress(result);
                }
            })
        );
    }
}
