<div *ngIf="expanded">
    <div *ngIf="routes$ | async; let routes;">
        <!--
        12-12-2023: As far as I can check, this component is not being shown
        <p-gmap
            [style]="{
                margin: '0 -1.5rem;',
                width: '100%',
                height: '250px',
                'min-height': '250px'
            }"
            [options]="mapOptions"
            [overlays]="mapLayers"
            (onMapReady)="setMap($event)">
        </p-gmap>
        -->
    </div>
</div>
