<ul *ngIf="quotation" class="list-group list-group-flush">
    <li class="list-group-item px-4 py-2">
        <div class="row align-items-center font-weight-bold">
            <div class="col-3">{{'portal.cargo-items.columns.packing' | translate}}</div>
            <div class="col-3">{{'common.quotations.page.cargo.dimensions' | translate}}</div>
            <div class="col-3 text-right">{{'common.weight' | translate}}</div>
            <div class="col-3 text-right">{{'common.volume' | translate | titlecase}}</div>
        </div>
    </li>
    <li *ngFor="let pkg of quotation.request.packages" class="list-group-item">
        <div class="row align-items-center">
            <div class="col-3">{{ pkg.quantity }}
                x {{ pkg.quantity > 1 ? pkg.packaging.description_plural : pkg.packaging.description }} {{pkg.container_type_name}}</div>
            <div class="col-3">
                <ng-container *ngIf="!!pkg.unit_length">
                    {{ pkg.unit_length}} x {{ pkg.unit_width }}
                    x {{ pkg.unit_height}} {{ pkg.uom === 'metric' ? 'cm' : 'in' }}
                </ng-container>
            </div>
            <div class="col-3 text-right">
                <ng-container *ngIf="!!pkg.unit_weight">
                    {{ pkg.unit_weight | number : '1.2-2' }} {{ pkg.uom === 'metric' ? 'kgs' : 'lbs' }}
                </ng-container>
            </div>
            <div class="col-3 text-right">
                <ng-container *ngIf="!!pkg.unit_volume">
                    {{ pkg.unit_volume | number : '1.3-3' }} {{ pkg.uom === 'metric' ? 'cbm' : 'cft' }}
                </ng-container>
            </div>
        </div>
    </li>
</ul>
