<header
    class="navbar navbar-light bg-primary navbar-expand-sm py-0 shadow-sm"
    id="topNavbar"
>
    <div class="container-fluid p-0">
        <div
            class="header-content w-100 d-flex flex-row justify-content-between"
        >
            <div class="title-container d-flex align-items-stretch flex-grow-1">
                <h1 *ngIf="siteDesign$ | async; let design" class="title py-1">
                    <button
                        *ngIf="!design.links.alternative_homepage"
                        [routerLink]="['/']"
                        class="btn btn-primary btn-sm"
                    >
                        <ng-container
                            *ngIf="
                                siteDesign$ | async;
                                let design;
                                else: defaultNavbarBrand
                            "
                        >
                            <span
                                [innerHTML]="design.titles.navbar_brand"
                            ></span>
                        </ng-container>
                        <span
                            *ngIf="ENVIRONMENT.badge"
                            [ngClass]="ENVIRONMENT.badge"
                            class="badge ml-2 rounded-pill"
                            style="font-size: 0.65em !important"
                        >{{ ENVIRONMENT.name }}</span
                        >
                    </button>
                    <a
                        *ngIf="design.links.alternative_homepage"
                        [href]="design.links.alternative_homepage"
                        class="btn btn-primary btn-sm"
                    >
                        <ng-container
                            *ngIf="
                                siteDesign$ | async;
                                let design;
                                else: defaultNavbarBrand
                            "
                        >
                            <span
                                [innerHTML]="design.titles.navbar_brand"
                            ></span>
                        </ng-container>
                        <span
                            *ngIf="ENVIRONMENT.badge"
                            [ngClass]="ENVIRONMENT.badge"
                            class="badge ml-2 rounded-pill"
                            style="font-size: 0.65em !important"
                        >{{ ENVIRONMENT.name }}</span
                        >
                    </a>
                </h1>
                <ul
                    class="list-unstyled navbar-sub-nav d-none d-md-flex m-0 ml-1"
                >
                    <li
                        #shipmentsDropdown="ngbDropdown"
                        class="dropdown"
                        ngbDropdown
                        *ngIf="!(features$ | async)?.disable_booking_tracking?.value &&
                        permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                    >
                        <button
                            [routerLink]="['/my-portal/shipments']"
                            class="btn navbar-button btn-primary btn-sm"
                        >
                            {{'portal.menu.side-menu.shipments' | translate}}
                            <fa-icon
                                [icon]="['far', 'angle-down']"
                                class="caret-down ml-1"
                            ></fa-icon>
                        </button>
                    </li>
                    <li class="dropdown">
                        <button
                            [routerLink]="['/my-portal/quotations']"
                            class="btn navbar-button btn-primary btn-sm"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                        >
                            {{'portal.menu.side-menu.quotations' | translate}}
                            <fa-icon
                                [icon]="['far', 'angle-down']"
                                class="caret-down ml-1"
                            ></fa-icon>
                        </button>
                    </li>
                    <li #addDropdown="ngbDropdown" class="dropdown" ngbDropdown *ngIf="displayCreateAny">
                        <button
                            class="btn navbar-button btn-primary btn-sm"
                            ngbDropdownToggle
                            ngbTooltip="New"
                        >
                            <fa-icon
                                [icon]="['fas', 'plus-square']"
                                class="mr-1"
                            ></fa-icon>
                            <fa-icon
                                [icon]="['far', 'angle-down']"
                                class="caret-down ml-1"
                            ></fa-icon>
                        </button>
                        <div
                            aria-labelledby="dropdownBasic1"
                            class="navbar-dropdown dropdown-menu-right py-1"
                            ngbDropdownMenu
                        >
                            <a
                                (click)="$event.preventDefault()"
                                [routerLink]="['/my-portal/booking/new/choose']"
                                ngbDropdownItem
                                *ngIf="!(features$ | async)?.disable_booking_tracking?.value &&
                                    permissions.includes(customerPermissionsEnum.CREATE_BOOKINGS)"
                            >{{'portal.menu.side-menu.new-shipment' | translate}}</a
                            >
                            <a
                                (click)="$event.preventDefault()"
                                [routerLink]="['/my-portal/booking-wizard']"
                                ngbDropdownItem
                                *ngIf="permissions.includes(customerPermissionsEnum.CREATE_QUOTATIONS)"
                            >{{'portal.menu.side-menu.new-quotation' | translate}}</a
                            >
                            <a
                                (click)="$event.preventDefault()"
                                [routerLink]="['/my-portal/orders/new']"
                                ngbDropdownItem
                                *ngIf="permissions.includes(customerPermissionsEnum.CREATE_PURCHASEORDERS)"
                            >{{'portal.menu.side-menu.new-purchase-order' | translate}}</a
                            >
                        </div>
                    </li>
                </ul>
            </div>
            <div
                class="navbar-collapse collapse align-items-stretch flex-grow-0"
            >
                <ul class="nav navbar-nav d-flex navbar-sub-nav w-100">
                    <!--                    <li-->
                    <!--                        class="nav-search d-none d-sm-none d-md-flex mr-1 flex-grow-1"-->
                    <!--                    >-->
                    <!--                        <qw-site-wide-search></qw-site-wide-search>-->
                    <!--                    </li>-->
                    <li *ngIf="ENVIRONMENT.flags.enableHelp" class="dropdown">
                        <button
                            #userPop="ngbPopover"
                            [ngbPopover]="helpPopContent"
                            class="btn navbar-button btn-primary btn-sm btn-icon"
                            placement="bottom"
                            popoverClass="user-popover"
                            triggers="click"
                        >
                            <fa-icon
                                [icon]="['fas', 'question-circle']"
                            ></fa-icon>
                        </button>
                        <ng-template #helpPopContent>
                            <ul class="list-group list-group-flush">
                                <a
                                    (click)="
                                        onboardingService.startOnboarding()
                                    "
                                    *ngIf="ENVIRONMENT.flags.enableOnboarding"
                                    class="list-group-item list-group-item-action"
                                    href="javascript:void(0)"
                                >
                                    {{'portal.menu.side-menu.welcome' | translate}}
                                </a>
                                <a
                                    class="list-group-item list-group-item-action"
                                    href="https://docs.qwykportals.com/"
                                    target="_blank"
                                >
                                    {{'portal.menu.side-menu.about-portals' | translate}}
                                </a>
                                <a
                                    class="list-group-item list-group-item-action"
                                    href="https://docs.qwykportals.com/getting-started/"
                                    target="_blank"
                                >
                                    {{'portal.menu.side-menu.quickstart' | translate}}
                                </a>
                                <a
                                    class="list-group-item list-group-item-action"
                                    href="https://docs.qwykportals.com/features"
                                    target="_blank"
                                >
                                    {{'portal.menu.side-menu.help-with-features' | translate}}
                                </a>
                                <a
                                    class="list-group-item list-group-item-action"
                                    href="https://docs.qwykportals.com/resources/change-log"
                                    target="_blank"
                                >
                                    {{'portal.menu.side-menu.see-whats-new' | translate}}
                                </a>
                            </ul>
                        </ng-template>
                    </li>
                    <li class="dropdown">
                        <button
                            [routerLink]="['/my-portal', 'notifications']"
                            class="btn navbar-button btn-primary btn-sm btn-icon"
                            ngbTooltip="{{'portal.menu.side-menu.notifications' | translate}}"
                            placement="bottom"
                        >
                            <fa-layers [fixedWidth]="true">
                                <fa-icon [icon]="['fas', 'bell']"></fa-icon>
                                <fa-layers-counter
                                    *ngIf="
                                        unreadNotifications$ | async;
                                        let unread
                                    "
                                    class="fa-icon-counter"
                                    content="{{ unread }}"
                                >
                                </fa-layers-counter>
                            </fa-layers>
                        </button>
                    </li>
                    <li
                        *ngIf="user$ | async; let user"
                        class="dropdown"
                        display="dynamic"
                        ngbDropdown
                        placement="bottom-end"
                    >
                        <button
                            #userPop="ngbPopover"
                            [ngbPopover]="userPopContent"
                            class="btn navbar-button btn-primary btn-sm avatar"
                            placement="bottom-end"
                            popoverClass="user-popover"
                        >
                            <span
                                class="text-color-contrast-secondary bg-secondary"
                            >{{ user.name[0] | uppercase }}</span
                            >
                            <fa-icon
                                [icon]="['far', 'angle-down']"
                                class="caret-down ml-1"
                            ></fa-icon>
                        </button>

                        <ng-template #userPopContent>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item border-bottom">
                                    <div class="font-weight-bold">
                                        {{ user.name }}
                                    </div>
                                    <div>{{ user.email }}</div>
                                </li>
                                <hr class="m-0 border-0"/>
                                <button
                                    [routerLink]="[
                                        '/my-portal/settings/profile'
                                    ]"
                                    class="list-group-item list-group-item-action border-0"
                                    type="button"
                                >
                                    <fa-icon
                                        [fixedWidth]="true"
                                        [icon]="['far', 'user']"
                                        class="mr-1"
                                    ></fa-icon>
                                    {{'portal.menu.side-menu.profile' | translate}}
                                </button>
                                <button *ngIf="(isMagayaNetwork$ | async) === false"
                                        [routerLink]="[
                                        '/my-portal/settings/profile'
                                    ]"
                                        class="list-group-item list-group-item-action"
                                        fragment="changePassword"
                                        type="button"
                                >
                                    <fa-icon
                                        [fixedWidth]="true"
                                        [icon]="['far', 'lock']"
                                        class="mr-1"
                                    ></fa-icon>
                                    {{ 'portal.menu.top-menu.change-password' | translate }}
                                </button>
                                <!-- <button type="button" class="list-group-item list-group-item-action"
                                    [routerLink]="['/my-portal/settings/team']">
                                    <fa-icon [icon]="['far', 'users']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                    Team
                                </button> -->
                                <button
                                    (click)="logout()"
                                    class="list-group-item list-group-item-action"
                                    type="button"
                                >
                                    <fa-icon
                                        [fixedWidth]="true"
                                        [icon]="['far', 'power-off']"
                                        class="mr-1"
                                    ></fa-icon>
                                    {{ 'portal.menu.top-menu.log-out' | translate }}
                                </button>
                            </ul>
                        </ng-template>
                        <!-- <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="['far', 'user']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                Profile
                            </button>
                            <hr class="my-0">
                            <button ngbDropdownItem (click)="logout()">
                                <fa-icon [icon]="['far', 'power-off']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                Log out
                            </button>
                        </div> -->
                    </li>
                </ul>
            </div>

            <ul class="list-unstyled navbar-sub-nav d-flex d-sm-none m-0 ml-1">
                <li
                    *ngIf="user$ | async; let user"
                    class="dropdown"
                    display="dynamic"
                    ngbDropdown
                    placement="bottom-end"
                >
                    <button
                        #userPop="ngbPopover"
                        [ngbPopover]="userPopContent"
                        class="btn navbar-button btn-primary btn-sm avatar"
                        placement="bottom-end"
                        popoverClass="user-popover"
                    >
                        <span
                            class="text-color-contrast-secondary bg-secondary"
                        >{{ user.name[0] | uppercase }}</span
                        >
                        <fa-icon
                            [icon]="['far', 'angle-down']"
                            class="caret-down ml-1"
                        ></fa-icon>
                    </button>
                    <ng-template #userPopContent>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item border-bottom">
                                <div class="font-weight-bold">
                                    {{ user.name }}
                                </div>
                                <div>{{ user.email }}</div>
                            </li>
                            <hr class="m-0 border-0"/>
                            <button
                                [routerLink]="['/my-portal/settings/profile']"
                                class="list-group-item list-group-item-action border-0"
                                type="button"
                            >
                                <fa-icon
                                    [fixedWidth]="true"
                                    [icon]="['far', 'user']"
                                    class="mr-1"
                                ></fa-icon>
                                Profile
                            </button>
                            <button
                                [routerLink]="['/my-portal/settings/profile']"
                                class="list-group-item list-group-item-action"
                                fragment="changePassword"
                                type="button"
                            >
                                <fa-icon
                                    [fixedWidth]="true"
                                    [icon]="['far', 'lock']"
                                    class="mr-1"
                                ></fa-icon>
                                Change Password
                            </button>
                            <!-- <button type="button" class="list-group-item list-group-item-action"
                                [routerLink]="['/my-portal/settings/team']">
                                <fa-icon [icon]="['far', 'users']" [fixedWidth]="true" class="mr-1"></fa-icon>
                                Team
                            </button> -->
                            <button
                                (click)="logout()"
                                class="list-group-item list-group-item-action"
                                type="button"
                            >
                                <fa-icon
                                    [fixedWidth]="true"
                                    [icon]="['far', 'power-off']"
                                    class="mr-1"
                                ></fa-icon>
                                Log out
                            </button>
                        </ul>
                    </ng-template>
                </li>
            </ul>
            <!--            <button class="navbar-toggler d-block d-sm-none">...</button>-->
        </div>
    </div>
</header>

<div class="page">
    <div
        [ngClass]="{ 'nav-sidebar-collapsed': (sidebarStyle$ | async) }"
        class="nav-sidebar border-right d-flex flex-column justify-content-between shadow-sm"
        id="navSidebar"
    >
        <div class="nav-sidebar-inner-scroll">
            <nav class="nav nav-top-level-items flex-column">
                <li
                    [routerLinkActiveOptions]="{ exact: false }"
                    [routerLink]="['/my-portal/dashboard']"
                    class="nav-item"
                    routerLinkActive="active"
                >
                    <a class="nav-link">
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'tachometer-alt-fast']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.dashboard' | translate}}</span>
                    </a>
                    <ul
                        class="nav nav-inner-level-items flex-column only-collapsed"
                    >
                        <li class="nav-item nav-inner-level-header d-none bold">
                            <a class="nav-link"> {{'portal.menu.side-menu.dashboard' | translate}} </a>
                        </li>
                    </ul>
                </li>
                <li
                    class="nav-item"
                    routerLinkActive="active"
                    *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                >
                    <a
                        [routerLink]="['/my-portal', 'quotations']"
                        class="nav-link"
                    >
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'hands-usd']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.quotations' | translate}}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">
                        <li
                            class="nav-item nav-inner-level-header d-none border-bottom bold"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'quotations']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.quotations' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.CREATE_QUOTATIONS)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'booking-wizard']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.create-quotation' | translate}}
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active" style="cursor:wait">
                            <a class="nav-link disabled" [routerLink]="['/my-portal', 'quotations', 'history']">
                                Bookmarks <span class="badge badge-secondary py-0">Coming soon</span>
                            </a>
                        </li> -->
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'quotations']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.list' | translate}}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    class="nav-item"
                    routerLinkActive="active"
                    *ngIf="(isMagayaNetwork$ | async) && displayMainWarehouseLink"
                >
                    <a
                        [routerLink]="['/my-portal', resolveDefaultWarehouseRoute()]"
                        class="nav-link"
                    >
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'warehouse']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.warehouse'| translate}}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">
                        <li
                            class="nav-item nav-inner-level-header d-none border-bottom bold"
                        >
                            <a
                                [routerLink]="['/my-portal', 'cargo-detail']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.warehouse'| translate}}
                            </a>
                        </li>


                        <li
                            class="nav-item"
                            routerLinkActive="active"
                        >
                            <a
                                [routerLink]="['/my-portal', 'cargo-detail']"
                                class="nav-link"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_COMMODITIES)"
                            >
                                {{'portal.menu.side-menu.cargo-detail' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_CARGORELEASES)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'cargo-releases']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.cargo-releases' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_INVENTORY)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'warehouse-inventory']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.inventory' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PICKUPORDERS)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'pickup-orders']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.pickup-orders'| translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_WAREHOUSERECEIPTS)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'warehouse-receipts']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.warehouse-receipts' | translate}}
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item"
                    routerLinkActive="active"
                    *ngIf="!(features$ | async)?.disable_booking_tracking?.value &&
                        permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                >
                    <a
                        [routerLink]="['/my-portal', 'shipments']"
                        class="nav-link"
                    >
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'box-alt']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.shipments' | translate}}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">
                        <li
                            class="nav-item nav-inner-level-header d-none border-bottom bold"
                            *ngIf="!(features$ | async)?.disable_booking_tracking?.value &&
                                    permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'shipments']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.shipments' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="!(features$ | async)?.disable_booking_tracking?.value &&
                                permissions.includes(customerPermissionsEnum.CREATE_BOOKINGS)"
                        >
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'booking',
                                    'new',
                                    'choose'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.create-shipment' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                        >
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'shipments',
                                    'list'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.list' | translate}}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    class="nav-item"
                    routerLinkActive="active"
                    *ngIf="ENVIRONMENT.flags.enablePurchaseOrders && permissions.includes(customerPermissionsEnum.VIEW_PURCHASEORDERS)"
                >
                    <a [routerLink]="['/my-portal', 'orders']" class="nav-link">
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'shopping-cart']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.purchase-orders' | translate}}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">
                        <li
                            class="nav-item nav-inner-level-header d-none border-bottom bold"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PURCHASEORDERS)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'orders']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.purchase-orders' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PURCHASEORDERS)"
                        >
                            <a
                                [routerLink]="['/my-portal', 'orders', 'list']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.list' | translate}}
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a
                        [routerLink]="['/my-portal', 'schedules']"
                        class="nav-link"
                    >
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'clock']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.schedules' | translate}}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">
                        <li
                            class="nav-item nav-inner-level-header d-none border-bottom bold"
                        >
                            <a
                                [routerLink]="['/my-portal', 'schedules']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.schedules' | translate}}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'schedules',
                                    'search'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.search' | translate}}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'schedules',
                                    'saved'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.list' | translate}}
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" [routerLink]="['/my-portal', 'schedules', 'history']">
                                History
                            </a>
                        </li> -->
                    </ul>
                </li>
                <li *ngIf="(isMagayaNetwork$ | async) && displayBilling" class="nav-item" routerLinkActive="active">
                    <a
                        [routerLink]="['/my-portal', permissions.includes(customerPermissionsEnum.VIEW_INVOICES) ? 'invoices' : 'payments']"
                        class="nav-link"
                    >
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'money-check']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.billing' | translate}}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">
                        <li
                            class="nav-item nav-inner-level-header d-none border-bottom bold"
                        >
                            <a
                                [routerLink]="['/my-portal', permissions.includes(customerPermissionsEnum.VIEW_INVOICES) ? 'invoices' : 'payments']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.billing' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_INVOICES)"
                        >
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'invoices',
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.invoices' | translate}}
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PAYMENTS)"
                        >
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'payments',
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.payments' | translate}}
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item" routerLinkActive="active">
                    <a
                        [routerLink]="['/my-portal', 'reports']"
                        class="nav-link"
                    >
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'chart-bar']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.reports'|translate}}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">

                        <li
                            class="nav-item nav-inner-level-header d-none border-bottom bold"
                        >
                            <a
                                [routerLink]="['/my-portal', 'reports']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.reports'|translate}}
                            </a>
                        </li>
                        <ng-container *ngIf="isMagayaNetwork$ | async">
                            <li class="nav-item" routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_CARGORELEASES)"
                            >
                                <a
                                    [routerLink]="[
                                    '/my-portal',
                                    'reports',
                                    'cargo-releases'
                                ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.cargo-releases' | translate}}
                                </a>
                            </li>
                            <li class="nav-item" routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_INVOICES)"
                            >
                                <a
                                    [routerLink]="[
                                    '/my-portal',
                                    'reports',
                                    'invoices'
                                ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.invoices' | translate}}
                                </a>
                            </li>
                            <li class="nav-item" routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_PICKUPORDERS)"
                            >
                                <a
                                    [routerLink]="[
                                    '/my-portal',
                                    'reports',
                                    'pickup-orders'
                                ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.pickup-orders' | translate}}
                                </a>
                            </li>
                        </ng-container>
                        <li class="nav-item" routerLinkActive="active"
                            *ngIf="permissions.includes(customerPermissionsEnum.VIEW_QUOTATIONS)"
                        >
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'reports',
                                    'quotations'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.quotations' | translate}}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'reports',
                                    'schedules'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.schedules' | translate}}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active"
                            *ngIf="!(features$ | async)?.disable_booking_tracking?.value &&
                                permissions.includes(customerPermissionsEnum.VIEW_SHIPMENTS)"
                        >
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'reports',
                                    'shipments'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.shipments' | translate}}
                            </a>
                        </li>

                        <ng-container *ngIf="isMagayaNetwork$ | async">
                            <li class="nav-item" routerLinkActive="active"
                                *ngIf="permissions.includes(customerPermissionsEnum.VIEW_WAREHOUSERECEIPTS)"
                            >
                                <a
                                    [routerLink]="[
                                    '/my-portal',
                                    'reports',
                                    'warehouse-receipts'
                                ]"
                                    class="nav-link"
                                >
                                    {{'portal.menu.side-menu.warehouse-receipts' | translate}}
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
                <li
                    [routerLinkActiveOptions]="{ exact: true }"
                    [routerLink]="['/my-portal/notifications']"
                    class="nav-item"
                    routerLinkActive="active"
                >
                    <a class="nav-link">
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'bell']"
                        ></fa-icon>
                        <div
                            class="nav-link-text d-flex justify-content-between flex-grow-1"
                        >
                            {{'portal.menu.side-menu.notifications' | translate}}
                            <span
                                *ngIf="unreadNotifications$ | async; let unread"
                                class="badge text-bg-light px-2 py-0 rounded-pill"
                                style="background-color: rgba(0, 0, 0, 0.1); margin: auto 0;"
                            >
                                {{ unread }}
                            </span>
                        </div>
                    </a>
                    <ul
                        class="nav nav-inner-level-items flex-column only-collapsed"
                    >
                        <li class="nav-item nav-inner-level-header d-none bold">
                            <a class="nav-link"> {{'portal.menu.side-menu.notifications' | translate}} </a>
                        </li>
                    </ul>
                </li>
                <ng-container *ngIf="features$ | async; let features">
                    <li class="nav-item" routerLinkActive="active"
                    *ngIf="!features.disable_global_chat?.value">
                    <a
                        [routerLink]="['/my-portal', 'conversations']"
                        class="nav-link"
                    >
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'comments']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.chat' | translate}}</span>
                    </a>
                </li>
                </ng-container>
                <li class="nav-item" routerLinkActive="active">
                    <a
                        [routerLink]="['/my-portal', 'settings']"
                        class="nav-link"
                    >
                        <fa-icon
                            [fixedWidth]="true"
                            [icon]="['far', 'cog']"
                        ></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.settings' | translate}}</span>
                    </a>
                    <ul class="nav nav-inner-level-items flex-column">
                        <li
                            class="nav-item nav-inner-level-header d-none border-bottom bold"
                        >
                            <a
                                [routerLink]="['/my-portal', 'settings']"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.settings' | translate}}
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active">
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'settings',
                                    'profile'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.profile' | translate}}
                            </a>
                        </li>
                        <!-- <li class="nav-item" routerLinkActive="active">
                            <a class="nav-link" [routerLink]="['/my-portal', 'settings', 'team']">
                                Team
                            </a>
                        </li> -->
                        <li class="nav-item" routerLinkActive="active">
                            <a
                                [routerLink]="[
                                    '/my-portal',
                                    'settings',
                                    'notifications'
                                ]"
                                class="nav-link"
                            >
                                {{'portal.menu.side-menu.notifications' | translate}}
                            </a>
                        </li>
                    </ul>
                </li>

            </nav>
        </div>
        <div class="nav-sidebar-collapse-button overflow-hidden">
            <ul class="nav flex-column">
                <li class="nav-item border-top">
                    <a (click)="toggleSidebar()" class="nav-link">
                        <fa-icon [icon]="['far', 'arrow-from-right']"></fa-icon>
                        <span class="nav-link-text">{{'portal.menu.side-menu.collapse' | translate}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <section
        [ngClass]="{ expanded: sidebarStyle$ | async }"
        class="page-container"
    >
        <router-outlet></router-outlet>
    </section>
</div>

<ng-template #defaultNavbarBrand>Portal</ng-template>
