import {
    Input,
    Output,
    OnInit,
    OnDestroy,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
} from '@angular/core';
import { ui } from '@qwyk/models';

@Directive({
    selector: '[qwykGooglePlacesAutocomplete]',
})
export class GooglePlacesAutocompleteDirective implements OnInit, OnDestroy {
    @Output() placeSelected: EventEmitter<ui.GooglePlacesAddress> =
        new EventEmitter();
    @Output() invalidate: EventEmitter<boolean> = new EventEmitter();
    @Input() placeTypes: string[] = ['address'];
    private listener: google.maps.MapsEventListener;

    constructor(private elRef: ElementRef) {}

    ngOnInit() {
        // const serv = new google.maps.places.AutocompleteService();
        // serv.getPlacePredictions({input: 'Nieuwstraat 7'}, (result) => {
        //     console.log(result);
        // });

        const autocomplete = new google.maps.places.Autocomplete(
            this.elRef.nativeElement,
            {
                types: this.placeTypes,
            }
        );

        this.listener = google.maps.event.addListener(
            autocomplete,
            'place_changed',
            () => {
                const place = autocomplete.getPlace();
                if (Object.keys(place).length < 2) {
                    // If you hit enter in this control it will trigger place_changed but only the `name` prop will be set with the input.
                    // Test for this and return if we have less than 2 props.
                    this.invalidate.emit(true);
                } else {
                    this.placeSelected.emit(
                        this.getFormattedAddress(autocomplete.getPlace())
                    );
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.listener) {
            this.listener.remove();
        }
    }

    @HostListener('keydown.backspace', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onInputBackspace(e) {
        this.invalidate.emit(true);
    }

    /**
     * Handles clearing of the input by hitting the spacebar
     * @param e
     */
    @HostListener('keydown', ['$event'])
    onKeyDown(e) {
        if (e?.target?.value?.trim() === '' || !e?.target?.value) {
            this.invalidate.emit(true);
        }
    }

    private getFormattedAddress(
        place: google.maps.places.PlaceResult
    ): ui.GooglePlacesAddress {
        const location: ui.GooglePlacesAddress = {
            formattedAddress: null,
            streetNumber: null,
            route: null,
            locality: null,
            postalCode: null,
            state: null,
            countryName: null,
            countryCode: null,
            position: null,
            types: null,
            code: null,
            placeId: null,
            plusCode: null,
        };
        for (const i in place.address_components) {
            // eslint-disable-next-line no-prototype-builtins
            if (!place.address_components.hasOwnProperty(i)) {
                continue;
            }

            const item = place.address_components[i];

            location.formattedAddress =
                this.elRef.nativeElement.value ?? place.formatted_address;
            location.types = place.types;
            location.position = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };

            if (item.types.indexOf('locality') > -1) {
                // eslint-disable-next-line no-prototype-builtins
                if (!location.hasOwnProperty('code')) {
                    location.code = item.short_name;
                }
                location.locality = item.long_name;
            } else if (
                item.types.indexOf('sublocality') > -1 &&
                !location.locality
            ) {
                location.locality = item.long_name;
            } else if (item.types.indexOf('administrative_area_level_1') > -1) {
                location.state = item.short_name;
            } else if (item.types.indexOf('street_number') > -1) {
                location.streetNumber = item.short_name;
            } else if (item.types.indexOf('route') > -1) {
                location.route = item.long_name;
            } else if (item.types.indexOf('country') > -1) {
                location.countryName = item.long_name;
                location.countryCode = item.short_name;
            } else if (item.types.indexOf('postal_code') > -1) {
                location.code = item.short_name;
                location.postalCode = item.short_name;
            }
        }

        location.placeId = place.place_id;
        if (place.plus_code) {
            location.plusCode = place.plus_code.global_code;
        }

        try {
            if (location.countryCode === 'CN' && !location.postalCode) {
                const matches = /\d{6}$/gm.exec(this.elRef.nativeElement.value);
                location.postalCode =
                    matches && matches.length > 0 ? matches[0] : null;
            }
        } catch {
            /* empty */
        }

        return location;
    }
}
