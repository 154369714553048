<div [@fadeIn] *ngIf="schedule" class="card my-3 animate-shadow text-dark" (mouseover)="isMouseOver = true"
    (mouseleave)="isMouseOver = false"
    [ngClass]="{'shadow border-white': isMouseOver || isExpanded, 'shadow-sm': !isMouseOver && !isExpanded}">
    <div class="card-body" (click)="isExpanded = !isExpanded" style="cursor: pointer;">
        <div class="row align-items-center">
            <div class="col">
                <div class="head-label text-muted" *ngIf="schedule.product !== 'air'">
                    <fa-icon [icon]="['far', 'ship']" class="mr-1"></fa-icon>{{ 'hub.shipment.search.placeholders.vessel' | translate }}
                </div>
                <div class="head-label text-muted" *ngIf="schedule.product === 'air'">
                    <fa-icon [icon]="['far', 'plane']" class="mr-1"></fa-icon>{{ 'portal.schedules.search.flight' | translate }}
                </div>
                <h5 class="mb-3" *ngIf="schedule.product !== 'air'">{{ schedule.vessel }}</h5>
                <h4 class="mb-3" *ngIf="schedule.product === 'air'">{{ schedule.vessel }}</h4>
                <div class="head-label text-muted" *ngIf="schedule.product !== 'air'">
                    <fa-icon [icon]="['far', 'hashtag']" class="mr-1"></fa-icon>{{ 'hub.shipment.sections.instructions.subsections.transport-fields.voyage' | translate }}
                </div>
                <h5 *ngIf="schedule.product !== 'air'">{{ schedule.voyage }}</h5>
            </div>

            <div class="col">
                <div class="head-label text-muted">
                    <fa-icon [icon]="['far', 'clock']" class="mr-1"></fa-icon>{{ 'portal.landing-page.quotation-offer-schedules.columns.closing' | translate }}
                </div>
                <h5 *ngIf="schedule.product !== 'air'">{{ schedule.closing | date }}</h5>
                <h5 *ngIf="schedule.product === 'air'">{{ schedule.closing | date:'short' }}</h5>
                <div class="head-label text-muted">
                    <fa-icon [icon]="['far', schedule.product !== 'air'  ? 'anchor' : 'plane-arrival']" class="mr-1">
                    </fa-icon>ETD
                </div>
                <h5>{{ schedule.etd | date }}</h5>
            </div>

            <div class="col">
                <div class="head-label text-muted">
                    <fa-icon [icon]="['far', 'building']" class="mr-1"></fa-icon>{{ 'portal.warehouse-receipts.index-page.index.search.placeholders.carrier' | translate }}
                </div>
                <h5 *ngIf="schedule.carrier">{{ schedule.carrier.display_name }}</h5>
                <div class="head-label text-muted">
                    <fa-icon [icon]="['far', schedule.product !== 'air'  ? 'anchor' : 'plane-arrival']" class="mr-1">
                    </fa-icon>ETA
                </div>
                <h5 *ngIf="schedule.product !== 'air'">{{ schedule.eta | date }}</h5>
                <h5 *ngIf="schedule.product === 'air'">{{ schedule.eta | date:'short' }}</h5>
            </div>

            <div class="col-6 order-1 order-lg-2 col-lg-auto text-center d-flex flex-column justify-content-center align-items-center">
                <h3 class="m-0" *ngIf="schedule.product !== 'air'"
                    [ngbTooltip]="(schedule.closing ? 'portal.schedules.search.closing-to-ETA' : 'portal.schedules.search.etd-to-eta') | translate">
                    {{ schedule.eta | amDifference: schedule.closing || schedule.etd : 'days' }}
                    {{ 'common.days' | translate }}</h3>
                <div *ngIf="schedule.product === 'air'" class="d-flex">
                    <h3 class="m-0" [ngbTooltip]="'portal.schedules.search.etd-to-eta' | translate">
                        {{ eta | amDifference: etd : 'hours' }}
                        {{ 'common.hours' | translate }}</h3>
                    <fa-icon [icon]="['far', 'exclamation-circle']" class="text-muted" *ngIf="!etdSet || !etaSet"
                        [ngbTooltip]="'portal.schedules.search.couldnt-determine-timezones' | translate"></fa-icon>
                </div>
                <div class="text-muted">
                    {{
                        schedule.legs.length > 1
                        ? ('portal.schedules.search.x-stops' | translate : { value : schedule.legs.length - 1 })
                        : 'portal.schedules.search.direct' | translate
                    }}
                </div>
                <span class="mt-3" *ngIf="hasBookLinkAction && !features.disable_booking_tracking?.value"
                    [ngbTooltip]="(schedule.closing | amIsBefore) ? ('portal.schedules.search.saling-close-in-past' | translate) : null">
                    <button class="btn btn-secondary font-weight-bold shadow" (click)="onBook($event)"
                        *ngIf="(features$ | async).portal.value"
                        [disabled]="isSaving || (schedule.closing | amIsBefore)">
                        <div *ngIf="canBook">{{ 'portal.schedules.search.book-shipment' | translate }}</div>
                        <div *ngIf="!canBook">{{ 'portal.schedules.search.attach-to-booking-btn' | translate }}</div>
                        <fa-icon [icon]="isSaving ? ['far', 'spinner'] : ['fas', 'long-arrow-right']"
                            [pulse]="isSaving">
                        </fa-icon>
                    </button>
                </span>
                <!-- <small class="text-muted" *ngIf="schedule.updated_at">
                    Last updated {{ schedule.updated_at | amLocal | amTimeAgo}}
                </small> -->
            </div>
        </div>
    </div>

    <div class="card-body border-top py-2" [@expandCollapse2] *ngIf="isExpanded">
        <div class="row">
            <div class="col">
                <div class="ml-2">
                    <h5>{{ 'portal.schedules.search.timeline' | translate }}</h5>
                    <qwyk-schedule-timeline [schedule]="schedule"></qwyk-schedule-timeline>
                </div>
            </div>
            <div class="col border-left">
                <h5>{{ 'portal.schedules.search.deadlines' | translate }}</h5>
                <div *ngIf="schedule.closing" class="mb-2">
                    <small>{{ 'portal.schedules.search.booking' | translate }}</small>
                    <div>{{ schedule.closing | date }}</div>
                </div>
                <div *ngIf="schedule.closing_cargo" class="mb-2">
                    <small>{{ 'portal.shipment.page.packages.cargo' | translate }}</small>
                    <div>{{ schedule.closing_cargo | date }}</div>
                </div>
                <div *ngIf="schedule.closing_doc" class="mb-2">
                    <small>{{ 'portal.schedules.search.documentation' | translate }}</small>
                    <div>{{ schedule.closing_doc | date }}</div>
                </div>
                <div *ngIf="schedule.closing_imo">
                    <small>IMO</small>
                    <div>{{ schedule.closing_imo | date }}</div>
                </div>
                <h5>{{ 'common.notes' | translate }}</h5>
                {{ schedule.notes }}
            </div>
        </div>
    </div>

    <div class="card-footer py-2">
        <button class="btn btn-link pl-0" (click)="isExpanded = !isExpanded">
            <fa-icon [icon]="['far', 'angle-down']" class="mr-2 rotatable text-secondary"
                [rotate]="isExpanded ? 180 : 0"></fa-icon>
                {{ (isExpanded ? 'portal.common.hide_details' : 'portal.common.show_details') | translate }}
        </button>
        <button class="btn btn-link" (click)="onSave(schedule)" [disabled]="isSaving"
            *ngIf="(features$ | async).portal.value">
            <fa-icon [icon]="['far', 'save']" class="mr-2 text-secondary"></fa-icon>{{ 'portal.schedules.search.save-schedule' | translate }}
        </button>
    </div>
</div>
