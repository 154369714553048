<ul *ngIf="quotation" class="list-group list-group-flush">
    <li class="list-group-item px-4 py-2">
        <div class="row align-items-center font-weight-bold">
            <div class="col">{{'common.quotations.status.request' | translate}}</div>
            <div class="col">{{'common.quotations.page.request-list.response' | translate}}</div>
            <div class="col">{{'common.quotations.page.request-list.offer' | translate}}</div>
        </div>
    </li>

    <li *ngFor="let rateRequest of quotation.rate_requests" class="list-group-item py-4">
        <div class="row align-items-baseline">
            <div class="col">
                {{ rateRequest.creator.name }} {{ 'common.on' | translate }} {{ rateRequest.created_at | amLocal | amDateFormat: 'L' }}
                <div>{{ rateRequest.reason }}</div>
                <div>{{ rateRequest.remarks }}</div>
            </div>
            <div class="col">
                <ng-container *ngIf="rateRequest.responder">
                    {{ rateRequest.responder.name }} {{ 'common.on' | translate }} {{rateRequest.updated_at | amLocal | amDateFormat: 'L' }}
                    <div>{{ rateRequest.response_message }}</div>
                </ng-container>
            </div>
            <div class="col">
                <a *ngIf="rateRequest.offer" [routerLink]="['/', 'quotation', quotation.id]">
                    {{ rateRequest.offer.total_amount | currency: rateRequest.offer.currency : rateRequest.offer.currency + ' ' }}
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </a>
            </div>
        </div>
    </li>

    <li *ngIf="!quotation.rate_requests || quotation.rate_requests.length === 0"
        class="list-group-item py-4 text-center">
        {{ 'common.no_item' | translate : { value : 'common.quotations.page.navs.rate-requests' | translate | lowercase } }}
    </li>
</ul>
