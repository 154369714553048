import { Component } from '@angular/core';

@Component({
    selector: 'qwyk-on-hold-indicator',
    templateUrl: './on-hold-indicator.component.html',
    styleUrls: ['./on-hold-indicator.component.scss'],
})
export class OnHoldIndicatorComponent {
    constructor() {}
}
