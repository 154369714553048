<input type="file" [attr.name]="name" [attr.id]="name" class="d-none" [attr.accept]="accept"
    (change)="onFileSelected($event)" [disabled]="busy || disabled" />
<label [attr.for]="name" class="label btn overflow-hidden" [ngClass]="buttonClass">
    <div class="d-flex align-items-center justify-content-start">
        <fa-icon [icon]="['far', busy ? 'spinner' : (selectedFile ? 'cloud-upload' : 'plus')]" [pulse]="busy"
            [fixedWidth]="true" class="mr-1 d-inline-block">
        </fa-icon>
        <div class="label-text d-inline-block">{{ selectedFile ? selectedFile.name : ('common.select' | translate) }}</div>
    </div>
</label>
<div class="text-danger small">{{ error }}</div>
