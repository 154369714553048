import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { OnboardingResponse } from '../../onboarding.response';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnboardingStatusEnum } from '../../onboarding-status.enum';

@Component({
    selector: 'qwyk-onboarding-notice',
    templateUrl: './onboarding-notice.component.html',
    styleUrls: ['./onboarding-notice.component.scss'],
})
export class OnboardingNoticeComponent implements AfterViewInit {
    @Input() onboardingStatus!: OnboardingResponse;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('content') content: any;

    constructor(private modalService: NgbModal) {}

    private static shouldDisplayModal(
        status: OnboardingResponse | null
    ): boolean {
        if (!status) {
            return false;
        }
        return (
            status.previous_status === OnboardingStatusEnum.NEW &&
            status.current_status === OnboardingStatusEnum.IN_PROGRESS
        );
    }

    ngAfterViewInit() {
        if (
            OnboardingNoticeComponent.shouldDisplayModal(this.onboardingStatus)
        ) {
            this.modalService.open(this.content, {
                centered: true,
                size: 'lg',
            });
        }
    }
}
