import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Shipment } from '@qwyk/shared-stores/shipments';

import { ShipmentAttachment } from './shipment-attachments.models';
import * as ShipmentAttachmentsActions from './shipment-attachments.actions';

export const SHIPMENTATTACHMENTS_FEATURE_KEY = 'shipmentAttachments';

export interface State extends EntityState<ShipmentAttachment> {
    selectedId?: string | number; // which ShipmentAttachments record has been selected
    loading: boolean; // has the ShipmentAttachments list been loaded
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any | null; // last known error (if any)
    shipment?: Shipment;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attachmentLinks: any[];
}

export interface ShipmentAttachmentsPartialState {
    readonly [SHIPMENTATTACHMENTS_FEATURE_KEY]: State;
}

export const shipmentAttachmentsAdapter: EntityAdapter<ShipmentAttachment> =
    createEntityAdapter<ShipmentAttachment>();

export const initialState: State = shipmentAttachmentsAdapter.getInitialState({
    // set initial required properties
    loading: false,
    shipment: null,
    attachmentLinks: [],
});

const shipmentAttachmentsReducer = createReducer(
    initialState,
    on(
        ShipmentAttachmentsActions.init,
        ShipmentAttachmentsActions.loadShipmentAttachmentsOnly,
        (state, { shipment }) => ({
            ...state,
            loading: true,
            error: null,
            shipment,
        })
    ),
    on(
        ShipmentAttachmentsActions.loadShipmentAttachmentsSuccess,
        (state, { shipmentAttachments }) => {
            const attachmentLinks = state.attachmentLinks ?? [];
            attachmentLinks[1] = shipmentAttachments.map((file, i) => ({
                ...file,
                hasPrevPage: 1 > 1,
                hasNextPage: 1 < 1,
                isFirstItemOnPage: i === 0,
                isLastItemOnPage: i === shipmentAttachments.length - 1,
                page: 1,
            }));
            return shipmentAttachmentsAdapter.setAll(shipmentAttachments, {
                ...state,
                loading: false,
                attachmentLinks,
            });
        }
    ),
    on(
        ShipmentAttachmentsActions.loadShipmentAttachmentsOnlySuccess,
        (state, { attachmentLinks }) => {
            const dupe = [...state.attachmentLinks];
            dupe[1] = attachmentLinks.map((file, i) => ({
                ...file,
                hasPrevPage: 1 > 1,
                hasNextPage: 1 < 1,
                isFirstItemOnPage: i === 0,
                isLastItemOnPage: i === attachmentLinks.length - 1,
                page: 1,
            }));
            return {
                ...state,
                error: null,
                loading: false,
                attachmentLinks: dupe,
            };
        }
    ),
    on(
        ShipmentAttachmentsActions.generateAttachmentLink,
        ShipmentAttachmentsActions.createShipmentAttachment,
        ShipmentAttachmentsActions.deleteShipmentAttachment,
        state => ({
            ...state,
            error: null,
            loading: true,
        })
    ),
    on(ShipmentAttachmentsActions.generateAttachmentLinkSuccess, state => ({
        ...state,
        loading: false,
    })),
    on(
        ShipmentAttachmentsActions.createShipmentAttachmentSuccess,
        (state, { shipmentAttachment }) =>
            shipmentAttachmentsAdapter.setAll(
                [
                    shipmentAttachment,
                    ...shipmentAttachmentsAdapter
                        .getSelectors()
                        .selectAll(state),
                ],
                {
                    ...state,
                    loading: false,
                }
            )
    ),
    on(
        ShipmentAttachmentsActions.deleteShipmentAttachmentSuccess,
        (state, { shipmentAttachmentId }) =>
            shipmentAttachmentsAdapter.removeOne(shipmentAttachmentId, {
                ...state,
                loading: false,
            })
    ),
    on(
        ShipmentAttachmentsActions.loadShipmentAttachmentsFailure,
        ShipmentAttachmentsActions.loadShipmentAttachmentsOnlyFailure,
        ShipmentAttachmentsActions.generateAttachmentLinkFailure,
        ShipmentAttachmentsActions.createShipmentAttachmentFailure,
        ShipmentAttachmentsActions.deleteShipmentAttachmentFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error,
        })
    ),
    on(ShipmentAttachmentsActions.reset, () => initialState),
    on(ShipmentAttachmentsActions.dismissError, state => ({
        ...state,
        error: null,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return shipmentAttachmentsReducer(state, action);
}
