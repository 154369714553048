<ngb-alert
    *ngIf="onboardingStatus?.current_status === onboardingStatusEnum.IN_PROGRESS"
    [type]="'light'"
    class="d-flex justify-content-between align-items-center shadow-sm border"
    [dismissible]="false"
>
    <div class="mr-3">
        <fa-icon [icon]="['far', 'spinner']" [pulse]="true" size="lg"></fa-icon>
    </div>
    <div class="flex-grow-1">
        <h6 class="m-0">{{ 'portal.dashboard.synchronizing-data' | translate }}</h6>
        <p class="small m-0">{{ 'portal.dashboard.synchronizing-data-message' | translate }}</p>
    </div>
</ngb-alert>
