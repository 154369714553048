import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShipmentContainersViewComponent} from "./components";
import {
    SharedStoresShipmentContainersModule,
    ShipmentContainersServiceBase
} from "@qwyk/shared-stores/shipment-containers";
import {ShipmentContainersService} from "./shipment-containers.service";
import {HttpClient} from "@angular/common/http";
import {UiModule} from "@qwyk/ui";

@NgModule({
    imports: [
        CommonModule,
        SharedStoresShipmentContainersModule.forFeature({
            service: {
                provide: ShipmentContainersServiceBase,
                useFactory: (http) => new ShipmentContainersService(http),
                deps: [HttpClient]
            }
        }),
        UiModule
    ],
    declarations: [ShipmentContainersViewComponent],
    exports: [ShipmentContainersViewComponent]
})
export class PortalsShipmentContainersModule {
}
