import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromAuthentication from './authentication.reducer';
import * as AuthenticationSelectors from './authentication.selectors';
import * as AuthenticationActions from './authentication.actions';
import { environment } from '@qwyk/hub/environment';

@Injectable()
export class AuthenticationFacade {
    authenticated$ = this.store.pipe(
        select(AuthenticationSelectors.getAuthenticationAuthenticated)
    );
    user$ = this.store.pipe(select(AuthenticationSelectors.getUser));
    userMeta$ = this.store.pipe(select(AuthenticationSelectors.getUserMeta));
    features$ = this.store.pipe(
        select(AuthenticationSelectors.getUserFeatures)
    );

    userAndToken$ = this.store.pipe(
        select(AuthenticationSelectors.getUserAndToken)
    );

    constructor(
        private store: Store<fromAuthentication.AuthenticationPartialState>
    ) {}

    public setup() {
        this.dispatch(AuthenticationActions.setupAuthentication());
    }

    public login() {
        this.dispatch(AuthenticationActions.login());
    }

    public logout() {
        this.dispatch(
            AuthenticationActions.logout({
                redirectTo: environment.url,
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public handleCallbackSuccess(token: any) {
        this.dispatch(
            AuthenticationActions.handleAuth0CallbackSuccess({ token })
        );
    }

    public resetImpersonation() {
        this.dispatch(AuthenticationActions.resetImpersonation());
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
