import {isArray} from 'rxjs/internal-compatibility';
import {HttpParams} from '@angular/common/http';

export abstract class AbstractMapper {
    public mapQuery(query: any) {
        this.baseMapping(query);
        return this.customMapping(query);
    }
    protected baseMapping(query: any): any {
        Object.keys(query).forEach(key => {
            if (query[key] === null) {
                delete query[key];
            } else if (isArray(query[key])) {
                query[`${key}[]`] = query[key];
                delete query[key];
            }
        });
    }
    protected abstract customMapping(query: any): HttpParams;
}
