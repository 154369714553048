import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SHIPMENTCONTAINERS_FEATURE_KEY,
    State,
    ShipmentContainersPartialState,
    shipmentContainersAdapter,
} from './shipment-containers.reducer';

// Lookup the 'ShipmentContainers' feature state managed by NgRx
export const getShipmentContainersState = createFeatureSelector<
    ShipmentContainersPartialState,
    State
>(SHIPMENTCONTAINERS_FEATURE_KEY);

const { selectAll, selectEntities } = shipmentContainersAdapter.getSelectors();

export const getShipmentContainersLoading = createSelector(
    getShipmentContainersState,
    (state: State) => state.loading
);

export const getShipmentContainersError = createSelector(
    getShipmentContainersState,
    (state: State) => state.error
);

export const getGeotrackLoading = createSelector(
    getShipmentContainersState,
    (state: State) => state.geotrackLoading
);

export const getGeotrackError = createSelector(
    getShipmentContainersState,
    (state: State) => state.geotrackError
);

export const getGeotrack = createSelector(
    getShipmentContainersState,
    (state: State) => state.geotrack
);


export const getAllShipmentContainers = createSelector(
    getShipmentContainersState,
    (state: State) => selectAll(state)
);

export const getShipmentContainersEntities = createSelector(
    getShipmentContainersState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getShipmentContainersState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getShipmentContainersEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);
