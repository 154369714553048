import { createReducer, Action } from '@ngrx/store';

export const initialState = {};

const reducer = createReducer(initialState);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appReducer(state: any | undefined, action: Action) {
    return reducer(state, action);
}
