import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [NgClass, NgbTooltipModule, TranslateModule, FontAwesomeModule],
    selector: 'qwyk-list-table-switcher',
    templateUrl: './list-table-switcher.component.html',
    styleUrls: ['./list-table-switcher.component.scss'],
})
export class ListTableSwitcherComponent {
    @Input() public view: 'list' | 'table';
    @Input() public class: string;
    @Output() public viewChange: EventEmitter<'list' | 'table'> =
        new EventEmitter<'list' | 'table'>();

    public setView(view: 'list' | 'table'): void {
        this.view = view;
        this.viewChange.emit(this.view);
    }
}
