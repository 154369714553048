import {
    CreateShipmentAttachmentRequest,
    ShipmentAttachment,
} from './+state/shipment-attachments.models';
import { Observable } from 'rxjs';

export abstract class ShipmentAttachmentsServiceBase {
    public abstract getShipmentAttachments(
        shipmentId: string
    ): Observable<ShipmentAttachment[]>;

    public abstract generateShipmentAttachmentLink(
        shipmentId: string,
        attachmentId: number
    ): Observable<string>;

    /**
     * Creates a new ShipmentAttachment for a given shipment(id)
     * @param shipmentId
     * @param request
     */
    public abstract createShipmentAttachment(
        shipmentId: string,
        request: CreateShipmentAttachmentRequest
    ): Observable<ShipmentAttachment>;

    public abstract deleteShipmentAttachment(
        shipmentId: string,
        attachmentId: number
    ): Observable<void>;
}
