import {
    Router,
    UrlTree,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AuthenticationFacade } from '../state/authentication.facade';

@Injectable({
    providedIn: 'root',
})
export class VerifyAllowedPermissions implements CanActivate, CanActivateChild {
    private permissions$ = this.authentication.permissions$;

    constructor(
        private router: Router,
        private authentication: AuthenticationFacade
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        // eslint-disable-next-line no-prototype-builtins
        if (!next.data || !next.data.hasOwnProperty('requiredPermissions')) {
            return true;
        }

        const requiredPermissions = next.data.requiredPermissions;
        return this.authentication.permissionsLoaded$.pipe(
            filter(permissionsLoaded => permissionsLoaded === true),
            switchMap(() =>
                this.permissions$.pipe(
                    take(1),
                    map(permissions => {
                        const allowed = requiredPermissions.every(p =>
                            permissions.includes(p)
                        );
                        if (!allowed) {
                            this.redirect(next, 403, 'Forbidden');
                        }
                        return allowed;
                    })
                )
            )
        );
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }

    private redirect(next: ActivatedRouteSnapshot, errorCode, errorName): void {
        this.router.navigate([`/error/${errorCode}`], {
            queryParams: {
                source: next.pathFromRoot.map(p => p.url).join('/'),
                errorName: errorName,
            },
        });
    }
}
