import { createAction, props } from '@ngrx/store';
import { Portals } from '@qwyk/models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Shipment } from '@qwyk/shared-stores/shipments';

export const init = createAction(
    '[Portals/Shipment/TrackingEvents] Init',
    props<{ shipment: Shipment }>()
);

export const loadShipmentTrackingEventsSuccess = createAction(
    '[Portals/Shipment/TrackingEvents] Load ShipmentTrackingEvents Success',
    props<{ shipmentTrackingEvents: Portals.ShipmentTrackingEvent[] }>()
);

export const loadShipmentTrackingEventsFailure = createAction(
    '[Portals/Shipment/TrackingEvents] Load ShipmentTrackingEvents Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const reset = createAction('[Portals/Shipments/TrackingEvents] Reset');
