import {
    ActivitiesEntity,
    ActivitiesIndexQuery,
    CommentActivityPayload,
    HasActivities
} from './+state/activities.models';
import {Observable} from 'rxjs';
import {common} from '@qwyk/models';

export abstract class ActivitiesServiceBase {
    public abstract loadActivities(parent: HasActivities, query: ActivitiesIndexQuery):
        Observable<common.CursorPaginatedResponse<ActivitiesEntity>>;

    public abstract createComment(parent: HasActivities, payload: CommentActivityPayload):
        Observable<ActivitiesEntity>;

    public abstract updateComment(parent: HasActivities, activity: ActivitiesEntity, payload: CommentActivityPayload):
        Observable<ActivitiesEntity>;

    public abstract deleteComment(parent: HasActivities, activity: ActivitiesEntity): Observable<void>
}
