import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { slideInRight } from './../../helpers/reusableAnimations';

@Component({
    selector: 'qw-welcome-modal',
    templateUrl: './welcome-modal.component.html',
    styleUrls: ['./welcome-modal.component.scss'],
    animations: [slideInRight],
})
export class WelcomeModalComponent {
    constructor(public modal: NgbActiveModal) {}

    public startTour() {
        this.modal.close('start-tour');
    }
}
