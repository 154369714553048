import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SHIPMENTATTACHMENTS_FEATURE_KEY,
    State,
    ShipmentAttachmentsPartialState,
    shipmentAttachmentsAdapter,
} from './shipment-attachments.reducer';

// Lookup the 'ShipmentAttachments' feature state managed by NgRx
export const getShipmentAttachmentsState = createFeatureSelector<
    ShipmentAttachmentsPartialState,
    State
>(SHIPMENTATTACHMENTS_FEATURE_KEY);

const { selectAll, selectEntities } = shipmentAttachmentsAdapter.getSelectors();

export const getShipmentAttachmentsLoading = createSelector(
    getShipmentAttachmentsState,
    (state: State) => state.loading
);

export const getShipmentAttachmentsError = createSelector(
    getShipmentAttachmentsState,
    (state: State) => state.error
);

export const getAllShipmentAttachments = createSelector(
    getShipmentAttachmentsState,
    (state: State) => selectAll(state)
);

export const getShipmentAttachmentsEntities = createSelector(
    getShipmentAttachmentsState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getShipmentAttachmentsState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getShipmentAttachmentsEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);

export const getAttachmentLinks = createSelector(
    getShipmentAttachmentsState,
    (state: State) => state.attachmentLinks
);
