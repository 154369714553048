<div [ngClass]="{'card': wrapInCard}">
    <div [ngClass]="{'card-body': wrapInCard}">
        <div class="row">

            <div [ngClass]="rowBreak" class="mb-4">
                <h5 class="font-weight-bold mb-3">Request</h5>
                <small class="text-muted font-weight-bold">MODE</small>
                <div class="mb-2">
                    <fa-icon [icon]="['fas', request.transport_mode | transportModeIcon]" [fixedWidth]="true"
                        class="text-muted mr-1">
                    </fa-icon>
                    {{ request.transport_mode | titlecase }}
                    {{ request.load_type | uppercase }}
                </div>

                <small class="text-muted font-weight-bold">FROM</small>
                <div class="mb-2">
                    <ng-container *ngTemplateOutlet="locationIcon, context: {$implicit: request.origin, od: 'o'}">
                    </ng-container>
                    {{ request.origin.name }}
                </div>
                <small class="text-muted font-weight-bold">TO</small>
                <div>
                    <ng-container *ngTemplateOutlet="locationIcon, context: {$implicit: request.destination, od: 'd'}">
                    </ng-container>
                    {{ request.destination.name }}
                </div>

                <hr class="my-2">

                <small class="text-muted font-weight-bold">COMMODITY</small>
                <div class="mb-2">
                    <fa-icon [icon]="['fas', 'pallet-alt']" [fixedWidth]="true" class="text-muted mr-1">
                    </fa-icon>
                    {{ request.commodity.description }}
                </div>
                <small class="text-muted font-weight-bold">PREFERRED CURRENCY</small>
                <div class="mb-2">
                    <fa-icon [icon]="['fas', 'dollar-sign']" [fixedWidth]="true" class="text-muted mr-1">
                    </fa-icon>
                    {{ request.currency }}
                </div>
                <small class="text-muted font-weight-bold">CARGO READY</small>
                <div>
                    <fa-icon [icon]="['fas', 'calendar']" [fixedWidth]="true" class="text-muted mr-1">
                    </fa-icon>
                    {{ request.cargo_ready || '' | amDateFormat: 'L' }}
                </div>
            </div>
            <!-- <hr> -->
            <div [ngClass]="rowBreak" class="mb-4">
                <h5 class="font-weight-bold mb-3">Cargo</h5>
                <div *ngFor="let package of request.packages">
                    <ng-container *ngIf="request.load_type === 'fcl'">
                        {{ package.quantity }} x {{ package.packaging.description }}
                    </ng-container>
                    <ng-container *ngIf="request.load_type === 'lcl'">
                        <div class="form-row mb-2">
                            <div class="col-6">
                                {{ package.quantity }} x {{ package.packaging.description }}
                            </div>
                            <div class="col-3 text-right text-underline-dashed"
                                [ngbTooltip]="(package.total_weight_kgs * 2.205 | number: '1.2-2') + ' lbs'">
                                {{ package.total_weight_kgs | number: '1.2-2'}} kgs
                            </div>
                            <div class="col-3 text-right text-underline-dashed"
                                [ngbTooltip]="(package.total_volume_cbm * 35.315 | number: '1.3-3') + ' cft'">
                                {{ package.total_volume_cbm | number: '1.3-3'}} cbm
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="form-row" *ngIf="request.load_type === 'lcl'">
                    <div class="col-6 font-weight-bold border-top pt-2">
                        {{ request.total_quantity }}
                    </div>
                    <div class="col-3 text-right text-underline-dashed font-weight-bold  border-top pt-2"
                        [ngbTooltip]="(request.total_weight_kgs * 2.205 | number: '1.2-2') + ' lbs'">
                        {{ request.total_weight_kgs | number: '1.2-2' }} kgs
                    </div>
                    <div class="col-3 text-right text-underline-dashed font-weight-bold border-top pt-2"
                        [ngbTooltip]="(request.total_volume_cbm * 35.315 | number: '1.3-3') + ' cft'">
                        {{ request.total_volume_cbm | number: '1.3-3' }} cbm
                    </div>
                </div>

                <div *ngIf="(request.additionals | objectToKVP).length > 0" class="mt-4">
                    <h5 class="font-weight-bold mb-3">Additionals</h5>
                    <div
                        [ngClass]="{'': request.require_origin_charges, 'text-muted': !request.require_origin_charges}">
                        <fa-icon [icon]="['far', request.require_origin_charges ? 'check' : 'times']" class="mr-1"
                            [ngClass]="{'text-success font-weight-bold': request.require_origin_charges, 'text-muted': !request.require_origin_charges}"
                            [fixedWidth]="true">
                        </fa-icon>
                        Origin charges required
                    </div>
                    <div
                        [ngClass]="{'': request.require_destination_charges, 'text-muted': !request.require_destination_charges}">
                        <fa-icon [icon]="['far', request.require_destination_charges ? 'check' : 'times']" class="mr-1"
                            [ngClass]="{'text-success font-weight-bold': request.require_destination_charges, 'text-muted': !request.require_destination_charges}"
                            [fixedWidth]="true">
                        </fa-icon>
                        Destination charges required
                    </div>
                    <ng-container *ngFor="let additional of request.additionals | objectToKVP">
                        <div [ngClass]="{'': additional.value, 'text-muted': !additional.value}">
                            <fa-icon [icon]="['far', additional.value ? 'check' : 'times']" class="mr-1"
                                [ngClass]="{'text-success font-weight-bold': additional.value, 'text-muted': !additional.value}"
                                [fixedWidth]="true">
                            </fa-icon>
                            {{ additional.key | slugToTitle }}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #locationIcon let-location let-od="od">
    <span class="text-muted mr-1">
        <ng-container *ngIf="location.type === 'port'">
            <ng-container [ngSwitch]="request.transport_mode">
                <fa-icon *ngSwitchCase="'AIR'" [icon]="['fas', od === 'o' ? 'plane-departure' : 'plane-arrival']"
                    [fixedWidth]="true">
                </fa-icon>
                <fa-icon *ngSwitchCase="'OCEAN'" [icon]="['fas', 'anchor']" [fixedWidth]="true"></fa-icon>
                <fa-icon *ngSwitchCase="'TRUCK'" [icon]="['fas', 'warehouse']" [fixedWidth]="true"></fa-icon>
                <fa-icon *ngSwitchDefault [icon]="['fas', 'map-pin']" [fixedWidth]="true"></fa-icon>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="location.type !== 'port'">
            <fa-icon [icon]="['far', 'map-pin']" [fixedWidth]="true"></fa-icon>
        </ng-container>
    </span>
</ng-template>
