<div class="modal-header border-bottom-0 px-4 pt-4 text-right d-flex align-items-center justify-content-between">
    <h3 class="m-0 font-weight-normal">{{ title }}</h3>
    <a (click)="modal.dismiss()" class="text-muted" href="javascript:void(0);">
        <fa-icon [icon]="['far', 'times']" size="lg"></fa-icon>
    </a>
</div>
<div class="modal-body px-4">
    <div #player_container>
    </div>
</div>
