/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';

import { environment } from '@qwyk/portals/environment';

import {
    Shipment,
    ShipmentActivity,
    ShipmentPlot,
} from './../store/models/shipments.models';

@Injectable({
    providedIn: 'root',
})
export class ShipmentsService {
    constructor(private http: HttpClient) {}

    /**
     * @deprecated
     * @param query
     * @param page_size
     * @param orderBy
     * @param onlyBooked
     * @param archive
     * @param onlyData
     */
    getShipments(
        query?,
        page_size?: number,
        orderBy?: { key: string; direction: 'asc' | 'desc' },
        onlyBooked?: boolean,
        archive?: boolean,
        onlyData = false
    ): Observable<any | any[]> {
        if (!query) {
            query = {};
        } else {
            query.query = 1;
        }

        query.summary = 1;

        if (page_size) {
            query.page_size = page_size;
        }

        if (orderBy) {
            query.sort = orderBy.key;
            query.direction = orderBy.direction;
        }

        if (onlyBooked) {
            query.booked = 1;
        }

        if (archive) {
            query.archive = 1;
        }

        const queryString = new HttpParams({ fromObject: query });

        return this.http
            .get<{ data: any[] }>(
                `${environment.backendServer}/api/portals/shipments?${queryString}`
            )
            .pipe(
                take(1),
                map(r => (onlyData ? r.data : r))
            );
    }

    /**
     * @deprecated
     */
    getShipmentsPlot(): Observable<ShipmentPlot[]> {
        return this.http
            .get<{ data: ShipmentPlot[] }>(
                `${environment.backendServer}/api/portals/shipments/plot`
            )
            .pipe(
                take(1),
                map(r => r.data)
            );
    }

    getShipment(shipmentId: string): Observable<Shipment> {
        return this.http
            .get<Shipment>(
                `${environment.backendServer}/api/portals/shipments/${shipmentId}`
            )
            .pipe(take(1));
    }

    /**
     * @deprecated
     * @param shipmentId
     * @param limit
     * @param offset
     */
    getShipmentActivity(
        shipmentId: string,
        limit?: number,
        offset?: number
    ): Observable<{
        data: ShipmentActivity[];
        meta: { total: number; remaining: number };
    }> {
        let query = new HttpParams();

        if (limit) {
            query = query.set('limit', limit.toString());
        }

        if (offset) {
            query = query.set('offset', offset.toString());
        }

        return this.http
            .get<{
                data: ShipmentActivity[];
                meta: { total: number; remaining: number };
            }>(
                `${
                    environment.backendServer
                }/api/portals/shipments/${shipmentId}/activity?${query.toString()}`
            )
            .pipe(take(1));
    }

    /**
     * @deprecated
     */
    getRecentSearches(): Observable<{ query: any; created_at: Date }[]> {
        return this.http
            .get<{ data: { query: any; created_at: Date }[] }>(
                `${environment.backendServer}/api/portals/users/queries/shipments`
            )
            .pipe(
                take(1),
                map(d => d.data)
            );
    }

    public uploadShipmentAttachment(
        shipmentId: string,
        data: { file: File; description?: string }
    ): Observable<any> {
        const payload = new FormData();
        payload.append('file', data.file, data.file.name);
        payload.append('description', data.description || '');

        return this.http
            .post<{ data: any }>(
                `${environment.backendServer}/api/portals/shipments/${shipmentId}/attachments`,
                payload
            )
            .pipe(
                take(1),
                map(d => d.data)
            );
    }

    /**
     * @deprecated
     * @param shipmentId
     * @param payload
     */
    postShipmentComment(
        shipmentId: string,
        payload: { data: { comment: string } }
    ): Observable<ShipmentActivity> {
        return this.http
            .post<ShipmentActivity>(
                `${environment.backendServer}/api/portals/shipments/${shipmentId}/activity/comment`,
                payload
            )
            .pipe(take(1));
    }

    deleteShipmentComment(shipmentId: string, activityId: string) {
        return this.http
            .delete<void>(
                `${environment.backendServer}/api/portals/shipments/${shipmentId}/activity/${activityId}`
            )
            .pipe(take(1));
    }

    /**
     * @deprecated
     * @param shipmentId
     */
    followShipment(shipmentId: string): Observable<void> {
        return this.http
            .post<void>(
                `${environment.backendServer}/api/portals/shipments/${shipmentId}/follow`,
                null
            )
            .pipe(take(1));
    }

    /**
     * @deprecated
     * @param shipmentId
     */
    unfollowShipment(shipmentId: string): Observable<void> {
        return this.http
            .delete<void>(
                `${environment.backendServer}/api/portals/shipments/${shipmentId}/follow`
            )
            .pipe(take(1));
    }

    getShipmentAttachmentLink(
        shipmentId: string,
        attachmentId: number
    ): Observable<string> {
        return this.http
            .get<{ data: any }>(
                `${environment.backendServer}/api/portals/shipments/${shipmentId}/attachments/${attachmentId}?download_link=1`
            )
            .pipe(
                take(1),
                map(d => d.data.download_link)
            );
    }

    public restoreDeletedBooking(id: string): Observable<Shipment> {
        return this.http
            .post<Shipment>(
                `${environment.backendServer}/api/portals/shipments/${id}/restore`,
                null
            )
            .pipe(take(1));
    }

    public cloneShipment(id: string, payload) {
        return this.http
            .post<Shipment>(
                `${environment.backendServer}/api/portals/shipments/${id}/clone`,
                payload
            )
            .pipe(first());
    }

    /**
     * @deprecated
     * @param shipmentId
     */
    downloadShipmentPdf(shipmentId: string): Observable<Blob> {
        return this.http
            .post(
                `${environment.backendServer}/api/portals/shipments/${shipmentId}/download`,
                null,
                { responseType: 'blob' }
            )
            .pipe(
                first(),
                map(response => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    });
                    return blob;
                })
            );
    }
}
