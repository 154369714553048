<div class="small text-muted mb-3 border-bottom pb-2">
    <a [routerLink]="['/my-portal']" class="text-muted">{{'portal.common.my-portal' | translate}}</a>
    &rsaquo;
    <a [routerLink]="['./']" class="text-muted font-weight-bold"
    >{{'common.quotations.quotations' | translate | titlecase}}</a
    >
</div>
<div class="d-flex justify-content-between align-items-center mb-3">
    <h3 class="font-weight-normal m-0">{{'portal.common.your-transaction' | translate: {
        value: 'common.quotations.quotations' | translate
    } }}</h3>
    <div>
        <div class="btn-group mr-2 d-none d-md-inline-block">
            <button [routerLink]="['/my-portal/reports/quotations']" class="btn btn-outline-primary">
                {{'portal.common.create-report' | translate}}
            </button>
        </div>
        <div *ngIf="allowCreateQuotations" class="btn-group">
            <button [routerLink]="['/']" class="btn btn-primary">
                {{'common.create' | translate}}
                <span class="d-none d-md-inline">
                    {{'common.quotations.quotation' | translate | titlecase}}
                </span>
            </button>
        </div>

    </div>
</div>

