<div class="d-flex align-items-center">
    <div class="flex-grow-0 mr-3 icon-container">
        <div class="icon-circle border rounded-circle text-muted bg-white">
            <fa-icon
                [fixedWidth]="true"
                [icon]="['far', 'clock']"
                size="lg"
            ></fa-icon>
        </div>
    </div>
    <div class="flex-grow-1" style="margin-top: 0.4em">
        <p class="m-0">
            <b [ngbTooltip]="shipmentActivity.user?.email">
                {{ shipmentActivity.user?.name || '@system' }}
            </b>
            <a [queryParams]="{ view: 'milestones' }" [routerLink]="[]">
                {{ 'portal.shipment.page.activities.updated-the-expected' | translate : { value: shipmentActivity.data.milestone_label } }}
            </a>
            {{ 'common.to_pagination' | translate | lowercase }}
            <b>
                {{
                    shipmentActivity.data.expected_timestamp
                        | amParseZone
                        | amDateFormat: 'L LT Z'
                }}
            </b>
            <span class="text-muted">
                {{ shipmentActivity.created_at | amTimeAgo }}
            </span>
        </p>
    </div>
</div>
