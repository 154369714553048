import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as ShipmentTrackingEventsActions from './shipment-tracking-events.actions';
import { map } from 'rxjs/operators';
import { ShipmentTrackingServiceBase } from '../shipmentTrackingServiceBase';

@Injectable()
export class ShipmentTrackingEventsEffects {
    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentTrackingEventsActions.init),
            fetch({
                run: action => {
                    return this.service
                        .getShipmentTrackingEvents(action.shipment.id)
                        .pipe(
                            map(shipmentTrackingEvents =>
                                ShipmentTrackingEventsActions.loadShipmentTrackingEventsSuccess(
                                    { shipmentTrackingEvents }
                                )
                            )
                        );
                },

                onError: (action, error) => {
                    console.error(error);
                    return ShipmentTrackingEventsActions.loadShipmentTrackingEventsFailure(
                        { error }
                    );
                },
            })
        )
    );

    constructor(
        private actions$: Actions,
        private service: ShipmentTrackingServiceBase
    ) {}
}
