import {Component, Input} from '@angular/core';
import {Portals} from '@qwyk/models';

@Component({
    selector: 'qwyk-quotations-index-list',
    templateUrl: './quotations-index-list.component.html',
    styleUrls: ['./quotations-index-list.component.scss']
})
export class QuotationsIndexListComponent {

    @Input() quotations: Portals.QuotationEntity[];
    @Input() loading: boolean;

}
