import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationsEntity } from './state/notifications.models';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';
import {
    debounceTime,
    filter,
    first,
    map,
    switchMap,
    take,
    withLatestFrom,
} from 'rxjs/operators';
import { environment } from '@qwyk/portals/environment';
import { Update } from '@ngrx/entity';
import { common } from '@qwyk/models';
import { ToastrService } from 'ngx-toastr';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';

@Injectable({
    providedIn: 'root',
})
export class PortalsNotificationsService {
    browserNotification$: BehaviorSubject<NotificationsEntity> =
        new BehaviorSubject<NotificationsEntity>(null);
    private design$ = this.siteConfig.design$;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private siteConfig: SiteConfigFacade
    ) {
        this.registerBrowserNotificationsHandler();
    }

    private static checkNotificationPromise(): boolean {
        try {
            Notification.requestPermission().then();
        } catch (e) {
            console.log(e);
            return false;
        }

        return true;
    }

    private static hasNotificationPermission(): Observable<boolean> {
        if (window.Notification && Notification.permission === 'granted') {
            return of(true);
        } else if (
            window.Notification &&
            Notification.permission !== 'denied'
        ) {
            let promise: Promise<'default' | 'denied' | 'granted'>;

            if (PortalsNotificationsService.checkNotificationPromise()) {
                promise = Notification.requestPermission();
            } else {
                // Non promise based notification (Safari)
                promise = new Promise<'default' | 'denied' | 'granted'>(
                    resolve =>
                        Notification.requestPermission(status =>
                            resolve(status)
                        )
                );
            }

            return from(promise).pipe(map(status => status === 'granted'));
        } else {
            return of(false);
        }
    }

    public getNotifications(
        page?: number
    ): Observable<common.PaginatedResponse<NotificationsEntity>> {
        let queryParams = new HttpParams();

        if (page) {
            queryParams = new HttpParams({
                fromObject: { page: page.toString() },
            });
        }

        return this.http
            .get<common.PaginatedResponse<NotificationsEntity>>(
                `${
                    environment.backendServer
                }/api/portals/notifications?${queryParams.toString()}`
            )
            .pipe(first());
    }

    public getUnreadNotificationsCount(): Observable<number> {
        return this.http
            .get<{ unread: number }>(
                `${environment.backendServer}/api/portals/notifications/unread`
            )
            .pipe(
                first(),
                map(response => response.unread)
            );
    }

    public toggleNotificationRead(
        notification: Update<NotificationsEntity>
    ): Observable<number> {
        return this.http
            .post<{ data: NotificationsEntity; unread: number }>(
                `${environment.backendServer}/api/portals/notifications/${
                    notification.id
                }/${notification.changes.read ? 'read' : 'unread'}`,
                null
            )
            .pipe(
                first(),
                map(response => response.unread)
            );
    }

    public deleteNotification(
        notification: NotificationsEntity
    ): Observable<number> {
        return this.http
            .delete<{ unread: number }>(
                `${environment.backendServer}/api/portals/notifications/${notification.id}`
            )
            .pipe(
                first(),
                map(response => response.unread)
            );
    }

    public showNotificationToast(notification: NotificationsEntity): void {
        let content = notification.subject;
        content += `<div><a href="/my-portal/notifications">View</a></div>`;
        this.toastr.info(content, 'New notification', {
            progressBar: true,
            enableHtml: true,
            closeButton: true,
        });
    }

    /**
     * Registers a handler for showing browser notifications.
     *
     * @private
     */
    private registerBrowserNotificationsHandler() {
        this.browserNotification$
            .pipe(
                debounceTime(2000), // Not too many notifications
                filter(notification => notification !== null), // Check if we actually have one
                switchMap(notification => {
                    // Get permissions
                    return forkJoin([
                        PortalsNotificationsService.hasNotificationPermission(),
                        of(notification),
                    ]).pipe(take(1));
                }),
                filter(value => value !== null && value[0] === true), // Only proceed if permission was given
                withLatestFrom(this.design$) // Get the design for styling
            )
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .subscribe(([[_, notification], design]) => {
                new Notification(notification.subject, {
                    icon: design.media.favicon_url,
                    badge: design.media.favicon_url,
                    image: design.media.logo_url_path,
                    tag: notification.id,
                    body: design.titles.page_title,
                }).onclick = function (e) {
                    e.preventDefault();
                    window.open('/my-portal/notifications'); // Open the notifications page
                };
            });
    }
}
