import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as SchedulesSearchActions from './schedules-search.actions';
import { Portals } from '@qwyk/models';

export const SCHEDULESSEARCH_FEATURE_KEY = 'schedulesSearch';

export interface State extends EntityState<Portals.Schedule> {
    selectedId?: string | number; // which SchedulesSearch record has been selected
    loaded: boolean; // has the SchedulesSearch list been loaded
    error?: string | null; // last none error (if any)
    query?: Portals.SchedulesSearchQuery;
    searching: boolean;
}

export interface SchedulesSearchPartialState {
    readonly [SCHEDULESSEARCH_FEATURE_KEY]: State;
}

export const schedulesSearchAdapter: EntityAdapter<Portals.Schedule> = createEntityAdapter<
    Portals.Schedule
>();

export const initialState: State = schedulesSearchAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    searching: false,
});

const schedulesSearchReducer = createReducer(
    initialState,
    on(SchedulesSearchActions.searchSchedules, (state, { query }) =>
        schedulesSearchAdapter.removeAll({
            ...state,
            loaded: false,
            searching: true,
            error: null,
            query,
        })
    ),
    on(SchedulesSearchActions.searchSchedulesSuccess, (state, { schedules }) =>
        schedulesSearchAdapter.setAll(schedules, {
            ...state,
            searching: false,
            loaded: true,
        })
    ),
    on(SchedulesSearchActions.searchSchedulesFailure, (state, { error }) => ({
        ...state,
        searching: false,
        error,
    })),
    on(SchedulesSearchActions.resetSearchResults, state => ({
        ...state,
        ...initialState,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return schedulesSearchReducer(state, action);
}
