/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as AuthenticationActions from './authentication.actions';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '@qwyk/hub/environment';
import * as Sentry from '@sentry/angular';

@Injectable()
export class AuthenticationEffects {
    setupAuthentication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.setupAuthentication),
            switchMap(_ =>
                this.service.setupLocalAuthentication().pipe(
                    first(),
                    map(result => {
                        console.debug(
                            '[Hub/Authentication$] Setup Authentication'
                        );
                        if (result) {
                            return of(
                                AuthenticationActions.setupAuthenticationSuccess(
                                    { token: result[1] }
                                )
                            );
                        } else {
                            const currentLocation = window.location.href;
                            const callbackUri =
                                environment.auth0.authorizationParams
                                    .redirect_uri;

                            if (
                                currentLocation.substr(
                                    0,
                                    callbackUri.length
                                ) !== callbackUri
                            ) {
                                return of(AuthenticationActions.login());
                            } else {
                                return of(
                                    AuthenticationActions.handleAuth0Callback()
                                );
                            }
                        }
                    }),
                    switchMap(action => action)
                )
            )
        )
    );

    setupAuthenticationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthenticationActions.setupAuthenticationSuccess,
                AuthenticationActions.handleAuth0CallbackSuccess
            ),
            tap(action => {
                console.debug(
                    '[Hub/Authentication$] Setup Authentication Success'
                );
                localStorage.setItem('id_token', action.token);
            }),
            switchMap(_ => of(AuthenticationActions.loadUser()))
        )
    );

    handleAuth0Callback$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.handleAuth0Callback),
            switchMap(_ =>
                this.service.handleAuth0Callback().pipe(
                    first(),
                    map(result => {
                        console.debug(
                            '[Hub/Authentication$] Handle Auth0 Callback'
                        );
                        if (result[2]) {
                            return AuthenticationActions.handleAuth0CallbackSuccess(
                                { token: result[1] }
                            );
                        } else {
                            return AuthenticationActions.login();
                        }
                    })
                )
            )
        )
    );

    handleAuth0CallbackSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.handleAuth0CallbackSuccess),
                tap(_ => {
                    console.debug(
                        '[Hub/Authentication$] Handle Auth0 Callback Success'
                    );
                })
            ),
        { dispatch: false }
    );

    loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.loadUser),
            tap(_ => console.debug('[Hub/Authentication$] Load User')),
            switchMap(_ => {
                return this.service.loadUser().pipe(
                    map(user => AuthenticationActions.loadUserSuccess(user)),
                    catchError(() =>
                        of(AuthenticationActions.loadUserFailure())
                    )
                );
            })
        )
    );

    loadUserSuccess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.loadUserSuccess),
                tap(action => {
                    console.debug('[Hub/Authentication$] Load User Success');
                    Sentry.setUser({
                        id: action.user.id,
                        email: action.user.email,
                        username: action.user.email,
                        organization_id: action.user.organization_id,
                        name: action.user.name,
                    });
                })
            ),
        { dispatch: false }
    );

    loadUserFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.loadUserFailure),
            tap(_ => console.debug('[Hub/Authentication$] Load User Failure')),
            switchMap(_ =>
                of(
                    AuthenticationActions.logout({
                        redirectTo: environment.url,
                    })
                )
            )
        )
    );

    login$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.login),
                tap(_ => {
                    console.debug('[Hub/Authentication$] Login');
                    this.service.login();
                })
            ),
        { dispatch: false }
    );

    logout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.logout),
                tap(action => {
                    console.debug('[Hub/Authentication$] Logout');
                    this.service.logout(action.redirectTo);
                })
            ),
        { dispatch: false }
    );

    resetImpersonation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.resetImpersonation),
                switchMap(() => {
                    return this.service.resetImpersonation();
                }),
                map(_ => {
                    window.location.reload();
                }),
                catchError(() => {
                    alert('Something went wrong, contact an admin.');
                    return null;
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private service: AuthenticationService,
        private injector: Injector
    ) {}
}
