import { Pipe, PipeTransform } from '@angular/core';

interface Extension {
    [index: string]: string;
}

@Pipe({
    name: 'fileExtensionIcon',
})
export class FileExtensionIconPipe implements PipeTransform {
    private MAPPING: Extension = {
        pdf: 'file-pdf',
        xls: 'file-excel',
        xlsx: 'file-excel',
        doc: 'file-word',
        docx: 'file-word',
        csv: 'file-csv',
        txt: 'file-alt',
    };

    private DEFAULT = 'file';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    transform(value: string, ...args: any[]): string {
        if (this.MAPPING[value]) {
            return this.MAPPING[value];
        }
        return this.DEFAULT;
    }
}
