import { Injectable } from '@angular/core';
import { Portals } from '@qwyk/models';

export abstract class ScriptsInjector {
    /**
     * Loops through an array of scripts and creates elements for them
     * @param scripts the scripts to injects
     */
    abstract inject(script: Portals.SiteInjectableScript[]): void;

    /**
     * Removes all injected scripts
     */
    abstract reset(): void;
}

/**
 * Injects custom scripts defined in Sites into the HTML of the site
 * To load Chat widgets for instance or custom tracking scripts.
 */
@Injectable()
export class BrowserScriptsInjector implements ScriptsInjector {
    private ID_PREFIX = 'qwyk-injected-script-';
    private loadedScripts = [];

    /**
     * Loops through an array of scripts and creates elements for them
     * @param scripts the scripts to injects
     */
    public inject(scripts: Portals.SiteInjectableScript[]): void {
        this.loadedScripts = scripts.map((script, index) =>
            this.createScriptElement(script, `${this.ID_PREFIX}${index}`)
        );
    }

    /**
     * Injects a script into the page
     * @param script The script to create
     * @param scriptId The ID to give the script element
     * @returns string the ID
     */
    private createScriptElement(
        script: Portals.SiteInjectableScript,
        scriptId: string
    ): string {
        try {
            this.removeNode(scriptId);

            const scriptElement = document.createElement('script');
            scriptElement.setAttribute('id', scriptId);
            ['type', 'defer', 'async'].forEach(prop => {
                if (script[prop]) {
                    scriptElement.setAttribute(prop, script[prop]);
                }
            });

            if (script.is_href) {
                scriptElement.setAttribute('src', script.script);
            } else {
                scriptElement.append(script.script);
            }

            document.body.appendChild(scriptElement);

            return scriptId;
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Removes all injected scripts
     */
    public reset(): void {
        this.loadedScripts.forEach(scriptId => this.removeNode(scriptId));
        this.loadedScripts = [];
    }

    /**
     * Removes an injected script based on its ID.
     * @param scriptId the id of the script to remove
     */
    private removeNode(scriptId: string): void {
        const scriptElement = document.body.querySelector(`#${scriptId}`);

        if (scriptElement) {
            document.body.removeChild(scriptElement);
        }
    }
}
