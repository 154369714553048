<div class="page-wrapper bg-light">
    <div class="page-container overflow-auto" [ngClass]="{'has-results': (schedules$ | async)?.length > 0}">
        <div class="row">
            <div class="col-12 col-xxxl-10 offset-xxxl-1">
                <h2 class="font-weight-normal">{{ 'portal.menu.side-menu.schedules' | translate }}</h2>
                <h6 class="font-weight-light">{{ 'portal.schedules.search.after-title-message' | translate }}</h6>
                <hr>
                <qwyk-schedule-search></qwyk-schedule-search>
            </div>
        </div>
    </div>
</div>
