import { Component, ViewChild } from '@angular/core';

import Player from '@vimeo/player';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [FontAwesomeModule],
    selector: 'qwyk-vimeo-player-modal',
    templateUrl: './vimeo-player-modal.component.html',
    styleUrls: ['./vimeo-player-modal.component.scss'],
})
export class VimeoPlayerModalComponent {
    // @HostBinding('class') _class = 'h-100 d-flex flex-column';
    @ViewChild('player_container', { static: true }) playerContainer: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        nativeElement: any;
    };
    public title: string | null = null;
    private player: Player;

    constructor(public modal: NgbActiveModal) {}

    public playVideo(id: number): void {
        this.player = new Player(this.playerContainer.nativeElement, {
            id,
            autoplay: true,
            responsive: true,
        });

        this.player
            .getVideoTitle()
            .then((title: string | null) => (this.title = title));
    }
}
