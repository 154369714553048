import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from '../dashboard.state';

const selectFeature = createFeatureSelector<DashboardState>('dashboard');

export const selectShipmentsCount = createSelector(
    selectFeature,
    (state: DashboardState) => state.data.shipmentsCount
);

export const selectSchedulesCount = createSelector(
    selectFeature,
    (state: DashboardState) => state.data.schedulesCount
);

export const selectQuotationsCount = createSelector(
    selectFeature,
    (state: DashboardState) => state.data.quotationsCount
);

export const selectShipmentsList = createSelector(
    selectFeature,
    (state: DashboardState) => state.data.shipmentsList
);

export const selectSchedulesList = createSelector(
    selectFeature,
    (state: DashboardState) => state.data.schedulesList
);
