import { ImprovedDashboardComponent } from './components/improved-dashboard/improved-dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    { path: 'dashboard', component: ImprovedDashboardComponent },
    // {
    //     path: 'dashboard/v2',
    //     component: ImprovedDashboardComponent,
    // },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
