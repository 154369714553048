import { createAction, props } from '@ngrx/store';

export const loadDashboardData = createAction(
    '[Dashboard] Load Dashboard Data'
);

export const loadOpsData = createAction('[Dashboard] Load Ops Data');

export const shipmentsCountLoaded = createAction(
    '[Dashboard] Shipments Count Loaded',
    props<{
        result: {
            active: number;
            drafts: number;
            overdue: number;
            upcoming: number;
        };
    }>()
);

export const schedulesCountLoaded = createAction(
    '[Dashboard] Schedules Count Loaded',
    props<{ result: { active: number } }>()
);

export const quotationsCountLoaded = createAction(
    '[Dashboard] Quotations Count Loaded',
    props<{ result: { active: number } }>()
);

export const shipmentsListLoaded = createAction(
    '[Dashboard] Shipments List Loaded',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ result: any }>()
);
