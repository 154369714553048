<div class="vh-100 bg-light d-flex justify-content-center align-items-center">
    <div>
        <div class="text-center">
            <img height="80" src="https://cdn.qwyk.io/images/newlogos/PNG/magaya-g-r.png"/>
        </div>
        <div class="card shadow mt-3 border-0" style="min-width: 400px">
            <ng-container *ngIf="params$ | async; let params">
                <div class="card-body text-center p-5" *ngIf="!params.error">
                    <!-- <img class="mb-3" style="width: 80%;" src="https://cdn.qwyk.io/images/newlogos/SVG/logo.svg"> -->
                    <div class="my-5">
                        <fa-icon [icon]="['far', 'spinner']" pulse="true" size="4x" class="text-primary"></fa-icon>
                    </div>
                    <h3 class="m-0 mb-2 font-weight-normal">You've been logged in</h3>
                    <p>Redirecting you to the application...</p>
                    <button *ngIf="showRedirectButton" class="btn btn-primary">Click here if you're not automatically
                        redirected</button>
                </div>
                <div class="card-body text-center p-5" *ngIf="params.error">
                    <h3 class="m-0">Unable to log you in</h3>
                    <div class="my-5">
                        <!-- <fa-icon [icon]="['far', 'exclamation-triangle']" size="3x" class="text-danger"></fa-icon> -->
                    </div>
                    <h5 class="m-0">{{ params.error | titlecase }}</h5>
                    <p>{{ params.error_description | titlecase }}</p>
                    <a class="btn btn-link" href="/">Try again</a>
                </div>
            </ng-container>
        </div>
    </div>
</div>
