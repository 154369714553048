<div
    class="btn-group "
    [ngClass]="class ?? 'btn-group-sm mr-2'"
>
    <div
        (click)="setView('list')"
        [ngClass]="view === 'list' ? 'btn-primary' : 'btn-outline-primary'"
        class="btn"
        ngbTooltip="{{ 'common.list' | translate }}"
    >
        <fa-icon [icon]="['far', 'list']"></fa-icon>
    </div>
    <div
        (click)="setView('table')"
        [ngClass]="view === 'table' ? 'btn-primary' : 'btn-outline-primary'"
        class="btn"
        ngbTooltip="{{ 'common.table' | translate }}"
    >
        <fa-icon [icon]="['far', 'table']"></fa-icon>
    </div>
</div>
