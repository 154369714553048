/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { switchMap, first } from 'rxjs/operators';
import { environment } from '@qwyk/portals/environment';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class ResetPasswordService {
    constructor(
        private http: HttpClient,
        private siteConfig: SiteConfigFacade
    ) {}

    public requestPasswordReset(email: string): Observable<any> {
        return this.siteConfig.organization$.pipe(
            switchMap(organization =>
                this.http
                    .post<any>(
                        `${environment.backendServer}/api/portals/auth/forgot-password`,
                        { ...organization, email }
                    )
                    .pipe(first())
            )
        );
    }

    public resetPassword(payload: any): Observable<any> {
        return this.siteConfig.organization$.pipe(
            switchMap(organization =>
                this.http
                    .post<any>(
                        `${environment.backendServer}/api/portals/auth/reset-password`,
                        { ...organization, ...payload }
                    )
                    .pipe(first())
            )
        );
    }
}
