import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';

@Injectable({
    providedIn: 'root',
})
export class SideBarService {
    private isCollapsed = false;
    private isCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public collapsed$ = this.isCollapsed$.asObservable();
    private isSmall = false;
    private readonly defaultBackground = 'bg-light';

    constructor(private breakpointObserver: BreakpointObserver) {
        breakpointObserver.observe(['(max-width: 768px)']).subscribe(small => {
            if (small.matches && !this.isSmall) {
                this.collapse();
            }
            this.isSmall = small.matches;
        });
    }

    private _background = 'bg-light';

    public get background() {
        return this._background;
    }

    public set background(background: string) {
        this._background = background;
    }

    public get collapsed() {
        return this.isCollapsed;
    }

    public toggle() {
        this.isCollapsed = !this.isCollapsed;
        this.isCollapsed$.next(this.isCollapsed);
    }

    public collapse() {
        this.isCollapsed = true;
        this.isCollapsed$.next(this.isCollapsed);
    }

    public open() {
        this.isCollapsed = false;
        this.isCollapsed$.next(this.isCollapsed);
    }

    public setCollapsed(collapsed: boolean) {
        this.isCollapsed = collapsed;
        this.isCollapsed$.next(this.isCollapsed);
    }

    public setDefaultBackgroundColor(): void {
        this.background = this.defaultBackground;
    }
}
