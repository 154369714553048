<div class="vh-100 p-5">
    <ng-container *ngIf="queryParams$ | async; let queryParams">
        <div class="container">
            <div class="lead border-bottom pb-3 text-muted">
                <fa-icon [icon]="['fad', 'exclamation-triangle']" class="mr-3 text-warning" size="lg"></fa-icon>We
                couldn't
                load this portal
            </div>
            <div class="inner border-bottom">
                <div class="my-5">
                    <h5>What went wrong?</h5>
                    <p class="text-muted" *ngIf="!queryParams.has('reason-code')">
                        The portal at the requested address
                        may not exist, or it may be unavailable at this time.
                    </p>
                    <p class="text-muted" *ngIf="queryParams.get('reason-code') === 'trial-expired'">
                        The trial period for the portal at this address has expired.
                    </p>
                </div>
                <div class="pb-5">
                    <h5>What can I do?</h5>
                    <ul class="text-muted" style="padding-inline-start: 1em;">
                        <ng-container *ngIf="!queryParams.has('reason-code')">
                            <li>Check the address in your browser's addressbar.</li>
                            <li>Press the back button on your browser.</li>
                            <li><a href="/">Return to the home page.</a></li>
                        </ng-container>
                        <ng-container *ngIf="queryParams.get('reason-code') === 'trial-expired'">
                            <li>
                                If you are the owner of this portal, contact <a
                                    href="mailto:sales@qwyk.io">sales@qwyk.io</a> to activate your subscription.
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </ng-container>
</div>