import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { MomentModule } from 'ngx-moment';
import { EffectsModule } from '@ngrx/effects';
import { CalendarModule } from 'primeng/calendar';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectModule } from 'primeng/multiselect';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UiModule } from '@qwyk/ui';
import {
    PaginationComponent,
    BreadcrumbsComponent,
    LoadingIndicatorComponent,
    ScheduleTimelineComponent,
} from '@qwyk/ui-standalone';

import {
    ScheduleSearchComponent,
    SchedulesFilterComponent,
    ScheduleListItemComponent,
    SchedulesSearchContainerComponent,
} from './components';

import { SchedulesSearchFacade } from './state/schedules-search.facade';
import * as fromSchedulesSearch from './state/schedules-search.reducer';
import { SchedulesSearchEffects } from './state/schedules-search.effects';

@NgModule({
    declarations: [
        ScheduleSearchComponent,
        SchedulesFilterComponent,
        ScheduleListItemComponent,
        SchedulesSearchContainerComponent,
    ],
    imports: [
        UiModule,
        NgbModule,
        FormsModule,
        CommonModule,
        MomentModule,
        RouterModule,
        CalendarModule,
        TranslateModule,
        FontAwesomeModule,
        MultiSelectModule,
        ReactiveFormsModule,
        PaginationComponent,
        BreadcrumbsComponent,
        LoadingIndicatorComponent,
        ScheduleTimelineComponent,
        StoreModule.forFeature(
            fromSchedulesSearch.SCHEDULESSEARCH_FEATURE_KEY,
            fromSchedulesSearch.reducer
        ),
        EffectsModule.forFeature([SchedulesSearchEffects]),
    ],
    providers: [SchedulesSearchFacade],
    exports: [
        ScheduleSearchComponent,
        ScheduleListItemComponent,
        SchedulesFilterComponent,
    ],
})
export class PortalsSchedulesSearchWithoutRoutingModule {}
