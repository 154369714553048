<div class="modal-body p-3 p-xs-4 p-md-5 bg-white shadow-sm rounded text-center text-lg-left"
    *ngIf="design$ | async; let design;">
    <div class="row align-items-stretch">
        <div
            class="col-12 col-lg-6 pr-lg-5 d-flex flex-column justify-content-center pb-3 pb-lg-0"
        >
            <!-- <img [src]="design.media.logo_url_path" class="modal-body-logo mb-4" alt="Site Logo" /> -->
            <h4 class="font-weight-normal mb-3">
                <span [translate]="'portal.login-modal.title-prefix'">Welcome to</span> -
                <span [innerHtml]="design.titles.navbar_brand"></span>
            </h4>
            <p [translate]="'portal.login-modal.subtitle'" class="lead">We make it simple to ship cargo - with online
                quoting, booking, shipment
                tracking, and
                more.
            </p>
            <div class="d-none d-lg-block" style="font-weight: 300;">
                <span [translate]="'portal.login-modal.no-account-prefix'">Don't have a free account yet?</span>
                <a [routerLink]="['/auth', 'register']" target="_blank"
                   class="text-primary font-weight-bold text-underline">
                    <fa-icon [icon]="['far', 'rocket']" class="mr-1"></fa-icon>
                    <span [translate]="'portal.login-modal.no-account-signup'">Sign up now</span>
                </a>
                <span [translate]="'portal.login-modal.no-account-suffix'">and we will get you set up in no time.</span>
            </div>
            <div *ngIf="allowContinueWithoutLogin" class="text-left mt-5 d-none d-lg-block">
                <button class="btn btn-primary" (click)="cancelDialog('continue-without-login')">
                    Get a demo quote without logging in
                    <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
                </button>
                <div>You won't be able to create a booking or do anything else until you've registered for an account
                    and are logged in.</div>
            </div>
        </div>
        <div class="col-12 d-lg-none border-bottom mb-4"></div>
        <div
            class="col-12 col-lg-6 pl-lg-5 d-flex flex-column justify-content-center"
        >
            <ng-container
                *ngIf="authenticationType$ | async; let authenticationType"
            >
                <qwyk-login-form
                    (completed)="closeDialog()"
                    (navigate)="onNavigationRequested($event)"
                    *ngIf="component === 'login'"
                    [authenticationType]="authenticationType"
                    [data]="navigationData"
                    [design]="design"
                    [isMagayaNetwork]="isMagayaNetwork$ | async"
                    [siteConfiguration]="siteConfiguration$ | async"
                >
                </qwyk-login-form>
            </ng-container>

            <qwyk-request-password-reset-form
                (navigate)="onNavigationRequested($event)"
                *ngIf="component === 'request-password-reset'"
                [data]="navigationData"
            ></qwyk-request-password-reset-form>

            <qwyk-reset-password-form
                (navigate)="onNavigationRequested($event)"
                *ngIf="component === 'reset-password'"
                [data]="navigationData"
                [isMagayaNetwork]="isMagayaNetwork$ | async"
            >
            </qwyk-reset-password-form>

            <qwyk-resend-confirmation-email
                (navigate)="onNavigationRequested($event)"
                *ngIf="component === 'resend-confirmation-email'"
            ></qwyk-resend-confirmation-email>
        </div>
    </div>
    <div class="d-block d-lg-none mt-4 mt-lg-0" style="font-weight: 300;">
        <span [translate]="'portal.login-modal.no-account-prefix'">Don't have a free account yet?</span>
        <a [routerLink]="['/auth/register']" class="text-primary font-weight-normal" (click)="cancelDialog()">
            <fa-icon [icon]="['far', 'rocket']" class="mr-1"></fa-icon>
            <span [translate]="'portal.login-modal.no-account-signup'">Sign up now</span>
        </a>
        <span [translate]="'portal.login-modal.no-account-suffix'">and we will get you set up in no time.</span>
    </div>
    <div
        *ngIf="allowContinueWithoutLogin"
        class="text-center mt-4 d-block d-lg-none"
    >
        <button
            (click)="cancelDialog('continue-without-login')"
            class="btn btn-primary"
        >
            Get a demo quote without logging in
            <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
        </button>
        <div>
            You won't be able to create a booking or do anything else until
            you've registered for an account and are logged in.
        </div>
    </div>
</div>
