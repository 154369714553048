<button
    (click)="onToggleFullscreen()"
    *ngIf="canFullscreen"
    [disabled]="disabled"
    [ngClass]="buttonClass"
    class="btn">
    <fa-icon [icon]="['far', !isFullScreen ? 'expand' : 'compress']"></fa-icon>
    <span *ngIf="textLabel" class="ml-2">
        {{ (!isFullScreen ? 'portal.shipment.page.map.fullscreen' : 'portal.shipment.page.map.exit-fullscreen') | translate}}
    </span>
</button>
