import { Injectable } from '@angular/core';

import { ActionsSubject, select, Store } from '@ngrx/store';

import { common } from '@qwyk/models';

import { CustomViewsActions } from './custom-views.actions';
import * as CustomViewsSelectors from './custom-views.selectors';

@Injectable()
export class CustomViewsFacade {
    /**
     * Combine pieces of state using createSelector,
     * and expose them as observables through the facade.
     */
    loading$ = this.store.pipe(
        select(CustomViewsSelectors.getCustomViewsLoading)
    );

    allCustomViews$ = this.store.pipe(
        select(CustomViewsSelectors.getAllCustomViews)
    );

    selectedView$ = this.store.pipe(select(CustomViewsSelectors.getSelected));

    error$ = this.store.pipe(select(CustomViewsSelectors.getCustomViewsError));

    constructor(
        private store: Store,
        private actionsListener$: ActionsSubject
    ) {}

    /**
     * Use the initialization action to perform one
     * or more tasks in your Effects.
     */
    init() {
        this.store.dispatch(CustomViewsActions.init());
    }

    public addNewCustomView(parent: string, request: common.CustomView) {
        this.store.dispatch(
            CustomViewsActions.createCustomView({ parent, request })
        );
    }

    public updateCustomView(
        parent: string,
        request: Partial<common.CustomView>
    ) {
        this.store.dispatch(
            CustomViewsActions.updateCustomView({ parent, request })
        );
    }

    public deleteCustomView(parent: string, request: common.CustomView) {
        this.store.dispatch(
            CustomViewsActions.deleteCustomView({ parent, request })
        );
    }

    public setSelectedView(uid: string) {
        this.store.dispatch(CustomViewsActions.setSelectedView({ uid }));
    }

    public reset() {
        this.store.dispatch(CustomViewsActions.reset());
    }
}
