import { NgModule, Provider, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromInvoices from './state/invoices.reducer';
import { InvoicesEffects } from './state/invoices.effects';
import { InvoicesFacade } from './state/invoices.facade';
import { InvoiceExistsGuard } from './guards/invoice-exists.guard';

export interface SharedStoresInvoicesConfig {
    service: Provider;
}

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromInvoices.INVOICES_FEATURE_KEY,
            fromInvoices.reducer
        ),
        EffectsModule.forFeature([InvoicesEffects]),
    ],
    providers: [InvoicesFacade, InvoiceExistsGuard],
})
export class SharedStoresInvoicesModule {
    static forFeature(
        config: SharedStoresInvoicesConfig
    ): ModuleWithProviders<SharedStoresInvoicesModule> {
        return {
            ngModule: SharedStoresInvoicesModule,
            providers: [config.service],
        };
    }
}
