<div class="min-vh-100 bg-light">
    <div class="container py-3">
        <ng-container *ngIf="quotation$| async; let quotation; else: loading">
            <qwyk-quotation-detail-header
                    (download)="onDownloadQuotation($event)"
                    [loading]="loading$ | async"
                    [quotation]="quotation"
            ></qwyk-quotation-detail-header>
            <div *ngIf="error$ | async; let error">
                <div class="pt-3">
                    <ngb-alert (closed)="onDismissError()" type="danger">
                        {{'common.generic-error-alt' | translate}}
                    </ngb-alert>
                </div>
            </div>
            <div *ngIf="view$ | async; let view;" class="mt-3">
                <qwyk-quotation-detail-navs
                        [currentView]="view"
                        [quotation]="quotation"
                ></qwyk-quotation-detail-navs>
                <div [ngSwitch]="view" class="mt-4">
                    <ng-container *ngSwitchCase="'summary'">
                        <qwyk-detail-card cardClass="mb-4">
                            <ng-template qwykDetailCardBody>
                                <div class="card-body p-4">
                                    <qwyk-quotation-summary
                                            [quotation]="quotation">
                                    </qwyk-quotation-summary>
                                </div>
                            </ng-template>
                        </qwyk-detail-card>
                    </ng-container>
                    <ng-container *ngSwitchCase="'cargo'">
                        <qwyk-detail-card
                            cardClass="mb-4"
                            caption="{{'portal.common.cargo_items' | translate}}"
                        >
                            <ng-template qwykDetailCardBody>
                                <qwyk-quotation-packages-list
                                        [quotation]="quotation"
                                ></qwyk-quotation-packages-list>
                            </ng-template>
                        </qwyk-detail-card>
                    </ng-container>
                    <ng-container *ngSwitchCase="'requests'">
                        <qwyk-detail-card
                            cardClass="mb-4"
                            caption="{{'common.quotations.page.navs.rate-requests' | translate}}"
                        >
                            <ng-template qwykDetailCardBody>
                                <qwyk-quotation-rate-request-list
                                        [quotation]="quotation"
                                ></qwyk-quotation-rate-request-list>
                            </ng-template>
                        </qwyk-detail-card>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #loading>
    <div class="text-center text-muted">
        <fa-icon [icon]="['far', 'spinner']" [pulse]="true"></fa-icon>
        <p>Your quotation is being loaded...</p>
    </div>
</ng-template>
