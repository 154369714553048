<div class="card search-card border-0" style="background-color: transparent !important;">
    <div class="card-body px-0">
        <form [formGroup]="searchForm" (submit)="search()">
            <div class="form-row">
                <div class="form-group col">
                    <qwyk-location-typeahead
                        formControlName="origin"
                        placeholder="{{ 'common.quotations.search.origin' | translate }}"
                        [size]="controlSize"
                        labelProperty="display_name"
                        (inputChanged)="onAutocomplete($event)"
                        [suggestions]="locationsSuggestions"
                        (selectedItemChanged)="locationSelected('origin', $event)"
                        [searching]="isSearchingLocations"
                        [invalid]="searchForm.get('origin').invalid && searchForm.get('origin').touched">
                        <ng-template qwykLocationTypeaheadItem let-item="item">
                            <div>
                                <span [innerHTML]="item._highlightResult.display_name.value"></span>
                                (<span [innerHTML]="item._highlightResult.locode.value"></span>)
                            </div>
                            <small>
                                <span [innerHTML]="item._highlightResult.country_name.value"></span>
                            </small>
                        </ng-template>
                        <ng-template qwykLocationTypeaheadPlaceholder let-item="item">
                            <div>
                                <span>{{ item.display_name }}</span>
                                ({{item.locode}})</div>
                            <small>{{ item.country_name }}</small>
                        </ng-template>
                    </qwyk-location-typeahead>
                    <small class="text-danger"
                        *ngIf="searchForm.get('origin').invalid && searchForm.get('origin').touched">
                        {{ 'portal.schedules.search.select-a-location' | translate }}
                    </small>
                </div>
                <div [@fadeIn] *ngIf="searchForm.valid" class="form-group col-auto align-self-center">
                    <button class="btn btn-outline-secondary btn-sm" type="button" (click)="flipLocations()">
                        <fa-icon [icon]="['far', 'retweet']"></fa-icon>
                    </button>
                </div>

                <div class="form-group col">
                    <qwyk-location-typeahead
                        formControlName="destination"
                        placeholder="{{ 'common.quotations.search.destination' | translate }}"
                        labelProperty="display_name"
                        (inputChanged)="onAutocomplete($event)"
                        [size]="controlSize"
                        [suggestions]="locationsSuggestions"
                        (selectedItemChanged)="locationSelected('destination', $event)"
                        [searching]="isSearchingLocations"
                        [invalid]="searchForm.get('destination').invalid && searchForm.get('destination').touched">
                        <ng-template qwykLocationTypeaheadItem let-item="item">
                            <div>
                                <span [innerHTML]="item._highlightResult.display_name.value"></span>
                                (<span [innerHTML]="item._highlightResult.locode.value"></span>)
                            </div>
                            <small>
                                <span [innerHTML]="item._highlightResult.country_name.value"></span>
                            </small>
                        </ng-template>
                        <ng-template qwykLocationTypeaheadPlaceholder let-item="item">
                            <div>
                                <span>{{ item.display_name }}</span>
                                ({{ item.locode }})
                            </div>
                            <small>{{ item.country_name }}</small>
                        </ng-template>
                    </qwyk-location-typeahead>
                    <small class="text-danger"
                        *ngIf="searchForm.get('destination').invalid && searchForm.get('destination').touched">
                        {{ 'portal.schedules.search.select-a-location' | translate }}
                    </small>
                </div>

                <div class="form-group col-auto">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn-outline-secondary" ngbTooltip="FCL"
                            [ngClass]="{'btn-lg': controlSize === 'lg'}"
                            [ngClass]="searchForm.get('product').value === 'fcl' ? 'active' : ''"
                        >
                            <input type="radio" value="fcl" formControlName="product">
                            <fa-icon [icon]="['far', 'container-storage']" class="mr-2"></fa-icon>
                        </label>
                        <label class="btn btn-outline-secondary" ngbTooltip="LCL"
                            [ngClass]="{'btn-lg': controlSize === 'lg'}"
                               [ngClass]="searchForm.get('product').value === 'csl' ? 'active' : ''"
                        >
                            <input type="radio" value="csl" formControlName="product">
                            <fa-icon [icon]="['far', 'pallet']" class="mr-2"></fa-icon>
                        </label>
                        <label class="btn btn-outline-secondary" ngbTooltip="{{ 'common.air' | translate }}"
                            [ngClass]="{'btn-lg': controlSize === 'lg'}"
                               [ngClass]="searchForm.get('product').value === 'air' ? 'active' : ''"
                        >
                            <input type="radio" value="air" formControlName="product">
                            <fa-icon [icon]="['far', 'plane']" class="mr-2"></fa-icon>
                        </label>
                    </div>
                    <div>
                        <small class="text-danger"
                            *ngIf="searchForm.get('product').invalid && searchForm.get('product').touched">
                            {{ 'portal.schedules.search.select-a-modality' | translate }}
                        </small>
                    </div>
                </div>

                <div class="form-group col-auto mb-0">
                    <button class="btn btn-primary" type="submit" [disabled]="searchForm.disabled"
                        [ngClass]="{'btn-lg': controlSize === 'lg'}">{{ 'common.search' | translate }}</button>
                </div>
            </div>
        </form>
    </div>

    <div class="alert alert-info " *ngIf="linkToBookingId">
        <h4 class="alert-heading">{{ 'portal.schedules.search.selecting-a-schedule' | translate }}</h4>
        <p>{{ 'portal.schedules.search.attach-to-booking' | translate }}</p>
        <hr>
        <p>{{ 'portal.schedules.search.return-without-selecting' | translate }}</p>
        <button class="btn btn-outline-info" [routerLink]="['/my-portal/booking/new', linkToBookingId]">
            {{ 'portal.schedules.search.return-to-booking' | translate }}
        </button>
    </div>

    <div [@expand] class="card-body bg-white text-dark text-center border-top" *ngIf="error$ | async">
        <h5>{{ 'portal.schedules.search.no-results' | translate }}</h5>
        <p class="mb-0">{{ 'portal.schedules.search.no-results-extra' | translate }}</p>
    </div>
</div>

<div [@expand] *ngIf="searching$ | async"
    class="backdrop d-flex flex-column justify-content-center text-center p-0 mt-5">
    <div class="backdrop-text">
        <div>
            <fa-icon [icon]="['far', 'spinner']" pulse="true" size="3x"></fa-icon>
        </div>
        <div class="mt-3 font-weight-bold">{{ 'portal.schedules.search.label-searching' | translate }}</div>
        <p *ngIf="query$ | async; let query;">
            {{ query.origin.display_name }}
            {{ 'common.to' | translate }}
            {{ query.destination.display_name }}
        </p>
    </div>
</div>

<div *ngIf="{ schedulesResult: schedulesResult$ | async, schedules: schedules$ | async } as values;">
    <div class="row mt-3">
        <div class="col-12 col-xl-4 col-xxxl-3 mb-4 pt-3">
            <qwyk-schedules-filter [schedules]="values.schedulesResult" *ngIf="values.schedulesResult.length" (filter)="filter($event)"></qwyk-schedules-filter>
        </div>
        <div class="col">
            <qwyk-schedule-list-item
                *ngFor="let schedule of values.schedulesResult | slice: (schedulesResultPage-1) * pageSize : (schedulesResultPage-1) * pageSize + pageSize; index as idx;"
                [schedule]="schedule" (book)="book($event, idx)" (save)="save($event, idx)"
                [isSaving]="savingScheduleIdx === idx" [canBook]="!linkToBookingId" [features]="features$ | async"></qwyk-schedule-list-item>
        </div>
    </div>

    <div class="text-muted small text-center" *ngIf="values.schedulesResult.length > 0">
        {{ schedulesResultPage * pageSize - pageSize + 1 }} -
        {{ (schedulesResultPage-1) * pageSize + pageSize > values.schedulesResult.length ? values.schedulesResult.length : (schedulesResultPage-1) * pageSize + pageSize }}
        {{ 'common.of' | translate }} {{ values.schedulesResult.length }}</div>
    <ngb-pagination *ngIf="values.schedulesResult.length > pageSize" [pageSize]="pageSize" [(page)]="schedulesResultPage"
                    [collectionSize]="values.schedulesResult.length" class="d-flex justify-content-center">
    </ngb-pagination>
</div>
