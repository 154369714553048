/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { Portals } from '@qwyk/models';

export const loadQuotation = createAction(
    '[Portals/BookingWizardOffers] Load Quotation',
    props<{ id: string }>()
);
export const loadQuotationSuccess = createAction(
    '[Portals/BookingWizardOffers] Load Quotation Success',
    props<{
        quotation: Portals.QuotationEntity;
        isDemo: boolean;
        tempToken: string;
    }>()
);
export const loadQuotationFailure = createAction(
    '[Portals/BookingWizardOffers] Load Quotation Failure',
    props<{ error: any }>()
);

export const loadQuotationOffers = createAction(
    '[Portals/BookingWizardOffers] Load Quotation Offers',
    props<{
        quotation: Portals.QuotationEntity;
        isDemo: boolean;
        tempToken: string;
    }>()
);
export const loadQuotationOffersSuccess = createAction(
    '[Portals/BookingWizardOffers] Load Quotation Offers Success',
    props<{ offers: Portals.QuotationOffer[] }>()
);
export const loadQuotationOffersFailure = createAction(
    '[Portals/BookingWizardOffers] Load Quotation Offers Failure',
    props<{ error: any }>()
);

export const polForOffers = createAction(
    '[Portals/BookingWizardOffers] Pol for Quotation Offers',
    props<{
        quotation: Portals.QuotationEntity;
        isDemo: boolean;
        tempToken: string;
    }>()
);

export const polForOffersCompleted = createAction(
    '[Portals/BookingWizardOffers] Pol for Quotation Offfers Completed'
);
export const abortPollingForOffers = createAction(
    '[Portals/BookingWizardOffers] Abort polling for Quotation Offers'
);
export const abortedPollingForOffers = createAction(
    '[Portals/BookingWizardOffers] Aborted polling for Quotation Offers'
);

export const reset = createAction('[Portals/BookingWizardOffers] Reset');
