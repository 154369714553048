import { Pipe, PipeTransform } from '@angular/core';
import * as md5 from 'md5';

@Pipe({
    name: 'md5',
})
export class MD5Pipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: unknown, ...args: unknown[]): unknown {
        return md5(value);
    }
}
