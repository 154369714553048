<div class="d-inline-block" ngbDropdown>
    <button [ngClass]="buttonClass" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
        <fa-icon [icon]="['far', 'question-circle']"
                 class="d-inline-block d-md-none d-xl-inline-block mr-xl-2"></fa-icon>
        <span class="d-none d-md-inline-block">{{ 'common.learn-more' | translate }}</span>
    </button>
    <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
        <button (click)="onShowTutorialVideo()" *ngIf="vimeoVideoId" ngbDropdownItem>{{ 'common.tutorial-video' | translate }}</button>
        <a *ngIf="documentationUrl" [href]="documentationUrl" ngbDropdownItem target="_blank">{{ 'common.how-to-article' | translate }}</a>
    </div>
</div>
