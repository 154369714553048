import * as dashboardActions from '../actions/dashboard.actions';
import { createReducer, Action, on } from '@ngrx/store';
import { DashboardState } from '../dashboard.state';

const initialState: DashboardState = {
    data: {
        shipmentsCount: null,
        schedulesCount: null,
        quotationsCount: null,
        shipmentsList: null,
        schedulesList: null,
    },
};

const reducer = createReducer(
    initialState,
    on(dashboardActions.loadDashboardData, state => ({
        ...state,
    })),
    on(dashboardActions.shipmentsCountLoaded, (state, { result }) => ({
        ...state,
        data: { ...state.data, shipmentsCount: result },
    })),
    on(dashboardActions.schedulesCountLoaded, (state, { result }) => ({
        ...state,
        data: { ...state.data, schedulesCount: result },
    })),
    on(dashboardActions.quotationsCountLoaded, (state, { result }) => ({
        ...state,
        data: { ...state.data, quotationsCount: result },
    })),
    on(dashboardActions.shipmentsListLoaded, (state, { result }) => ({
        ...state,
        data: { ...state.data, shipmentsList: result },
    }))
);

export function dashboardReducer(
    state: DashboardState | undefined,
    action: Action
) {
    return reducer(state, action);
}
