import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsOptedOutGuard implements CanActivate, CanActivateChild {
    public constructor(
        private router: Router,
        private siteConfig: SiteConfigFacade
    ) {}

    canActivate(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.siteConfig.design$.pipe(
            switchMap(design => {
                if (design.landing_page.optin) {
                    return this.router.navigate(['/']);
                } else {
                    return of(true);
                }
            })
        );
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }
}
