import { Component, HostBinding, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-conversation-list-item',
    templateUrl: './conversation-list-item.component.html',
    styleUrls: ['./conversation-list-item.component.scss'],
})
export class ConversationListItemComponent {
    @HostBinding('class') hostClass =
        'list-group-item list-group-item-action d-flex justify-content-start align-items-start overflow-hidden flex-shrink-0';
    @Input() conversation: Portals.Conversation;

    constructor() {}
}
