import { Shipment, ShipmentActivity } from './../models/shipments.models';
import { createAction, props } from '@ngrx/store';

export const reset = createAction('[Shipments] Reset');

export const loadShipment = createAction(
    '[Shipments] Load Shipment',
    props<{ shipmentId: string }>()
);
export const loadShipmentSuccess = createAction(
    '[Shipments] Load Shipment Success',
    props<{ shipment: Shipment }>()
);
export const loadShipmentFailure = createAction(
    '[Shipments] Load Shipment Failure',
    props<{ error }>()
);

export const loadShipmentActivity = createAction(
    '[Shipments] Load Shipment Activity',
    props<{ shipmentId: string; limit?: number }>()
);
export const loadShipmentActivitySuccess = createAction(
    '[Shipments] Load Shipment Activity Success',
    props<{
        activity: {
            data: ShipmentActivity[];
            meta: { total: number; remaining: number };
        };
    }>()
);
export const loadShipmentActivityFailure = createAction(
    '[Shipments] Load Shipment Activity Failure'
);

export const loadMoreShipmentActivity = createAction(
    '[Shipments] Load More Shipment Activity',
    props<{ shipmentId: string; offset: number; limit?: number }>()
);
export const loadMoreShipmentActivitySuccess = createAction(
    '[Shipments] Load More Shipment Activity Success',
    props<{
        activity: {
            data: ShipmentActivity[];
            meta: { total: number; remaining: number };
        };
    }>()
);
export const loadMoreShipmentActivityFailure = createAction(
    '[Shipments] Load More Shipment Activity Failure'
);

export const postComment = createAction(
    '[Shipments] Post Comment',
    props<{ shipmentId: string; data: { comment: string } }>()
);
export const postCommentSuccess = createAction(
    '[Shipments] Post Comment Success',
    props<{ activity: ShipmentActivity }>()
);
export const postCommentFailure = createAction(
    '[Shipments] Post Comment Failure'
);

export const deleteComment = createAction(
    '[Shipments] Delete Comment',
    props<{ shipmentId: string; activity: ShipmentActivity }>()
);

export const deleteCommentSuccess = createAction(
    '[Shipments] Delete Comment Success',
    props<{ activity: ShipmentActivity }>()
);

export const deleteCommentFailure = createAction(
    '[Shipments] Delete Comment Failure'
);
