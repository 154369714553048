import { Pipe, PipeTransform } from '@angular/core';

import { Constants } from '@qwyk/core';

interface TransportMode {
    [index: string]: string | { [index: string]: string };
}

@Pipe({
    name: 'transportModeIcon',
})
export class TransportModeIconPipe implements PipeTransform {
    private _transportMode: TransportMode = Constants.TRANSPORT_MODE_ICONS;

    transform(
        value: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
        ...args: any[]
    ): string | { [index: string]: string } {
        // eslint-disable-next-line no-prototype-builtins
        if (this._transportMode.hasOwnProperty(value)) {
            return this._transportMode[value];
        }
        return 'question';
    }
}
