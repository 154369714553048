import { Component, Input } from '@angular/core';

import { Portals } from '@qwyk/models';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

@Component({
    selector: 'qwyk-quotation-summary',
    templateUrl: './quotation-summary.component.html',
    styleUrls: ['./quotation-summary.component.scss'],
})
export class QuotationSummaryComponent {
    @Input() quotation: Portals.QuotationEntity;

    public user$ = this.authentication.user$;

    constructor(private authentication: AuthenticationFacade) {}

    get shouldShowChargeableWeight() {
        return (
            this.quotation.request.transport_mode === 'AIR' ||
            ['lcl', 'ltl'].includes(this.quotation.request.load_type)
        );
    }

    get layout() {
        return this.shouldShowChargeableWeight ? 'col-3' : 'col-4';
    }

    get layoutResponsive() {
        return this.shouldShowChargeableWeight ? 'col-md-3' : 'col-md-4';
    }
}
