<div class="row align-items-center">
    <div class="col-3">
        <div class="font-weight-bold">{{ shipmentContainer.container_number }}</div>
        <div *ngIf="shipmentContainer.iso6346_code">
            {{ shipmentContainer.iso6346_code }}
        </div>
        <div *ngIf="shipmentContainer.seal_number">
            {{'portal.shipment.page.containers-list.seal' | translate}}: {{ shipmentContainer.seal_number }}
        </div>
    </div>
    <ng-container *ngIf="shipmentContainer.availability_known">
        <div class="col-2 text-center">
            <fa-icon
                *ngIf="shipmentContainer.available_for_pickup !== null"
                [icon]="['far', shipmentContainer.available_for_pickup ? 'check' : 'times']"
                [ngClass]="shipmentContainer.available_for_pickup ? 'text-success' : 'text-danger'"
                class="mr-1"
            ></fa-icon>
            {{ (shipmentContainer.available_for_pickup ? 'common.yes' : 'common.no') | translate}}
        </div>
        <div class="col-2 text-center">
            <fa-icon
                *ngIf="shipmentContainer.has_holds !== null"
                [icon]="['far', shipmentContainer.has_holds ? 'times' : 'check']"
                [ngClass]="shipmentContainer.has_holds ? 'text-danger' : 'text-success'"
                class="mr-1"
            ></fa-icon>
            {{ (shipmentContainer.has_holds ? 'common.yes' : 'common.no') | translate }}
        </div>
        <div class="col-2">{{ shipmentContainer.location_at_pod_terminal}}</div>
        <div class="col-3">
            <span *ngIf="shipmentContainer.pickup_lfd_local">
                {{ shipmentContainer.pickup_lfd_local | amParseZone | amDateFormat: 'L' }}
                <span [ngbTooltip]="shipmentContainer.timezone"
                      class="small text-muted">
                    {{ shipmentContainer.timezone_code | uppercase }}
                </span>
            </span>
        </div>
    </ng-container>
    <div *ngIf="!shipmentContainer.availability_known" class="col-9 py-4">
        {{'portal.shipment.page.containers-list.container-availability-unknown' | translate}}
    </div>
</div>
