import { Component } from '@angular/core';
import { ShipmentActivitiesListItemComponent } from '../shipment-activities-list-item/shipment-activities-list-item.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-activities-status-update]',
    templateUrl: './shipment-activities-status-update.component.html',
    styleUrls: ['./shipment-activities-status-update.component.scss'],
})
export class ShipmentActivitiesStatusUpdateComponent extends ShipmentActivitiesListItemComponent {}
