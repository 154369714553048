import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

import * as moment from 'moment';

import { Animations } from '@qwyk/ui';
import { Portals } from '@qwyk/models';

import { QuotationsService } from '../../services/quotations.service';
@Component({
    selector: 'qwyk-quotation-offer-list-item',
    templateUrl: './quotation-offer-list-item.component.html',
    styleUrls: ['./quotation-offer-list-item.component.scss'],
    animations: [Animations.fadeInAnimation],
})
export class QuotationOfferListItemComponent {
    private _offer: Portals.QuotationOffer;
    @Input()
    public set offer(offer: Portals.QuotationOffer) {
        this._offer = offer;
    }
    public get offer(): Portals.QuotationOffer {
        return this._offer;
    }

    @Input() allowBooking = true;
    @Input() quotation: Portals.QuotationEntity;

    hoverHeader = false;
    expandCharges = false;
    expandSchedules = false;
    expandRemarks = false;
    expandMap = false;
    busy = false;
    bookingSchedulesIndex = null;

    constructor(private service: QuotationsService, private router: Router) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onCreateBooking(schedule: any = null) {
        this.busy = true;
        if (schedule) {
            this.bookingSchedulesIndex = schedule.index;
        }
        this.service
            .createShipmentFromOffer(
                this.offer,
                schedule ? schedule.schedule : null
            )
            .subscribe(
                response => {
                    this.busy = false;
                    this.bookingSchedulesIndex = null;
                    this.router.navigate([
                        '/my-portal/booking/new',
                        response.id,
                    ]);
                },
                error => {
                    console.error(error);
                    this.busy = false;
                    this.bookingSchedulesIndex = null;
                    alert(
                        'Something went wrong, please try again or contact support if the problem persists.'
                    ); // FIXME: proper error display.
                }
            );
    }

    public get hasExpired() {
        if (null === this.offer.valid_until) {
            return false;
        }
        return moment().isAfter(this.offer.valid_until);
    }
}
