import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentPurchaseOrder} from '@qwyk/shared-stores/shipments';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'qwyk-shipment-purchase-orders',
    standalone: true,
    imports: [CommonModule, RouterLink, TranslateModule],
    templateUrl: './shipment-purchase-orders.component.html',
    styleUrls: ['./shipment-purchase-orders.component.css'],
})
export class ShipmentPurchaseOrdersComponent {
    @Input() shipmentPurchaseOrders: ShipmentPurchaseOrder[];
}
