import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationFacade } from '../state/authentication.facade';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate, CanActivateChild {
    constructor(
        private authentication: AuthenticationFacade,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.authentication.authenticated$.pipe(
            map(authenticated => {
                if (!authenticated) {
                    return this.router.parseUrl(
                        '/?login=true&next=' + state.url
                    );
                }
                return true;
            })
        );
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }
}
