import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSiteConfig from './state/site-config.reducer';
import { SiteConfigEffects } from './state/site-config.effects';
import { SiteConfigFacade } from './state/site-config.facade';
import {
    Favicons,
    BrowserFavicons,
    BROWSER_FAVICONS_CONFIG,
} from './helpers/favicons';
import { BrowserScriptsInjector, ScriptsInjector } from './helpers/scripts';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromSiteConfig.SITECONFIG_FEATURE_KEY,
            fromSiteConfig.reducer
        ),
        EffectsModule.forFeature([SiteConfigEffects]),
    ],
    providers: [
        SiteConfigFacade,
        { provide: Favicons, useClass: BrowserFavicons },
        { provide: BROWSER_FAVICONS_CONFIG, useValue: { cacheBusting: true } },
        { provide: ScriptsInjector, useClass: BrowserScriptsInjector },
    ],
})
export class PortalsSiteconfigModule {}
