import {
    trigger,
    transition,
    animate,
    style,
    state,
} from '@angular/animations';

export const fadeInAnimation = trigger('fadeIn', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(500)]),
]);

export const fadeInOutAnimation = trigger('fadeInOut', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(200)]),
    transition(':leave', [style({ opacity: 1 }), animate(200)]),
]);

export const fadeDelayInOutAnimation = trigger('fadeDelayInOut', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate('200ms 350ms')]),
    transition(':leave', [style({ opacity: 1 }), animate(200)]),
]);

export const expandAnimation = trigger('expand', [
    state('*', style({ 'max-height': '1000px' })),
    transition(':enter', [style({ 'max-height': 0 }), animate(500)]),
]);

export const expandCollapseAnimation = trigger('expandCollapse', [
    state('*', style({ 'max-height': '1000px' })),
    transition(':enter', [style({ 'max-height': 0 }), animate(500)]),
    transition(':leave', [animate(500, style({ 'max-height': '1000px' }))]),
]);

export const fadeInExpandAnimation = trigger('fadeInExpand', [
    state('*', style({ opacity: 1, 'max-height': '1000px' })),
    transition(':enter', [
        style({ opacity: 0, 'max-height': 0 }),
        animate(500),
    ]),
]);

export const expandCollapse2 = trigger('expandCollapse2', [
    state(
        '*',
        style({
            'overflow-y': 'hidden',
            height: '*',
        })
    ),
    state(
        'void',
        style({
            height: '0',
            'overflow-y': 'hidden',
        })
    ),
    transition('* => void', animate('250ms ease-out')),
    transition('void => *', animate('250ms ease-in')),
]);

export const slideInRight = trigger('slideInRight', [
    state(
        '*',
        style({
            'overflow-y': 'hidden',
            position: 'relative',
            transform: 'translateX(0%)',
        })
    ),
    state(
        'void',
        style({
            'overflow-y': 'hidden',
            position: 'relative',
            right: '-100px',
        })
    ),
    transition('* => void', animate('250ms ease-out')),
    transition('void => *', animate('250ms ease-in')),
]);
