/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const TOGGLE_BUTTON_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(() => ToggleButtonComponent),
    multi: true,
};

@Component({
    selector: 'qwyk-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss'],
    providers: [TOGGLE_BUTTON_CONTROL_VALUE_ACCESSOR],
})
export class ToggleButtonComponent implements ControlValueAccessor {
    @Input() buttonClass = '';
    @Input() onClass = '';
    @Input() offClass = '';
    @Input() onText = '';
    @Input() offText = '';
    @Input() public onIcon: string | null;
    @Input() public offIcon: string | null;
    @Input() public iconType: 'far' | 'fas' | 'fad' | 'fal' = 'far';

    private onChange: (_: any) => void;
    private onTouched: (_: any) => void;

    private _disabled = false;
    public get disabled(): boolean {
        return this._disabled;
    }

    private _state = false;
    public get state(): boolean {
        return this._state;
    }

    public get controlClass(): object {
        return {
            [this.buttonClass]: true,
            [this.onClass]: this.state,
            [this.offClass]: !this.state,
        };
    }

    public get labelText(): string {
        return this.state ? this.onText : this.offText;
    }

    public get labelIcon(): string | null {
        return this.state ? this.onIcon : this.offIcon;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this._state = obj;
    }

    public onClick(): void {
        this._state = !this._state;
        if (this.onChange) {
            this.onChange(this._state);
        }
        if (this.onTouched) {
            this.onTouched(true);
        }
    }
}
