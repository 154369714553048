import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    standalone: true,
    name: 'dateDiffInPercent',
})
export class DateDiffInPercentPipe implements PipeTransform {
    transform(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...args: any[]
    ): { percent: number; days: number | null } {
        if (!value || args.length < 1 || !args[0]) {
            return { percent: 0, days: null };
        }
        const nowToNext = moment(value).diff(moment(), 'hours');
        const lastToNext = moment(args[0]).diff(value, 'hours');

        const percent = Math.abs((nowToNext / lastToNext) * 100);
        return {
            percent: percent || 0.5,
            days: nowToNext,
        };
    }
}
