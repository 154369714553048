import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { SegmentService } from 'ngx-segment-analytics';

import { SegmentEvent } from '@qwyk/core';

import { RequestedNavigation } from '../login-modal/login-modal.component';
import { ResendVerificationEmailServiceService } from '../../services/resend-verification-email-service.service';

@Component({
    selector: 'qwyk-resend-confirmation-email',
    templateUrl: './resend-confirmation-email.component.html',
    styleUrls: ['./resend-confirmation-email.component.scss'],
})
export class ResendConfirmationEmailComponent implements OnInit {
    @Output() navigate: EventEmitter<RequestedNavigation> = new EventEmitter();
    public resendVerificationEmailForm: FormGroup;
    public error = false;
    constructor(
        private fb: FormBuilder,
        private resendEmailVerificationEmailService: ResendVerificationEmailServiceService,
        private segment: SegmentService
    ) {}

    ngOnInit(): void {
        this.resendVerificationEmailForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
        });
    }

    onGoToLogin() {
        this.navigate.emit({ component: 'login' });
    }

    onResendVerificationEmail() {
        this.resendVerificationEmailForm.markAllAsTouched();
        if (this.resendVerificationEmailForm.invalid) {
            return;
        }
        this.segment.track(SegmentEvent.EMAIL_CONFIRMATION_RESENT, {
            email: this.resendVerificationEmailForm.get('email').value,
        });

        this.resendEmailVerificationEmailService
            .resendVerificationEmail(
                this.resendVerificationEmailForm.get('email').value
            )
            .subscribe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                _ => {
                    this.resendVerificationEmailForm.enable();
                    this.navigate.emit({ component: 'login' });
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                __ => {
                    this.error = true;
                    this.resendVerificationEmailForm.enable();
                }
            );
    }
}
