import {Component, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
    template: `
    `
})
export class EmitsOnDestroyComponent implements OnDestroy {
    public destroy$ = new Subject<boolean>();

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
