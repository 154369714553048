import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    BOOKINGWIZARDOFFERS_FEATURE_KEY,
    State,
    BookingWizardOffersPartialState,
    bookingWizardOffersAdapter,
} from './booking-wizard-offers.reducer';

// Lookup the 'BookingWizardOffers' feature state managed by NgRx
export const getBookingWizardOffersState = createFeatureSelector<
    BookingWizardOffersPartialState,
    State
>(BOOKINGWIZARDOFFERS_FEATURE_KEY);

const { selectAll, selectEntities } = bookingWizardOffersAdapter.getSelectors();

export const getBookingWizardOffersLoaded = createSelector(
    getBookingWizardOffersState,
    (state: State) => state.loaded
);

export const getBookingWizardOffersPolling = createSelector(
    getBookingWizardOffersState,
    (state: State) => state.polling
);

export const getBookingWizardOffersError = createSelector(
    getBookingWizardOffersState,
    (state: State) => state.error
);

export const getQuotationRequest = createSelector(
    getBookingWizardOffersState,
    (state: State) => state.quotation
);

export const getAllBookingWizardOffers = createSelector(
    getBookingWizardOffersState,
    (state: State) => selectAll(state)
);

export const getBookingWizardOffersEntities = createSelector(
    getBookingWizardOffersState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getBookingWizardOffersState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getBookingWizardOffersEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);

export const getPollingState = createSelector(
    getBookingWizardOffersState,
    (state: State) => ({
        counter: state.polCounter,
        polling: state.polling,
        interval: state.polInterval,
        maximum: state.polMaximum,
        abort: state.abortPolling,
    })
);
