<div class="small text-muted border-bottom pb-2">
    <a [routerLink]="['/my-portal']" class="text-muted">{{'portal.common.my-portal' | translate}}</a>
    &rsaquo;
    <a [routerLink]="['/my-portal', 'quotations']" class="text-muted">
        {{'common.quotations.quotations' | translate | titlecase}}
    </a>
    &rsaquo;
    <a [routerLink]="['./']" class="text-muted font-weight-bold">{{quotation.friendly_id}}</a>
</div>

<div class="mb-3 border-bottom py-2 d-flex align-items-center justify-content-between">
    <div class="flex-grow-1">
        <qwyk-quotation-status-badge [quotation]="quotation" class="mr-2"></qwyk-quotation-status-badge>
        {{ 'portal.common.created-at-by' | translate : {at: quotation.request.created_at | amTimeAgo } }}
        {{ quotation.owner.name }}
    </div>
    <div>
        <button (click)="onDownload($event, quotation)"
                [disabled]="loading"
                class="btn btn-outline-secondary">
            <fa-icon
                    [icon]="['far', !loading ? 'file-download' : 'spinner']"
                    [pulse]="loading" class="mr-2"></fa-icon>
            {{ 'common.download' | translate | titlecase }}
        </button>
    </div>
</div>

<h3 class="font-weight-normal">
    {{'common.quotations.quotation' | translate | titlecase }} {{ quotation.friendly_id }}
</h3>
