export enum QuotationStatus {
    DRAFT = 'draft',
    OPEN = 'open',
    REQUEST = 'request',
    BOOKED = 'booked',
    CLOSED = 'closed',
}

export const QUOTATION_STATUS_ICONS = {
    [QuotationStatus.DRAFT]: '',
    [QuotationStatus.OPEN]: 'hand-holding-usd',
    [QuotationStatus.REQUEST]: 'question-circle',
    [QuotationStatus.BOOKED]: 'shopping-cart',
    [QuotationStatus.CLOSED]: 'check',
};

export const QUOTATION_STATUS_LABELS = {
    [QuotationStatus.DRAFT]: 'common.quotations.status.draft',
    [QuotationStatus.OPEN]: 'common.quotations.status.created',
    [QuotationStatus.REQUEST]: 'common.quotations.status.request',
    [QuotationStatus.BOOKED]: 'common.quotations.status.booked',
    [QuotationStatus.CLOSED]: 'common.quotations.status.closed',
};

export const QUOTATION_STATUS_COLORS = {
    [QuotationStatus.DRAFT]: 'light',
    [QuotationStatus.OPEN]: 'primary',
    [QuotationStatus.REQUEST]: 'secondary',
    [QuotationStatus.BOOKED]: 'success',
    [QuotationStatus.CLOSED]: 'dark',
};

export const INDEX_SORT_OPTIONS = [
    { value: 'created_at', label: 'common.quotations.sort-options.created-at' },
    { value: 'updated_at', label: 'common.quotations.sort-options.updated_at' },
    {
        value: 'friendly_id',
        label: 'common.quotations.sort-options.friendly_id',
    },
    {
        value: 'origin_name',
        label: 'common.quotations.sort-options.origin_name',
    },
    {
        value: 'destination_name',
        label: 'common.quotations.sort-options.destination_name',
    },
];

export const INDEX_PRODUCT_OPTIONS = [
    { value: 'air:lcl', label: 'common.air' },
    { value: 'ocean:fcl', label: 'common.ocean', load_type: 'FCL' },
    { value: 'ocean:lcl', label: 'common.ocean', load_type: 'LCL' },
    { value: 'truck:fcl', label: 'common.truck', load_type: 'FTL' },
    { value: 'truck:lcl', label: 'common.truck', load_type: 'LTL' },
];

export const INDEX_STATUS_OPTIONS = [
    { value: 'open', label: 'common.quotations.status-options.created' },
    { value: 'booked', label: 'common.quotations.status-options.booked' },
    { value: 'request', label: 'common.quotations.status-options.request' },
    { value: 'closed', label: 'common.quotations.status-options.closed' },
];

export function safeGet(map, key, def = null): string | null {
    // eslint-disable-next-line no-prototype-builtins
    if (map.hasOwnProperty(key)) {
        return map[key];
    }
    return def;
}
