import { NgClass } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as prettyBytes from 'pretty-bytes';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

export interface FileSelectedEvent {
    control_name: string | null;
    preview_url: string | null;
    file: File | null;
}

@Component({
    standalone: true,
    imports: [NgClass, TranslateModule, FontAwesomeModule],
    selector: 'qwyk-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
    @Input() name = null;
    @Input() buttonClass = 'btn-primary';
    @Input() accept = '*/*';
    @Input() maxSize: number | null = null;
    @Input() disabled = false;

    @Output() fileSelected: EventEmitter<FileSelectedEvent> =
        new EventEmitter();

    public busy = false;
    public error: string | null = null;
    public selectedFile: { name: string } | null = null;

    constructor() {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onFileSelected(e: any) {
        if (this.busy) {
            // Already working, abort.
            return;
        }
        this.error = null;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let file: any = null;
        if (e.target.files.length > 0) {
            file = e.target.files[0];
        } else {
            // No file, abort.
            return;
        }

        if (this.maxSize && file && file.size > this.maxSize) {
            this.error = `This file is too large, maximum size: ${prettyBytes(
                this.maxSize
            )}, file: ${prettyBytes(file.size)}`;
            return;
        }

        this.busy = true;

        const reader = new FileReader();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reader.onload = _ => {
            this.selectedFile = file;
            this.busy = false;

            this.fileSelected.emit({
                control_name: this.name,
                preview_url:
                    file.type.match(/image\/*/) !== null &&
                    reader &&
                    reader.result
                        ? reader.result.toString()
                        : null,
                file,
            });
        };
        reader.readAsDataURL(file);
    }
}
