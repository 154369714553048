import { createAction, props } from '@ngrx/store';
import { Portals } from '@qwyk/models';

export const searchSchedules = createAction(
    '[Portals/Schedules] Search Schedules',
    props<{ query: Portals.SchedulesSearchQuery }>()
);

export const searchSchedulesSuccess = createAction(
    '[Portals/Schedules] Search Schedules Success',
    props<{ schedules: Portals.Schedule[] }>()
);

export const searchSchedulesFailure = createAction(
    '[Portals/Schedules] Search Schedules Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const saveSchedulesSearchQuery = createAction(
    '[Portals/Schedules] Save Query',
    props<{ query: Portals.SchedulesSearchQuery }>()
);

export const resetSearchResults = createAction(
    '[Portals/Schedules] Reset Search Results'
);
