import { Injectable } from '@angular/core';
import { LOCALSTORAGE_TOKEN_KEY } from './authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root',
})
export class TokenGetterService {
    private jwtHelperService;
    constructor() {
        this.jwtHelperService = new JwtHelperService();
    }

    public getToken(): string | null {
        const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
        if (this.checkToken(token)) {
            return token;
        } else {
            return null;
        }
    }

    /**
     * Checks if the token has expired, if so throws an alert, error and redirects
     * @param token
     * @private
     */
    private checkToken(token): boolean {
        if (token && this.jwtHelperService.isTokenExpired(token)) {
            // Remove the token so we don't get into an infinite loop...
            localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);

            if (
                location.pathname !== '/' &&
                !location.pathname.startsWith('/auth')
            ) {
                // Only do this when we're not visiting root.
                alert(
                    'Your session has expired, you will be asked to login again.'
                );
                location.href = '/?login=true';
            }
            return false;
        }
        return true;
    }
}
