<div class="modal-body px-4 pt-5 pb-4">
    {{ 'portal.leave-magaya-modal.warning' | translate }}
</div>
<div class="modal-footer border-top-0 pt-0 px-4 pb-4">
    <button class="confirm btn" [ngClass]="confirmStyle" (click)="confirm()">
        {{ confirmAction | translate }}
    </button>
    <button class="dismiss btn" [ngClass]="!confirmAction ? confirmStyle : (dismissStyle || 'btn-light')" (click)="dismiss()">
        {{ dismissAction | translate }}
    </button>
</div>
