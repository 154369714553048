import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { EventHint } from '@sentry/browser';
import { Event } from '@sentry/types/types/event';
import { isArray } from 'rxjs/internal-compatibility';
import { ErrorEvent } from '@sentry/types/types-ts3.8/event';
import { Integrations as SentryTracingIntegrations } from '@sentry/tracing';
import { CaptureConsole as SentryCaptureConsoleIntegration } from '@sentry/integrations';

import { environment } from '@qwyk/portals/environment';

import { AppModule } from './app/app.module';
import { versions } from './environments/versions';

Sentry.init({
    enabled: environment.utils.Sentry.enabled,
    dsn: environment.utils.Sentry.dsn,
    environment: environment.name,
    release: versions.version,
    integrations: [
        new SentryTracingIntegrations.BrowserTracing({
            tracingOrigins: environment.utils.Sentry.traceOrigins,
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new SentryCaptureConsoleIntegration({
            levels: ['error'],
        }),
    ],
    tracesSampleRate: environment.utils.Sentry.tracesSampleRate,
    beforeSend: (
        event: ErrorEvent,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hint: EventHint
    ): Event | PromiseLike<Event> => {
        if (
            typeof event?.message === 'string' &&
            event?.message.includes('[object Object]') &&
            event?.extra?.arguments &&
            isArray(event.extra.arguments) &&
            event?.extra?.arguments.length > 0 &&
            event.extra.arguments[0]?.message
        ) {
            event.message = event.extra.arguments[0].message;
        }
        return { ...event } as Event;
    },
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
