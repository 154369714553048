/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { take, map, shareReplay } from 'rxjs/operators';

import { environment } from '@qwyk/portals/environment';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private http: HttpClient) {}

    public getShipmentCounts(): Observable<{
        active: number;
        drafts: number;
        overdue: number;
        upcoming: number;
    }> {
        return this.http
            .get<{
                active: number;
                drafts: number;
                overdue: number;
                upcoming: number;
            }>(`${environment.backendServer}/api/portals/shipments/count`)
            .pipe(shareReplay(1));
    }

    public getScheduleCounts(): Observable<{ active: number }> {
        return of({ active: 0 });
    }

    public getQuotationCounts(): Observable<{ active: number }> {
        return of({ active: 0 });
    }

    public getShipments(): Observable<any> {
        return this.http
            .get<{ data: any[] }>(
                `${environment.backendServer}/api/portals/shipments/dashboard`
            )
            .pipe(
                take(1),
                map(r => r.data)
            );
    }

    public getQuotations(): Observable<any[]> {
        /*
        const queryString = new HttpParams({
            fromObject: {
                limit: '5',
            },
        });
        */

        return of([]);
    }

    public getLayouts(): Observable<any> {
        return this.http
            .get(`${environment.backendServer}/api/dashboard/layouts`)
            .pipe(take(1));
    }

    getDataSets(): Observable<any> {
        return this.http
            .get<{ data: any[] }>(
                `${environment.backendServer}/api/dashboard/datasets`
            )
            .pipe(
                take(1),
                map(d => {
                    d.data = d.data.sort((a, b) =>
                        a.group > b.group ? 1 : -1
                    );
                    return d;
                })
            );
    }

    loadDashboardData(endpoint): Observable<any> {
        return this.http.get(`${environment.backendServer}${endpoint}`);
    }
}
