<ng-container *ngFor="let breakdownGroup of offer.grouped_breakdown">

    <li class="list-group-item list-group-item-action font-weight-bold d-flex justify-content-between border-0"
        (click)="groupExpansion[breakdownGroup.group] = !groupExpansion[breakdownGroup.group]">
        <div class="d-flex align-items-center">
            <div class="text-left" style="width: 35px;" size="lg">
                <fa-icon [ngClass]="breakdownGroup.lines.length === 0 ? 'text-muted' : null"
                    [icon]="['far', !groupExpansion[breakdownGroup.group] ? 'angle-down' : 'angle-up']">
                </fa-icon>
            </div>
            <div>
                {{ breakdownGroup.group | titlecase }}
            </div>
        </div>
        <div>
            {{ breakdownGroup.total | currency: offer.currency }}
        </div>
    </li>
    <ng-container *ngIf="groupExpansion[breakdownGroup.group]">
        <li *ngFor="let line of breakdownGroup.lines"
            class="list-group-item d-flex justify-content-between py-1 border-0 small">
            <div style="padding-left: 35px;">
                <div>
                    {{ line.charge_description }}
                    <fa-icon *ngIf="line.remarks" [icon]="['far', 'exclamation-circle']" style="cursor: help"
                        [ngbTooltip]="line.remarks" class="text-muted"></fa-icon>
                </div>
                <small class="text-muted">
                    {{ line.base_rate | currency: line.base_currency }} /
                    {{ line.base_calculation?.name }}
                </small>
            </div>
            <div class="text-right">
                <div>
                    {{ line.total_amount_quotation | currency: offer.currency }}
                </div>
                <small class="text-muted" *ngIf="line.exchange_rate !== 1">
                    {{ line.total_amount | currency: line.base_currency }} @ {{ line.exchange_rate }}
                </small>
            </div>
        </li>
    </ng-container>

</ng-container>
