export enum SegmentEvent {
    ATTACHMENT_DOWNLOAD = 'Attachment Downloaded',
    ATTACHMENT_UPLOAD = 'Attachment Uploaded',
    CARGO_DETAIL_LIST_EXPORTED = 'Cargo Detail List Exported',
    CARGO_DETAIL_LIST_VIEW = 'Cargo Detail List Viewed',
    CARGO_RELEASE_DOWNLOAD = 'Cargo Release PDF Downloaded',
    CARGO_RELEASE_LIST_VIEW = 'Cargo Release List Viewed',
    CARGO_RELEASE_NEW = 'Cargo Release Started Creating',
    CARGO_RELEASE_SENT = 'Cargo Release Sent',
    CARGO_RELEASE_VIEW = 'Cargo Release Viewed',
    DASHBOARD_VIEW = 'Dashboard Viewed',
    EMAIL_CONFIRMATION = 'Email Confirmed',
    EMAIL_CONFIRMATION_RESENT = 'Confirmation Email Resent',
    INVENTORY_DOWNLOAD = 'Inventory Downloaded',
    INVENTORY_LIST_VIEW = 'Inventory List Viewed',
    INVENTORY_VIEW = 'Inventory Item Viewed',
    INVOICE_LIST_VIEW = 'Invoice List Viewed',
    INVOICE_PAYMENT_COMPLETED = 'Invoice Payment Completed',
    INVOICE_PAYMENT_STARTED = 'Invoice Payment Started',
    INVOICE_VIEW = 'Invoice Viewed',
    INVOICE_DOWNLOAD = 'Invoice PDF Downloaded',
    LOGIN_ATTEMPT = 'User Attempted Login',
    LOGIN_FAILURE = 'User Failed Login',
    LOGIN_SUCCESS = 'User Logged In',
    LOGOUT = 'User Logged Out',
    OPEN_SITE = 'Site Opened',
    PASSWORD_RESET = 'Password Reset',
    PASSWORD_RESET_REQUEST = 'Password Reset Requested',
    PAYMENT_LIST_VIEW = 'Payment List Viewed',
    PAYMENT_VIEW = 'Payment Viewed',
    PAYMENT_DOWNLOAD = 'Payment PDF Downloaded',
    PICKUP_ORDER_LIST_VIEW = 'Pickup Order List Viewed',
    PICKUP_ORDER_VIEW = 'Pickup Order Viewed',
    PICKUP_ORDER_DOWNLOAD = 'Pickup Order PDF Downloaded',
    PURCHASE_ORDER_LIST_VIEW = 'Purchase Order List Viewed',
    PURCHASE_ORDER_VIEW = 'Purchase Order Viewed',
    QUOTATION_CONVERTED = 'Quotation Converted',
    QUOTATION_CREATED = 'Quotation Created',
    QUOTATION_DOWNLOAD = 'Quotation PDF Downloaded',
    QUOTATION_LIST_VIEW = 'Quotation List Viewed',
    QUOTATION_OFFER_LIST_VIEW = 'Quotation Offer List Viewed',
    QUOTATION_OFFER_VIEW = 'Quotation Offer Viewed',
    QUOTATION_REQUEST_SENT = 'Quotation Rate Request Sent',
    QUOTATION_VIEW = 'Quotation Viewed',
    REGISTRATION = 'User Registered',
    REPORT_EXPORTED = 'Report Exported',
    REPORT_SAVED = 'Report Created',
    REPORT_SCHEDULED = 'Report Scheduled',
    REPORT_VIEW = 'Report Viewed',
    SCHEDULE_CONVERTED = 'Schedule Converted',
    SCHEDULE_LIST_VIEW = 'Schedule List Viewed',
    SCHEDULE_SAVED = 'Schedule Saved',
    SCHEDULE_SEARCH = 'Schedule Searched',
    SCHEDULE_VIEW = 'Schedule Viewed',
    SHIPMENT_DOWNLOAD = 'Shipment PDF Downloaded',
    SHIPMENT_DRAFT_VIEW = 'Shipment Draft Viewed',
    SHIPMENT_LIST_VIEW = 'Shipment List Viewed',
    SHIPMENT_SENT = 'Shipment Sent',
    SHIPMENT_VIEW = 'Shipment Viewed',
    WAREHOUSE_RECEIPT_LIST_VIEW = 'Warehouse Receipt List Viewed',
    WAREHOUSE_RECEIPT_VIEW = 'Warehouse Receipt Viewed',
    WAREHOUSE_RECEIPT_DOWNLOAD = 'Warehouse Receipt PDF Downloaded',
}
