import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    private trackingId = null;
    private routerEventsSubscription: Subscription;
    constructor(private router: Router) {}

    public initialize(
        gaTrackingId: string,
        subscribeRouterEvents = true
    ): void {
        if (this.routerEventsSubscription) {
            this.routerEventsSubscription.unsubscribe();
        }
        this.trackingId = gaTrackingId;

        window['dataLayer'] = window['dataLayer'] || [];
        window['gtag'] =
            window['gtag'] ||
            function (...args) {
                window['dataLayer'].push(args);
            };
        window['gtag']('config', this.trackingId);
        window['gtag']('js', new Date());
        const s: HTMLScriptElement = document.createElement('script');
        s.async = true;
        s.src = `https://www.googletagmanager.com/gtag/js?id=${this.trackingId}`;
        s.id = 'gtag';
        const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
        head.appendChild(s);

        if (subscribeRouterEvents) {
            this.subscribeToRouterEvents();
        }
    }

    public destruct() {
        if (this.routerEventsSubscription) {
            this.routerEventsSubscription.unsubscribe();
        }
        this.trackingId = null;
    }

    private subscribeToRouterEvents() {
        this.routerEventsSubscription = this.router.events.subscribe(
            routerEvent => {
                if (routerEvent instanceof NavigationEnd) {
                    const ga = window['gtag'];

                    if (ga) {
                        const opt = {};
                        if (routerEvent.url) {
                            opt['page_path'] = routerEvent.url;
                        }
                        ga('config', this.trackingId, opt);
                    }
                }
            }
        );
    }
}
