import { Router } from '@angular/router';
import { Injectable, Inject, Injector } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { environment } from '@qwyk/portals/environment';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

import { WINDOW } from '../window.provider';

@Injectable({
    providedIn: 'root',
})
export class StartupService {
    constructor(
        @Inject(WINDOW) private window: Window,
        private injector: Injector,
        private siteConfig: SiteConfigFacade,
        private authentication: AuthenticationFacade
    ) {}

    private extractEndpointIdentifier(): {
        identifier: string;
        type: 'endpoint' | 'cname';
    } {
        const hostname = this.window.location.hostname;
        const hostMask = new RegExp(environment.hostnameMask, 'i').exec(
            hostname
        );

        if (!hostMask || hostMask.length < 1) {
            return { identifier: hostname, type: 'cname' };
        }
        return { identifier: hostMask[1], type: 'endpoint' };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    load(): Promise<any> {
        const waitForSubject = new BehaviorSubject(false);

        const loadedSubscription = this.siteConfig.loaded$.subscribe(loaded => {
            if (loaded) {
                this.authentication.initializeAuthenticationState();
                waitForSubject.complete();
            }
        });

        const errorLoadingSubscription = this.siteConfig.error$.subscribe(
            error => {
                if (error) {
                    // Cannot inject router into constructor due to dependencies: see https://github.com/robisim74/angular-l10n/issues/176
                    const router = this.injector.get(Router);
                    let queryParams = null;
                    if (error && error.error && error.status !== 404) {
                        queryParams = error.error;
                    }

                    router.navigate(['/404'], { queryParams });

                    // Complete it so the app loads.
                    waitForSubject.complete();
                }
            }
        );

        this.siteConfig.loadSiteConfig(this.extractEndpointIdentifier());

        return waitForSubject.toPromise().then(() => {
            loadedSubscription.unsubscribe();
            errorLoadingSubscription.unsubscribe();
            waitForSubject.unsubscribe();
        });
    }
}
