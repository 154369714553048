<div class="modal-header border-bottom-0 px-4 pt-4">
    <h5 class="modal-title" id="modal-basic-title">{{ 'portal.custom-table-views.title' | translate }}</h5>
    <input type="hidden" ngbAutofocus>
</div>
<form [formGroup]="form" (ngSubmit)="confirm()">
    <div class="modal-body px-4">
        <div class="form-group">
            <input
                formControlName="name"
                type="text"
                class="form-control"
                placeholder="{{ 'portal.custom-table-views.placeholders.custom_view_name' | translate }}" />
            <arv-validation-messages for="name">
                <arv-validation-message key="required">
                    <span class="text-danger">{{ 'common.validation.custom-view-required' | translate : { field: 'portal.custom-table-views.title' | translate | lowercase } }}</span>
                </arv-validation-message>
                <arv-validation-message key="pattern">
                    <span class="text-danger">{{ 'common.validation.pattern' | translate : { field: 'portal.custom-table-views.title' | translate | lowercase } }}</span>
                </arv-validation-message>
            </arv-validation-messages>
        </div>
    </div>
    <div class="modal-footer border-top-0 pt-0 px-4 pb-4">
        <button class="confirm btn" [ngClass]="confirmStyle" [disabled]="form.invalid" type="submit">
            {{ confirmAction | translate }}
        </button>
        <button class="dismiss btn" [ngClass]="!confirmAction ? confirmStyle : (dismissStyle || 'btn-light')" (click)="dismiss()">
            {{ dismissAction | translate }}
        </button>
    </div>
</form>
