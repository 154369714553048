import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { concat, Observable, of } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PathService {
    path$: Observable<string[]>;

    constructor(router: Router) {
        this.path$ = concat(
            of(PathService.splitUrl(router.url)),
            router.events.pipe(
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd
                ),
                map((evt: NavigationEnd) => {
                    return PathService.splitUrl(
                        evt.urlAfterRedirects || evt.url
                    );
                })
            )
        ).pipe(shareReplay());
    }

    private static splitUrl(url: string): string[] {
        return new URL(url, 'http://localhost').pathname.split('/');
    }
}
