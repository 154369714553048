import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UiModule } from '@qwyk/ui';
import {
    LineBreaksPipe,
    UnitConvertPipe,
    PaginationComponent,
    BreadcrumbsComponent,
    QueryBuilderComponent,
    SharingModalComponent,
    UserInfoTooltipComponent,
    LoadingIndicatorComponent,
    ListTableSwitcherComponent,
} from '@qwyk/ui-standalone';

import { pages } from './pages';
import {
    components,
    QuotationViewComponent,
    QuotationsListComponent,
    QuotationOfferListItemComponent,
    RequestForQuotationOfferComponent,
    QuotationOfferListItemMapComponent,
    QuotationOfferListItemChargesComponent,
    QuotationOfferListItemSchedulesComponent,
} from './components';

@NgModule({
    imports: [
        UiModule,
        NgbModule,
        FormsModule,
        TableModule,
        CommonModule,
        MomentModule,
        RouterModule,
        DropdownModule,
        NgSelectModule,
        LineBreaksPipe,
        InputTextModule,
        TranslateModule,
        UnitConvertPipe,
        FontAwesomeModule,
        ReactiveFormsModule,
        PaginationComponent,
        BreadcrumbsComponent,
        QueryBuilderComponent,
        SharingModalComponent,
        UserInfoTooltipComponent,
        LoadingIndicatorComponent,
        ListTableSwitcherComponent,
    ],
    declarations: [
        QuotationViewComponent,
        QuotationsListComponent,
        QuotationOfferListItemComponent,
        QuotationOfferListItemChargesComponent,
        QuotationOfferListItemMapComponent,
        QuotationOfferListItemSchedulesComponent,
        RequestForQuotationOfferComponent,
        ...pages,
        ...components,
    ],
    exports: [
        QuotationsListComponent,
        QuotationViewComponent,
        QuotationOfferListItemComponent,
        QuotationOfferListItemChargesComponent,
        QuotationOfferListItemMapComponent,
        QuotationOfferListItemSchedulesComponent,
        RequestForQuotationOfferComponent,
    ],
})
export class PortalsQuotationsWithoutRoutingModule {}
