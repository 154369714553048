import {
    trigger,
    transition,
    animate,
    style,
    state,
} from '@angular/animations';

export const fadeInAnimation = trigger('fadeIn', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(500)]),
]);

export const fadeInOutAnimation = trigger('fadeInOut', [
    state('*', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(200)]),
    transition(':leave', [style({ opacity: 1 }), animate(200)]),
]);

export const expandCollapse2 = trigger('expandCollapse2', [
    state(
        '*',
        style({
            'overflow-y': 'hidden',
            height: '*',
        })
    ),
    state(
        'void',
        style({
            height: '0',
            'overflow-y': 'hidden',
        })
    ),
    transition('* => void', animate('250ms ease-out')),
    transition('void => *', animate('250ms ease-in')),
]);

export const expandAnimation = trigger('expand', [
    state('*', style({ 'max-height': '1000px' })),
    transition(':enter', [style({ 'max-height': 0 }), animate(500)]),
]);
