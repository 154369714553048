import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    constructor() {}

    private _message?: string;

    public message$: BehaviorSubject<string | undefined> = new BehaviorSubject(
        this._message
    );

    public get message(): string | undefined {
        return this._message;
    }

    private set message(value: string | undefined) {
        this._message = value;
        this.message$.next(value);
    }

    private _shown = false;

    public shown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        this._shown
    );

    private get shown(): boolean {
        return this._shown;
    }

    private set shown(value: boolean) {
        this._shown = value;
        this.shown$.next(value);
    }

    public show(message?: string): void {
        this.shown = true;
        this.message = message;
    }

    public hide(): void {
        this.shown = false;
        this.message = undefined;
    }

    public toggle(): void {
        this.shown = !this.show;
    }
}
