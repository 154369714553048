import { Injectable } from '@angular/core';
import { InvoicesServiceBase } from '@qwyk/shared-stores/invoices';
import { Observable } from 'rxjs';
import { common, Portals } from '@qwyk/models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@qwyk/portals/environment';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class InvoicesService implements InvoicesServiceBase {
    constructor(private http: HttpClient) {}
    public getInvoices(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        query: any
    ): Observable<common.Pagination<Portals.Invoice>> {
        let httpQuery = null;
        if (query) {
            httpQuery = new HttpParams({ fromObject: query });
        }

        return this.http
            .get<common.Pagination<Portals.Invoice>>(
                `${environment.backendServer}/api/portals/invoices?${httpQuery}`
            )
            .pipe(first());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getInvoice(_id: string): Observable<Portals.Invoice> {
        throw new Error('Method not implemented.');
    }
    public updateInvoice(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _id: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        _payload: any
    ): Observable<Portals.Invoice> {
        throw new Error('Method not implemented.');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public deleteInvoice(_id: string): Observable<void> {
        throw new Error('Method not implemented.');
    }
}
