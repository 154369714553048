import { Component } from '@angular/core';

@Component({
    selector: 'qwyk-break-point-helper',
    templateUrl: './break-point-helper.component.html',
    styleUrls: ['./break-point-helper.component.scss'],
})
export class BreakPointHelperComponent {
    constructor() {}
}
