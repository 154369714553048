/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Portals } from '@qwyk/models';

import * as ShipmentContainersActions from './shipment-containers.actions';

export const SHIPMENTCONTAINERS_FEATURE_KEY = 'shipmentContainers';

export interface State extends EntityState<Portals.ShipmentDataContainer> {
    selectedId?: string | number; // which ShipmentContainers record has been selected
    loading: boolean; // has the ShipmentContainers list been loaded
    error?: string | null; // last known error (if any)
    shipment: any;
    geotrackLoading: boolean;
    geotrackError?: any | null;
    geotrack?: null | Portals.GeoTrackElement[];
}

export interface ShipmentContainersPartialState {
    readonly [SHIPMENTCONTAINERS_FEATURE_KEY]: State;
}

export const shipmentContainersAdapter: EntityAdapter<Portals.ShipmentDataContainer> =
    createEntityAdapter<Portals.ShipmentDataContainer>();

export const initialState: State = shipmentContainersAdapter.getInitialState({
    // set initial required properties
    loading: false,
    shipment: null,
    geotrackLoading: false,
});

const shipmentContainersReducer = createReducer(
    initialState,
    on(ShipmentContainersActions.init, (state, { shipment }) => ({
        ...state,
        loading: true,
        error: null,
        geotrackLoading: false,
        geotrackError: false,
        geotrack: null,
        shipment,
    })),
    on(
        ShipmentContainersActions.loadShipmentContainersSuccess,
        (state, { shipmentContainers }) =>
            shipmentContainersAdapter.setAll(shipmentContainers, {
                ...state,
                loading: false,
            })
    ),
    on(
        ShipmentContainersActions.loadShipmentContainersFailure,
        (state, { error }) => ({ ...state, error, loading: false })
    ),
    on(ShipmentContainersActions.reset, () => initialState),
    on(ShipmentContainersActions.select, (state, { container }) => ({
        ...state,
        selectedId: container.id,
    })),
    on(ShipmentContainersActions.loadShipmentContainerGeoTrack, state => ({
        ...state,
        geotrack: null,
        geotrackLoading: true,
        geotrackError: null,
    })),
    on(
        ShipmentContainersActions.loadShipmentContainerGeoTrackSuccess,
        (state, { geotrack }) => ({
            ...state,
            geotrack: geotrack,
            geotrackLoading: false,
        })
    ),
    on(
        ShipmentContainersActions.loadShipmentContainerGeoTrackFailure,
        (state, { error }) => ({
            ...state,
            geotrack: null,
            geotrackError: error,
            geotrackLoading: false,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return shipmentContainersReducer(state, action);
}
