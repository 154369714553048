import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    FaIconLibrary,
    FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
    faSpinner,
    faCheck,
    faExclamation,
    faMapPin,
    faTimes,
    faHistory,
} from '@fortawesome/pro-regular-svg-icons';
import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { UiModule } from '@qwyk/ui';

import {
    LocationTypeaheadItemDirective,
    LocationTypeaheadHintDirective,
    LocationTypeaheadPlaceholderDirective,
    LocationTypeaheadNotFoundDirective,
} from './components/location-typeahead/location-typeahead-templates.directive';
import { SharedModule } from './../shared/shared.module';
import { GooglePlacesDirective } from './directives/google-places.directive';
import { StateButtonComponent } from './components/state-button/state-button.component';
import { QueryBuilderComponent } from './components/query-builder/query-builder.component';
import { DimensionsDialogComponent } from './components/dimensions-dialog/dimensions-dialog.component';
import { LocationTypeaheadComponent } from './components/location-typeahead/location-typeahead.component';
import { LocationTypeaheadPanelComponent } from './components/location-typeahead/location-typeahead-panel.component';
import {TranslateModule} from '@ngx-translate/core';
@NgModule({
    declarations: [
        StateButtonComponent,
        LocationTypeaheadComponent,
        LocationTypeaheadPanelComponent,
        LocationTypeaheadItemDirective,
        LocationTypeaheadPlaceholderDirective,
        LocationTypeaheadHintDirective,
        LocationTypeaheadNotFoundDirective,
        QueryBuilderComponent,
        GooglePlacesDirective,
        DimensionsDialogComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        UiModule,
        MomentModule,
        TranslateModule,
    ],
    exports: [
        StateButtonComponent,
        LocationTypeaheadComponent,
        LocationTypeaheadItemDirective,
        LocationTypeaheadPlaceholderDirective,
        LocationTypeaheadHintDirective,
        LocationTypeaheadNotFoundDirective,
        QueryBuilderComponent,
        GooglePlacesDirective,
    ],
})
export class ControlsModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(
            faSpinner,
            faCheck,
            faExclamation,
            faMapPin,
            faTimes,
            faHistory
        );
    }
}
