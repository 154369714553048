import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { tap } from 'rxjs/operators';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveValidationModule } from 'angular-reactive-validation';

import { MasterDataService } from '@qwyk/core';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        AsyncPipe,
        NgSelectModule,
        TranslateModule,
        ReactiveFormsModule,
        ReactiveValidationModule,
    ],
    selector: 'qwyk-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent {
    @Input() form: FormGroup;
    @Input() hidden: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() countryChanged: EventEmitter<any> = new EventEmitter();

    countriesLoading = true;
    countries$ = this.masterData
        .getCountries()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .pipe(tap(_ => (this.countriesLoading = false)));

    constructor(private masterData: MasterDataService) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onCountryChanged(country: any) {
        this.countryChanged.emit(country);
    }
}
