import { Component } from '@angular/core';

@Component({
    selector: 'qw-procurement-dashboard',
    templateUrl: './procurement-dashboard.component.html',
    styleUrls: ['./procurement-dashboard.component.scss'],
})
export class ProcurementDashboardComponent {
    constructor() {}
}
