import { CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class IsMagayaNetworkGuard implements CanActivate, CanActivateChild {
    constructor(private siteConfig: SiteConfigFacade) {}

    canActivate(): Observable<boolean> {
        return this.siteConfig.isMagayaNetwork$;
    }

    canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }
}
