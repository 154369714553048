/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from '@qwyk/portals/environment';
import { first, map, take } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { common, Portals, UserSummary } from '@qwyk/models';
import { QuotationsServiceBase } from '@qwyk/shared-stores/quotations';

@Injectable({
    providedIn: 'root',
})
export class QuotationsService implements QuotationsServiceBase {
    constructor(private http: HttpClient) {}

    public getQuotations(
        query: any
    ): Observable<common.Pagination<Portals.QuotationEntity>> {
        let httpQuery = null;
        if (query) {
            const q = { ...query };
            Object.keys(q).forEach(key => {
                if (!q[key]) {
                    delete q[key];
                }
            });
            httpQuery = new HttpParams({ fromObject: q });
        }

        return this.http
            .get<common.Pagination<Portals.QuotationEntity>>(
                `${environment.backendServer}/api/portals/quotations/summary${
                    httpQuery ? '?' + httpQuery : ''
                }`
            )
            .pipe(first());
    }

    public getQuotation(id: string): Observable<Portals.QuotationEntity> {
        return this.http
            .get<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/portals/quotations/${id}`
            )
            .pipe(
                first(),
                map(response => response.data)
            );
    }

    public createQuotationRateRequest(
        quotationId: string,
        payload: { reason: string; remarks: string | null }
    ): Observable<Portals.QuotationRateRequest> {
        return this.http
            .post<{ data: Portals.QuotationRateRequest }>(
                `${environment.backendServer}/api/portals/quotations/${quotationId}/request`,
                payload
            )
            .pipe(
                first(),
                map(response => response.data)
            );
    }

    public getOfferSchedules(offer: Portals.QuotationOffer) {
        return this.http
            .get<any>(
                `${environment.backendServer}/api/portals/quotations/${offer.quotation_id}/offers/${offer.id}/schedules`
            )
            .pipe(first());
    }

    getRecentSearches(): Observable<{ query: any; created_at: Date }[]> {
        return this.http
            .get<{ data: { query: any; created_at: Date }[] }>(
                `${environment.backendServer}/api/portals/users/queries/quotations`
            )
            .pipe(
                first(),
                map(d => d.data)
            );
    }

    public createShipmentFromOffer(
        offer: Portals.QuotationOffer,
        schedule: any
    ): Observable<{ id: string }> {
        return this.http
            .post<{ id: string }>(
                `${environment.backendServer}/api/portals/quotations/${offer.quotation_id}/offers/${offer.id}/shipment`,
                { schedule }
            )
            .pipe(first());
    }

    public getUsersForSharing(term: string): Observable<UserSummary[]> {
        return this.http
            .get<{ data: UserSummary[] }>(
                `${environment.backendServer}/api/portals/users/by-email-strict?email=${term}`
            )
            .pipe(
                take(1),
                map(r => r.data)
            );
    }

    public shareQuotation(
        id: string,
        payload: { users: any[]; notify: boolean; message: string }
    ): Observable<Portals.QuotationEntity> {
        return this.http
            .post<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/portals/quotations/${id}/share`,
                payload
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    unShareQuotation(
        id: string,
        user: UserSummary
    ): Observable<Portals.QuotationEntity> {
        return this.http
            .delete<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/portals/quotations/${id}/share/${user.id}`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public downloadQuotation(
        quotationId: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _offerId: string = null
    ): Observable<Blob> {
        return this.http
            .post(
                `${environment.backendServer}/api/portals/quotations/${quotationId}/download`,
                null,
                { responseType: 'blob' }
            )
            .pipe(
                first(),
                map(response => {
                    return new Blob([response], {
                        type: 'application/pdf',
                    });
                })
            );
    }
}
