<ng-container *ngIf="shipmentCounts$ | async; let count;">
    <div [@fadeIn] class="row align-items-center">
        <div class="col-12 col-md-6 col-xl-3">
            <div class="card h-100 mb-3">
                <div class="card-body p-3 py-4 d-flex align-items-center justify-content-between">
                    <h1 class="m-0 mr-4 flex-shrink-0">
                        <fa-icon [icon]="['far', 'paper-plane']" class="mr-1"></fa-icon>
                        {{ count.active }}
                    </h1>
                    <div class="flex-grow-1 overflow-hidden">
                        <h6 class="m-0 font-weight-normal overflow-hidden"
                            style="white-space: pre; text-overflow: ellipsis;">{{ 'portal.dashboard.ops.active-shipments' | translate }}</h6>
                        <a [routerLink]="['/my-portal/shipments/list']"
                            [queryParams]="{query_key: 'milestone', query_value: 'origin.booked', query_display: 'Booked'}">
                            {{ 'common.view-all' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-xl-3">
            <div class="card h-100 mb-3">
                <div class="card-body p-3 py-4 d-flex align-items-center justify-content-start">
                    <h1 class="m-0 mr-4 flex-shrink-0">
                        <fa-icon [icon]="['far', 'alarm-clock']" class="mr-1"></fa-icon>
                        {{ count.upcoming }}
                    </h1>
                    <div class="flex-grow-1 overflow-hidden">
                        <h6 class="m-0 font-weight-normal overflow-hidden"
                            style="white-space: pre; text-overflow: ellipsis;">
                            {{ 'portal.dashboard.ops.upcoming-events' | translate }}
                            <span class="text-muted" ngbTooltip="{{ 'portal.dashboard.ops.upcoming-events-tooltip' | translate }}">
                                <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
                            </span>
                        </h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-xl-3">
            <div class="card h-100 mb-3">
                <div class="card-body p-3 py-4 d-flex align-items-center justify-content-between" [ngClass]="{
                    'text-success': count.overdue / count.active === 0,
                    'text-warning': count.overdue / count.active > 0 && count.overdue / count.active < .15,
                    'text-danger': count.overdue / count.active >= .15
                    }">
                    <h1 class="m-0 mr-4 flex-shrink-0">
                        <fa-icon [icon]="['far', 'alarm-exclamation']" class="mr-1"></fa-icon>
                        {{ count.overdue }}
                    </h1>
                    <div class="flex-grow-1 overflow-hidden">
                        <h6 class="m-0 font-weight-normal overflow-hidden"
                            style="white-space: pre; text-overflow: ellipsis;">
                            {{ 'portal.dashboard.ops.overdue-milestones' | translate }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6 col-xl-3">
            <div class="card h-100 mb-3">
                <div class="card-body p-3 py-4 d-flex align-items-center justify-content-between">
                    <h1 class="m-0 mr-4 flex-shrink-0">
                        <fa-icon [icon]="['far', 'file']" class="mr-1"></fa-icon>
                        {{ count.drafts }}
                    </h1>
                    <div class="flex-grow-1  overflow-hidden">
                        <h6 class="m-0 font-weight-normal overflow-hidden"
                            style="white-space: pre; text-overflow: ellipsis;">{{ 'common.drafts' | translate }}
                        </h6>
                        <a [routerLink]="['/my-portal/shipments/list']"
                            [queryParams]="{query_key: 'milestone', query_value: 'DRAFT', query_display: 'Draft'}">
                            {{ 'common.view-all' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="shipmentsData$ | async; let data; else loadingData">
    <div class="pb-3" [@fadeIn]>
        <div class="row mt-2">
            <div class="col-12">
                <div class="card h-100">
                    <div
                        class="card-header bg-white border-bottom-0 strong px-0 mx-4 py-3 d-flex justify-content-between align-items-center">
                        <h5 class="m-0">
                            {{ 'portal.dashboard.ops.shipments-with-upcoming-events' | translate }}
                        </h5>
                        <ngb-pagination *ngIf="upcomingPageSize < data.upcoming_shipments.length"
                            [(page)]="upcomingPage" [pageSize]="upcomingPageSize" [maxSize]="1"
                            class="d-flex justify-content-center" size="sm"
                            [collectionSize]="data.upcoming_shipments.length">
                        </ngb-pagination>
                    </div>
                    <ul class="list-group list-group-flush h-100">
                        <ng-container
                            *ngFor="let shipment of data.upcoming_shipments  | slice: (upcomingPage-1) * upcomingPageSize : (upcomingPage-1) * upcomingPageSize + upcomingPageSize">
                            <ng-container [ngTemplateOutlet]="shipmentSummary"
                                [ngTemplateOutletContext]="{shipment: shipment}">
                            </ng-container>
                        </ng-container>
                        <li class="list-group-item text-center" *ngIf="data.upcoming_shipments.length === 0">
                            <img src="https://cdn.qwyk.io/portals/assets/noupcoming.png" style="max-height: 180px;">
                            <h5 class="m-0 font-weight-normal text-muted">
                                {{ 'common.no_item' | translate : { value: 'portal.dashboard.ops.upcoming-events' | translate | lowercase } }}
                            </h5>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="card h-100">
                    <div
                        class="card-header border-bottom-0 bg-white strong px-0 mx-4 py-3  d-flex justify-content-between align-items-center">
                        <h5 class="m-0">
                            {{ 'portal.dashboard.ops.shipments-with-milestone-alerts' | translate }}
                        </h5>
                        <ngb-pagination *ngIf="overduePageSize < data.overdue_shipments.length" [(page)]="overduePage"
                            [pageSize]="overduePageSize" [maxSize]="1" class="d-flex justify-content-center" size="sm"
                            [collectionSize]="data.overdue_shipments.length">
                        </ngb-pagination>
                    </div>
                    <ul class="list-group list-group-flush p-0">
                        <ng-container
                            *ngFor="let shipment of data.overdue_shipments | slice: (overduePage-1) * overduePageSize : (overduePage-1) * overduePageSize + overduePageSize">
                            <ng-container [ngTemplateOutlet]="overdueShipmentSummary"
                                [ngTemplateOutletContext]="{shipment: shipment}">
                            </ng-container>
                        </ng-container>
                        <li class="list-group-item text-center" *ngIf="data.overdue_shipments.length === 0">
                            <img src="https://cdn.qwyk.io/portals/assets/nooverdue.png" style="max-height: 180px;">
                            <h5 class="m-0 font-weight-normal text-muted">
                                {{ 'common.no_item' | translate : { value: 'portal.dashboard.ops.overdue-milestones' | translate | lowercase } }}
                            </h5>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12">
                <div class="card h-100">
                    <div class="card-header bg-white strong px-0 mx-4 py-3">
                        <h5 class="m-0">
                            {{ 'portal.dashboard.ops.shipments-by-status' | translate }}
                        </h5>
                    </div>
                    <div class="card-body">
                        <div style="min-height: 200px; height: 100%">
                            <ngx-charts-bar-vertical
                                class="chart-container"
                                [view]="view"
                                [results]="data.by_status"
                                [showXAxisLabel]="false"
                                [showYAxisLabel]="false"
                                [yAxis]="true"
                                [xAxis]="true"
                                [scheme]="{
                                    domain: ['#f3f3f3', '#dae9d9', '#c0dec0', '#a6d4a7', '#8cc98e', '#70be76', '#51b25e', '#28a745', '#28a745', '#28a745', '#28a745', '#28a745']
                                }">
                            </ngx-charts-bar-vertical>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #overdueShipmentSummary let-shipment="shipment">
    <li class="list-group-item mx-4 px-0 py-3" style="margin-bottom: 0;">
        <div>
            <a [routerLink]="['/my-portal/shipments/', shipment.id]"><b>{{ shipment.name }}</b></a> |
            {{ shipment.organization_shipment_reference || ('portal.dashboard.ops.no-reference' | translate) }}
        </div>
        <div class="text-danger">
            <b>{{ shipment.next_milestone?.expected_label | titlecase }}</b> {{ 'common.overdue' | translate | lowercase }} {{ 'common.by' | translate }}
            {{ shipment.next_milestone?.expected_timestamp | amTimeAgo:true }}
        </div>
        <div>
            {{ 'common.expected' | translate }}
            <span [ngbTooltip]="shipment.next_milestone?.expected_timestamp | amDateFormat: 'LLL'  || ('common.unknown' | translate)">
                {{ shipment.next_milestone?.expected_timestamp | amDateFormat: 'LLL' }}
            </span>
        </div>
    </li>
</ng-template>

<ng-template #shipmentSummary let-shipment="shipment">
    <li class="list-group-item mx-4 px-0 py-3" style="margin-bottom: 0;">
        <div class="mb-2">
            <a [routerLink]="['/my-portal/shipments/', shipment.id]"><b>{{ shipment.name }}</b></a> |
            {{ shipment.organization_shipment_reference || ('portal.dashboard.ops.no-reference' | translate) }}
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="w-25">
                <div>
                    {{ shipment.status.actual_label }}
                </div>
                <div class="text-muted"
                    [ngbTooltip]="shipment.status?.actual_timestamp | amDateFormat: 'LLL'  || ('common.unknown' | translate)">
                    {{ shipment.status?.actual_timestamp | amDateFormat: 'MMM D'  || ('common.unknown' | translate) }}
                </div>
            </div>
            <div class="flex-grow-1 text-center">
                <ng-container
                    *ngIf="shipment.status.actual_timestamp | dateDiffInPercent: shipment.next_milestone?.expected_timestamp; let dayDiff">
                    <ngb-progressbar class="mt-1 rotate-180" style="opacity: .75"
                        [type]="dayDiff.days < 0 ? 'danger' : dayDiff.percent < 10 ? 'warning' : 'success'"
                        [value]="dayDiff.percent" height="14px">
                    </ngb-progressbar>
                    <small class="text-muted mt-1" *ngIf="shipment.next_milestone?.expected_timestamp">
                        {{ shipment.next_milestone?.expected_timestamp | amTimeAgo }}
                    </small>
                    <small class="text-muted mt-1" *ngIf="!shipment.next_milestone?.expected_timestamp">
                        &nbsp;
                    </small>
                </ng-container>
            </div>
            <div class="w-25 text-right">
                <div>
                    {{ shipment.next_milestone?.expected_label }}
                </div>
                <div class="text-muted"
                    [ngbTooltip]="shipment.next_milestone?.expected_timestamp | amDateFormat: 'LLL'  || ('common.unknown' | translate)">
                    &nbsp;{{ shipment.next_milestone?.expected_timestamp | amDateFormat: 'MMM D' || ('common.unknown' | translate) }}
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-4 d-flex align-items-start">
                <div style="margin-left: -12px; margin-right: -28px;">
                    <fa-icon [icon]="['far', shipment.transport_mode | transportModeIcon]" size="3x" [fixedWidth]="true"
                        style="opacity: 0.125;" class=" text-muted">
                    </fa-icon>
                </div>
                <div>
                    <div>
                        <a [routerLink]="['/my-portal/shipments/', shipment.id]"><b>{{ shipment.name }}</b></a>
                    </div>
                    <div>{{ shipment.organization_shipment_reference || 'No reference' }}</div>
                </div>
            </div>
            <div class="col-8">
                <div class="d-flex flow-row justify-content-between align-items-center">
                    <div class="w-25">
                        <div class="text-truncate">
                            {{ shipment.origin.name }}
                        </div>
                        <div class="text-muted">{{ (shipment.etd | amDateFormat: 'MMM D') || 'No ETD' }}</div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="text-center small" style="margin-bottom: -2px;">
                            {{ shipment.vessel }}&nbsp;{{ shipment.transport_mode === 'OCEAN' ? shipment.voyage : null }}
                        </div>
                        <div class="flex-grow-1 d-flex align-items-center">
                            <div class="line flex-grow-1 border-top-muted"></div>
                            <div class="lp-triangle"></div>
                        </div>
                    </div>
                    <div class="w-25 text-right">
                        <div class="text-truncate">{{ shipment.destination.name }}</div>
                        <div class="text-muted">{{ (shipment.eta | amDateFormat: 'MMM D') || 'No ETA' }}</div>
                    </div>

                </div>
                <hr>

        </div>
        </div> -->
    </li>
</ng-template>

<ng-template #loadingData>
    <qw-loading-indicator componentClass="m-5 text-muted" text="{{ 'portal.dashboard.ops.loading-message' | translate }}"></qw-loading-indicator>
</ng-template>
