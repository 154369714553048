import {AbstractControl, AsyncValidator, FormControl, ValidationErrors} from '@angular/forms';
import {Observable, of} from "rxjs";

export function requiredIfTrue(
    value: boolean
) {
    let thisControl: FormControl;

    return function requiredIfTrueValidate(control: FormControl) {
        if (!control.parent) {
            return null;
        }

        // Initializing the validator.
        if (!thisControl) {
            thisControl = control;
        }

        if (value) {
            return of({required: true});
        }

        return of(null);
    };
}

export class requiredIfTrueAsync implements AsyncValidator {
    constructor(private test: () => boolean) {
    }

    validate(
        control: AbstractControl
    ): Promise<ValidationErrors> | Observable<ValidationErrors> {
        if (!control.value && this.test()) {
            return of({required: true});
        }
        return of(null);
    }
}
