/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import {
    ActivitiesEntity,
    ActivitiesIndexQuery,
    CommentActivityPayload,
    HasActivities,
} from './activities.models';
import { common } from '@qwyk/models';

export const loadActivities = createAction(
    '[Activities/API] Load Activities',
    props<{ parent: HasActivities; query: ActivitiesIndexQuery }>()
);

export const loadMoreActivities = createAction(
    '[Activities/API] Load More Activities'
);

export const refreshActivities = createAction(
    '[Activities/API] Refresh Activities'
);

export const loadActivitiesSuccess = createAction(
    '[Activities/API] Load Activities Success',
    props<{
        activities: ActivitiesEntity[];
        pagination: common.CursorPaginationMeta;
    }>()
);

export const loadMoreActivitiesSuccess = createAction(
    '[Activities/API] Load More Activities Success',
    props<{
        activities: ActivitiesEntity[];
        pagination: common.CursorPaginationMeta;
    }>()
);

export const loadActivitiesFailure = createAction(
    '[Activities/API] Load Activities Failure',
    props<{ error: any }>()
);

export const createComment = createAction(
    '[Activities/API] Create Comment',
    props<{ parent: HasActivities; payload: CommentActivityPayload }>()
);

export const createCommentSuccess = createAction(
    '[Activities/API] Create Comment Success',
    props<{ activity: ActivitiesEntity }>()
);

export const createCommentFailure = createAction(
    '[Activities/API] Create Comment Failure',
    props<{ error: any }>()
);

export const updateComment = createAction(
    '[Activities/API] Update Comment',
    props<{
        parent: HasActivities;
        activity: ActivitiesEntity;
        payload: CommentActivityPayload;
    }>()
);

export const updateCommentSuccess = createAction(
    '[Activities/API] Update Comment Success',
    props<{ activity: ActivitiesEntity }>()
);

export const updateCommentFailure = createAction(
    '[Activities/API] Update Comment Failure',
    props<{ error: any }>()
);

export const deleteComment = createAction(
    '[Activities/API] Delete Comment',
    props<{ parent: HasActivities; activity: ActivitiesEntity }>()
);

export const deleteCommentSuccess = createAction(
    '[Activities/API] Delete Comment Success',
    props<{ activity: ActivitiesEntity }>()
);

export const deleteCommentFailure = createAction(
    '[Activities/API] Delete Comment Failure',
    props<{ error: any }>()
);

export const resetActivities = createAction(
    '[Activities/API] Reset Activities'
);
