<div class="bg-white">
    <div class="container py-3 pb-5 px-1 px-md-3">
        <div class="small text-muted mb-3 border-bottom pb-2">
            <a [routerLink]="['/my-portal/dashboard']" class="text-muted">{{ 'portal.common.my-portal' | translate }}</a>
            &rsaquo;
            <a [routerLink]="['./']" class="text-muted font-weight-bold">{{ 'portal.menu.side-menu.notifications' | translate }}</a>
        </div>

        <div class="d-flex justify-content-between align-items-center">
            <h3 class="font-weight-normal m-0">{{ 'portal.common.your-transaction' | translate : { value: 'portal.menu.side-menu.notifications' | translate | lowercase } }}</h3>
            <div>
                <qwyk-module-info-button
                    [vimeoVideoId]="738347851"
                    buttonClass="btn-sm"
                ></qwyk-module-info-button>
                <button [routerLink]="['/my-portal', 'settings', 'notifications']"
                        class="btn btn-primary btn-sm ml-2" ngbTooltip="{{ 'portal.notifications.notifications-settings' | translate }}">
                    <fa-icon [icon]="['far', 'cog']"></fa-icon>
                    {{ 'portal.notifications.notifications-settings' | translate }}
                </button>
            </div>
        </div>

        <ul class="list-group list-group-flush mt-3 border-top">
            <ng-container *ngIf="notifications$ | async; let notifications">
                <qwyk-notification (click)="onView(notification)"
                                   (delete)="onDelete(notification)"
                                   (toggleRead)="onToggleRead(notification)"
                                   *ngFor="let notification of notifications"
                                   [expanded]="(selectedNotification$ | async)?.id === notification.id"
                                   [ngClass]="{'bg-light': (selectedNotification$ | async)?.id === notification.id}"
                                   [notification]="notification"
                                   class="list-group-item list-group-item-action d-flex flex-column py-3"
                                   style="cursor: pointer;"
                ></qwyk-notification>
                <li *ngIf="(loadingPage$ | async) === false && (!notifications || notifications.length === 0)"
                    class="list-group-item text-center text-muted border-bottom">
                    <fa-icon [icon]="['far', 'search']" size="2x"></fa-icon>
                    <h6 class="mt-2 font-weight-normal">{{ 'portal.notifications.no-notifications' | translate }}</h6>
                </li>
                <li *ngIf="loadingPage$ | async" class="list-group-item text-center text-muted border-bottom">
                    <qwyk-loading-indicator componentClass="text-muted" size="sm" text="{{ 'common.loading' | translate }} {{ 'portal.menu.side-menu.notifications' | translate | lowercase }}...">
                    </qwyk-loading-indicator>
                </li>
            </ng-container>
        </ul>

        <div class="small text-muted text-center">{{ 'portal.notifications.notifications-will-be-kept' | translate }}
        </div>

        <div *ngIf="paginationInfo$ | async; let paginationInfo" class="text-center text-muted small">
            <button (click)="onLoadMore()" *ngIf="canLoadPage$ | async" [disabled]="loadingPage$ | async"
                    class="btn btn-light">
                <fa-icon *ngIf="loadingPage$ | async" [icon]="['far', 'spinner']" [pulse]="true" class="mr-2"></fa-icon>
                {{ 'common.load-more' | translate }}
            </button>
        </div>
    </div>
</div>

<div *ngIf="false" class="container p-3">
    <div class="small text-muted mb-3 border-bottom pb-2">
        <a [routerLink]="['/my-portal']" class="text-muted">{{ 'portal.common.my-portal' | translate }}</a>
        &rsaquo;
        <a [routerLink]="['./']" class="font-weight-bold text-muted">{{ 'portal.menu.side-menu.notifications' | translate }}</a>
    </div>

    <div class="d-flex justify-content-between items-align-center mb-2">
        <h3 class="font-weight-normal">{{ 'portal.common.your-transaction' | translate : { value: 'portal.menu.side-menu.notifications' | translate | lowercase } }}</h3>
        <div>
            <button [routerLink]="['/my-portal', 'settings', 'notifications']" class="btn btn-primary btn-sm"
                    ngbTooltip="{{ 'portal.notifications.notifications-settings' | translate }}">
                <fa-icon [icon]="['far', 'cog']"></fa-icon>
                {{ 'portal.notifications.notifications-settings' | translate }}
            </button>
        </div>
    </div>

    <ul *ngIf="loaded$ | async; else loading" class="list-group list-group-flush">
        <ng-container *ngIf="notifications$ | async; let notifications">
            <qwyk-notification (click)="onView(notification)"
                               (delete)="onDelete(notification)" (toggleRead)="onToggleRead(notification)"
                               *ngFor="let notification of notifications"
                               [expanded]="(selectedNotification$ | async)?.id === notification.id"
                               [notification]="notification"
                               class="list-group-item list-group-item-action border-top border-bottom p-0"
                               style="cursor: pointer;">
            </qwyk-notification>
            <li *ngIf="notifications.length === 0" class="list-group-item border-top border-bottom text-center p-4">
                <fa-icon [icon]="['fad', 'sun']" size="3x" style="color: #f9d71c"></fa-icon>
                <h4 class="font-weight-normal mt-3 mb-0">{{ 'portal.notifications.inbox-zero' | translate }}</h4>
            </li>
        </ng-container>
    </ul>

    <div *ngIf="paginationInfo$ | async; let paginationInfo" class="text-center text-muted small">
        <button (click)="onLoadMore()" *ngIf="canLoadPage$ | async" [disabled]="loadingPage$ | async"
                class="btn btn-light">
            <fa-icon *ngIf="loadingPage$ | async" [icon]="['far', 'spinner']" [pulse]="true" class="mr-2"></fa-icon>
            {{ 'common.load-more' | translate }}
        </button>
    </div>
</div>

<ng-template #loading>
    <qwyk-loading-indicator componentClass="text-muted my-5" text="{{ 'portal.notifications.loading-your-notifications' | translate }}">
    </qwyk-loading-indicator>
</ng-template>
