import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SITECONFIG_FEATURE_KEY, SiteConfigPartialState, State,} from './site-config.reducer';

// Lookup the 'SiteConfig' feature state managed by NgRx
export const getSiteConfigState = createFeatureSelector<SiteConfigPartialState,
    State>(SITECONFIG_FEATURE_KEY);

// const { selectAll, selectEntities } = siteConfigAdapter.getSelectors();

export const getSiteConfig = createSelector(
    getSiteConfigState,
    state => state.config
);
export const getSiteConfigOrganization = createSelector(
    getSiteConfigState,
    state =>
        state.config
            ? {
                organization_id: state.config.organization_id,
                organization_name: state.config.organization_name,
                site_id: state.config.site_id,
            }
            : null
);
export const getSiteConfigDesign = createSelector(getSiteConfigState, state =>
    state.config ? state.config.design : null
);

export const getSiteConfigFeatures = createSelector(getSiteConfigState, state =>
    state.config ? state.config.features : null
);

export const getSiteConfigAuthenticationType = createSelector(
    getSiteConfigState,
    state => (state.config ? state.config.authentication_type : null)
);

export const getSiteConfigLoaded = createSelector(
    getSiteConfigState,
    (state: State) => state.loaded
);

export const getSiteConfigError = createSelector(
    getSiteConfigState,
    (state: State) => state.error
);

export const isMagayaNetwork = createSelector(
    getSiteConfigState,
    (state: State) => state.config.is_magaya_network
);
