import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromActivities from './+state/activities.reducer';
import {ActivitiesEffects} from './+state/activities.effects';
import {ActivitiesFacade} from './+state/activities.facade';
import {SharedStoresActivitiesConfig} from './shared-stores-activities.config';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromActivities.ACTIVITIES_FEATURE_KEY, fromActivities.reducer),
        EffectsModule.forFeature([ActivitiesEffects]),
    ],
    providers: [ActivitiesFacade],
})
export class SharedStoresActivitiesModule {
    static forFeature(config: SharedStoresActivitiesConfig): ModuleWithProviders<SharedStoresActivitiesModule> {
        return {
            ngModule: SharedStoresActivitiesModule, providers: [config.service]
        };
    }
}
