export enum AmplitudeEvents {
    // User opens the payment dialog
    PAYMENT_DIALOG_OPENED = '[PAYMENT] Dialog Opened',
    // User selects a payment provider to start their payment (passes payment provider as metadata to event)
    PAYMENT_INITIATED = '[PAYMENT] Initiated',
    // User cancels out of the payment process after initiating it.
    PAYMENT_ABORTED = '[PAYMENT] Aborted',
    // An error occured during the payments process (includes failed password)
    PAYMENT_FAILED = '[PAYMENT] Failed',
    // The user completed the payment process through the provider
    PAYMENT_COMPLETED = '[PAYMENT] Completed',
    // The payments dialog was closed
    PAYMENT_DIALOG_CLOSED = '[PAYMENT] Dialog Closed'
}

export abstract class AmplitudeEvent {
    protected abstract readonly _event: AmplitudeEvents

    protected constructor() {
    }

    public log(instance: any): void {
        instance.logEvent(this._event);
    }
}

export class PaymentDialogOpenedEvent extends AmplitudeEvent {
    protected readonly _event: AmplitudeEvents = AmplitudeEvents.PAYMENT_DIALOG_OPENED;

    public static create(): AmplitudeEvent {
        return new this();
    }
}

export class PaymentInitiatedEvent extends AmplitudeEvent {
    protected readonly _event: AmplitudeEvents = AmplitudeEvents.PAYMENT_INITIATED;

    protected constructor(private paymentProvider: string) {
        super();
    }

    public static create(paymentProvider: string): AmplitudeEvent {
        return new this(paymentProvider);
    }

    public log(instance: any) {
        instance.logEvent(this._event, {paymentProvider: this.paymentProvider});
    }
}
