import { createAction, props } from '@ngrx/store';

export const setupAuthentication = createAction(
    '[Hub/Authentication] Setup Authentication'
);

export const setupAuthenticationSuccess = createAction(
    '[Hub/Authentication] Setup Authentication Success',
    props<{ token: string }>()
);

export const login = createAction('[Hub/Authentication] Login');
export const logout = createAction(
    '[Hub/Authentication] Logout',
    props<{ redirectTo: string | null }>()
);
export const handleAuth0Callback = createAction(
    '[Hub/Authentication] Handle Auth0 Callback'
);
export const handleAuth0CallbackSuccess = createAction(
    '[Hub/Authentication] Handle Auth0 Callback Success',
    props<{ token: string }>()
);

export const loadUser = createAction('[Hub/Authentication] Load User');
export const loadUserSuccess = createAction(
    '[Hub/Authentication] Load User Success',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ user: any; meta?: any }>()
);
export const loadUserFailure = createAction(
    '[Hub/Authentication] Load User Failure'
);

export const resetImpersonation = createAction(
    '[Hub/Authentication] Reset Impersonation'
);
