import { Inject, InjectionToken, Injectable } from '@angular/core';

export interface FaviconsConfig {
    cacheBusting?: boolean;
}

export interface Favicon {
    href: string;
    type: string;
}

export const BROWSER_FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>(
    'Favicons Config'
);

export abstract class Favicons {
    abstract activate(icon: Favicon): void;
    abstract reset(): void;
}

@Injectable()
export class BrowserFavicons implements Favicons {
    private elementId: string;
    private useCacheBusting: boolean;

    constructor(@Inject(BROWSER_FAVICONS_CONFIG) config: FaviconsConfig) {
        this.elementId = 'favicons-service-injected-node';
        this.useCacheBusting = config.cacheBusting || false;
    }

    public activate(icon: Favicon): void {
        this.setNode(icon.type, icon.href);
    }

    public reset(): void {}

    private addNode(type: string, href: string): void {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('id', this.elementId);
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', type);
        linkElement.setAttribute('href', href);
        document.head.appendChild(linkElement);
    }

    private cacheBustHref(href: string): string {
        const augmentedHref =
            href.indexOf('?') === -1
                ? `${href}?faviconCacheBust=${Date.now()}`
                : `${href}faviconCacheBust=${Date.now()}`;

        return augmentedHref;
    }

    // I remove any favicon nodes that are not controlled by this service.
    // tslint:disable-next-line: no-unused-variable
    private removeExternalLinkElements(): void {
        const linkElements = document.querySelectorAll(
            // tslint:disable-next-line: quotemark
            "link[ rel ~= 'icon' i]"
        );

        for (const linkElement of Array.from(linkElements)) {
            linkElement.parentNode.removeChild(linkElement);
        }
    }

    // I remove the favicon node from the document header.
    private removeNode(): void {
        const linkElement = document.head.querySelector('#' + this.elementId);

        if (linkElement) {
            document.head.removeChild(linkElement);
        }
    }

    // I remove the existing favicon node and inject a new favicon node with the given
    // element settings.
    private setNode(type: string, href: string): void {
        const augmentedHref = this.useCacheBusting
            ? this.cacheBustHref(href)
            : href;

        this.removeNode();
        this.addNode(type, augmentedHref);
    }
}
