<div class="flex-grow-1 d-none d-md-block">
    <form [formGroup]="searchForm">
        <div class="grid p-fluid">
            <div class="form-row mb-3">

                <div class="form-group mb-0 col">
                    <input formControlName="friendly_id" id="friendly_id" name="friendly_id"
                           pInputText placeholder="{{'common.quotations.quotation' | translate | titlecase}} #"
                           type="text"
                    >
                </div>

                <div class="form-group mb-0 col">
                    <p-dropdown
                            [options]="statusOptions"
                            [showClear]="true"
                            appendTo="body"
                            class="flex-grow-1"
                            formControlName="status"
                            placeholder="{{'common.sort_options.status' | translate | titlecase}}"
                    >
                        <ng-template pTemplate="selectedItem" let-option>
                            <div class="flex align-items-center gap-2">
                                <div>{{ option.label | translate }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ option.label | translate }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div class="form-group mb-0 col">
                    <p-dropdown
                            [options]="productOptions"
                            [showClear]="true"
                            appendTo="body"
                            class="flex-grow-1"
                            formControlName="product"
                            placeholder="{{'portal.shipment.table.columns.product' | translate | titlecase}}"
                    >
                        <ng-template pTemplate="selectedItem" let-option>
                            <div class="flex align-items-center gap-2">
                                <div>
                                    {{ option.label | translate }}
                                    {{ option.load_type }}
                                </div>
                            </div>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ option.label | translate }} {{option.load_type}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div [ngClass]="{'d-none': view === 'list'}" class="form-group mb-0 col">
                    <input formControlName="origin_name" id="origin_name" name="origin_name"
                           pInputText placeholder="{{'common.quotations.search.origin' | translate}}"
                           type="text"
                    >
                </div>

                <div [ngClass]="{'d-none': view === 'list'}" class="form-group mb-0 col">
                    <input formControlName="destination_name" id="destination_name" name="destination_name"
                           pInputText placeholder="{{'common.quotations.search.destination' | translate}}"
                           type="text"
                    >
                </div>

                <div class="form-group mb-0 col">
                    <div class="input-group">
                        <p-dropdown
                                [options]="sortOptions"
                                [style]="{
                                'border-top-right-radius': '0',
                                'border-bottom-right-radius': '0'
                            }"
                                appendTo="body"
                                class="flex-grow-1"
                                formControlName="sort"
                        >
                            <ng-template pTemplate="selectedItem" let-option>
                                <div class="flex align-items-center gap-2">
                                    <div>{{ option.label | translate }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-option pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ option.label | translate }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <div class="input-group-append">
                            <button
                                    (click)="toggleOrderDirection()"
                                    [disabled]="loading"
                                    ngbTooltip="{{ (orderByDirection === 'asc'
                                    ? 'common.ascending'
                                    : 'common.descending') | translate }}"
                                    class="btn btn-outline-primary"
                                    style="max-height: 36.5px;"
                                    type="button"
                            >
                                <fa-icon
                                        [icon]="[
                                        'fas',
                                        orderByDirection === 'asc'
                                            ? 'sort-amount-up'
                                            : 'sort-amount-down'
                                    ]"
                                        size="sm"
                                >
                                </fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showMore && view !== 'table'" class="form-row mb-3">
                <div [ngClass]="{'d-none': view !== 'list'}" class="form-group mb-0 col">
                    <input formControlName="origin_name" id="origin_name_2" name="origin_name"
                           pInputText placeholder="{{'common.quotations.search.origin' | translate}}"
                           type="text"
                    >
                </div>

                <div [ngClass]="{'d-none': view !== 'list'}" class="form-group mb-0 col">
                    <input formControlName="destination_name" id="destination_name_2" name="destination_name"
                           pInputText placeholder="{{'common.quotations.search.destination' | translate}}"
                           type="text"
                    >
                </div>
            </div>

            <div class="form-row align-items-end">
                <div class="col">
                    <qwyk-list-table-switcher [(view)]="view"></qwyk-list-table-switcher>
                </div>
                <div class="col text-right">
                    <button (click)="showMore = !showMore" *ngIf="view !== 'table'" [disabled]="searchForm.disabled"
                            class="btn btn-link btn-sm mr-2">
                        {{ (showMore ? 'common.less-options' : 'common.more-options') | translate }}
                    </button>
                    <button (click)="onSearch()" [disabled]="searchForm.disabled" class="btn btn-secondary mr-2">
                        <ng-container *ngIf="!loading">{{'common.search' | translate}}</ng-container>
                        <ng-container *ngIf="loading">
                            <fa-icon [icon]="['far', 'spinner']" [pulse]="true"></fa-icon>
                        </ng-container>
                    </button>
                    <button (click)="onReset()" [disabled]="searchForm.disabled" class="btn btn-outline-primary">
                        {{'common.reset'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
