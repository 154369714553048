<button
        (click)="onClick()"
        [ngClass]="controlClass"
        [disabled]="disabled"
        type="button"
>
    <fa-icon
            *ngIf="labelIcon"
            [fixedWidth]="true"
            [icon]="[iconType, labelIcon]"
            class="mr-1"
    ></fa-icon>
    {{ labelText }}
</button>
