import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-containers-list-item]',
    templateUrl: './shipment-containers-list-item.component.html',
    styleUrls: ['./shipment-containers-list-item.component.scss'],
})
export class ShipmentContainersListItemComponent {
    @Input() shipmentContainer: Portals.ShipmentDataContainer;
}
