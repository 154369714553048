import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import {
    BILLING,
    CREATE_ANY,
    WAREHOUSE_MAIN,
    CustomerPermissions,
} from '@qwyk/core';
import { Portals } from '@qwyk/models';
import { SideBarService } from '@qwyk/portals/ui';
import { environment } from '@qwyk/portals/environment';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { NotificationsFacade } from '@qwyk/portals/notifications';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

import { AppState } from '../../../../store/app.state';
import { OnboardingService } from '../../../../services/onboarding.service';

@Component({
    selector: 'qw-portal-index',
    templateUrl: './portal-index.component.html',
    styleUrls: ['./portal-index.component.scss'],
})
export class PortalIndexComponent implements OnInit, OnDestroy {
    ENVIRONMENT = environment;
    siteDesign$ = this.siteConfig.design$;
    sidebarCollapsed = false;
    sidebarStyle$ = this.sideBar.collapsed$;
    user$ = this.authentication.user$;
    features$ = this.siteConfig.features$;
    unreadNotifications$: Observable<number> =
        this.notificationsFacade.unreadCount$;
    public isMagayaNetwork$ = this.siteConfig.config$.pipe(
        map((config: Portals.SiteConfig) =>
            config ? config.is_magaya_network : false
        )
    );
    public permissions: CustomerPermissions[];
    public customerPermissionsEnum = CustomerPermissions;
    public displayMainWarehouseLink: boolean;
    public displayBilling: boolean;
    public displayCreateAny: boolean;
    public permissionsSub: Subscription;

    constructor(
        private store: Store<AppState>,
        public sideBar: SideBarService,
        public onboardingService: OnboardingService,
        private notificationsFacade: NotificationsFacade,
        private authentication: AuthenticationFacade,
        private siteConfig: SiteConfigFacade
    ) {}

    ngOnInit() {
        this.permissionsSub = this.authentication.permissions$.subscribe(
            permissions => {
                this.permissions = permissions;
                this.displayMainWarehouseLink = WAREHOUSE_MAIN.some(p =>
                    permissions.includes(p)
                );
                this.displayBilling = BILLING.some(p =>
                    permissions.includes(p)
                );
                this.displayCreateAny = CREATE_ANY.some(p =>
                    permissions.includes(p)
                );
            }
        );
    }

    ngOnDestroy() {
        this.permissionsSub.unsubscribe();
    }

    toggleSidebar() {
        this.sideBar.toggle();
        this.sidebarCollapsed = !this.sidebarCollapsed;
    }

    public logout() {
        this.authentication.logout();
    }

    public resolveDefaultWarehouseRoute() {
        let defaultRoute = '';
        const warehousePermissions = [
            CustomerPermissions.VIEW_COMMODITIES,
            CustomerPermissions.VIEW_CARGORELEASES,
            CustomerPermissions.VIEW_PICKUPORDERS,
            CustomerPermissions.VIEW_INVENTORY,
            CustomerPermissions.VIEW_WAREHOUSERECEIPTS,
        ];
        warehousePermissions.every(permission => {
            if (this.permissions.includes(permission)) {
                switch (permission) {
                    case CustomerPermissions.VIEW_COMMODITIES:
                        defaultRoute = 'cargo-detail';
                        break;
                    case CustomerPermissions.VIEW_CARGORELEASES:
                        defaultRoute = 'cargo-releases';
                        break;
                    case CustomerPermissions.VIEW_PICKUPORDERS:
                        defaultRoute = 'pickup-orders';
                        break;
                    case CustomerPermissions.VIEW_INVENTORY:
                        defaultRoute = 'warehouse-inventory';
                        break;
                    case CustomerPermissions.VIEW_WAREHOUSERECEIPTS:
                        defaultRoute = 'warehouse-receipts';
                        break;
                }
                return false;
            }
            return true;
        });
        return defaultRoute;
    }
}
