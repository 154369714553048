/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer, on, Action } from '@ngrx/store';

import * as AuthenticationActions from './authentication.actions';

export const AUTHENTICATION_FEATURE_KEY = 'authentication';

export interface State {
    authenticated: boolean;
    error?: string | null;
    token: any;
    user: any;
    user_meta?: any;
}

export interface AuthenticationPartialState {
    readonly [AUTHENTICATION_FEATURE_KEY]: State;
}

export const initialState: State = {
    // set initial required properties
    authenticated: false,
    token: null,
    user: null,
};

const authenticationReducer = createReducer(
    initialState,
    on(AuthenticationActions.setupAuthentication, state => ({
        ...state,
        authenticated: false,
    })),
    on(
        AuthenticationActions.setupAuthenticationSuccess,
        AuthenticationActions.handleAuth0CallbackSuccess,
        (state, { token }) => ({
            ...state,
            token,
        })
    ),
    on(AuthenticationActions.login, state => ({
        ...state,
    })),
    on(AuthenticationActions.loadUserSuccess, (state, { user, meta }) => ({
        ...state,
        authenticated: true,
        user,
        user_meta: meta ? meta : state.user_meta,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return authenticationReducer(state, action);
}
