import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromCustomViews from './+state/custom-views.reducer';
import { CustomViewsFacade } from './+state/custom-views.facade';
import { CustomViewsEffects } from './+state/custom-views.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromCustomViews.customViewsFeature),
        EffectsModule.forFeature([CustomViewsEffects]),
    ],
    providers: [CustomViewsFacade],
})
export class SharedStoresCustomViewsModule {}
