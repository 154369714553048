import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IsAuthenticatedGuard } from '@qwyk/portals/authentication';

import { AllowDebugGuard } from './guards/allow-debug.guard';
import { DebugPageComponent } from './components/debug-page/debug-page.component';
import { FourOFourComponent } from './components/four-ofour/four-ofour.component';
import { AppFourOFourComponent } from './components/app-four-o-four/app-four-o-four.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./modules/public-site/public-site.module').then(
                m => m.PublicSiteModule
            ),
    },
    {
        path: 'my-portal',
        loadChildren: () =>
            import('./modules/portal/portal.module').then(m => m.PortalModule),
        canActivate: [IsAuthenticatedGuard],
        canActivateChild: [IsAuthenticatedGuard],
    },
    {
        path: 'quotes/new',
        redirectTo: '/',
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('@qwyk/portals/registration').then(
                M => M.PortalsRegistrationModule
            ),
    },
    {
        path: '404',
        component: FourOFourComponent,
    },
    {
        path: '404-Page-Not-Found',
        component: AppFourOFourComponent,
    },
    {
        path: 'admin/debug',
        component: DebugPageComponent,
        canActivate: [AllowDebugGuard],
    },
    { path: '**', redirectTo: '/404-Page-Not-Found', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 45],
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
