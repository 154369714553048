import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as SiteConfigActions from './site-config.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SiteConfigService } from '../services/site-config.service';
import { of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Favicons } from '../helpers/favicons';
import * as Sentry from '@sentry/angular';
import { ScriptsInjector } from '../helpers/scripts';
import { SegmentService } from 'ngx-segment-analytics';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SegmentEvent } from '@qwyk/core';
import { environment } from '@qwyk/portals/environment';

@Injectable()
export class SiteConfigEffects {
    loadSiteConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SiteConfigActions.loadSiteConfig),
            switchMap(action =>
                this.service.getSiteConfig(action.siteIdentifier).pipe(
                    map(siteConfig =>
                        SiteConfigActions.loadSiteConfigSuccess({ siteConfig })
                    ),
                    catchError(error => {
                        console.log(error);
                        return of(
                            SiteConfigActions.loadSiteConfigFailure({ error })
                        );
                    })
                )
            )
        )
    );

    loadSiteConfigSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SiteConfigActions.loadSiteConfigSuccess),
                tap(action => {
                    // Set favicon
                    this.favIcon.activate({
                        href: action.siteConfig.design.media.favicon_url,
                        type: 'image',
                    });

                    // Injects scripts for this site, such as chat widgets.
                    const scripts = action.siteConfig.design.injectable_scripts;
                    if (scripts && scripts.length > 0) {
                        this.scriptsInjector.inject(scripts);
                    }

                    // Set site title
                    this.title.setTitle(
                        action.siteConfig.design.titles.page_title
                    );

                    Sentry.setExtras({
                        organization_id:
                            action.siteConfig.organization_id.toString(),
                        site_id: action.siteConfig.site_id.toString(),
                    });

                    localStorage.setItem(
                        'network_id',
                        action.siteConfig.magaya_ltm?.network_id
                    );
                    localStorage.setItem(
                        'organization_id',
                        action.siteConfig.organization_id.toString()
                    );
                    localStorage.setItem(
                        'organization_name',
                        action.siteConfig.organization_name
                    );
                    this.segment.track(SegmentEvent.OPEN_SITE, {
                        site_id: action.siteConfig.site_id,
                        organization_id: action.siteConfig.organization_id,
                        organization_name: action.siteConfig.organization_name,
                        network_id: action.siteConfig.magaya_ltm?.network_id,
                        hostname: window.location.hostname,
                        environment: environment.name,
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    constructor(
        private actions$: Actions,
        private service: SiteConfigService,
        private title: Title,
        private favIcon: Favicons,
        private scriptsInjector: ScriptsInjector,
        private segment: SegmentService
    ) {}
}
