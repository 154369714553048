import {
    Router,
    UrlTree,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@qwyk/portals/environment';

@Injectable({
    providedIn: 'root',
})
export class AllowDebugGuard implements CanActivate {
    /**
     *
     */
    constructor(public router: Router) {}

    canActivate(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (environment.name !== 'production') {
            return true;
        } else {
            return this.router.navigate(['/']);
        }
    }
}
