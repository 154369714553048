import { createAction, props } from '@ngrx/store';
import { Portals } from '@qwyk/models';

export const loadSiteConfig = createAction(
    '[Portals/SiteConfig] Load SiteConfig',
    props<{
        siteIdentifier: {
            identifier: string;
            type: 'endpoint' | 'cname';
        };
    }>()
);

export const loadSiteConfigSuccess = createAction(
    '[Portals/SiteConfig] Load SiteConfig Success',
    props<{ siteConfig: Portals.SiteConfig }>()
);

export const loadSiteConfigFailure = createAction(
    '[Portals/SiteConfig] Load SiteConfig Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);
