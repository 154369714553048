<div [ngClass]="cardClass" class="card shadow-sm">
    <div [ngClass]="headerClass" class="card-header bg-white border-0 px-4">
        <h5 [ngClass]="headingClass" class="m-0">
            {{'portal.shipment.page.tracking-events.tracking-events' | translate}}
        </h5>
    </div>
    <ul class="list-group list-group-flush border-top-0">
        <li class="list-group-item px-4 py-2">
            <div class="row align-items-center">
                <div class="col-4">
                    {{'portal.shipment.page.tracking-events.description' | translate}}
                </div>
                <div class="col-3">
                    {{'portal.shipment.page.tracking-events.event-time' | translate}}
                </div>
                <div class="col-3">
                    {{'portal.shipment.page.tracking-events.location' | translate}}
                </div>
                <div class="col-2">
                    {{'portal.shipment.page.tracking-events.container' | translate}}
                </div>
            </div>
        </li>
        <li
            *ngFor="
                let event of events
                    | slice: (page - 1) * pageSize:page * pageSize
            "
            [event]="event"
            class="list-group-item p-4"
            qwyk-shipment-tracking-events-list-item
        ></li>
        <li
            *ngIf="!loading && (!events || !events.length)"
            class="list-group-item p-4 text-center text-muted no-hover"
        >
            <fa-icon [icon]="['far', 'radar']" size="lg"></fa-icon>
            <p class="m-0">{{'common.shipment.no-events-found' | translate }}</p>
        </li>
        <li *ngIf="loading" class="list-group-item p-4 text-center no-hover">
            <fa-icon
                [icon]="['far', 'radar']"
                [pulse]="true"
                size="lg"
            ></fa-icon>
            <p class="m-0">{{'portal.shipment.page.tracking-events.loading' | translate}}</p>
        </li>
        <li *ngIf="events.length > pageSize" class="list-group-item no-hover">
            <ngb-pagination
                [(page)]="page"
                [collectionSize]="events.length"
                [pageSize]="pageSize"
                class="d-flex justify-content-center"
                size="sm"
            ></ngb-pagination>
        </li>
    </ul>
</div>
