export const PURCHASEORDER_STATUSES = [
    {
        key: 'DRAFT',
        value: 'common.draft',
        icon: 'pen',
    },
    {
        key: 'PENDING',
        value: 'common.pending',
        icon: 'clock',
    },
    {
        key: 'IN_PROGRESS',
        value: 'common.in-progress',
        icon: 'play',
    },
    {
        key: 'PARTIALLY_RECEIVED',
        value: 'common.partially-received',
        icon: 'truck-loading',
    },
    {
        key: 'RECEIVED',
        value: 'common.received',
        icon: 'truck-loading',
    },
    {
        key: 'CANCELLED',
        value: 'common.cancelled',
        icon: 'ban',
    },
];

export const PURCHASEORDER_CURRENCIES = [
    { code: 'USD', value: 'United States Dollar' },
    { code: 'EUR', value: 'Euro' },
];

export const PURCHASEORDER_PAYMENT_TERMS = [
    {
        code: 'COD',
        value: 'portal.purchase-orders.payment-terms.cash-on-delivery',
    },
    {
        code: 'NET30',
        value: 'portal.purchase-orders.payment-terms.30-days-after-invoice',
    },
    { code: 'OTH', value: 'common.other' },
];

export const PURCHASEORDER_PLANNED_EQUIPMENT = {
    // tslint:disable-next-line: quotemark
    OCEAN: ["20'", "40'", "40HC'", "45'"],
    AIR: null,
    // tslint:disable-next-line: quotemark
    TRUCK: ["53'", 'Flatbed', 'Refrigerated Trailer'],
    RAIL: null,
    UNDETER: null,
};

export const PURCHASEORDER_STATUS_NAMES = PURCHASEORDER_STATUSES.reduce(
    (prev, curr) => {
        prev[curr.key] = curr.value;
        return prev;
    },
    {}
);

export const PURCHASEORDER_STATUS_ICONS = PURCHASEORDER_STATUSES.reduce(
    (prev, curr) => {
        prev[curr.key] = curr.icon;
        return prev;
    },
    {}
);
