import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Portals } from '@qwyk/models';
import { Observable } from 'rxjs';
import { environment } from '@qwyk/portals/environment';
import { first, map, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root',
})
export class SchedulesSearchService {
    constructor(private http: HttpClient) {}

    public searchSchedules(
        query: Portals.SchedulesSearchQuery,
        organizationId: number,
        authenticated: boolean
    ): Observable<Portals.Schedule[]> {
        return this.http
            .get<Portals.Schedule[]>(
                `${environment.backendServer}/api/${
                    authenticated ? 'portals/' : 'public/'
                }schedules/search/` +
                    `${organizationId}/${query.product}/${query.origin.locode}/${query.destination.locode}`
            )
            .pipe(
                first(),
                map(schedules =>
                    schedules.map(e => {
                        e.id = uuidv4();
                        return e;
                    })
                )
            );
    }

    public saveSchedule(
        schedule: Portals.Schedule
    ): Observable<Portals.SavedSchedule> {
        return this.http
            .post<{ data: Portals.SavedSchedule }>(
                `${environment.backendServer}/api/portals/schedules/saved`,
                schedule
            )
            .pipe(
                take(1),
                map(result => result.data)
            );
    }

    public updateBookingWithSchedule(scheduleId: string, bookingId: string) {
        return this.http.post<void>(
            `${environment.backendServer}/api/portals/shipments/schedule-to-booking/${bookingId}/${scheduleId}`,
            null
        );
    }
}
