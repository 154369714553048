import { Component, OnInit } from '@angular/core';
import {
    CustomerPermissions,
    LocalStorageService,
    SegmentEvent,
} from '@qwyk/core';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';
import { PageRequest } from '@qwyk/ui-standalone';
import { Observable } from 'rxjs';
import { AuthenticationFacade } from '@qwyk/portals/authentication';
import { SegmentService } from 'ngx-segment-analytics';

@Component({
    selector: 'qwyk-quotations-index-page',
    templateUrl: './quotations-index-page.component.html',
    styleUrls: ['./quotations-index-page.component.scss'],
})
export class QuotationsIndexPageComponent implements OnInit {
    public quotations$ = this.quotations.allQuotations$;
    public error$ = this.quotations.error$;
    public loading$ = this.quotations.loading$;
    public query$ = this.quotations.query$;
    public pagination$ = this.quotations.pagination$;
    public permissions$: Observable<CustomerPermissions[]> =
        this.authentication.permissions$;
    public customerPermissionsEnum = CustomerPermissions;
    public pageSize = 25;

    private readonly VIEW_STORAGE_KEY = 'portals.quotations.index.view';

    constructor(
        private quotations: QuotationsFacade,
        private authentication: AuthenticationFacade,
        private localStorage: LocalStorageService,
        private segment: SegmentService
    ) {}

    private _view: string =
        this.localStorage.getString(this.VIEW_STORAGE_KEY) || 'list';

    public get view(): 'list' | 'table' {
        return this._view as 'list' | 'table';
    }

    public set view(view: 'list' | 'table') {
        this._view = view;
        this.localStorage.setString(this.VIEW_STORAGE_KEY, view);
    }

    ngOnInit() {
        this.segment.track(SegmentEvent.QUOTATION_LIST_VIEW);
        this.onSearch({ pageSize: this.pageSize, page: 1 });
    }

    onDismissError() {
        this.quotations.dismissError();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSearch($event: any) {
        this.quotations.loadQuotations($event);
    }

    onPageChange($event: PageRequest = { page: 1, page_size: this.pageSize }) {
        this.quotations.loadQuotations({
            ...$event,
            pageSize: $event.page_size,
        });
    }
}
