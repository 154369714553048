import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromQuotations from './../state/quotations.reducer';
import { QuotationsServiceBase } from '../quotations-service-base';
import * as quotationsSelectors from './../state/quotations.selectors';
import { map, take, tap, catchError } from 'rxjs/operators';
import * as QuotationsActions from './../state/quotations.actions';

@Injectable({
    providedIn: 'root',
})
export class QuotationExistsGuard implements CanActivate, CanActivateChild {
    constructor(
        private store: Store<fromQuotations.QuotationsPartialState>,
        private service: QuotationsServiceBase,
        private router: Router
    ) {}

    hasQuotationInStore(id: string): Observable<boolean> {
        return this.store.pipe(
            select(quotationsSelectors.getQuotationsEntities),
            map(entities => !!entities[id]),
            take(1)
        );
    }

    hasQuotationInApi(
        id: string,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.service.getQuotation(id).pipe(
            map(quotation =>
                QuotationsActions.loadQuotationSuccess({ quotation })
            ),
            tap(action => this.store.dispatch(action)),
            map(quotation => !!quotation),
            catchError(error => {
                this.router.navigate([`/error/${error.status}`], {
                    queryParams: {
                        source: next.pathFromRoot.map(p => p.url).join('/'),
                        errorName: error.statusText,
                    },
                });
                return of(false);
            })
        );
    }

    hasQuotation(
        id: string,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.hasQuotationInApi(id, next);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.hasQuotation(next.params['id'], next);
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.hasQuotation(next.params['id'], next);
    }
}
