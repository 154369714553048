import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as AuthenticationActions from './authentication.actions';
import { AuthenticationService } from '../services/authentication.service';
import {
    catchError,
    concatMap,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { AuthenticationFacade } from './authentication.facade';
import { NotificationsFacade } from '@qwyk/portals/notifications';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { PortalsAuth } from '@qwyk/models';
import * as LogRocket from 'logrocket';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AmplitudeService, SegmentEvent } from '@qwyk/core';
import { PermissionsService } from '../services/permissions.service';
import { SegmentService } from 'ngx-segment-analytics';
import { environment } from '@qwyk/portals/environment';

@Injectable()
export class AuthenticationEffects {
    initializeAuthenticationState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.initializeAuthenticationState),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap(_ =>
                this.service.fetchTokenFromLocalStorage(true).pipe(
                    map(token => {
                        if (
                            token &&
                            window.location.pathname !== '/auth/with-token'
                        ) {
                            return AuthenticationActions.initializeAuthenticationStateWithToken(
                                { token }
                            );
                        } else {
                            return AuthenticationActions.initializeAuthenticationStateWithoutToken();
                        }
                    })
                )
            )
        )
    );

    initializeAuthenticationStateWithToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthenticationActions.initializeAuthenticationStateWithToken
            ),
            switchMap(() =>
                of(
                    AuthenticationActions.loadPermissions(),
                    AuthenticationActions.loadUser()
                )
            )
        )
    );

    loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.loadUser),
            switchMap(() =>
                this.service.getUser().pipe(
                    map(user =>
                        AuthenticationActions.loadUserSuccess({ user })
                    ),
                    catchError(error =>
                        of(AuthenticationActions.loadUserFailure({ error }))
                    )
                )
            )
        )
    );

    loadUserSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.loadUserSuccess),
                withLatestFrom(this.authentication.token$),
                tap(([action, token]) => {
                    // Initialize notifications listener
                    this.notifications.initializeEchoListener(
                        token,
                        action.user.id
                    );

                    this.notifications.loadUnreadCount();

                    localStorage.setItem('uom', action.user.units_of_measure);
                    // Initialize sentry
                    Sentry.setUser({
                        id: action.user.id,
                        email: action.user.email,
                        username: action.user.email,
                    });

                    LogRocket.identify(action.user.id, {
                        name: action.user.name,
                        email: action.user.email,
                        organizationId: action.user.organization_id,
                        teamId: action.user.team_id,
                        site: window.location.hostname,
                    });
                    this.amplitude.identify(
                        action.user.id,
                        action.user.email,
                        action.user.email,
                        action.user.organization_id,
                        window.location.hostname
                    );

                    this.segment.identify(
                        `${environment.name}-${action.user.id}`,
                        {
                            name: action.user.name,
                            email: action.user.email,
                            created_at: action.user.created_at,
                            company: action.user.team?.name,
                            organization_id: action.user.organization_id,
                            organization_name:
                                localStorage.getItem('organization_name'),
                            site: window.location.hostname,
                            network_id: localStorage.getItem('network_id'),
                            environment: environment.name,
                        }
                    );

                    this.segment.group(
                        `${environment.name}-${action.user.organization_id}`,
                        {
                            organization_id: action.user.organization_id,
                            organization_name:
                                localStorage.getItem('organization_name'),
                            network_id: localStorage.getItem('network_id'),
                            environment: environment.name,
                        }
                    );
                })
            ),
        { dispatch: false }
    );

    loadUserFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.loadUserFailure),
            map(() => AuthenticationActions.logout())
        )
    );

    newTokenReceived$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.newTokenReceived),
            switchMap(action =>
                this.service.storeTokenInLocalStorage(action.token).pipe(
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    tap(_ => this.service.hookTokenRefresh()),
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    switchMap(_ =>
                        of(
                            AuthenticationActions.loadPermissions(),
                            AuthenticationActions.loadUser()
                        )
                    )
                )
            )
        )
    );

    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.refreshToken),
            switchMap(() =>
                this.service.refreshToken().pipe(
                    map(token =>
                        AuthenticationActions.newTokenReceived({ token })
                    ),
                    catchError(error =>
                        of(
                            AuthenticationActions.refreshTokenFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    refreshTokenFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.refreshTokenFailure),
            map(() => AuthenticationActions.logout())
        )
    );

    loginWithCredentials$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.loginWithCredentials),
            withLatestFrom(this.siteConfig.organization$),
            tap(([, organization]) =>
                this.segment.track(SegmentEvent.LOGIN_ATTEMPT, {
                    network_id: localStorage.getItem('network_id'),
                    environment: environment.name,
                    organization_id: organization.organization_id,
                    organization_name: organization.organization_name,
                })
            ),
            mergeMap(([action, organization]) => {
                const credentials: PortalsAuth.Credentials = {
                    ...action.credentials,
                    organization_id: organization.organization_id,
                    site_id: organization.site_id,
                };
                return this.service.loginWithCredentials(credentials).pipe(
                    map(token =>
                        AuthenticationActions.loginWithCredentialsSuccess({
                            token,
                        })
                    ),
                    catchError(error =>
                        of(
                            AuthenticationActions.loginWithCredentialsFailure({
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    loginWithCredentialsFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.loginWithCredentialsFailure),
                tap(() =>
                    this.segment.track(SegmentEvent.LOGIN_FAILURE, {
                        network_id: localStorage.getItem('network_id'),
                        environment: environment.name,
                        organization_id:
                            localStorage.getItem('organization_id'),
                        organization_name:
                            localStorage.getItem('organization_name'),
                    })
                ),
                tap(action => {
                    if (
                        action.error?.status === 412 &&
                        action.error?.error?.type === 'email-unknown'
                    ) {
                        this.modal.dismissAll();
                        this.router.navigate(
                            ['/auth/magaya-onboarding/email'],
                            {
                                queryParams: {
                                    'upgrade-uri':
                                        action.error?.headers?.get(
                                            'x-upgrade-with'
                                        ),
                                },
                            }
                        );
                    }

                    if (
                        action.error?.status === 412 &&
                        action.error?.error?.type === 'account-upgrade-required'
                    ) {
                        this.modal.dismissAll();
                        this.router.navigate(
                            ['/auth/magaya-onboarding/create-account'],
                            {
                                queryParams: {
                                    'upgrade-uri':
                                        action.error?.headers?.get(
                                            'x-upgrade-with'
                                        ),
                                },
                            }
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.loginWithCredentialsSuccess),
            tap(() =>
                this.segment.track(SegmentEvent.LOGIN_SUCCESS, {
                    network_id: localStorage.getItem('network_id'),
                    environment: environment.name,
                    organization_id: localStorage.getItem('organization_id'),
                })
            ),
            switchMap(action =>
                of(
                    AuthenticationActions.newTokenReceived({
                        token: action.token,
                    })
                )
            )
        )
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.logout),
            tap(() => this.segment.track(SegmentEvent.LOGOUT)),
            concatMap(() => {
                this.service.unhookTokenRefresh();

                return forkJoin([
                    this.service.logoutFromServer(),
                    this.service.removeTokenFromLocalStorage(),
                ]).pipe(
                    map(() => AuthenticationActions.logoutSuccess()),
                    catchError(() => of(AuthenticationActions.logoutSuccess()))
                );
            })
        )
    );

    logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.logoutSuccess),
                withLatestFrom(
                    this.authentication.redirectOnLogout$,
                    this.siteConfig.design$
                ),
                map(([, shouldRedirect, design]) => {
                    Sentry.setUser(null);
                    if (shouldRedirect) {
                        window.location.href =
                            design.links.alternative_homepage || '/';
                    }
                    this.segment.reset();
                })
            ),
        { dispatch: false }
    );

    deinitEchoListener$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActions.logout),
                tap(() => this.notifications.deinitializeEchoListener())
            ),
        { dispatch: false }
    );

    loadPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.loadPermissions),
            switchMap(() =>
                this.permissionsService.getUsersPermissions().pipe(
                    map(permissions => {
                        return AuthenticationActions.loadPermissionsSuccess({
                            permissions,
                        });
                    }),
                    catchError(error => {
                        console.error(
                            'Permissions could not be loaded, logging user out because irrecoverable.'
                        );
                        return of(
                            AuthenticationActions.refreshTokenFailure({
                                error,
                            })
                        );
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private service: AuthenticationService,
        private authentication: AuthenticationFacade,
        private permissionsService: PermissionsService,
        private notifications: NotificationsFacade,
        private siteConfig: SiteConfigFacade,
        private router: Router,
        private modal: NgbModal,
        private amplitude: AmplitudeService,
        private segment: SegmentService
    ) {}
}
