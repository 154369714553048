import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SCHEDULESSEARCH_FEATURE_KEY,
    State,
    SchedulesSearchPartialState,
    schedulesSearchAdapter,
} from './schedules-search.reducer';

// Lookup the 'SchedulesSearch' feature state managed by NgRx
export const getSchedulesSearchState = createFeatureSelector<
    SchedulesSearchPartialState,
    State
>(SCHEDULESSEARCH_FEATURE_KEY);

const { selectAll, selectEntities } = schedulesSearchAdapter.getSelectors();

export const getSchedulesSearchLoaded = createSelector(
    getSchedulesSearchState,
    (state: State) => state.loaded
);
export const getSchedulesSearchSearching = createSelector(
    getSchedulesSearchState,
    (state: State) => state.searching
);

export const getSchedulesSearchError = createSelector(
    getSchedulesSearchState,
    (state: State) => state.error
);

export const getSchedulesSearchQuery = createSelector(
    getSchedulesSearchState,
    (state: State) => state.query
);

export const getAllSchedulesSearch = createSelector(
    getSchedulesSearchState,
    (state: State) => selectAll(state)
);

export const getSchedulesSearchEntities = createSelector(
    getSchedulesSearchState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getSchedulesSearchState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getSchedulesSearchEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);
