import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Portals } from '@qwyk/models';
import { SegmentService } from 'ngx-segment-analytics';
import { SegmentEvent } from '@qwyk/core';

@Component({
    selector: 'qwyk-quotation-detail-page',
    templateUrl: './quotation-detail-page.component.html',
    styleUrls: ['./quotation-detail-page.component.scss'],
})
export class QuotationDetailPageComponent implements OnDestroy {
    public quotation$ = this.quotations.selectedQuotations$;
    public loading$ = this.quotations.loading$;
    public error$ = this.quotations.error$;
    public view$ = this.route.queryParams.pipe(
        map(p => {
            return p.view || 'summary';
        })
    );
    private actionSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private quotations: QuotationsFacade,
        private segment: SegmentService
    ) {
        this.actionSubscription = this.route.paramMap.subscribe(params => {
            this.quotations.selectQuotation(params.get('id'));
            this.segment.track(SegmentEvent.QUOTATION_VIEW, {
                id: params.get('id'),
            });
        });
    }

    ngOnDestroy() {
        this.actionSubscription.unsubscribe();
        this.quotations.selectQuotation(null);
    }

    onDownloadQuotation(quotation: Portals.QuotationEntity) {
        this.quotations.downloadQuotation(quotation);
        this.segment.track(SegmentEvent.QUOTATION_DOWNLOAD, {
            id: quotation.id,
        });
    }

    onDismissError() {
        this.quotations.dismissError();
    }
}
