import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    QUOTATIONS_FEATURE_KEY,
    quotationsAdapter,
    QuotationsPartialState,
    State,
} from './quotations.reducer';

// Lookup the 'Quotations' feature state managed by NgRx
export const getQuotationsState = createFeatureSelector<
    QuotationsPartialState,
    State
>(QUOTATIONS_FEATURE_KEY);

const { selectAll, selectEntities } = quotationsAdapter.getSelectors();

export const getQuotationsLoaded = createSelector(
    getQuotationsState,
    (state: State) => state.loaded
);

export const getQuotationsLoading = createSelector(
    getQuotationsState,
    (state: State) => state.loading
);

export const getQuotationsError = createSelector(
    getQuotationsState,
    (state: State) => state.error
);

export const getQuotationsPagination = createSelector(
    getQuotationsState,
    (state: State) => state.pagination
);

export const getAllQuotations = createSelector(
    getQuotationsState,
    (state: State) => selectAll(state)
);

export const getQuotationsEntities = createSelector(
    getQuotationsState,
    (state: State) => selectEntities(state)
);

export const getQuotationsQuery = createSelector(
    getQuotationsState,
    (state: State) => state.query
);

export const getSelectedId = createSelector(
    getQuotationsState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getQuotationsEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);
