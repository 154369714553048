<div class="row mb-3">
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'common.quotations.sort-options.friendly_id' | translate}}
        </label>
        <div [innerHtml]="quotation.friendly_id | lineBreaks"></div>
    </div>
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'common.quotations.status.created'|translate}}
        </label>
        <div [innerHtml]="quotation.request.created_at | amLocal | amDateFormat: 'L'"></div>
    </div>
</div>

<div class="row mb-3">
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'portal.shipment.table.columns.product' | translate}}
        </label>
        <div>
            {{ 'portal.shipment.transport-mode.' + quotation.request.transport_mode | translate | titlecase }}
            {{ quotation.request.load_type | transportModeTransform: quotation.request.transport_mode | uppercase}}
        </div>
    </div>
    <div [ngClass]="layoutResponsive" class="col-12">
        <label class="small font-weight-bold m-0">
            {{'common.quotations.search.origin' | translate}}
        </label>
        <div class="d-flex align-items-baseline">
            <div class="mr-2">
                <ng-container
                    *ngTemplateOutlet="locationIcon, context: {$implicit: quotation.request.origin, od: 'o'}">
                </ng-container>
            </div>
            <div>
                {{ quotation.request.origin.name }}
            </div>
        </div>
    </div>
    <div [ngClass]="layoutResponsive" class="col-12">
        <label class="small font-weight-bold m-0">
            {{'common.quotations.sort-options.destination_name' | translate}}
        </label>
        <div class="d-flex align-items-baseline">
            <div class="mr-2">
                <ng-container
                    *ngTemplateOutlet="locationIcon, context: {$implicit: quotation.request.destination, od: 'd'}">
                </ng-container>
            </div>
            <div>
                {{ quotation.request.destination.name }}
            </div>
        </div>
    </div>
</div>

<div class="row mb-3">
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'common.quotations.table.requested-currency' | translate}}
        </label>
        <div>{{ quotation.request.currency }}</div>
    </div>
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'portal.landing-page.quotation-request.page2.label-commodity' | translate}}
        </label>
        <div>{{ quotation.request.commodity?.description }}</div>
    </div>
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'portal.shipment.page.instructions.cargo-ready' | translate}}
        </label>
        <div>{{ quotation.request.cargo_ready | amLocal | amDateFormat: 'L' }}</div>
    </div>
</div>

<div class="row" *ngIf="user$ | async; let user">
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'common.quotations.page.summary.total-quantity'| translate}}
        </label>
        <div>{{ quotation.request.total_quantity }}</div>
    </div>
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'common.quotations.page.summary.total-weight'| translate}}
        </label>
        <div>
            {{ quotation.request.total_weight_kgs | unitConvert: 'mass' : 'metric' : user?.units_of_measure || 'metric' | number: '1.2-2'}}
            {{ user?.units_of_measure === 'metric' ? 'kgs' : 'lbs' }}
        </div>
    </div>
    <div [ngClass]="layout">
        <label class="small font-weight-bold m-0">
            {{'common.quotations.page.summary.total-volume'|translate}}
        </label>
        <div>
            {{ quotation.request.total_volume_cbm | unitConvert: 'volume' : 'metric' : user?.units_of_measure || 'metric' | number: '1.3-3' }}
            {{ user?.units_of_measure === 'metric' ? 'cbm' : 'cft' }}
        </div>
    </div>
    <div [ngClass]="layout" *ngIf="shouldShowChargeableWeight">
        <label class="small font-weight-bold m-0">{{ 'common.quotations.page.summary.chargeable-weight' | translate }}</label>
        <div>
            {{ quotation.request.total_chargeable_weight_kgs | unitConvert: 'mass' : 'metric' : user?.units_of_measure || 'metric' | number: '1.2-2' }}
            {{ user?.units_of_measure === 'metric' ? 'kgs' : 'lbs' }}
        </div>
    </div>
</div>

<ng-template #locationIcon let-location let-od="od">
    <span [ngbTooltip]="location.type | titlecase" class="text-secondary">
        <ng-container *ngIf="location.type === 'port'">
            <ng-container [ngSwitch]="quotation.request.transport_mode">
                <fa-icon *ngSwitchCase="'AIR'"
                         [fixedWidth]="true"
                         [icon]="['fas', od === 'o' ? 'plane-departure' : 'plane-arrival']">
                </fa-icon>
                <fa-icon *ngSwitchCase="'OCEAN'" [fixedWidth]="true" [icon]="['fas', 'anchor']">
                </fa-icon>
                <fa-icon *ngSwitchCase="'TRUCK'" [fixedWidth]="true"
                         [icon]="['fas', 'warehouse']"></fa-icon>
                <fa-icon *ngSwitchDefault [fixedWidth]="true" [icon]="['fas', 'map-pin']">
                </fa-icon>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="location.type !== 'port'">
            <fa-icon [fixedWidth]="true" [icon]="['fas', 'map-pin']"></fa-icon>
        </ng-container>
    </span>
</ng-template>
