/* eslint-disable @typescript-eslint/no-explicit-any */
import { props, emptyProps, createActionGroup } from '@ngrx/store';

import { common } from '@qwyk/models';

export const CustomViewsActions = createActionGroup({
    source: 'Custom Views/API',
    events: {
        Init: emptyProps(),
        'Load Custom Views Success': props<{
            parent: string;
            custom_views: common.CustomView[];
            selectedId: string;
        }>(),
        'Load Custom Views Failure': props<{ error: any }>(),
        'Create Custom View': props<{
            parent: string;
            request: common.CustomView;
        }>(),
        'Create Custom View Success': props<{
            custom_view: common.CustomView;
        }>(),
        'Create Custom View Failure': props<{ error: any }>(),
        'Update Custom View': props<{
            parent: string;
            request: Partial<common.CustomView>;
        }>(),
        'Update Custom View Success': props<{
            custom_view: common.CustomView;
        }>(),
        'Update Custom View Failure': props<{ error: any }>(),
        'Delete Custom View': props<{
            parent: string;
            request: common.CustomView;
        }>(),
        'Delete Custom View Success': props<{
            custom_view: common.CustomView;
        }>(),
        'Delete Custom View Failure': props<{ error: any }>(),
        'Set Selected View': props<{ uid: string }>(),
        Reset: emptyProps(),
    },
});
