<div class="modal-header border-bottom-0 px-4 pt-4">
    <h5 class="modal-title" id="modal-basic-title">
        <fa-icon [icon]="['far', 'user-plus']" [fixedWidth]="true" class="mr-2"></fa-icon>
        {{ header | translate }}
    </h5>
</div>
<ul class="list-group list-group-flush">
    <li class="list-group-item">
        <ng-select
            [(ngModel)]="addingUsers"
            [multiple]="true"
            typeToSearchText="{{ 'portal.ui.share-modal.enter-email-address' | translate }}"
            [typeahead]="userInput$"
            [items]="users$ | async"
            placeholder="{{ 'portal.ui.share-modal.add-people' | translate }}"
            [clearable]="false"
            [minTermLength]="4"
            [loading]="usersLoading"
            bindLabel="name"
            [hideSelected]="true"
            loadingText="{{ 'common.loading' | translate }}..."
            notFoundText="{{ 'portal.ui.share-modal.no-people-found' | translate }}"
            [trackByFn]="trackUserByFn"
            [readonly]="busy">
            <ng-template ng-option-tmp let-user="item">
                <div>{{ user.name }}</div>
                <div class="text-muted">{{ user.email }}</div>
            </ng-template>
        </ng-select>
    </li>
    <ng-container *ngIf="addingUsers.length === 0">
        <li class="list-group-item d-flex align-items-center justify-content-between">
            <div class="flex-grow-1">
                <div class="font-weight-bold">{{ owner.name }}</div>
                <div>{{ owner.email }}</div>
            </div>
            <div class="text-muted">{{ 'portal.ui.share-modal.owner' | translate }}</div>
        </li>
        <li *ngFor="let user of sharedUsers"
            class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
            <div class="flex-grow-1">
                <div class="font-weight-bold">{{ user.name }}</div>
                <div>{{ user.email }}</div>
            </div>
            <div class="flex-grow-0">
                <button class="btn" (click)="onUnshare(user)" [disabled]="busy">
                    <fa-icon [icon]="['far', !busy ? 'times' : 'spinner']" [pulse]="busy"></fa-icon>
                </button>
            </div>
        </li>
    </ng-container>
</ul>

<ng-container *ngIf="addingUsers.length > 0">
    <div class="card-body">
        <div class="form-group mb-0">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="notify" [(ngModel)]="notify" [disabled]="busy">
                <label class="form-check-label" for="notify">{{ 'portal.ui.share-modal.notify-people' | translate }}</label>
            </div>
        </div>
        <div class="form-group mt-1" *ngIf="notify">
            <textarea rows="4" placeholder="{{ 'hub.quotations.create.fields.message' | translate }}" class="form-control" [(ngModel)]="message" [disabled]="busy"
                maxlength="500"></textarea>
        </div>
    </div>
</ng-container>

<div class="modal-footer border-top-0 pt-0 px-4 pb-4">
    <button class="btn btn-light mr-1" *ngIf="addingUsers.length > 0" (click)="onCancelAdding()"
        [disabled]="busy">{{ 'common.cancel' | translate }}</button>
    <button class="confirm btn btn-primary px-4" (click)="onShare()" [disabled]="busy">
        <fa-icon [icon]="['far', 'spinner']" [pulse]="true" *ngIf="busy" class="mr-1"></fa-icon>
        {{ (addingUsers.length > 0 ? (notify ? 'common.send' : 'common.share') : 'common.done') | translate }}
    </button>
</div>
