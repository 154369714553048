import { createAction, props } from '@ngrx/store';
import { Portals } from '@qwyk/models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Shipment } from '@qwyk/shared-stores/shipments';
import * as moment from 'moment';

export const init = createAction(
    '[Portals/Shipments/Containers] Init',
    props<{ shipment: Shipment }>()
);

export const loadShipmentContainersSuccess = createAction(
    '[Portals/Shipments/Containers] Load ShipmentContainers Success',
    props<{ shipmentContainers: Portals.ShipmentDataContainer[] }>()
);

export const loadShipmentContainersFailure = createAction(
    '[Portals/Shipments/Containers] Load ShipmentContainers Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const select = createAction(
    '[Portals/Shipment/Containers] Select Container',
    props<{
        container: Portals.ShipmentDataContainer;
        geotrack: boolean;
        shipmentId: string;
        selectedAt: moment.Moment;
    }>()
);

export const loadShipmentContainerGeoTrack = createAction(
    '[Portals/Shipment/Containers] Load GeoTrack',
    props<{ container: Portals.ShipmentDataContainer; shipmentId: string }>()
);

export const loadShipmentContainerGeoTrackSuccess = createAction(
    '[Portals/Shipment/Containers] Load GeoTrack Success',
    props<{ geotrack: Portals.GeoTrackElement[] }>()
);

export const loadShipmentContainerGeoTrackFailure = createAction(
    '[Portals/Shipment/Containers] Load GeoTrack Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const reset = createAction('[Portals/Shipments/Containers] Reset');
