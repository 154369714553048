import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-shipment-tracking-events-list',
    templateUrl: './shipment-tracking-events-list.component.html',
    styleUrls: ['./shipment-tracking-events-list.component.scss'],
})
export class ShipmentTrackingEventsListComponent {
    @Input() loading = false;
    @Input() showInternalEvents = false;
    @Input() cardClass = null;
    @Input() pageSize = 5;
    @Input() public headingClass: string;
    @Input() public headerClass: string;

    public showLocalTime = true;
    public page = 1;

    public eventTypes = [];
    public containers = [];
    public locations = [];

    constructor() {}

    private _events: Portals.ShipmentTrackingEvent[];

    public get events(): Portals.ShipmentTrackingEvent[] {
        return this._events;
    }

    @Input()
    public set events(events: Portals.ShipmentTrackingEvent[]) {
        this._events = events;
    }
}
