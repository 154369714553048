<div class="vh-100 bg-light d-flex align-items-center justify-content-center">
    <div class="card shadow">
        <h5 class="card-header px-2 bg-white border-bottom-0">Debug</h5>
        <table class="table">
            <tbody>
                <tr>
                    <th>Version</th>
                    <td>{{ versionInfo.version }}</td>
                </tr>
                <tr>
                    <th>Revision</th>
                    <td>{{ versionInfo.revision }}</td>
                </tr>
                <tr>
                    <th>Branch</th>
                    <td>{{ versionInfo.branch }}</td>
                </tr>
                <tr>
                    <th>Date</th>
                    <td>{{ versionInfo.date | date: 'long' }}</td>
                </tr>
            </tbody>
        </table>
        <div class="card-body px-2 pt-0">
            <button class="btn btn-outline-danger" (click)="throwException()">
                <fa-icon [icon]="['far', 'bug']" class="mr-2"></fa-icon>Throw exception
            </button>
        </div>
    </div>
</div>