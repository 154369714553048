export class EnvironmentConfig {
    backendServer!: string;
    utils!: {
        Algolia: {
            applicationId: string;
            adminApiKey: string;
        };
        Amplitude: {
            enabled: boolean;
            apiKey: string;
        };
    };
}
