import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-request-summary',
    templateUrl: './quotation-request-summary.component.html',
    styleUrls: ['./quotation-request-summary.component.scss'],
})
export class QuotationRequestSummaryComponent {
    @Input() quotation: Portals.QuotationEntity;
    @Input() rowBreak = 'col-12';
    @Input() wrapInCard = false;

    get request() {
        return this.quotation.request;
    }
}
