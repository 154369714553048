<nav class="nav tab-nav border-bottom mt-3">
    <ng-container *ngFor="let nav of NAVS">
        <a
                *ngIf="nav.viewTag !== 'offers'"
                [ngClass]="{
            active: currentView === nav.viewTag,
            'disabled text-muted': !nav.enabled
        }"
                [queryParams]="{ view: nav.viewTag }"
                [routerLink]="['./']"
                class="nav-link"
        >
            <fa-icon [icon]="['far', nav.icon]" class="mr-1"></fa-icon>
            {{ nav.title | translate }}
            <span *ngIf="nav.badgeValue && nav.badgeValue()" class="badge text-bg-light px-2 py-0">
            {{ nav.badgeValue() }}
        </span>
        </a>

        <a
                *ngIf="nav.viewTag === 'offers'"
                [ngClass]="{
            active: currentView === nav.viewTag,
            'disabled text-muted': !nav.enabled
        }"
                [routerLink]="['/quotation', quotation?.id]"
                class="nav-link"
        >
            <fa-icon [icon]="['far', nav.icon]" class="mr-1"></fa-icon>
            {{ nav.title | translate }}
        </a>
    </ng-container>
</nav>
