import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ShipmentActivity} from '@qwyk/shared-stores/shipment-activities';
import {ShipmentActivityType} from '../../data/ShipmentActivityType';
import {common} from '@qwyk/models';

@Component({
    selector: 'qwyk-shipment-activities-list',
    templateUrl: './shipment-activities-list.component.html',
    styleUrls: ['./shipment-activities-list.component.scss'],
})
export class ShipmentActivitiesListComponent {
    @Input() shipmentActivities: ShipmentActivity[];
    @Input() loading: boolean;
    @Input() disabled: boolean;
    @Input() pagination: common.PaginationMeta;

    @Output() viewMore: EventEmitter<void> = new EventEmitter<void>();

    public ActivityType = ShipmentActivityType;

    public get canPaginate(): boolean {
        return (
            !!this.pagination &&
            this.pagination.current_page < this.pagination.last_page
        );
    }

    onViewMore() {
        if (this.canPaginate) {
            this.viewMore.emit();
        }
    }
}
