import { common } from '@qwyk/models';

export interface TableColumnFilter {
    field: string;
    type: string;
    options?: any[];
}

export interface TableColumn {
    id: string;
    key: string;
    sub_key?: string;
    label: string;
    field?: string;
    header?: string;
    disabled?: boolean;
    default?: boolean;
    sort_key?: string | boolean;
    filter?: TableColumnFilter | null;
    custom_field?: {
        id: string;
        data_type: string;
    };
    header_class?: string;
    body_class?: string;
    type?: string;
    format?: string;
}

export class CustomFieldDefinitionConverter {
    public static toTableColumns(
        customFields: common.CustomFieldDefinition[]
    ): TableColumn[] {
        if (!customFields) {
            return [];
        }

        return customFields.map(e => ({
            id: `custom_fields_dictionary.${e.name}`,
            key: `custom_fields_dictionary.${e.name}`,
            sub_key: e.name,
            label: e.display_name,
            sort_key: `custom_fields_dictionary.${e.name}.value`,
            filter: ![].includes(e.data_type)
                ? {
                      field: `custom_fields_dictionary.${e.name}.value`,
                      type: ['string', 'lookup', 'picklist', 'money'].includes(
                          e.data_type
                      )
                          ? 'text'
                          : ['int', 'decimal'].includes(e.data_type)
                          ? 'numeric'
                          : e.data_type,
                  }
                : null,
            custom_field: {
                id: e.id,
                data_type: e.data_type,
            },
        }));
    }
}
