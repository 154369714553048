import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Portals} from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-detail-header',
    templateUrl: './quotation-detail-header.component.html',
    styleUrls: ['./quotation-detail-header.component.scss']
})
export class QuotationDetailHeaderComponent {
    @Input() quotation: Portals.QuotationEntity;
    @Input() loading = false;
    @Output() download: EventEmitter<Portals.QuotationEntity> = new EventEmitter<Portals.QuotationEntity>();

    onDownload($event: MouseEvent, quotation: Portals.QuotationEntity) {
        this.download.emit(quotation);
    }
}
