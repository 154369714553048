<div class="modal-header border-bottom-0 px-4 pt-4" *ngIf="header">
    <h5 class="modal-title" id="modal-basic-title">{{ header }}</h5>
    <input type="hidden" ngbAutofocus>
</div>
<div class="modal-body px-4" *ngIf="message">
    <div class="message" [innerHTML]="message | lineBreaks"></div>
</div>
<div class="modal-body px-4" *ngIf="showInput">
    <div class="form-group">
        <label *ngIf="inputLabel && inputType !== 'checkbox'">{{ inputLabel }}</label>
        <ng-container [ngSwitch]="inputType">
            <input [attr.type]="inputType" *ngIf="inputType === 'text' || inputType === 'number'" [(ngModel)]="inputValue"
                class="form-control" [attr.placeholder]="inputPlaceholder" [attr.required]="inputRequired">

            <textarea *ngIf="inputType === 'textarea'" [(ngModel)]="inputValue" class="form-control"
                [attr.placeholder]="inputPlaceholder" [attr.required]="inputRequired" rows="3"></textarea>
            <ng-container *ngIf="inputType === 'checkbox'">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="switch-input" [(ngModel)]="inputValue">
                    <label class="custom-control-label font-weight-bold" for="switch-input">{{ inputLabel }}</label>
                </div>
            </ng-container>
        </ng-container>
        <div *ngIf="inputHint" [style.margin-left]="inputType === 'checkbox' ? '34.2px': ''"
              class="small text-muted">
            {{ inputHint }}
        </div>
    </div>
</div>
<div class="modal-footer border-top-0 pt-0 px-4 pb-4">
    <button class="confirm btn" [ngClass]="confirmStyle" *ngIf="confirmAction" (click)="confirm()">
        {{ confirmAction }}
    </button>
    <button class="dismiss btn" [ngClass]="!confirmAction ? confirmStyle : (dismissStyle || 'btn-light')"
        *ngIf="dismissAction" (click)="dismiss()">
        {{ dismissAction }}
    </button>
</div>
