import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as ShipmentContainersActions from './shipment-containers.actions';
import { ShipmentContainersServiceBase } from '../shipmentContainersServiceBase';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ShipmentContainersEffects {
    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentContainersActions.init),
            fetch({
                run: action => {
                    return this.service
                        .getShipmentContainers(action.shipment.id)
                        .pipe(
                            map(shipmentContainers =>
                                ShipmentContainersActions.loadShipmentContainersSuccess(
                                    { shipmentContainers }
                                )
                            )
                        );
                },

                onError: (action, error) => {
                    console.error(error);
                    return ShipmentContainersActions.loadShipmentContainersFailure(
                        { error }
                    );
                },
            })
        )
    );

    select$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentContainersActions.select),
            filter(action => action.geotrack === true),
            distinctUntilChanged(
                (a, b) =>
                    a.container.id === b.container.id &&
                    b.selectedAt.diff(a.selectedAt, 'seconds') < 2
            ),
            map(action =>
                ShipmentContainersActions.loadShipmentContainerGeoTrack({
                    container: action.container,
                    shipmentId: action.shipmentId,
                })
            )
        )
    );

    loadGeoTrack$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentContainersActions.loadShipmentContainerGeoTrack),
            switchMap(action =>
                this.service
                    .getShipmentContainerGeoTrack(
                        action.shipmentId,
                        action.container.id
                    )
                    .pipe(
                        map(geotrack =>
                            ShipmentContainersActions.loadShipmentContainerGeoTrackSuccess(
                                { geotrack }
                            )
                        ),
                        catchError(error => {
                            console.error(error);
                            return of(
                                ShipmentContainersActions.loadShipmentContainerGeoTrackFailure(
                                    { error }
                                )
                            );
                        })
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private service: ShipmentContainersServiceBase
    ) {}
}
