import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export abstract class EntityExistsGuard<KeyType>
    implements CanActivate, CanActivateChild
{
    protected abstract getEntityKey(next: ActivatedRouteSnapshot): KeyType;
    protected abstract hasEntityInStore(id: KeyType): Observable<boolean>;
    protected abstract hasEntityInApi(
        id: KeyType,
        next: ActivatedRouteSnapshot
    ): Observable<boolean>;

    protected hasEntity(
        id: KeyType,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.hasEntityInStore(id).pipe(
            switchMap(inStore => {
                if (inStore) {
                    return of(inStore);
                }
                return this.hasEntityInApi(id, next);
            })
        );
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.hasEntity(this.getEntityKey(next), next);
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.canActivate(next, state);
    }
}
