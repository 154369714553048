import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { TranslateService } from '@ngx-translate/core';

import { TypingAnimationDirective } from '../../directives/typing-animation/typing-animation.directive';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, FontAwesomeModule, TypingAnimationDirective],
    selector: 'qwyk-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit {
    faSpinner = faSpinner;

    @Input()
    componentClass = 'text-muted';

    @Input()
    spinner = 'spinner';

    @Input()
    text = `${this.translate.instant('common.loading')}...`;

    @Input()
    size: 'md' | 'sm' | 'lg' = 'md';

    @Input()
    horizontal = false;

    @Input()
    typing = false;

    @Input()
    textClass = '';

    @Input()
    subText = null;

    constructor(private readonly translate: TranslateService) {}

    ngOnInit() {
        switch (this.spinner) {
            case 'spinner-third':
                this.faSpinner = faSpinnerThird;
                break;
        }
    }
}
