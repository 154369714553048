export const LOAD_TYPES = [
    { value: 'AIR:lcl', label: 'hub.shipment.search.filters.product.air:lcl' },
    {
        value: 'OCEAN:fcl',
        label: 'hub.shipment.search.filters.product.ocean:fcl',
    },
    {
        value: 'OCEAN:lcl',
        label: 'hub.shipment.search.filters.product.ocean:lcl',
    },
    {
        value: 'TRUCK:fcl',
        label: 'hub.shipment.search.filters.product.truck:fcl',
    },
    {
        value: 'TRUCK:lcl',
        label: 'hub.shipment.search.filters.product.truck:lcl',
    },
    {
        value: 'RAIL:fcl',
        label: 'hub.shipment.search.filters.product.rail:fcl',
    },
    {
        value: 'RAIL:lcl',
        label: 'hub.shipment.search.filters.product.rail:lcl',
    },
];
