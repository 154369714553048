<div class="row justify-content-center mx-0" [ngStyle]="{'margin-bottom.em': config.wrap ? 20 : 0}">
    <div class="col-12 col-lg-8 col-xl-7 col-xxl-6 col-xxxl-5">
        <h2 class="font-weight-normal">Get started</h2>
        <h6 class="font-weight-light">Navigate through the wizard to get a quotation, schedule, or start a booking
            instantly</h6>
        <hr>
        <h4 class="mt-5 font-weight-normal">How are you shipping?</h4>
        <div class="row">
            <div class="col">
                <div class="card overflow-hidden border-0 text-dark"
                    [ngClass]="{'shadow': loadType === 'fcl' || !loadType}">
                    <img src="https://cdn.qwyk.io/portals/assets/load_full.png" class="card-img-top"
                        [ngStyle]="loadType ? {position: 'absolute', bottom: 0, transform: 'translateY(25%)'} : {}" />
                    <div class="card-body py-2" style="z-index: 999; background-color: rgba(255, 255, 255, 0.75)">
                        <div [ngStyle]="loadType && loadType !== 'fcl' ? {opacity: .5} : {}">
                            <h5 class="card-title m-0 font-weight-normal">Full load</h5>
                            <p class="card-text pb-2 font-weight-light">Full container or full truck loads</p>
                        </div>
                        <button id="btnFcl" class="btn btn-primary" [disabled]="loadType === 'fcl'"
                            (click)="loadType = 'fcl'" [ngClass]="loadType ? 'btn-sm' : null">
                            {{ !loadType ? 'Select Full' : loadType === 'fcl' ? 'Selected' : 'Change to Full' }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card overflow-hidden text-dark" [ngClass]="{'shadow': loadType === 'lcl' || !loadType}">
                    <img src="https://cdn.qwyk.io/portals/assets/load_partial.png" class="card-img-top"
                        [ngStyle]="loadType ? {position: 'absolute', bottom: 0, transform: 'translateY(25%)'} : {}" />
                    <div class="card-body py-2" style="z-index: 999; background-color: rgba(255, 255, 255, 0.75)">
                        <div [ngStyle]="loadType && loadType !== 'lcl' ? {opacity: .5} : {}">
                            <h5 class="card-title m-0 font-weight-normal">Partial load</h5>
                            <p class="card-text pb-2 font-weight-light">Less-than-Container, Air, or Less-than-Truck</p>
                        </div>
                        <button id="btnLcl" class="btn btn-primary" [disabled]="loadType === 'lcl'"
                            (click)="loadType = 'lcl'" [ngClass]="loadType ? 'btn-sm' : null">
                            {{ !loadType ? 'Select Partial' : loadType === 'lcl' ? 'Selected' : 'Change to Partial' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="loadType">
            <h4 class="mt-5 font-weight-normal" [@fadeIn]>Select your preferred method</h4>
            <div class="row">
                <div class="col">
                    <div class="card overflow-hidden border-0 text-dark" [@fadeIn]
                        [ngClass]="{'shadow': transportMode === 'OCEAN' || !transportMode}">
                        <img src="https://cdn.qwyk.io/portals/assets/ocean.png" class="card-img-top"
                            [ngStyle]="transportMode ? {position: 'absolute', bottom: 0, transform: 'translateY(25%)'} : {}" />
                        <div class="card-body py-2" style="z-index: 999; background-color: rgba(255, 255, 255, 0.75)">
                            <h5 class="card-title font-weight-normal"
                                [ngStyle]="transportMode && transportMode !== 'OCEAN' ? {opacity: .5} : {}">
                                Ocean
                            </h5>
                            <button id="btnOcean" class="btn btn-primary" (click)="transportMode = 'OCEAN'"
                                [ngClass]="transportMode ? 'btn-sm' : null" [disabled]="transportMode === 'OCEAN'">
                                {{ !transportMode ? 'Select Ocean' : transportMode === 'OCEAN' ? 'Selected' : 'Change to Ocean' }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card overflow-hidden border-0 text-dark" *ngIf="loadType === 'lcl'" [@fadeIn]
                        [ngClass]="{'shadow': transportMode === 'AIR' || !transportMode}">
                        <img src="https://cdn.qwyk.io/portals/assets/air.png" class="card-img-top"
                            [ngStyle]="transportMode ? {position: 'absolute', bottom: 0, transform: 'translateY(25%)'} : {}" />
                        <div class="card-body py-2" style="z-index: 999; background-color: rgba(255, 255, 255, 0.75)">
                            <h5 class="card-title font-weight-normal"
                                [ngStyle]="transportMode && transportMode !== 'AIR' ? {opacity: .5} : {}">Air
                            </h5>
                            <button id="btnAir" class="btn btn-primary" (click)="transportMode = 'AIR'"
                                [ngClass]="transportMode ? 'btn-sm' : null" [disabled]="transportMode === 'AIR'">
                                {{ !transportMode ? 'Select Air' : transportMode === 'AIR' ? 'Selected' : 'Change to Air' }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card overflow-hidden border-0 text-dark" [@fadeIn]
                    [ngClass]="{'shadow': transportMode === 'TRUCK' || !transportMode}">
                    <img src="https://cdn.qwyk.io/portals/assets/truck.png" class="card-img-top"
                        [ngStyle]="transportMode ? {position: 'absolute', bottom: 0, transform: 'translateY(25%)'} : {}" />
                    <div class="card-body py-2" style="z-index: 999; background-color: rgba(255, 255, 255, 0.75)">
                        <h5 class="card-title font-weight-normal"
                            [ngStyle]="transportMode && transportMode !== 'TRUCK' ? {opacity: .5} : {}">
                            Truck
                        </h5>
                        <button id="btnTruck" class="btn btn-primary" (click)="transportMode = 'TRUCK'"
                            [ngClass]="transportMode ? 'btn-sm' : null" [disabled]="transportMode === 'TRUCK'">
                            {{ !transportMode ? 'Select Truck' : transportMode === 'TRUCK' ? 'Selected' : 'Change to Truck' }}
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="transportMode">
            <h4 class="mt-5 font-weight-normal">Where is your cargo and where does it need to go?</h4>
            <div class="card border-0 shadow flex-row text-dark overflow-hidden" [@fadeIn]>
                <div class="card-body">
                    <h5 class="card-title font-weight-normal">Origin</h5>
                    <qwyk-location-typeahead id="origin" labelProperty="display_name" placeholder="Origin"
                        (inputChanged)="onLocationsAutocomplete($event)" [suggestions]="locationsSuggestions"
                        [searching]="isSearchingLocations" [(ngModel)]="origin">
                        <ng-template qwykLocationTypeaheadItem let-item="item">
                            <div>
                                <span [innerHTML]="item._highlightResult.display_name.value"></span>
                                (<span [innerHTML]="item._highlightResult.locode.value"></span>)
                            </div>
                            <small>
                                <span [innerHTML]="item._highlightResult.country_name.value"></span>
                            </small>
                        </ng-template>
                        <ng-template qwykLocationTypeaheadPlaceholder let-item="item">
                            <div>
                                <span>{{ item.display_name }}</span>
                                ({{item.locode}})</div>
                            <small>{{ item.country_name }}</small>
                        </ng-template>
                    </qwyk-location-typeahead>

                    <div *ngIf="origin" class="custom-control custom-switch mt-3 custom-control-lg custom-switch-lg">
                        <input type="checkbox" class="custom-control-input" id="wants_pickup"
                            aria-describedby="wantsPickupHelp" [(ngModel)]="pickup">
                        <label class="custom-control-label pl-2" for="wants_pickup">
                            I want my cargo to be picked up from an address.
                        </label>
                        <small id="wantsPickupHelp" class="form-text text-muted pl-2 d-none" *ngIf="!pickup">
                            If you enable this we will pickup the cargo from an address of your choosing (if available),
                            otherwise we expect the cargo to be delivered to an address which we will provide when
                            confirming the booking.
                        </small>
                        <div class="pl-2 mt-2 form-group" *ngIf="pickup">
                            <input type="text" placeholder="Search for an address" class="form-control"
                                #pickupInput="ngModel" [(ngModel)]="pickupName"
                                [ngClass]="{'is-invalid': pickupInput.touched && !pickupAddress}"
                                qwykGooglePlacesAutocomplete (placeSelected)="onPlaceAutocomplete($event, 'pickup')"
                                [placeTypes]="['address']" />
                            <div class="invalid-feedback">Please select a place with a postal code.</div>
                        </div>
                    </div>
                    <div *ngIf="origin && !pickup"
                        class="custom-control custom-switch mt-3 custom-control-lg custom-switch-lg">
                        <input type="checkbox" class="custom-control-input" id="require_origin_charges"
                            [(ngModel)]="requireOriginCharges">
                        <label class="custom-control-label pl-2" for="require_origin_charges">
                            Require origin charges
                        </label>
                    </div>
                </div>
                <div *ngIf="originImage" class="card-body p-1 location-image-container"
                    [ngStyle]="{'background-image': 'url(' + originImage.image_url + ')'}">
                    <div class="image-attribution small px-1" *ngIf="originImage.attribution">&copy; <span
                            [innerHTML]="originImage.attribution"></span></div>
                </div>
            </div>

            <div class="card border-0 shadow mt-4 flex-row text-dark overflow-hidden" *ngIf="transportMode" [@fadeIn]>

                <div class="card-body">
                    <h5 class="card-title font-weight-normal">Destination</h5>
                    <qwyk-location-typeahead id="destination" labelProperty="display_name" placeholder="Destination"
                        (inputChanged)="onLocationsAutocomplete($event)" [suggestions]="locationsSuggestions"
                        [searching]="isSearchingLocations" [(ngModel)]="destination">
                        <ng-template qwykLocationTypeaheadItem let-item="item">
                            <div>
                                <span [innerHTML]="item._highlightResult.display_name.value"></span>
                                (<span [innerHTML]="item._highlightResult.locode.value"></span>)
                            </div>
                            <small>
                                <span [innerHTML]="item._highlightResult.country_name.value"></span>
                            </small>
                        </ng-template>
                        <ng-template qwykLocationTypeaheadPlaceholder let-item="item">
                            <div>
                                <span>{{ item.display_name }}</span>
                                ({{item.locode}})</div>
                            <small>{{ item.country_name }}</small>
                        </ng-template>
                    </qwyk-location-typeahead>
                    <div class="alert alert-warning border-top"
                        style="border-top-left-radius: 0px !important; border-top-right-radius: 0px !important; margin-top: -2px;"
                        *ngIf="origin && destination && origin?.locode === destination?.locode && transportMode !== 'TRUCK'">
                        You've selected the same Origin and Destination, is this correct?
                    </div>
                    <div *ngIf="destination"
                        class="custom-control custom-switch mt-3 custom-control-lg custom-switch-lg">
                        <input type="checkbox" class="custom-control-input" id="wants_delivery"
                            aria-describedby="wantsDeliveryHelp" [(ngModel)]="delivery">
                        <label class="custom-control-label pl-2" for="wants_delivery">I need my cargo delivered to an
                            address.</label>

                        <small id="wantsDeliveryHelp" class="form-text text-muted pl-2 d-none" *ngIf="!delivery">
                            If you enable this we will deliver to cargo to an address of your choosing, otherwise we
                            expect
                            the cargo to be picked up from a location which we'll provide on arrival.
                        </small>
                        <div class="pl-2 mt-2 form-group" *ngIf="delivery">
                            <input type="text" placeholder="Search for an address" class="form-control"
                                #deliveryInput="ngModel" [(ngModel)]="deliveryName"
                                [ngClass]="{'is-invalid': deliveryInput.touched && !deliveryAddress}"
                                qwykGooglePlacesAutocomplete (placeSelected)="onPlaceAutocomplete($event, 'delivery')"
                                [placeTypes]="['address']" />
                            <div class="invalid-feedback">Please select a place with a postal code.</div>
                        </div>
                    </div>
                    <div *ngIf="destination && !delivery"
                        class="custom-control custom-switch mt-3 custom-control-lg custom-switch-lg">
                        <input type="checkbox" class="custom-control-input" id="require_destination_charges"
                            [(ngModel)]="requireDestinationCharges">
                        <label class="custom-control-label pl-2" for="require_destination_charges">
                            Require destination charges
                        </label>
                    </div>
                </div>
                <div *ngIf="destinationImage" class="card-body p-1 location-image-container" [@fadeIn]
                    [ngStyle]="{'background-image': 'url(' + destinationImage.image_url + ')'}">
                    <div class="image-attribution small px-1" *ngIf="destinationImage.attribution">&copy; <span
                            [innerHTML]="destinationImage.attribution"></span></div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="features$ | async; let features">
            <div class="mt-4 text-center"
                *ngIf="transportMode && loadType && origin && destination && (!pickup || pickupAddress)  && (!delivery || deliveryAddress)">
                <div>
                    <button *ngIf="features.portal.public_quotes.value" class="btn btn-secondary btn-lg btn-block"
                        (click)="navigateToCargoDetails()">
                        Enter cargo details
                        <fa-icon [icon]="['far', 'arrow-right']" class="ml-2"></fa-icon>
                    </button>
                    <h6 class="mt-3 w-100">
                        <button (click)="getSchedule()" *ngIf="transportMode !== 'TRUCK'" class="btn btn-block"
                            [ngClass]="{'btn-outline-secondary': features.portal.public_quotes.value, 'btn-secondary btn-lg': !features.portal.public_quotes.value}">
                            <fa-icon [icon]="['far', 'clock']" class="mr-2"></fa-icon>
                            <span *ngIf="features.portal.public_quotes.value">I just need a schedule</span>
                            <span *ngIf="!features.portal.public_quotes.value">Show schedules</span>
                        </button>
                    </h6>
                </div>
            </div>
        </ng-container>
    </div>
</div>
