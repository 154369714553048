import { Observable } from 'rxjs';
import { common, Portals } from '@qwyk/models';

export abstract class QuotationsServiceBase {
    public abstract getQuotations(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        query: any
    ): Observable<common.Pagination<Portals.QuotationEntity>>;

    public abstract getQuotation(
        id: string
    ): Observable<Portals.QuotationEntity>;

    public abstract downloadQuotation(
        quotationId: string,
        offerId: string
    ): Observable<Blob>;
}
