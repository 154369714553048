import {Component, Input} from '@angular/core';
import {Portals} from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-packages-list',
    templateUrl: './quotation-packages-list.component.html',
    styleUrls: ['./quotation-packages-list.component.scss']
})
export class QuotationPackagesListComponent {
    @Input() quotation: Portals.QuotationEntity;

}
