import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromBookingWizard from './../state/booking-wizard-offers.reducer';
import { QuotationsService } from '../services/quotations.service';
import * as bookingWizardSelectors from './../state/booking-wizard-offers.selectors';
import { map, take, tap, catchError, switchMap } from 'rxjs/operators';
import * as BookingWizardOffersActions from './../state/booking-wizard-offers.actions';

@Injectable({
    providedIn: 'root',
})
export class QuotationExistsGuard implements CanActivate {
    constructor(
        private store: Store<fromBookingWizard.BookingWizardOffersPartialState>,
        private service: QuotationsService,
        private router: Router
    ) {}

    hasQuotationInStore(id: string): Observable<boolean> {
        return this.store.pipe(
            select(bookingWizardSelectors.getQuotationRequest),
            map(quotation => !!quotation && quotation.id === id),
            take(1)
        );
    }

    hasQuotationInApi(
        id: string,
        isDemo: boolean,
        tempToken,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.service.getQuotation(id, isDemo, tempToken).pipe(
            map(quotation =>
                BookingWizardOffersActions.loadQuotationSuccess({
                    quotation,
                    isDemo,
                    tempToken,
                })
            ),
            tap(action => this.store.dispatch(action)),
            map(quotation => !!quotation),
            catchError(error => {
                this.router.navigate([`/error/${error.status}`], {
                    queryParams: {
                        source: next.pathFromRoot.map(p => p.url).join('/'),
                        errorName: error.statusText,
                    },
                });
                return of(false);
            })
        );
    }

    hasQuotation(
        id: string,
        isDemo: boolean,
        tempToken = null,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.hasQuotationInStore(id).pipe(
            switchMap(inStore => {
                if (inStore) {
                    return of(inStore);
                }
                return this.hasQuotationInApi(id, isDemo, tempToken, next);
            })
        );
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.hasQuotation(
            next.params['quotationId'],
            next.queryParams['demo'] === 'true',
            next.queryParams['temp_token'],
            next
        );
    }
}
