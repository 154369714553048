<div class="card shadow-sm">
    <div class="card-header bg-white p-lg-4 border-0">
        <h5 class="font-weight-normal m-0">
            {{'portal.menu.side-menu.purchase-orders' | translate}}
        </h5>
    </div>
    <ul class="list-group list-group-flush">
        <li class="list-group-item p-lg-4">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <label class="small font-weight-bold m-0">
                        {{'portal.cargo-detail.search.supplier-po-number' | translate}}
                    </label>
                </div>
                <div class="col-12 col-lg-4">
                    <label class="small font-weight-bold m-0">
                        {{'common.supplier'|translate}}
                    </label>
                </div>
                <div class="col-12 col-lg-4">
                    <label class="small font-weight-bold m-0">
                        {{'portal.common.buyer'|translate}}
                    </label>
                </div>
            </div>
        </li>
        <li class="list-group-item p-lg-4" *ngFor="let shipmentPurchaseOrder of shipmentPurchaseOrders">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <a [routerLink]="['/orders', shipmentPurchaseOrder.id]">
                        {{ shipmentPurchaseOrder.po_number }}
                    </a>
                </div>
                <div class="col-12 col-lg-4">
                    {{ shipmentPurchaseOrder.supplier }}
                </div>
                <div class="col-12 col-lg-4">
                    {{ shipmentPurchaseOrder.buyer }}
                </div>
            </div>
        </li>
    </ul>
</div>
