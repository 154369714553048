import {
    NgModule,
    Injectable,
    ErrorHandler,
    APP_INITIALIZER,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    FaIconLibrary,
    FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import * as LogRocket from 'logrocket';
import { MomentModule } from 'ngx-moment';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from '@sentry/angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { JwtModule } from '@auth0/angular-jwt';
import { SegmentModule } from 'ngx-segment-analytics';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLaravelEchoModule } from 'ngx-laravel-echo';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { ReactiveValidationModule } from 'angular-reactive-validation';

import {
    CoreModule,
    AmplitudeService,
    VersionCheckerService,
} from '@qwyk/core';
import { UtilitiesModule } from '@qwyk/utilities';
import { environment } from '@qwyk/portals/environment';
import { PortalsSiteconfigModule } from '@qwyk/portals/siteconfig';
import {
    PortalsAuthenticationModule,
    TokenGetterService,
} from '@qwyk/portals/authentication';
import { PortalsNotificationsModule } from '@qwyk/portals/notifications';
import {
    TranslateConfigRoot,
    PortalsTranslationModule,
} from '@qwyk/portals/translation';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { PortalModule } from './modules/portal/portal.module';
import { ControlsModule } from './modules/controls/controls.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';

import { appReducer } from './store/app.reducer';
import { AppEffects } from './store/app.effects';

import loadIcons from './helpers/iconsLoader';
import { versions } from '../environments/versions';
import { WINDOW_PROVIDERS } from './window.provider';
import { DEPLOY_URL } from '../environments/deployUrl';
import { StartupService } from './services/startup.service';
import { AppComponent } from './components/app/app.component';
import { FourOFourComponent } from './components/four-ofour/four-ofour.component';
import { DebugPageComponent } from './components/debug-page/debug-page.component';
import { WelcomeModalComponent } from './components/welcome-modal/welcome-modal.component';
import { AppFourOFourComponent } from './components/app-four-o-four/app-four-o-four.component';

export function startupServiceFactory(
    startupService: StartupService
): () => Promise<void> {
    return () => startupService.load();
}

export function tokenGetter() {
    return new TokenGetterService().getToken();
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static handleChunkLoadingError(error: any) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
    }

    handleError(error) {
        SentryErrorHandler.handleChunkLoadingError(error);

        Sentry.captureException(error.originalError || error);
        console.error(error);
    }
}

@NgModule({
    declarations: [
        AppComponent,
        FourOFourComponent,
        DebugPageComponent,
        AppFourOFourComponent,
        WelcomeModalComponent,
    ],
    imports: [
        CommonModule,
        CoreModule.forRoot(environment),
        PortalsSiteconfigModule,
        PortalsAuthenticationModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        StoreModule.forRoot(
            { app: appReducer },
            {
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                    strictStateSerializability: false,
                    strictActionSerializability: false, // FIXME: this fails on nothing found in schedules, check
                },
            }
        ),
        EffectsModule.forRoot([AppEffects]),
        StoreDevtoolsModule.instrument({ logOnly: environment.production }),
        FormsModule,
        PortalModule,
        DashboardModule,
        MomentModule,
        FontAwesomeModule,
        NgbModule,
        ControlsModule,
        UtilitiesModule,
        PortalsNotificationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains:
                    environment.auth.bearerTokenConfig.allowedDomains,
                disallowedRoutes: [],
                skipWhenExpired: true,
            },
        }),
        ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
        NgxLaravelEchoModule.forRoot(environment.echoConfig),
        SegmentModule.forRoot(environment.utils.Segment),
        NgcCookieConsentModule.forRoot({
            cookie: {
                domain: window.location.hostname,
            },
            position: 'bottom',
            theme: 'edgeless',
            palette: {
                popup: {
                    background: '#000000',
                    text: '#ffffff',
                    link: '#ffffff',
                },
                button: {
                    background: '#007bff',
                    text: '#ffffff',
                    border: 'transparent',
                },
            },
            type: 'info',
            content: {
                message:
                    'This website uses cookies to ensure you get the best experience on our website.',
                dismiss: 'Got it!',
                deny: 'Refuse cookies',
                link: 'Learn more',
                href: 'https://docs.qwykportals.com/resources/legal/cookie-policy',
                policy: 'Cookie Policy',
            },
        }),
        ReactiveValidationModule.forRoot({
            displayValidationMessageWhen: control => {
                return control.touched;
            },
        }),
        PortalsTranslationModule.forRoot({
            translationsDeployUrl: `${DEPLOY_URL}assets/i18n/`,
        }),
        TranslateModule.forRoot(TranslateConfigRoot),
    ],
    providers: [
        WINDOW_PROVIDERS,
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: Sentry.TraceService, deps: [Router], useValue: undefined },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private readonly _library: FaIconLibrary,
        private readonly _amplitude: AmplitudeService,
        private readonly _versionChecker: VersionCheckerService
    ) {
        if (environment.utils.LogRocket.enable) {
            LogRocket.init(environment.utils.LogRocket.appId, {
                release: versions.version,
            });

            LogRocket.getSessionURL(sessionURL => {
                Sentry.configureScope(scope => {
                    scope.setExtra('sessionURL', sessionURL);
                });
            });
        }

        loadIcons(_library);

        _amplitude.initialize();

        if (environment.production) {
            _versionChecker.init(versions.version);
        }
    }
}
