/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { NotificationsEntity } from './notifications.models';
import { Update } from '@ngrx/entity';
import { common } from '@qwyk/models';

export const loadNotifications = createAction(
    '[Portals/Notifications] Load Notifications'
);

export const loadNotificationsSuccess = createAction(
    '[Portals/Notifications] Load Notifications Success',
    props<{ notifications: common.PaginatedResponse<NotificationsEntity> }>()
);

export const loadNotificationsFailure = createAction(
    '[Portals/Notifications] Load Notifications Failure',
    props<{ error: any }>()
);

export const loadMoreNotifications = createAction(
    '[Portals/Notifications] Load More Notifications'
);

export const deleteNotification = createAction(
    '[Portals/Notifications] Delete Notification',
    props<{ notification: NotificationsEntity }>()
);
export const deleteNotificationSuccess = createAction(
    '[Portals/Notifications] Delete Notification Success',
    props<{ unread: number }>()
);
export const deleteNotificationFailure = createAction(
    '[Portals/Notifications] Delete Notification Failure',
    props<{ error: any; restore: NotificationsEntity }>()
);

export const toggleNotificationRead = createAction(
    '[Portals/Notifications] Toggle Read',
    props<{ notification: Update<NotificationsEntity> }>()
);
export const toggleNotificationReadSuccess = createAction(
    '[Portals/Notifications] Toggle Read Success',
    props<{ unread: number }>()
);
export const toggleNotificationReadFailure = createAction(
    '[Portals/Notifications] Toggle Read Failure',
    props<{ error: any; restore: Update<NotificationsEntity> }>()
);

export const selectNotification = createAction(
    '[Portals/Notifications] Select Notification',
    props<{ notification: NotificationsEntity }>()
);
export const unselectNotification = createAction(
    '[Portals/Notifications] Unselect Notification'
);

export const loadUnreadNotifications = createAction(
    '[Portals/Notifications] Load Unread Notifications'
);
export const loadUnreadNotificationsSuccess = createAction(
    '[Portals/Notifications] Load Unread Notifications Success',
    props<{ unread: number }>()
);
export const loadUnreadNotificationsFailure = createAction(
    '[Portals/Notifications] Load Unread Notifications Failure',
    props<{ error: any }>()
);

// Echo
export const initializeEchoListener = createAction(
    '[Portals/Notifications] Initialize Echo Listener',
    props<{ token: string; userId: string }>()
);

export const deinitializeEchoListener = createAction(
    '[Portals/Notifications] Deinitialize Echo Listener'
);

export const echoNotificationReceived = createAction(
    '[Portals/Notifications] Echo Notification Received',
    props<{ notification: NotificationsEntity }>()
);
