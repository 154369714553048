import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestedNavigation } from '../login-modal/login-modal.component';
import { ResetPasswordService } from '../../services/reset-password.service';
import { Subscription } from 'rxjs';
import { SegmentService } from 'ngx-segment-analytics';
import { SegmentEvent } from '@qwyk/core';

@Component({
    selector: 'qwyk-request-password-reset-form',
    templateUrl: './request-password-reset-form.component.html',
    styleUrls: ['./request-password-reset-form.component.scss'],
})
export class RequestPasswordResetFormComponent implements OnDestroy {
    @Output() navigate: EventEmitter<RequestedNavigation> = new EventEmitter();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    error = false;
    private resetSubscription: Subscription;

    public requestPasswordResetForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private resetPasswordService: ResetPasswordService,
        private segment: SegmentService
    ) {
        this.requestPasswordResetForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
        });
    }

    ngOnDestroy() {
        if (this.resetSubscription) {
            this.resetSubscription.unsubscribe();
        }
    }

    onGoToLogin() {
        this.navigate.emit({ component: 'login' });
    }

    onGoToResetPassword() {
        this.navigate.emit({ component: 'reset-password' });
    }

    onRequestPasswordReset() {
        this.error = false;
        this.requestPasswordResetForm.markAllAsTouched();
        if (this.requestPasswordResetForm.invalid) {
            return;
        }

        const data = this.requestPasswordResetForm.value;
        this.segment.track(SegmentEvent.PASSWORD_RESET_REQUEST, { data });
        this.requestPasswordResetForm.disable();
        this.resetSubscription = this.resetPasswordService
            .requestPasswordReset(data.email)
            .subscribe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                _ => {
                    this.requestPasswordResetForm.enable();
                    this.navigate.emit({ component: 'reset-password', data });
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                __ => {
                    this.error = true;
                    this.requestPasswordResetForm.enable();
                }
            );
    }
}
