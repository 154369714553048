import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as InvoicesActions from './invoices.actions';
import { InvoicesServiceBase } from '../invoices-service-base';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class InvoicesEffects {
    loadInvoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InvoicesActions.loadInvoices),
            fetch({
                run: action => {
                    return this.service.getInvoices(action.query).pipe(
                        map(response =>
                            InvoicesActions.loadInvoicesSuccess({
                                invoices: response.data,
                                pagination: response.meta,
                            })
                        )
                    );
                },

                onError: (action, error) => {
                    console.error(error);
                    return InvoicesActions.loadInvoicesFailure({ error });
                },
            })
        )
    );

    updateInvoice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InvoicesActions.updateInvoice),
            switchMap(action =>
                this.service
                    .updateInvoice(action.invoice.id, action.invoice)
                    .pipe(
                        map(invoice =>
                            InvoicesActions.updateInvoiceSuccess({ invoice })
                        ),
                        catchError(error =>
                            of(InvoicesActions.updateInvoiceFailure({ error }))
                        )
                    )
            )
        )
    );

    deleteInvoice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InvoicesActions.deleteInvoice),
            switchMap(action =>
                this.service.deleteInvoice(action.invoice.id).pipe(
                    map(() =>
                        InvoicesActions.deleteInvoiceSuccess({
                            invoice: action.invoice,
                        })
                    ),
                    catchError(error =>
                        of(InvoicesActions.deleteInvoiceFailure({ error }))
                    )
                )
            )
        )
    );

    updateInvoiceSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(InvoicesActions.updateInvoiceSuccess),
                tap(() => {
                    this.router.navigate(['/invoices'], {
                        queryParams: {
                            message_type: 'success',
                            message: 'Invoice was saved',
                        },
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    deleteInvoiceSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(InvoicesActions.deleteInvoiceSuccess),
                tap(() => {
                    this.router.navigate(['/invoices'], {
                        queryParams: {
                            message_type: 'success',
                            message: 'Invoice was deleted',
                        },
                    });
                })
            ),
        {
            dispatch: false,
        }
    );

    constructor(
        private actions$: Actions,
        private service: InvoicesServiceBase,
        private router: Router
    ) {}
}
