import { Component } from '@angular/core';

@Component({
    selector: 'qwyk-schedules',
    templateUrl: './schedules.component.html',
    styleUrls: ['./schedules.component.scss'],
})
export class SchedulesComponent {
    constructor() {}
}
