import { Pipe, PipeTransform } from '@angular/core';
import { Portals } from '@qwyk/models';

@Pipe({ name: 'multiUnitPipe' })
export class MultiUnitPipe implements PipeTransform {
    transform(
        value: Portals.MultiUnitValue,
        unit: string = 'metric'
    ): { value: number; unit: string } {
        if (!value) {
            return;
        }
        const unitValue: Portals.UnitValue = value[unit];
        if (!unitValue) {
            return;
        }
        return { value: unitValue.value, unit: unitValue.unit };
    }
}
