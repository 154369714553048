import {
    Input,
    Output,
    Component,
    HostBinding,
    EventEmitter,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [NgClass, NgIf, FontAwesomeModule, TranslateModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-list-item-nothing-found]',
    templateUrl: './list-item-nothing-found.component.html',
    styleUrls: ['./list-item-nothing-found.component.scss'],
})
export class ListItemNothingFoundComponent {
    @Input() resource = 'Items';
    @Input() icon: IconName = 'file';
    @Output() action: EventEmitter<void> = new EventEmitter<void>();
    @Input() actionLabel = 'Action';
    @Input() dense = false;
    @Input() actionEnabled = false;
    private normalClass =
        'list-group-item p-4 text-center text-muted nothing-found';
    private denseClass = 'list-group-item text-center text-muted nothing-found';

    @HostBinding('class')
    public get class(): string {
        return this.dense ? this.denseClass : this.normalClass;
    }
}
