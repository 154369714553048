<div class="small text-muted border-bottom pb-2">
    <ng-container *ngFor="let element of url; let idx = index;">
        <a *ngIf="idx < url.length - 1 || !entityIdValue" class="text-muted" [ngClass]="{'font-weight-bold': idx === url.length - 1}" [routerLink]="element.path">
            {{ (idx === 0 ? root : app + '.common.' + element.name | translate)  }}
        </a>
        <a *ngIf="idx === url.length - 1 && entityIdValue" class="text-muted" [ngClass]="{'font-weight-bold': idx === url.length - 1}" [routerLink]="element.path">
            {{ entityIdValue }}
        </a>
        <span *ngIf="idx < url.length - 1">&nbsp;&rsaquo;&nbsp;</span>
    </ng-container>
</div>
