<div class="row align-items-center" [ngClass]="{'text-muted': event.is_internal}">
    <div class="col-4">
        <div class=" d-flex align-items-center">
            <ng-container *ngIf="event.code | valueMap: EVENT_CODE_ICONS:'null'; let icon;">
                <fa-icon *ngIf="icon !== 'null'" [fixedWidth]="true" [icon]="['far', icon]" class="mr-3"
                         size="lg"></fa-icon>
            </ng-container>
            <div [innerHTML]="event.description | lineBreaks"></div>
        </div>
    </div>
    <div class="col-3">
        <ng-container *ngIf="!showLocalTime">
            {{ event.timestamp | amUtc | amDateFormat: "L LT" }}
            <span [ngbTooltip]="userTimezone?.tz()" class="small text-muted">{{ userTimezone?.zoneAbbr() }}</span>
        </ng-container>
        <ng-container *ngIf="showLocalTime">
            {{ event.timestamp_local | amParseZone | amLocal |  amDateFormat: "L LT (Z)" }}
        </ng-container>
    </div>
    <div class="col-3">
        {{ event.location?.name }}
    </div>
    <div class="col-2">
        {{ event.container?.container_number }}
    </div>
</div>
