<ng-container *ngIf="(selectedView$ | async) as sv">
    <div class="input-group justify-content-end">
        <div class="input-group-prepend">
            <button (click)="createView()" class="btn btn-primary" [disabled]="loading$ | async" style="max-height: 36.5px;">
                {{ 'common.create' | translate }}
                <fa-icon
                    *ngIf="loading$ | async"
                    [icon]="['far', 'spinner']"
                    [pulse]="true"
                    class="mr-1"
                ></fa-icon>
            </button>
        </div>
        <p-dropdown
            dataKey="uid"
            appendTo="body"
            optionLabel="name"
            class="d-flex flex-grow-1"
            [options]="storedViews"
            [style]="{ 'border-radius': '0', 'minWidth':'200px', 'width': '200px' }"
            [ngModel]="sv"
            (onChange)="onChangeView($event.value.uid)"
            >
            <ng-template let-view pTemplate="selectedItem">
                <div class="flex-grow-1" *ngIf="view">
                    <span>{{ view.name }}</span>
                </div>
            </ng-template>
            <ng-template let-view pTemplate="item">
                <div class="d-flex align-items-center justify-content-between">
                    <span>{{ view.name }}</span>
                    <button
                        *ngIf="view.uid !== DEFAULT_VIEW_ID"
                        type="button"
                        (click)="onRemoveView(view, $event)"
                        class="btn btn-primary btn-sm ml-2 d-flex">
                        <fa-icon [icon]="['far', 'trash']"></fa-icon>
                    </button>
                </div>
            </ng-template>
        </p-dropdown>
        <div class="input-group-append">
            <button (click)="display = true" class="btn btn-primary" [disabled]="sv.uid === DEFAULT_VIEW_ID" style="max-height: 36.5px;">
                <fa-icon [icon]="['far', 'cog']"></fa-icon>
            </button>
        </div>
    </div>

    <p-sidebar [(visible)]="display" position="right" (onHide)="updateView()">
        <ng-template pTemplate="header">{{ 'common.customize' | translate }}</ng-template>
        <ng-template pTemplate="content">
            <p-tabView [formGroup]="customizeForm">
                {{ 'portal.custom-table-views.message' | translate }}

                <p-tabPanel header="{{ 'common.overview' | translate }}" [selected]="true">
                    <div class="row mt-3">
                        <div class="col-12 mb-3" *ngIf="sv.uid !== DEFAULT_VIEW_ID">
                            <div class="form-group p-fluid">
                                <label for="name" class="form-label">{{ 'portal.custom-table-views.labels.name' | translate }}</label>
                                <input
                                    formControlName="name"
                                    id="name"
                                    name="name"
                                    pInputText
                                    type="text"/>
                                <arv-validation-messages for="name"></arv-validation-messages>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row">
                                <label class="col-sm-6 col-form-label">{{ 'portal.custom-table-views.labels.default_view' | translate }}</label>
                                <div class="col-sm-6 text-right">
                                    <p-selectButton
                                        id="default_view"
                                        name="default_view"
                                        formControlName="default_view"
                                        [options]="stateOptions"
                                        optionLabel="label"
                                        optionValue="value"></p-selectButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="{{ 'common.columns' | translate }}">
                    <div class="mt-2">
                        <p-table
                            #updateViewTable
                            dataKey="id"
                            class="update-view_table"
                            styleClass="custom-table"
                            selectionMode="multiple"
                            [value]="columns"
                            [selectAll]="selectAll"
                            [selection]="checkedColumns"
                            [columns]="[{ field: 'column', header: 'common.column' }]"
                            [tableStyle]="{ 'border-collapse': 'separate', 'border-spacing': '0 0.3em' }"
                            [globalFilterFields]="['label']"
                            (selectionChange)="onSelectionChange($event, sv, updateViewTable)"
                            (selectAllChange)="onSelectAllChange($event, sv, updateViewTable)"
                            (onRowReorder)="onRowReorder($event, sv, updateViewTable)"
                        >
                            <ng-template pTemplate="caption">
                                <div class="d-flex justify-content-center">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input
                                            pInputText
                                            #searchField
                                            type="text"
                                            placeholder="{{ 'common.search' | translate }}"
                                            (input)="updateViewTable.filterGlobal(searchField.value, 'contains')"
                                            />
                                    </span>
                                </div>
                            </ng-template>

                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th style="width: 4rem" class="text-center bg-white">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th *ngFor="let col of columns" class="bg-white">
                                        {{ col.header | translate }}
                                    </th>
                                    <th style="width:3rem" class="bg-white"></th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body"
                                         let-column
                                         let-index="rowIndex">

                                <tr [pReorderableRow]="index">
                                    <td class="text-center">
                                        <p-tableCheckbox
                                            [value]="column"
                                            [disabled]="shouldDisableRow(column)"
                                            [index]="index"
                                        ></p-tableCheckbox>
                                    </td>
                                    <td>
                                        {{ column.label }}
                                    </td>
                                    <td>
                                        <span class="pi pi-bars" pReorderableRowHandle></span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </ng-template>
    </p-sidebar>
</ng-container>
