import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { select, Store } from '@ngrx/store';

import { Portals } from '@qwyk/models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Shipment } from '@qwyk/shared-stores/shipments';

import * as ShipmentContainersActions from './shipment-containers.actions';
import * as ShipmentContainersSelectors from './shipment-containers.selectors';

@Injectable()
export class ShipmentContainersFacade {
    /**
     * Combine pieces of state using createSelector,
     * and expose them as observables through the facade.
     */
    loading$ = this.store.pipe(
        select(ShipmentContainersSelectors.getShipmentContainersLoading)
    );
    allShipmentContainers$ = this.store.pipe(
        select(ShipmentContainersSelectors.getAllShipmentContainers)
    );
    selectedShipmentContainers$ = this.store.pipe(
        select(ShipmentContainersSelectors.getSelected)
    );

    geotrackLoading$ = this.store.pipe(
        select(ShipmentContainersSelectors.getGeotrackLoading)
    );
    geotrackError$ = this.store.pipe(
        select(ShipmentContainersSelectors.getGeotrackError)
    );
    geotrack$ = this.store.pipe(
        select(ShipmentContainersSelectors.getGeotrack)
    );

    constructor(private store: Store) {}

    /**
     * Use the initialization action to perform one
     * or more tasks in your Effects.
     */
    init(shipment: Shipment) {
        this.store.dispatch(ShipmentContainersActions.init({ shipment }));
    }

    public select(
        container: Portals.ShipmentDataContainer,
        shipmentId: string,
        geotrack = false
    ): void {
        this.store.dispatch(
            ShipmentContainersActions.select({
                container,
                shipmentId,
                geotrack,
                selectedAt: moment(),
            })
        );
    }
}
