import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideBarService} from './services/side-bar.service';

@NgModule({
    imports: [CommonModule],
    providers: [SideBarService],
})
export class PortalsUiModule {
}
