import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@qwyk/portals/environment';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {CustomerPermissions} from '@qwyk/core';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    constructor(
        private http: HttpClient
    ) {
    }

    getUsersPermissions(): Observable<CustomerPermissions[]> {
        return this.http.get<CustomerPermissions[]>(`${environment.backendServer}/api/portals/auth/permissions`).pipe(first());
    }
}


