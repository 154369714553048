import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    INVOICES_FEATURE_KEY,
    State,
    InvoicesPartialState,
    invoicesAdapter,
} from './invoices.reducer';

// Lookup the 'Invoices' feature state managed by NgRx
export const getInvoicesState = createFeatureSelector<
    InvoicesPartialState,
    State
>(INVOICES_FEATURE_KEY);

const { selectAll, selectEntities } = invoicesAdapter.getSelectors();

export const getInvoicesLoaded = createSelector(
    getInvoicesState,
    (state: State) => state.loaded
);

export const getInvoicesLoading = createSelector(
    getInvoicesState,
    (state: State) => state.loading
);

export const getInvoicesError = createSelector(
    getInvoicesState,
    (state: State) => state.error
);

export const getInvoicesPagination = createSelector(
    getInvoicesState,
    (state: State) => state.pagination
);

export const getAllInvoices = createSelector(getInvoicesState, (state: State) =>
    selectAll(state)
);

export const getInvoicesEntities = createSelector(
    getInvoicesState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getInvoicesState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getInvoicesEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);
