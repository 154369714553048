<div class="container d-flex flex-column align-items-center">
    <div class="item border border-primary d-none d-xxxl-block w-xxxl">
        XXXL
    </div>
    <div class="item border border-primary d-none d-xxl-block w-xxl">
        XXL
    </div>
    <div class="item border border-primary d-none d-xl-block w-xl">
        XL
    </div>
    <div class="item border border-primary d-none d-lg-block w-lg">
        LG
    </div>
    <div class="item border border-primary d-none d-md-block w-md">
        MD
    </div>
    <div class="item border border-primary d-none d-sm-block w-sm">
        SM
    </div>
    <div class="item border border-primary d-none d-xs-block w-xs">
        XS
    </div>
    <div class="item border border-primary d-none d-xxs-block w-xxs">
        XXS
    </div>
</div>