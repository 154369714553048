import { createReducer, on, Action } from '@ngrx/store';
import {
    EntityState,
    EntityAdapter,
    createEntityAdapter,
    Update,
} from '@ngrx/entity';

import * as NotificationsActions from './notifications.actions';
import { NotificationsEntity } from './notifications.models';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface State extends EntityState<NotificationsEntity> {
    selectedId?: string | number; // which Notifications record has been selected
    loaded: boolean; // has the Notifications list been loaded
    error?: string | null; // last none error (if any)
    loadingPage: boolean;
    loadedPage: number | null;
    lastPage: number | null;
    perPage: number | null;
    total: number | null;
    unread: number;
    echoConfig?: {
        token: string;
        userId: string;
    };
}

export interface NotificationsPartialState {
    readonly [NOTIFICATIONS_FEATURE_KEY]: State;
}

export function sortByDate(
    a: NotificationsEntity,
    b: NotificationsEntity
): number {
    if (a.received_at === b.received_at) {
        return a.subject > b.subject ? 1 : -1;
    }
    return a.received_at > b.received_at ? -1 : 1;
}

export const notificationsAdapter: EntityAdapter<NotificationsEntity> =
    createEntityAdapter<NotificationsEntity>({ sortComparer: sortByDate });

export const initialState: State = notificationsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loadingPage: false,
    loadedPage: null,
    lastPage: null,
    perPage: null,
    total: null,
    unread: 0,
    echoConfig: null,
});

const notificationsReducer = createReducer(
    initialState,
    on(NotificationsActions.loadNotifications, state => ({
        ...state,
        error: null,
    })),
    on(
        NotificationsActions.loadNotificationsSuccess,
        (state, { notifications }) => {
            return notificationsAdapter.addMany(notifications.data, {
                ...state,
                loaded: true,
                loadingPage: false,
                loadedPage: notifications.meta.current_page,
                lastPage: notifications.meta.last_page,
                perPage: notifications.meta.per_page,
                total: notifications.meta.total,
                unread: notifications.extra.unread,
            });
        }
    ),
    on(NotificationsActions.loadNotificationsFailure, (state, { error }) => ({
        ...state,
        loadingPage: false,
        error,
    })),
    on(NotificationsActions.loadMoreNotifications, state => ({
        ...state,
        loadingPage: true,
    })),
    on(NotificationsActions.deleteNotification, (state, { notification }) =>
        notificationsAdapter.removeOne(notification.id, {
            ...state,
            unread: notification.read ? state.unread : state.unread - 1,
        })
    ),
    on(NotificationsActions.deleteNotificationSuccess, (state, { unread }) => ({
        ...state,
        unread,
    })),
    on(NotificationsActions.deleteNotificationFailure, (state, { restore }) =>
        notificationsAdapter.addOne(restore, {
            ...state,
            unread: restore.read ? state.unread : state.unread + 1,
        })
    ),
    on(NotificationsActions.toggleNotificationRead, (state, { notification }) =>
        notificationsAdapter.updateOne(notification, {
            ...state,
            unread: notification.changes.read
                ? state.unread - 1
                : state.unread + 1,
        })
    ),
    on(
        NotificationsActions.toggleNotificationReadSuccess,
        (state, { unread }) => ({
            ...state,
            unread,
        })
    ),
    on(
        NotificationsActions.toggleNotificationReadFailure,
        (state, { restore }) => {
            const change: Update<NotificationsEntity> = {
                id: restore.id as string,
                changes: { read: !restore.changes.read },
            };
            return notificationsAdapter.updateOne(change, {
                ...state,
                unread: restore.changes.read
                    ? state.unread + 1
                    : state.unread - 1,
            });
        }
    ),
    on(NotificationsActions.selectNotification, (state, { notification }) => ({
        ...state,
        selectedId: notification.id,
    })),
    on(NotificationsActions.unselectNotification, state => ({
        ...state,
        selectedId: null,
    })),
    on(
        NotificationsActions.loadUnreadNotificationsSuccess,
        (state, { unread }) => ({ ...state, unread })
    ),
    on(
        NotificationsActions.initializeEchoListener,
        (state, { token, userId }) => ({
            ...state,
            echoConfig: {
                token,
                userId,
            },
        })
    ),
    on(NotificationsActions.deinitializeEchoListener, state => ({
        ...state,
        echoConfig: null,
    })),
    on(
        NotificationsActions.echoNotificationReceived,
        (state, { notification }) =>
            notificationsAdapter.addOne(notification, {
                ...state,
                unread: state.unread + 1,
            })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return notificationsReducer(state, action);
}
