import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
    trigger,
    transition,
    style,
    query,
    animate,
} from '@angular/animations';
import { BookingWizardConfig } from './../../bookingWizardConfig';

export const slideInAnimation = trigger('routeAnimations', [
    transition('* => *', [
        query(':enter', [style({ opacity: 0 })], { optional: true }),

        query(
            ':leave',
            [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))],
            { optional: true }
        ),

        query(
            ':enter',
            [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))],
            { optional: true }
        ),
    ]),
]);

@Component({
    selector: 'qwyk-booking-wizard-container',
    templateUrl: './booking-wizard-container.component.html',
    styleUrls: ['./booking-wizard-container.component.scss'],
    animations: [slideInAnimation],
})
export class BookingWizardContainerComponent {
    constructor(public config: BookingWizardConfig) {}

    prepareRoute(outlet: RouterOutlet) {
        return (
            outlet &&
            outlet.activatedRouteData &&
            outlet.activatedRouteData['animation']
        );
    }
}
