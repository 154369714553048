import {Injectable} from '@angular/core';
import {ShipmentTrackingServiceBase} from '@qwyk/shared-stores/shipment-tracking-events';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Portals} from '@qwyk/models';
import {environment} from '@qwyk/portals/environment';
import {map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ShipmentTrackingEventsService
    implements ShipmentTrackingServiceBase {
    constructor(private http: HttpClient) {
    }

    getShipmentTrackingEvents(
        shipmentId: string
    ): Observable<Portals.ShipmentTrackingEvent[]> {
        return this.http
            .get<{ data: Portals.ShipmentTrackingEvent[] }>(
                `${environment.backendServer}/api/portals/shipments/v2/${shipmentId}/tracking-events`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }
}
