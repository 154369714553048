<div class="wrapper row justify-content-center mx-0">
    <div class="col-12 col-lg-8 col-xxxl-6">
        <h2 class="font-weight-normal">Schedules</h2>
        <h6 class="font-weight-light">Access our point-to-point schedules by searching for your
            preferred routing.</h6>
        <hr>
        <qwyk-schedule-search></qwyk-schedule-search>
        <div class="back-button-container text-center mt-3">
            <button class="btn btn-secondary" [routerLink]="['..']">
                <fa-icon [icon]="['far', 'arrow-left']" class="mr-2"></fa-icon>Start over
            </button>
        </div>
    </div>
</div>