<div class="row align-items-center">
    <div class="col">
        <div>
            <div class="small text-muted">{{ 'common.entities.quotation' | translate }}</div>
            <a [routerLink]="['/quotations', quotation.id]">
                {{quotation.friendly_id}}
            </a>
        </div>
    </div>
    <div class="col">
        <div class="small text-muted">{{ 'common.quotations.page.request-list.offer' | translate }}</div>
        <div>{{ quotation.carrier_name}} - {{ quotation.rate_name}}</div>
    </div>
    <div class="col text-right">
        <div class="small text-muted">{{ 'common.total' | translate }}</div>
        <div class="font-weight-bold">{{ quotation.total_amount | currency: quotation.currency }}</div>
    </div>
</div>

