import { Observable } from 'rxjs';
import { common, Portals } from '@qwyk/models';

export abstract class InvoicesServiceBase {
    public abstract getInvoices(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        query: any
    ): Observable<common.Pagination<Portals.Invoice>>;

    public abstract getInvoice(id: string): Observable<Portals.Invoice>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public abstract updateInvoice(
        id: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: any
    ): Observable<Portals.Invoice>;

    public abstract deleteInvoice(id: string): Observable<void>;
}
