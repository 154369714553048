import { Component } from '@angular/core';

import * as moment from 'moment';
import { Store } from '@ngrx/store';

import { AppState } from './../../../../store/app.state';
import * as dashboardActions from '../../store/actions/dashboard.actions';
import * as fromDashboard from '../../store/selectors/dashboard.selectors';
import { fadeInAnimation } from './../../../../helpers/reusableAnimations';

@Component({
    selector: 'qw-ops-dashboard',
    templateUrl: './ops-dashboard.component.html',
    styleUrls: ['./ops-dashboard.component.scss'],
    animations: [fadeInAnimation],
})
export class OpsDashboardComponent {
    shipmentCounts$ = this._store.select(fromDashboard.selectShipmentsCount);
    shipmentsData$ = this._store.select(fromDashboard.selectShipmentsList);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    view: any[];
    collapsedShipments = {};
    overduePageSize = 5;
    overduePage = 1;
    upcomingPageSize = 4;
    upcomingPage = 1;

    constructor(private _store: Store<AppState>) {
        this._store.dispatch(dashboardActions.loadOpsData());
    }

    axisDateFormat(data) {
        return moment(data).format('MMM D');
    }
    axisWeekNum(data) {
        return moment(data).week();
    }
}
