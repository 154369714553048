import { Component, OnInit, Input } from '@angular/core';

import { Observable, of } from 'rxjs';
import { shareReplay, tap, delay, switchMap } from 'rxjs/operators';

import { Portals } from '@qwyk/models';
import { MasterDataService } from '@qwyk/core';

@Component({
    selector: 'qwyk-quotation-offer-list-item-map',
    templateUrl: './quotation-offer-list-item-map.component.html',
    styleUrls: ['./quotation-offer-list-item-map.component.scss'],
})
export class QuotationOfferListItemMapComponent implements OnInit {
    @Input() offer: Portals.QuotationOffer;
    @Input() quotation: Portals.QuotationEntity;
    @Input() expanded: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    routes$: Observable<any>;

    public mapOptions: google.maps.MapOptions = {
        center: { lat: 0, lng: 0 },
        zoom: 2,
        mapTypeId: 'satellite',
        disableDefaultUI: true,
    };
    public mapLayers = [];
    public map: google.maps.Map;

    constructor(private masterData: MasterDataService) {}

    ngOnInit() {
        this.routes$ = of(null).pipe(
            delay(Math.random() * 1000), // Delay so we don't load all routes at the same time
            switchMap(() =>
                this.offer.transport_mode === 'OCEAN'
                    ? this.masterData
                          .getOceanRoute(
                              this.offer.pol.locode,
                              this.offer.pod.locode
                          )
                          .pipe(shareReplay())
                    : of({
                          routes: [],
                      })
            ),
            tap(response => {
                if (response.routes.length > 0) {
                    const route = response.routes[0];
                    this.setMapFromOceanRoute(route);
                } else {
                    if (this.offer.transport_mode === 'TRUCK') {
                        this.setMapDirectionsRoute();
                    } else {
                        this.setMapGeodesicRoute();
                    }
                }
            })
        );
    }

    setMap(e) {
        this.map = e.map;
    }

    private setMapFromOceanRoute(route) {
        const path = [];
        const bounds = new google.maps.LatLngBounds();
        route.path.coordinates.forEach(element => {
            const marker = new google.maps.LatLng(element[1], element[0]);
            path.push(marker);
            bounds.extend(marker);
        });

        const polyOpts: google.maps.PolylineOptions = {
            path,
            strokeOpacity: 0.8,
            strokeWeight: 3,
            strokeColor: '#fff',
            geodesic: false,
        };

        this.mapLayers.push(new google.maps.Polyline(polyOpts));

        setTimeout(() => {
            if (this.map) {
                this.map.fitBounds(bounds, 10);
            }
        }, 500);
    }

    private setMapDirectionsRoute() {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        setTimeout(() => {
            if (this.map) {
                directionsRenderer.setMap(this.map);
            }
        }, 50);

        directionsService.route(
            {
                origin: {
                    query:
                        this.quotation.request.origin.type === 'place'
                            ? this.quotation.request.origin.name
                            : this.offer.pol.display_name,
                },
                destination: {
                    query:
                        this.quotation.request.destination.type === 'place'
                            ? this.quotation.request.destination.name
                            : this.offer.pod.display_name,
                },
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(response);
                }
            }
        );
    }

    private setMapGeodesicRoute() {
        const path = [];
        const bounds = new google.maps.LatLngBounds();

        let origin = null;
        if (this.offer.pol && this.offer.pol.latitude_dec) {
            origin = new google.maps.LatLng(
                this.offer.pol.latitude_dec,
                this.offer.pol.longitude_dec
            );
        } else if (this.quotation.request.origin.type === 'place') {
            origin = this.extractPlaceCodeLatLng(
                this.quotation.request.origin.code
            );
        }

        let destination = null;
        if (this.offer.pod && this.offer.pod.latitude_dec) {
            destination = new google.maps.LatLng(
                this.offer.pod.latitude_dec,
                this.offer.pod.longitude_dec
            );
        } else if (this.quotation.request.origin.type === 'place') {
            destination = this.extractPlaceCodeLatLng(
                this.quotation.request.destination.code
            );
        }

        if (!origin || !destination) {
            return;
        }

        path.push(origin);
        bounds.extend(origin);

        path.push(destination);
        bounds.extend(destination);

        const polyOpts: google.maps.PolylineOptions = {
            path,
            strokeOpacity: 0.8,
            strokeWeight: 3,
            strokeColor: '#fff',
            geodesic: true,
        };

        this.mapLayers.push(new google.maps.Polyline(polyOpts));

        setTimeout(() => {
            if (this.map) {
                this.map.fitBounds(bounds, 10);
            }
        }, 500);
    }

    private extractPlaceCodeLatLng(placeCode) {
        let output = null;
        try {
            const split = placeCode
                .split('|')
                .map(e => e.split(':'))
                .reduce((prev, curr) => {
                    prev[curr[0]] = curr[1];
                    return prev;
                }, {});

            if (split['X'] && split['Y']) {
                output = new google.maps.LatLng(
                    Number(split['Y']),
                    Number(split['X'])
                );
            }
        } catch {
            /* empty */
        }

        return output;
    }
}
