import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: 'ng-template[qwykDetailCardHeader]',
})
export class DetailCardHeaderDirective {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    selector: 'ng-template[qwykDetailCardBody]',
})
export class DetailCardBodyDirective {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(public template: TemplateRef<any>) {}
}
