<div class="user-info-tooltip-container p-2">
    <div class="font-weight-bold">{{ user.name }}</div>
    <div class="mb-2">
        <div>{{ user.email }}</div>
        <div>{{ user.team_name }}</div>
    </div>
    <div class="text-muted" *ngIf="user.job_title || user.company">
        <fa-icon [icon]="faBriefcase" class="mr-2" [fixedWidth]="true"></fa-icon>
        <span *ngIf="user.job_title">{{ user.job_title }}</span>
        <span *ngIf="user.job_title && user.company"> {{ 'common.at' | translate }} </span>
        <span *ngIf="user.company">{{ user.company }}</span>
    </div>
    <div class="text-muted" *ngIf="user.location">
        <fa-icon [icon]="faMapPin" class="mr-2" [fixedWidth]="true"></fa-icon>
        <span>{{ user.location }}</span>
    </div>
    <div class="text-muted" *ngIf="user.country">
        <img [src]="'https://cdn.qwyk.io/images/flags/r/' + (user.country.alpha_2_code | lowercase) + '.svg'"
            onerror="this.src='https://cdn.qwyk.io/images/flags/r/united-nations.svg'" height="16px" class="mr-2">
        <span>{{ user.country.short_name }}</span>
    </div>
    <div class="text-muted" *ngIf="user.phone_number">
        <fa-icon [icon]="faPhone" class="mr-2" [fixedWidth]="true"></fa-icon>
        <span>{{ user.phone_number }}</span>
    </div>
</div>
