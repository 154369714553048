<ng-template #content let-modal>
    <div class="modal-body text-center">
        <h4 class="pt-4 pb-4">{{ 'portal.dashboard.onboarding.welcome' | translate }}</h4>
        <p>{{ 'portal.dashboard.onboarding.text-content-1' | translate }}</p>
        <p>{{ 'portal.dashboard.onboarding.text-content-2' | translate }}</p>
        <!--        <img class="pt-4 pb-4" src="https://cdn.qwyk.io/portals/assets/onboarding.png" alt="onboarding" style="max-height: 180px;">-->
        <qwyk-file-transfer-animation></qwyk-file-transfer-animation>
        <p class="pt-4 pb-4">{{ 'portal.dashboard.onboarding.text-content-3' | translate }}</p>
        <button type="button" class="btn btn-primary px-5 text-center mb-4" (click)="modal.close()">{{ 'common.continue' | translate }}</button>
    </div>
</ng-template>
