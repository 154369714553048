import { TableColumn } from './table-column';
import { LocalStorageService } from '../services';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';

export class TableColumnManager {
    private selectedColumns!: TableColumn[];
    private _translateService: TranslateService;

    constructor(
        private readonly stateKey: string,
        public columns: TableColumn[],
        private readonly localStorage: LocalStorageService
    ) {
        this._translateService = inject(TranslateService);
        this.columns.forEach(col => {
            if (col.label) {
                col.label = this._translateService.instant(col.label);
                col.header = col.label;
            }
        });
        this.selectedColumns = this.resolveSelectedColumns();
    }

    private static encodeForLocalStorage(columns: TableColumn[]): string[] {
        return columns.map(e => e.id);
    }

    public getSelectedColumns(): TableColumn[] {
        return this.selectedColumns;
    }

    public setSelectedColumns(columns: TableColumn[]): void {
        this.selectedColumns = columns;
        this.storeColumns(columns);
    }

    public updateColumns(columns: TableColumn[]): void {
        this.columns = columns;
        this.selectedColumns = this.resolveSelectedColumns();
    }

    private decodeFromLocalStorage(columnIds: string[]): TableColumn[] {
        const columns = [...this.columns.filter(e => columnIds.includes(e.id))];
        return columns.sort(
            (a, b) => columnIds.indexOf(a.id) - columnIds.indexOf(b.id)
        );
    }

    private resolveSelectedColumns(): TableColumn[] {
        const storedColumns = localStorage.getItem(this.stateKey);
        if (storedColumns) {
            return this.decodeFromLocalStorage(JSON.parse(storedColumns));
        }

        const initialColumns = this.columns.filter(e => e.default);

        this.storeColumns(initialColumns);

        return initialColumns;
    }

    private storeColumns(columns: TableColumn[]): void {
        this.localStorage.setObject(
            this.stateKey,
            TableColumnManager.encodeForLocalStorage(columns)
        );
    }
}
