export const SERVICE_TYPES = [
    { key: 'port-port', value: 'Port to Port' },
    { key: 'door-door', value: 'Door to Door' },
    { key: 'port-door', value: 'Port to Door' },
    { key: 'door-port', value: 'Door to Port' },
];

export const TRANSPORT_MODES = [
    { key: 'OCEAN', value: 'Ocean' },
    { key: 'AIR', value: 'Air' },
    { key: 'RAIL', value: 'Rail' },
    { key: 'TRUCK', value: 'Truck' },
];

export const TRANSPORT_MODE_DICT = TRANSPORT_MODES.reduce((prev, next) => {
    prev[next.key] = next.value;
    return prev;
}, {});
