import {Injectable} from '@angular/core';
import {ShipmentContainersServiceBase} from '@qwyk/shared-stores/shipment-containers';
import {Observable} from 'rxjs';
import {Portals} from '@qwyk/models';
import {HttpClient} from '@angular/common/http';
import {environment} from '@qwyk/portals/environment';
import {map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ShipmentContainersService
    implements ShipmentContainersServiceBase {
    constructor(private http: HttpClient) {
    }

    getShipmentContainers(
        shipmentId: string
    ): Observable<Portals.ShipmentDataContainer[]> {
        return this.http
            .get<{ data: Portals.ShipmentDataContainer[] }>(
                `${environment.backendServer}/api/portals/shipments/v2/${shipmentId}/data/containers`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    getShipmentContainerGeoTrack(shipmentId: string, containerId: number): Observable<Portals.GeoTrackElement[]> {
        return this.http
            .get<{ data: Portals.GeoTrackElement[] }>(
                `${environment.backendServer}/api/portals/shipments/v2/${shipmentId}/data/containers/${containerId}/geotrack`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }
}
