import { Component } from '@angular/core';

@Component({
    selector: 'qw-app-four-o-four',
    templateUrl: './app-four-o-four.component.html',
    styleUrls: ['./app-four-o-four.component.scss'],
})
export class AppFourOFourComponent {
    constructor() {}
}
