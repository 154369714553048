import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    standalone: true,
    name: 'notificationChannelName',
})
export class NotificationChannelNamePipe implements PipeTransform {
    private MAPPINGS: { [index: string]: string } = {
        mail: 'portal.settings.notifications.channels-options.email',
        database: 'portal.settings.notifications.channels-options.inbox',
        broadcast: 'portal.settings.notifications.channels-options.popup',
    };

    constructor(private translate: TranslateService) {}

    transform(value: string): string {
        return this.translate.instant(this.MAPPINGS[value]) || value;
    }
}
