import * as SchedulesSearchActions from './schedules-search.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthenticationFacade } from '@qwyk/portals/authentication';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { SchedulesSearchService } from '../services/schedules-search.service';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';

@Injectable()
export class SchedulesSearchEffects {
    loadSchedulesSearch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SchedulesSearchActions.searchSchedules),
            withLatestFrom(
                this.siteConfig.organization$,
                this.authentication.authenticated$
            ),
            switchMap(([action, siteConfig, authenticated]) =>
                this.service
                    .searchSchedules(
                        action.query,
                        siteConfig.organization_id,
                        authenticated
                    )
                    .pipe(
                        map(schedules =>
                            SchedulesSearchActions.searchSchedulesSuccess({
                                schedules,
                            })
                        ),
                        catchError(error =>
                            of(
                                SchedulesSearchActions.searchSchedulesFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private service: SchedulesSearchService,
        private siteConfig: SiteConfigFacade,
        private authentication: AuthenticationFacade
    ) {}
}
