<form class="pt-1" [formGroup]="resendVerificationEmailForm" (submit)="onResendVerificationEmail()">
    <h5 [translate]="'portal.login-modal.resend-verification-email.title'" class="mb-3 font-weight-normal">Resend email verification.</h5>
    <div *ngIf="error" role="alert" class="alert alert-danger border-danger d-flex align-items-center">
        <fa-icon [icon]="['far', 'exclamation-circle']" class="mr-3 text-danger"></fa-icon>
        <span [translate]="'common.generic-error'">Something went wrong processing your request, please try again or contact support if the problem
            persists.
        </span>
    </div>
    <div class="form-group text-left">
        <label for="email">
            <span [translate]="'portal.login-modal.request-password-reset.label-email'">Email Address</span>
            <span class="text-primary"
                  [ngClass]="{'text-danger': resendVerificationEmailForm.get('email').invalid && resendVerificationEmailForm.get('email').touched}">
                *
            </span>
        </label>
        <input id="email" name="email" type="text" class="form-control form-control-lg" formControlName="email"
               [attr.placeholder]="'portal.login-modal.request-password-reset.placeholder-email' | translate"
               autocomplete="email"
               [ngClass]="{'is-invalid': resendVerificationEmailForm.get('email').invalid && resendVerificationEmailForm.get('email').touched}"/>
        <div class="invalid-feedback">
            <div *ngIf="resendVerificationEmailForm.get('email').errors?.required">
                {{ 'portal.login-modal.request-password-reset.warning-required-email' | translate }}
            </div>
            <div *ngIf="resendVerificationEmailForm.get('email').errors?.email">
                {{ 'portal.login-modal.request-password-reset.warning-invalid-email' | translate }}
            </div>
        </div>
    </div>
    <div class="form-group">
        <button class="btn btn-primary btn-block" type="submit" [disabled]="resendVerificationEmailForm.disabled">
            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" *ngIf="resendVerificationEmailForm.disabled" class="mr-1">
            </fa-icon>
            {{ 'portal.login-modal.resend-verification-email.button-resend-email' | translate }}
        </button>
    </div>
    <div class="text-center">
        <button class="btn btn-link text-primary btn-sm" type="button" [disabled]="resendVerificationEmailForm.disabled"
                (click)="onGoToLogin()">
            <fa-icon [icon]="['far', 'arrow-left']"></fa-icon>
            {{ 'portal.login-modal.request-password-reset.button-back-to-signin' | translate }}
        </button>
    </div>
</form>
