import {FormArray, FormControl, FormGroup} from "@angular/forms";

export function markGroupDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        if (formGroup.get(key) instanceof FormGroup) {
            markGroupDirty(formGroup.get(key) as FormGroup);
        } else if (formGroup.get(key) instanceof FormArray) {
            markArrayDirty(formGroup.get(key) as FormArray);
        } else if (formGroup.get(key) instanceof FormControl) {
            markControlDirty(formGroup.get(key) as FormControl);
        }
    });

}

export function markArrayDirty(formArray: FormArray) {
    formArray.controls.forEach(control => {
        if (control instanceof FormGroup) {
            markGroupDirty(control as FormGroup);
        } else if (control instanceof FormArray) {
            markArrayDirty(control as FormArray);
        } else if (control instanceof FormControl) {
            markControlDirty(control as FormControl);
        }
    });
}

export function markControlDirty(formControl: FormControl) {
    formControl.markAsDirty();
}

export function sanitizeFormStrings(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (typeof control?.value === 'string') {
            control.setValue(control.value.trim());
        }
    });
}
