import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { first, map, take, tap } from 'rxjs/operators';

import { masterdata } from '@qwyk/models';

import { EnvironmentConfig } from '../environmentConfig';

@Injectable({
    providedIn: 'root',
})
export class MasterDataService {
    private cache: {
        carriers: masterdata.Carrier[];
        countries: masterdata.Country[];
        containerTypes: masterdata.ContainerType[];
        currencies: masterdata.Currency[];
        commodities: masterdata.Commodity[];
        rateCalculations: masterdata.RateCalculation[];
        timezones: masterdata.Timezone[];
        rateChargeCodes: any;
        oceanroutes: any;
        locationImages: any;
    } = {
        carriers: [],
        countries: [],
        containerTypes: [],
        currencies: [],
        commodities: [],
        rateCalculations: [],
        timezones: [],
        rateChargeCodes: null,
        oceanroutes: {},
        locationImages: {},
    };
    private environment;

    constructor(env: EnvironmentConfig, private http: HttpClient) {
        if (env) {
            this.environment = env;
        } else {
            throw Error(
                `Environment config not provided for ${MasterDataService.name}`
            );
        }
    }

    public getCarriers(): Observable<masterdata.Carrier[]> {
        if (this.cache.carriers?.length) {
            return of(this.cache.carriers);
        }

        return this.http
            .get<{ data: masterdata.Carrier[] }>(
                `${this.environment.backendServer}/api/masters/carriers`
            )
            .pipe(
                take(1),
                map(d => d.data),
                tap(c => (this.cache.carriers = c))
            );
    }

    public getRateChargeCodes(): Observable<any[]> {
        if (this.cache.rateChargeCodes?.length) {
            return of(this.cache.rateChargeCodes);
        }

        return this.http
            .get<any>(
                `${this.environment.backendServer}/api/masters/charge_codes`
            )
            .pipe(
                take(1),
                tap(c => (this.cache.rateChargeCodes = c))
            );
    }

    public getCountries(): Observable<masterdata.Country[]> {
        if (this.cache.countries?.length) {
            return of(this.cache.countries);
        }

        return this.http
            .get<{ data: masterdata.Country[] }>(
                `${this.environment.backendServer}/api/masters/countries`
            )
            .pipe(
                take(1),
                map(d => d.data),
                tap(c => (this.cache.countries = c))
            );
    }

    public getContainerTypes(): Observable<masterdata.ContainerType[]> {
        if (this.cache.containerTypes?.length) {
            return of(this.cache.containerTypes);
        }

        return this.http
            .get<{ data: masterdata.ContainerType[] }>(
                `${this.environment.backendServer}/api/masters/container_types`
            )
            .pipe(
                take(1),
                map(d => d.data),
                tap(c => (this.cache.containerTypes = c))
            );
    }

    public getTransportModeLoadType(
        transportMode: string,
        loadType: string
    ): Observable<any> {
        return this.http
            .get<{ data: any[] }>(
                `${this.environment.backendServer}/api/masters/container_types/${transportMode}/${loadType}`
            )
            .pipe(
                take(1),
                map(d => d.data)
            );
    }

    public getCurrencies(): Observable<masterdata.Currency[]> {
        if (this.cache.currencies?.length) {
            return of(this.cache.currencies);
        }

        return this.http
            .get<{ data: masterdata.Currency[] }>(
                `${this.environment.backendServer}/api/masters/currencies`
            )
            .pipe(
                first(),
                map(response => response.data),
                tap(c => (this.cache.currencies = c))
            );
    }

    public getCommodities(): Observable<masterdata.Commodity[]> {
        if (this.cache.commodities?.length) {
            return of(this.cache.commodities);
        }

        return this.http
            .get<{ data: masterdata.Commodity[] }>(
                `${this.environment.backendServer}/api/masters/commodities`
            )
            .pipe(
                first(),
                map(response => response.data),
                tap(c => (this.cache.commodities = c))
            );
    }

    public getRateCalculations(): Observable<masterdata.RateCalculation[]> {
        if (this.cache.rateCalculations?.length) {
            return of(this.cache.rateCalculations);
        }

        return this.http
            .get<{ data: masterdata.RateCalculation[] }>(
                `${this.environment.backendServer}/api/masters/rate_calculations`
            )
            .pipe(
                first(),
                map(response => response.data),
                tap(c => (this.cache.rateCalculations = c))
            );
    }

    public getTimezones(): Observable<masterdata.Timezone[]> {
        if (this.cache.timezones?.length) {
            return of(this.cache.timezones);
        }

        return this.http
            .get<{ data: masterdata.Timezone[] }>(
                `${this.environment.backendServer}/api/masters/timezones`
            )
            .pipe(
                take(1),
                map(response => response.data),
                tap(c => (this.cache.timezones = c))
            );
    }

    public getPackagings(
        transportMode = null,
        loadType = null,
        onlyQuoting = false
    ): Observable<masterdata.Packaging[]> {
        let url = `${this.environment.backendServer}/api/masters/packagings`;

        if (transportMode) {
            url += `/${transportMode}`;
            if (loadType) {
                url += `/${loadType}`;
            }
        }

        if (!onlyQuoting) {
            url += '?onlyQuoting=false';
        }

        return this.http.get<{ data: masterdata.Packaging[] }>(url).pipe(
            first(),
            map(response => response.data)
        );
    }

    public getOceanRoute(
        origin: string,
        destination: string
    ): Observable<masterdata.OceanRoutesEntity> {
        const imageKey = `${origin}${destination}`;

        // eslint-disable-next-line no-prototype-builtins
        if (this.cache.oceanroutes.hasOwnProperty(imageKey)) {
            return of(this.cache.oceanroutes[imageKey]);
        }

        return this.http
            .get<masterdata.OceanRoutesEntity>(
                `${this.environment.backendServer}/api/masters/oceanroutes/${origin}/${destination}`
            )
            .pipe(
                first(),
                tap(route => {
                    this.cache.oceanroutes[imageKey] = route;
                })
            );
    }

    public getLocationImage(
        locode: string
    ): Observable<masterdata.GooglePlace> {
        // eslint-disable-next-line no-prototype-builtins
        if (this.cache.locationImages.hasOwnProperty(locode)) {
            return of(this.cache.locationImages[locode]);
        }

        return this.http
            .get<masterdata.GooglePlace>(
                `${this.environment.backendServer}/api/masters/gplaces/${locode}/photo`
            )
            .pipe(
                first(),
                tap(image => {
                    this.cache.locationImages[locode] = image;
                })
            );
    }
}
