import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { PaginatorModule } from 'primeng/paginator';
import { TranslateModule } from '@ngx-translate/core';

import { common } from '@qwyk/models';

import { PageRequest } from './page-request';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, TranslateModule, PaginatorModule],
    selector: 'qwyk-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent {
    @Input() pagination: common.PaginationMeta;
    @Input() loading: boolean;
    @Input() resource: string;
    @Input() small = false;
    @Input() pageSizeOptions: number[] = [10, 25, 50];
    @Output() pageChanged: EventEmitter<PageRequest> =
        new EventEmitter<PageRequest>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onPageChange($event: any) {
        const pageRequest: PageRequest = {
            page: Number($event.page) + 1,
            page_size: Number($event.rows),
        };
        this.pageChanged.emit(pageRequest);
    }
}
