import { Component, Input } from '@angular/core';

@Component({
    selector: 'qwyk-info',
    templateUrl: './info-tooltip.component.html',
    styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent {
    @Input() text: string;
    @Input() class = '';
    constructor() {}
}
