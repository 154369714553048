<ul class="list-group mb-0">
    <li class="d-none d-md-block list-group-item list-group-heading py-2 bg-light">
        <div class="row align-items-center font-weight-bold">
            <div class="col">
                {{'portal.warehouse-receipts.index-page.index.list.columns.number' | translate | titlecase}}
            </div>
            <div class="col">
                {{'portal.warehouse-receipts.index-page.index.list.columns.status' | translate | titlecase}}
            </div>
            <div class="col">
                {{'portal.shipment.table.columns.product' | translate | titlecase}}
            </div>
            <div class="col-3">
                {{'portal.shipment.table.columns.origin' | translate | titlecase}}
            </div>
            <div class="col-3">
                {{'portal.shipment.table.columns.destination' | translate | titlecase}}
            </div>
            <div class="col text-lg-start">
                {{'common.sort_options.created_at' | translate | titlecase}}
            </div>
        </div>
    </li>
    <li *ngFor="let quotation of quotations"
        [quotation]="quotation"
        [routerLink]="['.', quotation.id]"
        class="list-group-item list-group-item-action py-4"
        qwyk-quotations-list-item
        style="cursor: pointer;"
    ></li>
    <li
            *ngIf="quotations && quotations.length === 0 && !loading"
            class="list-group-item text-center py-4">
        <h5 class="font-weight-normal">{{'common.no-transaction-found' | translate : {
            value: 'common.quotations.quotations' | translate | titlecase
        } }}</h5>
        <p class="mb-0">
            {{'common.could-not-find-transaction' | translate : {
            value: 'common.quotations.quotation' | translate
        } }}
        </p>
    </li>
    <li *ngIf="loading" class="list-group-item text-center py-4">
        <fa-icon
                [icon]="['far', 'spinner']"
                [pulse]="true"
                class="mb-3"
                size="lg"
        ></fa-icon>
        <h5 class="font-weight-normal m-0 mt-3">
            {{'common.loading_items' | translate: {
            value: 'common.quotations.quotations' | translate
        } }}
        </h5>
    </li>
</ul>
