import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromInvoices from '../state/invoices.reducer';
import * as InvoicesActions from '../state/invoices.actions';
import * as invoicesSelectors from '../state/invoices.selectors';
import { take, map, tap, catchError, switchMap } from 'rxjs/operators';
import { InvoicesServiceBase } from '../invoices-service-base';
@Injectable({
    providedIn: 'root',
})
export class InvoiceExistsGuard implements CanActivate, CanActivateChild {
    constructor(
        private store: Store<fromInvoices.InvoicesPartialState>,
        private service: InvoicesServiceBase,
        private router: Router
    ) {}

    hasInvoiceInStore(id: string): Observable<boolean> {
        return this.store.pipe(
            select(invoicesSelectors.getInvoicesEntities),
            map(entities => !!entities[id]),
            take(1)
        );
    }

    hasInvoiceInApi(
        id: string,
        next: ActivatedRouteSnapshot
    ): Observable<boolean> {
        return this.service.getInvoice(id).pipe(
            map(invoice => InvoicesActions.loadInvoiceSuccess({ invoice })),
            tap(action => this.store.dispatch(action)),
            map(invoice => !!invoice),
            catchError(error => {
                this.router.navigate([`/error/${error.status}`], {
                    queryParams: {
                        source: next.pathFromRoot.map(p => p.url).join('/'),
                        errorName: error.statusText,
                    },
                });
                return of(false);
            })
        );
    }

    hasInvoice(id: string, next: ActivatedRouteSnapshot): Observable<boolean> {
        return this.hasInvoiceInStore(id).pipe(
            switchMap(inStore => {
                if (inStore) {
                    return of(inStore);
                }
                return this.hasInvoiceInApi(id, next);
            })
        );
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.hasInvoice(next.params['id'], next);
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.canActivate(next, state);
    }
}
