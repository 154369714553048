import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';

@Component({
    selector: 'qw-resource-error',
    templateUrl: './resource-error.component.html',
    styleUrls: ['./resource-error.component.scss'],
})
export class ResourceErrorComponent {
    public errorCode = '-1';
    public resource: string;
    public errorName;

    constructor(private route: ActivatedRoute) {
        this.errorCode = this.route.snapshot.params.errorCode;
        this.resource = this.route.snapshot.queryParams.source;
        this.errorName = this.route.snapshot.queryParams.errorName;
    }

    public get icon() {
        let icon = 'exclamation-triangle';
        switch (this.errorCode) {
            case '403':
                icon = 'times-circle';
                break;
            case '404':
                icon = 'search-location';
                break;
        }
        return ['far', icon];
    }

    public get label() {
        switch (this.errorCode) {
            case '403':
                return 'No access to resource';
            case '404':
                return 'Resource not found';
            default:
                return 'Error';
        }
    }

    public get description() {
        switch (this.errorCode) {
            case '403':
                return 'You are not allowed to access this record because you are missing the required permissions to access it.';
            case '404':
                return 'The resource you were looking for could not be found, please try again or change your parameters.';
            default:
                return 'An error occured, please try again later or contact support if the problem persists';
        }
    }
}
