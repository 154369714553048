export enum CustomerPermissions {
    VIEW_PICKUPORDERS = 'pickuporders:view',
    CREATE_PICKUPORDERS = 'pickuporders:create',
    CREATE_WAREHOUSERECEIPTS = 'warehousereceipts:create',
    VIEW_WAREHOUSERECEIPTS = 'warehousereceipts:view',
    VIEW_CARGORELEASES = 'cargoreleases:view',
    CREATE_CARGORELEASES = 'cargoreleases:create',
    VIEW_SHIPMENTS = 'shipments:view',
    CREATE_SHIPMENTS = 'shipments:create',
    VIEW_PURCHASEORDERS = 'purchaseorders:view',
    CREATE_PURCHASEORDERS = 'purchaseorders:create',
    // dont have
    VIEW_SALESORDERS = 'salesorders:view',
    CREATE_SALESORDER = 'salesorders:create',
    VIEW_COMMODITIES = 'commodities:view',
    VIEW_BOOKINGS = 'bookings:view',
    // for create shipment buton and also from bookings create booking
    CREATE_BOOKINGS = 'bookings:create',
    VIEW_INVENTORY = 'inventory:view',
    // dont have
    VIEW_RATES = 'rates:view',
    VIEW_INVOICES = 'invoices:view',
    VIEW_QUOTATIONS = 'quotations:view',
    CREATE_QUOTATIONS = 'quotations:create',
    // dont have
    VIEW_JOBS = 'jobs:view',
    VIEW_ATTACHMENTS = 'attachments:view',
    CREATE_ATTACHMENTS = 'attachments:create',
    VIEW_PAYMENTS = 'payments:view',
    CREATE_PAYMENTS = 'payments:create',
}

export const WAREHOUSE_MAIN = [
    CustomerPermissions.VIEW_WAREHOUSERECEIPTS,
    CustomerPermissions.VIEW_INVENTORY,
    CustomerPermissions.VIEW_CARGORELEASES,
    CustomerPermissions.VIEW_PICKUPORDERS,
];

export const BILLING = [
    CustomerPermissions.VIEW_PAYMENTS,
    CustomerPermissions.VIEW_INVOICES
];

export const CREATE_ANY = [
    CustomerPermissions.CREATE_PURCHASEORDERS,
    CustomerPermissions.CREATE_BOOKINGS,
    CustomerPermissions.CREATE_QUOTATIONS,
];


