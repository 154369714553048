import { Component, Input } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Shipment } from '@qwyk/shared-stores/shipments';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ShipmentPackage } from '@qwyk/shared-stores/shipment-packages';

@Component({
    selector: 'qwyk-shipment-data-package',
    template: '',
})
export class ShipmentDataPackageComponent {
    @Input() shipment: Shipment;
    @Input() shipmentPackages: ShipmentPackage[];
    @Input() displayUnit: 'metric' | 'imperial' = 'metric';
    constructor() {}

    public get hasDimensions(): boolean {
        let dimensions = 0;
        this.shipmentPackages.forEach(shipmentPackage => {
            dimensions += shipmentPackage.length?.metric?.value ?? 0;
            dimensions += shipmentPackage.width?.metric?.value ?? 0;
            dimensions += shipmentPackage.height?.metric?.value ?? 0;
        });
        return dimensions > 0;
    }

    public get displayTotals(): boolean {
        return (
            (this.shipment.data?.total_weight?.metric?.value ??
                this.shipment.data?.total_volume?.metric?.value ??
                this.shipment.data?.total_pieces ??
                0) > 0
        );
    }

    public get otherDisplayUnit(): 'metric' | 'imperial' {
        return this.displayUnit === 'metric' ? 'imperial' : 'metric';
    }
}
