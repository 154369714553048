import { Injectable } from '@angular/core';

import { ActionsSubject, select, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Shipment } from '@qwyk/shared-stores/shipments';

import * as ShipmentAttachmentsActions from './shipment-attachments.actions';
import * as ShipmentAttachmentsSelectors from './shipment-attachments.selectors';
import {
    CreateShipmentAttachmentRequest,
    ShipmentAttachment,
} from './shipment-attachments.models';

@Injectable()
export class ShipmentAttachmentsFacade {
    /**
     * Combine pieces of state using createSelector,
     * and expose them as observables through the facade.
     */
    loading$ = this.store.pipe(
        select(ShipmentAttachmentsSelectors.getShipmentAttachmentsLoading)
    );
    allShipmentAttachments$ = this.store.pipe(
        select(ShipmentAttachmentsSelectors.getAllShipmentAttachments)
    );
    selectedShipmentAttachments$ = this.store.pipe(
        select(ShipmentAttachmentsSelectors.getSelected)
    );
    error$ = this.store.pipe(
        select(ShipmentAttachmentsSelectors.getShipmentAttachmentsError)
    );

    attachmentLinks$ = this.store.pipe(
        select(ShipmentAttachmentsSelectors.getAttachmentLinks)
    );

    /** Listeners */
    createShipmentAttachmentSuccessListener$ = this.actionsListener$.pipe(
        ofType(ShipmentAttachmentsActions.createShipmentAttachmentSuccess)
    );

    constructor(
        private store: Store,
        private actionsListener$: ActionsSubject
    ) {}

    /**
     * Use the initialization action to perform one
     * or more tasks in your Effects.
     */
    public init(shipment: Shipment): void {
        this.store.dispatch(ShipmentAttachmentsActions.init({ shipment }));
    }

    public loadAttachmentsOnly(shipment: Shipment) {
        this.store.dispatch(
            ShipmentAttachmentsActions.loadShipmentAttachmentsOnly({
                shipment,
            })
        );
    }

    public generateAttachmentLink(
        shipment: Shipment,
        attachment: ShipmentAttachment
    ): void {
        this.store.dispatch(
            ShipmentAttachmentsActions.generateAttachmentLink({
                shipment,
                attachment,
            })
        );
    }

    public createAttachment(
        shipment: Shipment,
        request: CreateShipmentAttachmentRequest
    ): void {
        this.store.dispatch(
            ShipmentAttachmentsActions.createShipmentAttachment({
                shipment,
                request,
            })
        );
    }

    public deleteAttachment(
        shipmentId: string,
        shipmentAttachmentId: number
    ): void {
        this.store.dispatch(
            ShipmentAttachmentsActions.deleteShipmentAttachment({
                shipmentId,
                shipmentAttachmentId,
            })
        );
    }

    public dismissError(): void {
        this.store.dispatch(ShipmentAttachmentsActions.dismissError());
    }
}
