import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromSiteConfig from './site-config.reducer';
import * as SiteConfigActions from './site-config.actions';
import * as SiteConfigSelectors from './site-config.selectors';

@Injectable()
export class SiteConfigFacade {
    loaded$ = this.store.pipe(select(SiteConfigSelectors.getSiteConfigLoaded));
    error$ = this.store.pipe(select(SiteConfigSelectors.getSiteConfigError));
    config$ = this.store.pipe(select(SiteConfigSelectors.getSiteConfig));
    design$ = this.store.pipe(select(SiteConfigSelectors.getSiteConfigDesign));
    authenticationType$ = this.store.pipe(
        select(SiteConfigSelectors.getSiteConfigAuthenticationType)
    );
    features$ = this.store.pipe(
        select(SiteConfigSelectors.getSiteConfigFeatures)
    );
    organization$ = this.store.pipe(
        select(SiteConfigSelectors.getSiteConfigOrganization)
    );
    isMagayaNetwork$ = this.store.pipe(
        select(SiteConfigSelectors.isMagayaNetwork)
    );
    // allSiteConfig$ = this.store.pipe(
    //     select(SiteConfigSelectors.getAllSiteConfig)
    // );
    // selectedSiteConfig$ = this.store.pipe(
    //     select(SiteConfigSelectors.getSelected)
    // );

    constructor(private store: Store<fromSiteConfig.SiteConfigPartialState>) {}

    public loadSiteConfig(siteIdentifier: {
        identifier: string;
        type: 'endpoint' | 'cname';
    }) {
        this.dispatch(
            SiteConfigActions.loadSiteConfig({
                siteIdentifier,
            })
        );
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
