import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as ShipmentTrackingEventsActions from './shipment-tracking-events.actions';
import { Portals } from '@qwyk/models';

export const SHIPMENTTRACKINGEVENTS_FEATURE_KEY = 'shipmentTrackingEvents';

export interface State extends EntityState<Portals.ShipmentTrackingEvent> {
    selectedId?: string | number; // which ShipmentTrackingEvents record has been selected
    loading: boolean; // has the ShipmentTrackingEvents list been loaded
    error?: string | null; // last known error (if any)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shipment: any;
}

export interface ShipmentTrackingEventsPartialState {
    readonly [SHIPMENTTRACKINGEVENTS_FEATURE_KEY]: State;
}

export const shipmentTrackingEventsAdapter: EntityAdapter<Portals.ShipmentTrackingEvent> =
    createEntityAdapter<Portals.ShipmentTrackingEvent>();

export const initialState: State =
    shipmentTrackingEventsAdapter.getInitialState({
        // set initial required properties
        shipment: null,
        loading: false,
    });

const shipmentTrackingEventsReducer = createReducer(
    initialState,
    on(ShipmentTrackingEventsActions.init, (state, { shipment }) => ({
        ...state,
        loading: true,
        error: null,
        shipment: shipment,
        trackingRequestStatus: shipment.tracking_request_status,
    })),
    on(
        ShipmentTrackingEventsActions.loadShipmentTrackingEventsSuccess,
        (state, { shipmentTrackingEvents }) =>
            shipmentTrackingEventsAdapter.setAll(shipmentTrackingEvents, {
                ...state,
                loading: false,
            })
    ),
    on(
        ShipmentTrackingEventsActions.loadShipmentTrackingEventsFailure,
        (state, { error }) => ({ ...state, error })
    ),
    on(ShipmentTrackingEventsActions.reset, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
    return shipmentTrackingEventsReducer(state, action);
}
