import { Component, Input } from '@angular/core';

@Component({
    selector: 'qw-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
    @Input()
    componentClass = '';

    @Input()
    spinner = 'spinner';

    @Input()
    text = 'Loading...';

    @Input()
    size: 'md' | 'sm' | 'lg' = 'md';

    @Input()
    horizontal = false;

    @Input()
    typing = false;

    constructor() {}
}
