import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    SharedStoresShipmentTrackingEventsModule,
    ShipmentTrackingServiceBase,
} from '@qwyk/shared-stores/shipment-tracking-events';
import { ShipmentTrackingEventsService } from './shipment-tracking-events.service';
import { HttpClient } from '@angular/common/http';
import { ShipmentTrackingEventsViewComponent } from './components';
import { UiModule } from '@qwyk/ui';
import { LineBreaksPipe } from '@qwyk/ui-standalone';

@NgModule({
    imports: [
        CommonModule,
        SharedStoresShipmentTrackingEventsModule.forFeature({
            service: {
                provide: ShipmentTrackingServiceBase,
                useFactory: http => new ShipmentTrackingEventsService(http),
                deps: [HttpClient],
            },
        }),
        UiModule,
        LineBreaksPipe,
    ],
    declarations: [ShipmentTrackingEventsViewComponent],
    exports: [ShipmentTrackingEventsViewComponent],
})
export class PortalsShipmentTrackingEventsModule {}
