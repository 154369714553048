import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [NgFor, NgIf, NgClass, RouterLink, TranslateModule],
    selector: 'qwyk-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _url: any[];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() public set url(url: any[]) {
        this._url = url.map((e, i) => {
            const path = url.slice(0, i + 1);
            return {
                name: e,
                path,
            };
        });
    }

    @Input() public app: 'portal' | 'hub' = 'portal';
    @Input() public entityIdValue: string | null = null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get url(): any[] {
        return this._url;
    }

    @Input() root = 'Qwyk';

    constructor() {}
}
