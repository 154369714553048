import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import * as semver from 'semver';

/**
 * The version checker service compares the client version with a latest.json on the server.
 * If a newer version is found on the server, the user is asked if they want to refresh.
 */
@Injectable({
    providedIn: 'root',
})
export class VersionCheckerService {
    /**
     * Holds the current version, set on init.
     * @private
     */
    private _currentVersion!: string;

    /**
     * Prevents re-prompting the user if they have already been asked and answered no.
     * @private
     */
    private _suppressMessage = false;

    constructor(private http: HttpClient) {}

    /**
     * Starts an interval observable that periodically causes a fetch of the server version and compares to the current app version
     * If the client's version is before the server's version, we'll prompt the user to reload the app.
     *
     * @param currentVersion
     * @return void
     */
    public init(currentVersion: string): void {
        this._currentVersion = currentVersion;
        console.log(`Current client version: ${currentVersion}`);

        interval(4 * 6e4)
            .pipe(
                tap(() => 'Checking client version on host'),
                switchMap(() => this.checkVersion())
            )
            .subscribe(latestVersion => {
                console.log(`Currently hosted version ${latestVersion}`);
                if (
                    latestVersion &&
                    semver.valid(latestVersion) &&
                    semver.gt(latestVersion, currentVersion) &&
                    !this._suppressMessage
                ) {
                    console.log(
                        `Client on host ${latestVersion} is newer than current client ${this._currentVersion}... prompting user to refresh`
                    );

                    if (
                        window.confirm(
                            'A newer version of this application is available. Do you want to reload the page?'
                        )
                    ) {
                        window.location.reload();
                    } else {
                        this._suppressMessage = true;
                    }
                }
            });

        console.log('Version check initialized');
    }

    /**
     * Fetches latest.json from the server to check the latest version.
     * @private
     */
    private checkVersion(): Observable<string | null> {
        return this.http.get<string>('/latest.json').pipe(
            take(1),
            catchError(e => {
                console.log(e);
                return of(null);
            })
        );
    }
}
