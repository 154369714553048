import { Injectable } from '@angular/core';

import {
    map,
    take,
    switchMap,
    catchError,
    withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { fetch } from '@nx/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromActivities from './activities.reducer';
import * as ActivitiesActions from './activities.actions';
import * as ActivitiesSelectors from './activities.selectors';
import { ActivitiesServiceBase } from '../activities-service-base';

@Injectable()
export class ActivitiesEffects {
    loadActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadActivities),
            fetch({
                run: action => {
                    return this.service
                        .loadActivities(action.parent, action.query)
                        .pipe(
                            take(1),
                            map(response =>
                                ActivitiesActions.loadActivitiesSuccess({
                                    activities: response.data,
                                    pagination: response.meta,
                                })
                            )
                        );
                },
                onError: (action, error) => {
                    console.error('Error', error);
                    return ActivitiesActions.loadActivitiesFailure({ error });
                },
            })
        )
    );

    loadMoreActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadMoreActivities),
            withLatestFrom(
                this.store.select(ActivitiesSelectors.getActivitiesQuery),
                this.store.select(ActivitiesSelectors.getActivitiesPagination),
                this.store.select(ActivitiesSelectors.getActivitiesParent)
            ),
            map(([action, query, pagination, parent]) => {
                if (!query) {
                    query = { page_size: 10 };
                }
                if (!parent) {
                    throw new Error('Parent not set');
                }
                query.cursor = pagination?.cursor;
                return { action, query, parent };
            }),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap(({ action, query, parent }) =>
                this.service.loadActivities(parent, query).pipe(
                    take(1),
                    map(response =>
                        ActivitiesActions.loadMoreActivitiesSuccess({
                            activities: response.data,
                            pagination: response.meta,
                        })
                    ),
                    catchError(error =>
                        of(ActivitiesActions.loadActivitiesFailure({ error }))
                    )
                )
            )
        )
    );

    refreshActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.refreshActivities),
            withLatestFrom(
                this.store.select(ActivitiesSelectors.getActivitiesQuery),
                this.store.select(ActivitiesSelectors.getActivitiesParent)
            ),
            map(([action, query, parent]) => {
                if (!query) {
                    query = { page_size: 10 };
                }
                if (!parent) {
                    throw new Error('Parent not set');
                }
                return { action, query, parent };
            }),
            switchMap(({ query, parent }) =>
                this.service.loadActivities(parent, query).pipe(
                    take(1),
                    map(response =>
                        ActivitiesActions.loadActivitiesSuccess({
                            activities: response.data,
                            pagination: response.meta,
                        })
                    ),
                    catchError(error =>
                        of(ActivitiesActions.loadActivitiesFailure({ error }))
                    )
                )
            )
        )
    );

    createComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.createComment),
            switchMap(action =>
                this.service.createComment(action.parent, action.payload).pipe(
                    take(1),
                    map(activity =>
                        ActivitiesActions.createCommentSuccess({ activity })
                    ),
                    catchError(error =>
                        of(ActivitiesActions.createCommentFailure({ error }))
                    )
                )
            )
        )
    );

    updateComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.updateComment),
            switchMap(action =>
                this.service
                    .updateComment(
                        action.parent,
                        action.activity,
                        action.payload
                    )
                    .pipe(
                        take(1),
                        map(activity =>
                            ActivitiesActions.updateCommentSuccess({ activity })
                        ),
                        catchError(error =>
                            of(
                                ActivitiesActions.updateCommentFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.deleteComment),
            switchMap(action =>
                this.service.deleteComment(action.parent, action.activity).pipe(
                    take(1),
                    map(() =>
                        ActivitiesActions.deleteCommentSuccess({
                            activity: action.activity,
                        })
                    ),
                    catchError(error =>
                        of(ActivitiesActions.deleteCommentFailure({ error }))
                    )
                )
            )
        )
    );

    constructor(
        private readonly actions$: Actions,
        private service: ActivitiesServiceBase,
        private store: Store<fromActivities.ActivitiesPartialState>
    ) {}
}
