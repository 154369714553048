import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestedNavigation } from '../login-modal/login-modal.component';
import { QwykValidators, SegmentEvent } from '@qwyk/core';
import { ResetPasswordService } from '../../services/reset-password.service';
import { Subscription } from 'rxjs';
import { SegmentService } from 'ngx-segment-analytics';

@Component({
    selector: 'qwyk-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {
    @Output() navigate: EventEmitter<RequestedNavigation> = new EventEmitter();
    @Input() isMagayaNetwork = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    newPasswordMasked = true;
    confirmPasswordMasked = true;
    error = null;
    success = false;
    busy = false;

    public resetPasswordForm: FormGroup;
    private resetPasswordSubscription: Subscription;

    constructor(
        private fb: FormBuilder,
        private resetPasswordService: ResetPasswordService,
        private segment: SegmentService
    ) {}

    ngOnInit() {
        this.resetPasswordForm = this.fb.group({
            email: [
                this.data ? this.data.email : null,
                [Validators.required, Validators.email],
            ],
            reset_code: [
                null,
                [
                    Validators.required,
                    Validators.min(10000),
                    Validators.max(99999),
                ],
            ],
            password: [
                null,
                [
                    Validators.required,
                    Validators.minLength(this.isMagayaNetwork ? 6 : 12),
                    Validators.maxLength(this.isMagayaNetwork ? 15 : 36),
                    // eslint-disable-next-line no-useless-escape
                    Validators.pattern(/^[a-zA-Z0-9\!\@\#\$\%\&\-\.\*\=]+$/),
                ],
            ],
            password_confirmation: [
                null,
                [
                    Validators.required,
                    QwykValidators.matchOtherValidator('password'),
                ],
            ],
        });
    }

    ngOnDestroy() {
        if (this.resetPasswordSubscription) {
            this.resetPasswordSubscription.unsubscribe();
        }
    }

    onGoToLogin() {
        this.navigate.emit({ component: 'login' });
    }

    onGoToRequestPasswordReset() {
        this.navigate.emit({ component: 'request-password-reset' });
    }

    onResetPasword() {
        this.success = false;
        this.error = null;
        this.resetPasswordForm.markAllAsTouched();
        if (this.resetPasswordForm.invalid) {
            return;
        }

        const payload = this.resetPasswordForm.value;
        this.segment.track(SegmentEvent.PASSWORD_RESET, {
            email: payload.email,
        });

        setTimeout(() => {
            this.resetPasswordForm.disable();
            this.busy = true;
        }, 1);

        this.resetPasswordSubscription = this.resetPasswordService
            .resetPassword(payload)
            .subscribe(
                () => {
                    this.busy = false;
                    this.success = true;
                    this.resetPasswordForm.enable();
                },
                error => {
                    this.busy = false;
                    this.error = error;
                    this.resetPasswordForm.enable();
                }
            );
    }
}
