import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    AUTHENTICATION_FEATURE_KEY,
    State,
    AuthenticationPartialState,
} from './authentication.reducer';

// Lookup the 'Authentication' feature state managed by NgRx
export const getAuthenticationState = createFeatureSelector<
    AuthenticationPartialState,
    State
>(AUTHENTICATION_FEATURE_KEY);

export const getAuthenticationAuthenticated = createSelector(
    getAuthenticationState,
    (state: State) => state.authenticated
);

export const getAuthenticationError = createSelector(
    getAuthenticationState,
    (state: State) => state.error
);

export const getUser = createSelector(
    getAuthenticationState,
    (state: State) => state.user
);

export const getUserMeta = createSelector(
    getAuthenticationState,
    (state: State) => state.user_meta
);

export const getUserFeatures = createSelector(
    getAuthenticationState,
    (state: State) => state.user.organization_features
);

export const getUserAndToken = createSelector(
    getAuthenticationState,
    (state: State) => ({ user: state.user, token: state.token })
);
