<div class="dashboard-container container-fluid bg-white overflow-auto p-4" id="staticDashboard">
    <div class="pb-3 mt-3">
        <h4 *ngIf="user$ | async; let user">{{ greeting | translate }} {{ user.name }}</h4>
        <p>{{ 'portal.dashboard.snapshot-message' | translate }}</p>
        <qwyk-onboarding-banner
            *ngIf="onboardingStatus$ | async as onboardingStatus"
            [onboardingStatus]="onboardingStatus"
        ></qwyk-onboarding-banner>
    </div>
    <qwyk-onboarding-notice
        *ngIf="onboardingStatus$ | async as onboardingStatus"
        [onboardingStatus]="onboardingStatus"
    ></qwyk-onboarding-notice>
<!--    <nav class="nav tab-nav border-top border-bottom bg-white">-->
<!--        <a class="nav-link" routerLinkActive="active" [routerLink]="[]" [queryParams]="{view: 'ops'}">-->
<!--            Operations-->
<!--        </a>-->
<!--        <a class="nav-link disabled text-muted" routerLinkActive="active" [routerLink]="[]"-->
<!--            [queryParams]="{view: 'procurement'}">-->
<!--            Sales & Procurement-->
<!--        </a>-->
<!--        <a class="nav-link disabled text-muted" routerLinkActive="active" [routerLink]="[]"-->
<!--            [queryParams]="{view: 'schedules'}">-->
<!--            Schedules-->
<!--        </a>-->
<!--        <a class="nav-link disabled text-muted" routerLinkActive="active" [routerLink]="[]"-->
<!--            [queryParams]="{view: 'insights'}">-->
<!--            Insights-->
<!--        </a>-->
<!--    </nav>-->
    <div class="pb-3" *ngIf="view$ | async; let view">
        <qw-ops-dashboard *ngIf="view === 'ops'"></qw-ops-dashboard>
        <qw-procurement-dashboard *ngIf="view === 'procurement'"></qw-procurement-dashboard>
        <qw-schedules-dashboard *ngIf="view === 'schedules'"></qw-schedules-dashboard>
    </div>
</div>
