import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ActivitiesSelectors from './activities.selectors';
import * as ActivitiesActions from './activities.actions';
import {
    ActivitiesEntity,
    ActivitiesIndexQuery,
    CommentActivityPayload,
    HasActivities,
} from './activities.models';

@Injectable()
export class ActivitiesFacade {
    loading$ = this.store.pipe(
        select(ActivitiesSelectors.getActivitiesLoading)
    );
    saving$ = this.store.pipe(select(ActivitiesSelectors.getActivitiesSaving));
    pagination$ = this.store.pipe(
        select(ActivitiesSelectors.getActivitiesPagination)
    );
    allActivities$ = this.store.pipe(
        select(ActivitiesSelectors.getAllActivities)
    );
    error$ = this.store.pipe(select(ActivitiesSelectors.getActivitiesError));

    constructor(private readonly store: Store) {}

    /**
     * Use the initialization action to perform one
     * or more tasks in your Effects.
     */
    public load(parent: HasActivities, query: ActivitiesIndexQuery): void {
        this.store.dispatch(
            ActivitiesActions.loadActivities({ parent, query })
        );
    }

    public createComment(
        parent: HasActivities,
        payload: CommentActivityPayload
    ): void {
        this.store.dispatch(
            ActivitiesActions.createComment({ parent, payload })
        );
    }

    updateComment(
        parent: HasActivities,
        activity: ActivitiesEntity,
        payload: CommentActivityPayload
    ) {
        this.store.dispatch(
            ActivitiesActions.updateComment({ parent, activity, payload })
        );
    }

    deleteComment(parent: HasActivities, activity: ActivitiesEntity) {
        this.store.dispatch(
            ActivitiesActions.deleteComment({ parent, activity })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadMore(parent: HasActivities) {
        this.store.dispatch(ActivitiesActions.loadMoreActivities());
    }
}
