<ul class="schedule-timeline">
    <li class="schedule-timeline-item">
        <div class="schedule-timeline-item-inner w-100">
            <div class="schedule-timeline-icon border">
                <fa-icon
                    [icon]="['far', 'warehouse']"
                    size="lg"
                    [fixedWidth]="true"
                >
                </fa-icon>
            </div>
            <div class="schedule-timeline-item-content">
                <div>{{ 'portal.landing-page.quotation-offer-schedules.columns.closing' | translate }}</div>
                <div class="small">
                    {{
                        schedule.closing_cargo || schedule.closing
                        | amParseZone
                        | amDateFormat: 'LL'
                    }}
                </div>
            </div>
        </div>
    </li>

    <li
        class="schedule-timeline-item with-extra"
        *ngFor="let leg of schedule.legs; index as idx"
    >
        <div class="schedule-timeline-item-inner w-100">
            <div class="schedule-timeline-icon border">
                <fa-icon
                    [icon]="[
                        'far',
                        idx === 0
                            ? leg.type === 'precarriage'
                                ? 'truck'
                                : schedule.product !== 'air'
                                ? 'ship'
                                : 'plane-departure'
                            : leg[idx - 1]?.type !== 'precarriage' &&
                                leg[idx + 1]?.type !== 'oncarriage'
                            ? 'repeat'
                            : 'repeat'
                    ]"
                    size="lg"
                    [fixedWidth]="true"
                >
                </fa-icon>
            </div>
            <div class="schedule-timeline-item-content">
                <div>
                    {{
                        leg.origin?.display_name ||
                        leg.origin?.locode ||
                        leg.origin ||
                        ('common.unknown' | translate)
                    }}
                </div>
                <div class="small" *ngIf="schedule.product !== 'air'">
                    {{ leg.etd | amDateFormat: 'LL' }}
                </div>
                <div class="small" *ngIf="schedule.product === 'air'">
                    {{ leg.etd | amDateFormat: 'LLL' }}
                </div>
            </div>
            <div class="schedule-timeline-item-extra text-muted">
                <div class="small">{{ leg.ident }}</div>
                <div class="small">
                    {{ leg.carrier.display_name }}
                    <span *ngIf="leg.service">({{ leg.service }})</span>
                </div>
            </div>
        </div>
    </li>

    <li
        class="schedule-timeline-item"
        *ngIf="schedule.legs[schedule.legs.length - 1]; let leg"
    >
        <div class="schedule-timeline-item-inner w-100">
            <div class="schedule-timeline-icon border">
                <fa-icon
                    [icon]="[
                        'far',
                        leg.type !== 'oncarriage'
                            ? schedule.product !== 'air'
                                ? 'anchor'
                                : 'plane-arrival'
                            : 'truck'
                    ]"
                    size="lg"
                    [fixedWidth]="true"
                >
                </fa-icon>
            </div>
            <div class="schedule-timeline-item-content">
                <div>
                    {{
                        leg.destination?.display_name ||
                        leg.destination?.locode ||
                        leg.destination ||
                        ('common.unknown' | translate)
                    }}
                </div>
                <div class="small" *ngIf="schedule.product !== 'air'">
                    {{ leg.eta | amDateFormat: 'LL' }}
                </div>
                <div class="small" *ngIf="schedule.product === 'air'">
                    {{ leg.eta | amDateFormat: 'LLL' }}
                </div>
            </div>
        </div>
    </li>
</ul>
