import {Portals} from '@qwyk/models';
import {Observable} from "rxjs";

export abstract class ShipmentContainersServiceBase {

    public abstract getShipmentContainers(
        shipmentId: string
    ): Observable<Portals.ShipmentDataContainer[]>

    public abstract getShipmentContainerGeoTrack(
        shipmentId: string,
        containerId: number
    ): Observable<Portals.GeoTrackElement[]>

}
