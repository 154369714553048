/* eslint-disable @typescript-eslint/no-explicit-any */
import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [NgIf, FontAwesomeModule, NgbDropdownModule, TranslateModule],
    selector: 'qwyk-downloadable-drop-down',
    templateUrl: './downloadable-drop-down.component.html',
    styleUrls: ['./downloadable-drop-down.component.scss'],
})
export class DownloadableDropDownComponent {
    @Input() downloadable: boolean;
    @Input() loading: boolean;
    @Output() download: EventEmitter<any> = new EventEmitter<any>();
    @Output() email: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    onDownload() {
        this.download.emit();
    }

    onEmail() {
        this.email.emit();
    }
}
