import { Injectable } from '@angular/core';
import { EchoService } from 'ngx-laravel-echo';
import { Observable, Subscription } from 'rxjs';
import { NotificationsFacade } from './state/notifications.facade';
import { NotificationsEntity } from './state/notifications.models';
import { environment } from '@qwyk/portals/environment';

@Injectable({
    providedIn: 'root',
})
export class PortalsSocketNotificationsService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private echoListener$: Observable<any>;
    private echoConnectionStateSubscription: Subscription;
    private initialized = false;
    private connected = false;
    private userId = null;
    constructor(
        private echo: EchoService,
        private notificationsFacade: NotificationsFacade
    ) {}

    public registerEchoListener(
        token: string,
        userId: string
    ): Observable<NotificationsEntity> {
        if (this.initialized) {
            this.deregisterEchoListener();
        }

        this.userId = userId;
        this.echo.login({ Authorization: `Bearer ${token}` }, userId);

        // Manually join because logout prevents rejoining.
        this.echo.join(
            `${environment.echoConfig.userModel}.${userId}`,
            'private'
        );

        this.echoListener$ = this.echo.notification('*');
        this.initialized = true;
        this.subscribeEchoConnectionState();
        return this.echoListener$;
    }

    public deregisterEchoListener() {
        if (this.echoConnectionStateSubscription) {
            this.echoConnectionStateSubscription.unsubscribe();
        }
        this.initialized = false;
        this.echoListener$ = null;
        this.echo.leave(
            `private-${environment.echoConfig.userModel}.${this.userId}`
        );
        this.echo.logout();
        this.connected = false;
    }

    private subscribeEchoConnectionState() {
        if (this.echoConnectionStateSubscription) {
            this.echoConnectionStateSubscription.unsubscribe();
        }
        this.echoConnectionStateSubscription =
            this.echo.connectionState.subscribe(state => {
                if (!state && this.initialized && this.connected) {
                    setTimeout(() => {
                        this.notificationsFacade.reinitializeEchoListener();
                    }, 1000);
                }
                this.connected = state;
            });
    }
}
