import { Component, ContentChild, Input } from '@angular/core';
import {
    DetailCardBodyDirective,
    DetailCardHeaderDirective,
} from './detail-card-templates.directive';

@Component({
    selector: 'qwyk-detail-card',
    templateUrl: './detail-card.component.html',
    styleUrls: ['./detail-card.component.scss'],
})
export class DetailCardComponent {
    @Input()
    public caption?: string;

    @Input()
    public cardClass?: string;

    @ContentChild(DetailCardHeaderDirective, { static: false })
    tplHeader: DetailCardHeaderDirective;
    @ContentChild(DetailCardBodyDirective, { static: false })
    tplBody: DetailCardBodyDirective;
}
