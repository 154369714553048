import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private readonly _available: boolean;

    constructor() {
        this._available = null !== window && null !== window.localStorage;
    }

    public get available(): boolean {
        return this._available;
    }

    public setBool(property: string, value: boolean): void {
        if (this.available) {
            window.localStorage.setItem(property, value ? 'true' : 'false');
        }
    }

    public getBool(property: string, defaultValue: boolean = false): boolean {
        if (!this.available) {
            return defaultValue;
        }
        if (window.localStorage.getItem(property)) {
            return window.localStorage.getItem(property) === 'true';
        }
        return defaultValue;
    }

    public setString(property: string, value: string): void {
        if (this.available) {
            window.localStorage.setItem(property, value);
        }
    }

    public getString(property: string): string | null | undefined {
        if (!this.available) {
            return undefined;
        }

        return window.localStorage.getItem(property);
    }

    public setObject(property: string, value: object): void {
        if (this.available) {
            window.localStorage.setItem(property, JSON.stringify(value));
        }
    }

    public getObject(property: string): object | null | undefined {
        if (!this.available) {
            return undefined;
        }

        const value = window.localStorage.getItem(property);

        if (value) {
            return JSON.parse(value);
        }
        return undefined;
    }

    public getArray(property: string): any[] {
        if (!this.available) {
            return [];
        }

        const value = window.localStorage.getItem(property);

        if (value) {
            return JSON.parse(value);
        }
        return [];
    }

    public remove(property: string): void {
        if (this.available) {
            window.localStorage.removeItem(property);
        }
    }
}
