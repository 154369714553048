import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { ModuleWithProviders, NgModule } from '@angular/core';

import {
    AlgoliaLocationsService,
    GooglePlacesService,
    LocalStorageService,
    MasterDataService,
    PathService,
} from './services';
import { EnvironmentConfig } from './environmentConfig';

@NgModule({
    declarations: [],
    imports: [CommonModule, GoogleMapsModule],
    providers: [
        MasterDataService,
        AlgoliaLocationsService,
        PathService,
        GooglePlacesService,
        LocalStorageService,
    ],
})
export class CoreModule {
    static forRoot(config: EnvironmentConfig): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [{ provide: EnvironmentConfig, useValue: config }],
        };
    }
}
