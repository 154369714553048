import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notificationIcon',
})
export class NotificationIconPipe implements PipeTransform {
    icons: { [index: string]: string | { [index: string]: string } } = {
        'quotation-rate-posted': 'hand-holding-usd',
        'quotation-published': 'hands-usd',
        document: 'file',
        'private-message': 'envelope',
        'shipment-status-update': 'clock',
        comment: 'comment',
        follow: 'follow',
        'shipment-activity': {
            'milestone-change': 'calendar-edit',
            comment: 'comment',
            'schedule-change': 'ship',
            document: 'file',
        },
        'entity-shared': 'user-plus',
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: string, ...args: any[]): string {
        let icon: string | { [index: string]: string } = this.icons[value];

        if (
            icon &&
            icon !== null &&
            typeof icon === 'object' &&
            args.length > 0 &&
            args[0] !== null
        ) {
            icon = icon[args[0]];
        }

        if (!icon) {
            return 'envelope';
        }
        return icon as string;
    }
}
