import { Component, Input } from '@angular/core';
import { NotificationsEntity } from '../../state/notifications.models';

@Component({
    selector: 'qwyk-notification-detail',
    templateUrl: './notification-detail.component.html',
    styleUrls: ['./notification-detail.component.css'],
})
export class NotificationDetailComponent {
    @Input() notification: NotificationsEntity;
}
