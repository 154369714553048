import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SchedulesSearchFacade } from './state/schedules-search.facade';
import { PortalsSchedulesSearchWithoutRoutingModule } from './portals-schedules-search-without-routing.module';
import { SchedulesSearchContainerComponent } from './components';

@NgModule({
    declarations: [],
    imports: [
        PortalsSchedulesSearchWithoutRoutingModule,
        RouterModule.forChild([
            {
                path: '',
                pathMatch: 'full',
                component: SchedulesSearchContainerComponent,
            },
        ]),
    ],
    providers: [SchedulesSearchFacade],
})
export class PortalsSchedulesSearchModule {}
