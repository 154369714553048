import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationFacade } from '../state/authentication.facade';

@Injectable({
    providedIn: 'root',
})
export class VerifyFeaturesGuard implements CanActivate, CanActivateChild {
    private features$ = this.authentication.features$;
    constructor(
        private router: Router,
        private authentication: AuthenticationFacade
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        // eslint-disable-next-line no-prototype-builtins
        if (!next.data || !next.data.hasOwnProperty('requiredFeature')) {
            return true;
        }
        const requiredFeature = next.data.requiredFeature;
        return this.features$.pipe(
            map(features => {
                if(!features){
                    return true;
                }
                const notAllowed = features[requiredFeature]?.value;
                if (notAllowed === true) {
                    this.redirect(next, 403, 'Forbidden');
                }
                return true;
            })
        );
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }

    private redirect(next: ActivatedRouteSnapshot, errorCode, errorName): void {
        this.router.navigate([`/error/${errorCode}`], {
            queryParams: {
                source: next.pathFromRoot.map(p => p.url).join('/'),
                errorName,
            },
        });
    }
}
