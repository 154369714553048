<form [formGroup]="form" (submit)="onSubmit()">
    <div class="form-group p-fluid mb-2">
        <label for="comment"> {{'portal.shipment.page.activities.leave-a-comment' | translate}} </label>
        <textarea
            formControlName="comment"
            id="comment"
            pInputTextarea
            placeholder="{{'portal.shipment.page.activities.your-comment' | translate}}"
            rows="3"
        ></textarea>
        <arv-validation-messages for="comment"></arv-validation-messages>
    </div>
    <div class="form-group">
        <button [disabled]="disabled" class="btn btn-primary" type="submit">
            {{'portal.shipment.page.activities.post' | translate}}
        </button>
        <button [disabled]="disabled" class="btn btn-light ml-2" type="reset">
            {{'portal.shipment.page.activities.clear' | translate}}
        </button>
    </div>
</form>
