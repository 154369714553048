import { FormControl } from '@angular/forms';

export function requiredIfOtherValidator(
    otherControlName: string,
    otherControlValue: any | any[]
) {
    let thisControl: FormControl;
    let otherControl: FormControl;

    return function requiredIfOtherValidate(control: FormControl) {
        if (!control.parent) {
            return null;
        }

        // Initializing the validator.
        if (!thisControl) {
            thisControl = control;
            otherControl = control.parent.get(otherControlName) as FormControl;
            if (!otherControl) {
                throw new Error(
                    'matchOtherValidator(): other control is not found in parent group'
                );
            }
            otherControl.valueChanges.subscribe(() => {
                thisControl.updateValueAndValidity();
            });
        }

        if (!otherControl) {
            return null;
        }

        if (Array.isArray(otherControlValue)) {
            if (
                otherControlValue.includes(otherControl.value) &&
                !thisControl.value
            ) {
                return {
                    required: true,
                };
            }
        } else {
            if (
                otherControl.value === otherControlValue &&
                !thisControl.value &&
                thisControl.value !== 0
            ) {
                return {
                    required: true,
                };
            }
        }

        return null;
    };
}
