import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import * as fromShipmentAttachments from './shipment-attachments.reducer';
import * as ShipmentAttachmentsActions from './shipment-attachments.actions';
import * as ShipmentAttachmentsSelectors from './shipment-attachments.selectors';

import { ShipmentAttachmentsServiceBase } from '../shipment-attachments-service-base';

import {
    catchError,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';

@Injectable()
export class ShipmentAttachmentsEffects {
    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentAttachmentsActions.init),
            fetch({
                run: action => {
                    return this.service
                        .getShipmentAttachments(action.shipment.id)
                        .pipe(
                            map(shipmentAttachments =>
                                ShipmentAttachmentsActions.loadShipmentAttachmentsSuccess(
                                    { shipmentAttachments }
                                )
                            )
                        );
                },

                onError: (action, error) => {
                    console.error(error);
                    return ShipmentAttachmentsActions.loadShipmentAttachmentsFailure(
                        { error }
                    );
                },
            })
        )
    );

    loadAttachmentsOnly$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentAttachmentsActions.loadShipmentAttachmentsOnly),
            withLatestFrom(
                this.store.select(
                    ShipmentAttachmentsSelectors.getAllShipmentAttachments
                )
            ),
            fetch({
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                run: (action, _) =>
                    this.service
                        .getShipmentAttachments(action.shipment.id)
                        .pipe(
                            take(1),
                            map(response =>
                                ShipmentAttachmentsActions.loadShipmentAttachmentsOnlySuccess(
                                    {
                                        attachmentLinks: response,
                                    }
                                )
                            )
                        ),
                onError: (action, error) => {
                    return ShipmentAttachmentsActions.loadShipmentAttachmentsOnlyFailure(
                        {
                            error,
                        }
                    );
                },
            })
        )
    );

    generateAttachmentLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentAttachmentsActions.generateAttachmentLink),
            tap(() => this.toastr.info('Your attachment is being loaded...')),
            switchMap(action =>
                this.service
                    .generateShipmentAttachmentLink(
                        action.shipment.id,
                        action.attachment.id
                    )
                    .pipe(
                        map(attachmentLink =>
                            ShipmentAttachmentsActions.generateAttachmentLinkSuccess(
                                { attachmentLink }
                            )
                        )
                    )
            ),
            catchError(error => {
                console.error(error);
                return of(
                    ShipmentAttachmentsActions.generateAttachmentLinkFailure({
                        error,
                    })
                );
            })
        )
    );

    generateAttachmentLinkSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ShipmentAttachmentsActions.generateAttachmentLinkSuccess
                ),
                tap(action => {
                    window.open(action.attachmentLink, '_blank');
                })
            ),
        { dispatch: false }
    );

    genericFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ShipmentAttachmentsActions.generateAttachmentLinkFailure,
                    ShipmentAttachmentsActions.createShipmentAttachmentFailure,
                    ShipmentAttachmentsActions.deleteShipmentAttachmentFailure
                ),
                tap(action => {
                    this.toastr.error(
                        action.error?.error?.message ??
                            "We're sorry, try again later or contact support if the problem persists",
                        'Something went wrong'
                    );
                })
            ),
        { dispatch: false }
    );

    createShipmentAttachment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentAttachmentsActions.createShipmentAttachment),
            switchMap(action =>
                this.service
                    .createShipmentAttachment(
                        action.shipment.id,
                        action.request
                    )
                    .pipe(
                        map(shipmentAttachment =>
                            ShipmentAttachmentsActions.createShipmentAttachmentSuccess(
                                { shipmentAttachment }
                            )
                        )
                    )
            ),
            catchError(error => {
                console.error(error);
                return of(
                    ShipmentAttachmentsActions.createShipmentAttachmentFailure({
                        error,
                    })
                );
            })
        )
    );

    deleteShipmentAttachment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ShipmentAttachmentsActions.deleteShipmentAttachment),
            switchMap(action =>
                this.service
                    .deleteShipmentAttachment(
                        action.shipmentId,
                        action.shipmentAttachmentId
                    )
                    .pipe(
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        map(_ =>
                            ShipmentAttachmentsActions.deleteShipmentAttachmentSuccess(
                                {
                                    shipmentAttachmentId:
                                        action.shipmentAttachmentId,
                                }
                            )
                        ),
                        catchError(error =>
                            of(
                                ShipmentAttachmentsActions.deleteShipmentAttachmentFailure(
                                    { error }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteShipmentAttachmentSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ShipmentAttachmentsActions.deleteShipmentAttachmentSuccess
                ),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                tap(_ => {
                    this.toastr.success('Attachment deleted');
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly toastr: ToastrService,
        private readonly service: ShipmentAttachmentsServiceBase,
        private readonly store: Store<fromShipmentAttachments.ShipmentAttachmentsPartialState>
    ) {}
}
