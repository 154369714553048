import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'objectToKVP',
})
export class ObjectToKVPPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, flat = false): any {
        if (flat) {
            return Object.keys(value).flatMap(e => ({
                key: e,
                value: value[e],
            }));
        }
        return Object.keys(value).map(e => ({ key: e, value: value[e] }));
    }
}
