import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ACTIVITIES_FEATURE_KEY, activitiesAdapter, State,} from './activities.reducer';


// Lookup the 'Activities' feature state managed by NgRx
export const getActivitiesState = createFeatureSelector<State>(
    ACTIVITIES_FEATURE_KEY
);

const {selectAll, selectEntities} = activitiesAdapter.getSelectors();

export const getActivitiesLoading = createSelector(
    getActivitiesState,
    (state: State) => state.loading
);

export const getActivitiesSaving = createSelector(
    getActivitiesState,
    (state: State) => state.saving
);

export const getActivitiesError = createSelector(
    getActivitiesState,
    (state: State) => state.error
);

export const getActivitiesPagination = createSelector(
    getActivitiesState,
    (state: State) => state.pagination
);

export const getActivitiesQuery = createSelector(
    getActivitiesState,
    (state: State) => state.query
);

export const getActivitiesParent = createSelector(
    getActivitiesState,
    (state: State) => state.parent
);

export const getAllActivities = createSelector(
    getActivitiesState,
    (state: State) => selectAll(state)
);

export const getActivitiesEntities = createSelector(
    getActivitiesState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getActivitiesState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getActivitiesEntities,
    getSelectedId,
    (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
