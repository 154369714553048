import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Portals } from '@qwyk/models';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'qwyk-schedules-filter',
    templateUrl: './schedules-filter.component.html',
    styleUrls: ['./schedules-filter.component.scss'],
})
export class SchedulesFilterComponent {
    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
        if (value) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    private _schedules: Portals.Schedule[];

    @Input() set schedules(schedules: Portals.Schedule[]) {
        this._schedules = schedules;
        this.updateFilters();
    }

    get schedules() {
        return this._schedules;
    }

    form = this.fb.group({
        carrier: [null],
        vessel: [null],
        voyage: [null],
        etd: [null],
        eta: [null],
        closing: [null],
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() filter: EventEmitter<any> = new EventEmitter<any>();
    carriers: Portals.ScheduleCarrier[];
    vessels: string[];
    voyages: string[];

    private _disabled: boolean;

    constructor(private fb: FormBuilder) {}

    public onSearch() {
        this.form.markAllAsTouched();
        this.filter.emit(this.form.getRawValue());
    }

    public onReset() {
        this.filter.emit(null);
    }

    private updateFilters() {
        this.carriers = this.schedules.map(schedule => schedule.carrier);
        this.carriers = [
            ...new Map(
                this.carriers.map(item => [item['code'], item])
            ).values(),
        ];
        this.vessels = [
            ...new Set(this.schedules.map(schedule => schedule.vessel)),
        ];
        this.voyages = [
            ...new Set(this.schedules.map(schedule => schedule.voyage)),
        ];
    }
}
