import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromShipmentContainers from './+state/shipment-containers.reducer';
import {ShipmentContainersEffects} from './+state/shipment-containers.effects';
import {ShipmentContainersFacade} from './+state/shipment-containers.facade';

export interface SharedStoresShipmentContainersConfig {
    service: Provider
}

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromShipmentContainers.SHIPMENTCONTAINERS_FEATURE_KEY,
            fromShipmentContainers.reducer
        ),
        EffectsModule.forFeature([ShipmentContainersEffects]),
    ],
    providers: [ShipmentContainersFacade],
})
export class SharedStoresShipmentContainersModule {

    static forFeature(
        config: SharedStoresShipmentContainersConfig
    ): ModuleWithProviders<SharedStoresShipmentContainersModule> {
        return {
            ngModule: SharedStoresShipmentContainersModule,
            providers: [config.service]
        }
    }
}
