<div class="text-center" [ngClass]="componentClass">
    <div class="font-weight-normal mb-0" [ngClass]="{'h4': size === 'md', 'h2': size === 'lg', 'h6': size==='sm'}">
        <fa-icon *ngIf="spinner" [icon]="faSpinner" [pulse]="true" size="lg" class="mb-2"></fa-icon>
        <div
            [ngClass]="{'mt-2': size === 'md' && !horizontal, 'mt-3': size === 'lg' && !horizontal, 'mt-1': size==='sm' && !horizontal, 'mt-0 d-inline-block ml-2': horizontal}">
            <span qwykTypingAnimation [ngClass]="textClass" *ngIf="typing" [typeSpeed]="60" [startDelay]="200"
                [text]="text">{{ text }}</span>
            <span *ngIf="!typing" [ngClass]="textClass">{{ text }}</span>

        </div>
    </div>
    <span class="small" [ngClass]="textClass" *ngIf="subText">{{ subText }}</span>
</div>
