<div class="d-flex">
    <div class="flex-grow-0 mr-3 icon-container">
        <div class="icon-circle border rounded-circle text-muted bg-white">
            <fa-icon
                [fixedWidth]="true"
                [icon]="['far', 'ship']"
                size="lg"
            ></fa-icon>
        </div>
    </div>

    <div class="flex-grow-1" style="margin-top: 0.4em">
        <p class="m-0">
            <b [ngbTooltip]="shipmentActivity.user?.email">
                {{ shipmentActivity.user?.name || '@system' }}
            </b>
            {{ 'portal.shipment.page.activities.changed-the' | translate }}
            <a
                [queryParams]="{
                    view: 'instructions',
                    instructions_view: 'transport'
                }"
                [routerLink]="[]"
            >
                {{ 'portal.shipment.page.activities.transport-details' | translate }}
            </a>
            <span class="text-muted">
                {{ shipmentActivity.created_at | amTimeAgo }}
            </span>
        </p>
        <div
            *ngFor="let change of shipmentActivity.data | objectToKVP"
            class="small"
        >
            {{ change.key | titlecase }} {{ 'common.from' | translate }} {{ change.value?.from }} {{ 'common.to_pagination' | translate }}
            {{ change.value?.to }}
        </div>
    </div>
</div>
