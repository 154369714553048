import { Animations } from '@qwyk/ui';
import { Portals } from '@qwyk/models';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';

@Component({
    selector: 'qwyk-schedule-list-item',
    templateUrl: './schedule-list-item.component.html',
    styleUrls: ['./schedule-list-item.component.scss'],
    animations: [Animations.expandCollapse2, Animations.fadeInAnimation],
})
export class ScheduleListItemComponent {
    private _schedule: Portals.Schedule;
    @Input()
    public set schedule(schedule: Portals.Schedule) {
        this._schedule = schedule;
        let originTz = null;
        let destinationTz = null;
        if (
            // eslint-disable-next-line no-prototype-builtins
            schedule.hasOwnProperty('origin_info') &&
            schedule['origin_info'] !== null
        ) {
            originTz = schedule['origin_info'].timezone;
        } else {
            originTz = schedule.origin['timezone'];
        }

        if (
            // eslint-disable-next-line no-prototype-builtins
            schedule.hasOwnProperty('destination_info') &&
            schedule['destination_info'] !== null
        ) {
            destinationTz = schedule['destination_info'].timezone;
        } else {
            destinationTz = schedule.origin['timezone'];
        }
        this.setTimezone('etd', schedule.etd, originTz);
        this.setTimezone('eta', schedule.eta, destinationTz);
    }

    public get schedule(): Portals.Schedule {
        return this._schedule;
    }

    public etd;
    public etdSet = false;
    public eta;
    public etaSet = false;

    @Input()
    public isSaving = false;

    @Input()
    public hasBookLinkAction = true;

    @Input()
    public canBook = true;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public features: any;

    public isExpanded = false;
    public isMouseOver = false;

    @Output()
    public book: EventEmitter<Portals.Schedule> = new EventEmitter();

    @Output()
    public save: EventEmitter<Portals.Schedule> = new EventEmitter();

    public features$ = this.siteConfig.features$;

    constructor(private siteConfig: SiteConfigFacade) {}

    public onBook(e) {
        e.preventDefault();
        e.stopPropagation();
        this.book.next(this.schedule);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    public onSave(e: any) {
        this.save.next(this.schedule);
    }

    private setTimezone(assignTo, date, timezone) {
        const plusIdx = date.indexOf('+');
        if (plusIdx >= 0) {
            date = date.substr(0, plusIdx);
        }
        let mom;
        if (!timezone) {
            mom = moment(date);
        } else {
            mom = moment.tz(date, timezone);
            if (assignTo === 'etd') {
                this.etdSet = true;
            } else {
                this.etaSet = true;
            }
        }
        if (assignTo === 'etd') {
            this.etd = mom;
        } else {
            this.eta = mom;
        }
    }
}
