export const INCO_TERMS = [
    {
        code: 'EXW',
        name: 'common.inco-terms.ex-works',
        long_form: 'EXW - Ex Works',
        attributes: {
            applies_to: ['OCEAN', 'AIR', 'TRUCK'],
            show_on_origin_type: ['place'],
            show_on_destination_type: ['place', 'port'],
            setAdditionals: {
                import: {
                    require_origin_charges: true,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: false,
                    require_destination_charges: false,
                    require_freight_charges: false,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },
    {
        code: 'FCA',
        name: 'common.inco-terms.free-carrier',
        long_form: 'FCA - Free Carrier',
        attributes: {
            applies_to: ['OCEAN', 'AIR', 'TRUCK'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place', 'port'],
            setAdditionals: {
                import: {
                    require_origin_charges: true,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: false,
                    require_freight_charges: false,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },
    {
        code: 'FAS',
        name: 'common.inco-terms.free-alongside-ship',
        long_form: 'FAS - Free Alongside Ship',
        attributes: {
            applies_to: ['OCEAN'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place', 'port'],
            setAdditionals: {
                import: {
                    require_origin_charges: true,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: false,
                    require_freight_charges: false,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },
    {
        code: 'FOB',
        name: 'common.inco-terms.free-on-board',
        long_form: 'FOB - Free On Board',
        attributes: {
            applies_to: ['OCEAN'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place', 'port'],
            setAdditionals: {
                import: {
                    require_origin_charges: false,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: false,
                    require_freight_charges: false,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },
    {
        code: 'CFR',
        name: 'common.inco-terms.cost-and-freight',
        long_form: 'CFR - Cost and Freight',
        attributes: {
            applies_to: ['OCEAN'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place', 'port'],
            setAdditionals: {
                import: {
                    require_origin_charges: false,
                    require_destination_charges: true,
                    require_freight_charges: false,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: false,
                    require_freight_charges: true,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },
    {
        code: 'CIF',
        name: 'common.inco-terms.cost-insurance-freight',
        long_form: 'CIF - Cost, Insurance & Freight',
        attributes: {
            applies_to: ['OCEAN'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place', 'port'],
            setAdditionals: {
                import: {
                    require_origin_charges: false,
                    require_destination_charges: true,
                    require_freight_charges: false,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: false,
                    require_freight_charges: true,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },
    {
        code: 'CPT',
        name: 'common.inco-terms.carriage-paid-to',
        long_form: 'CPT - Carriage Paid To',
        attributes: {
            applies_to: ['OCEAN', 'AIR', 'TRUCK'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place', 'port'],
            setAdditionals: {
                import: {
                    require_origin_charges: false,
                    require_destination_charges: true,
                    require_freight_charges: false,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },

    {
        code: 'CIP',
        name: 'common.inco-terms.cost-and-insurance-paid-to',
        long_form: 'CIP - Cost and Insurance Paid To',
        attributes: {
            applies_to: ['OCEAN', 'AIR', 'TRUCK'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place', 'port'],
            setAdditionals: {
                import: {
                    require_origin_charges: false,
                    require_destination_charges: true,
                    require_freight_charges: false,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },
    {
        code: 'DAP',
        name: 'common.inco-terms.delivered-at-place',
        long_form: 'DAP - Delivered At Place',
        attributes: {
            applies_to: ['OCEAN', 'AIR', 'TRUCK'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place'],
            setAdditionals: {
                import: {
                    require_origin_charges: false,
                    require_destination_charges: true,
                    require_freight_charges: false,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },
    {
        code: 'DPU',
        name: 'common.inco-terms.delivered-at-place-unloaded',
        long_form: 'DPU - Delivered At Place Unloaded',
        attributes: {
            applies_to: ['OCEAN', 'AIR', 'TRUCK'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place'],
            setAdditionals: {
                import: {
                    require_origin_charges: false,
                    require_destination_charges: false,
                    require_freight_charges: false,
                    customs_clearance_origin: false,
                    customs_clearance_destination: true,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: true,
                    customs_clearance_destination: false,
                },
            },
        },
    },

    {
        code: 'DDP',
        name: 'common.inco-terms.delivered-duty-paid',
        long_form: 'DDP - Delivered Duty Paid',
        attributes: {
            applies_to: ['OCEAN', 'AIR', 'TRUCK'],
            show_on_origin_type: ['place', 'port'],
            show_on_destination_type: ['place'],
            setAdditionals: {
                import: {
                    require_origin_charges: false,
                    require_destination_charges: false,
                    require_freight_charges: false,
                    customs_clearance_origin: false,
                    customs_clearance_destination: false,
                },
                export: {
                    require_origin_charges: true,
                    require_destination_charges: true,
                    require_freight_charges: true,
                    customs_clearance_origin: true,
                    customs_clearance_destination: true,
                },
            },
        },
    },
];
