import { FormsModule } from '@angular/forms';
import { Component, EventEmitter } from '@angular/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

import { Subject, Observable } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UserSummary } from '@qwyk/models';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        FormsModule,
        NgSelectModule,
        TranslateModule,
        FontAwesomeModule,
    ],
    selector: 'qwyk-sharing-modal',
    templateUrl: './sharing-modal.component.html',
    styleUrls: ['./sharing-modal.component.scss'],
})
export class SharingModalComponent {
    header = 'portal.ui.share-modal.share-with-people';
    owner: UserSummary;
    users$: Observable<Partial<UserSummary>[]>;
    sharedUsers: UserSummary[];
    addingUsers: UserSummary[] = [];
    notify = true;
    message: string | null;
    userInput$ = new Subject<string>();
    usersLoading = false;
    share = new EventEmitter<{
        users: UserSummary[];
        notify: boolean;
        message: string | null;
    }>();
    unshare = new EventEmitter<{ user: UserSummary }>();
    cancel = new EventEmitter();
    busy = false;

    trackUserByFn(item: UserSummary) {
        return item.id;
    }

    public onShare() {
        if (this.addingUsers.length > 0) {
            this.share.emit({
                users: this.addingUsers,
                notify: this.notify,
                message: this.message,
            });
        } else {
            this.cancel.emit();
        }
    }

    public onCancelAdding() {
        this.sharedUsers = [];
        this.notify = true;
        this.message = null;
    }

    public onUnshare(user: UserSummary) {
        if (user) {
            this.unshare.emit({ user });
        }
    }
}
