import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    CustomViewsState,
    customViewsAdapter,
    CUSTOM_VIEWS_FEATURE_KEY,
} from './custom-views.reducer';

// Lookup the 'Custom Views' feature state managed by NgRx
export const getCustomViewsState = createFeatureSelector<CustomViewsState>(
    CUSTOM_VIEWS_FEATURE_KEY
);

const { selectAll, selectEntities } = customViewsAdapter.getSelectors();

export const getCustomViewsLoading = createSelector(
    getCustomViewsState,
    (state: CustomViewsState) => state.loading
);

export const getCustomViewsError = createSelector(
    getCustomViewsState,
    (state: CustomViewsState) => state.error
);

export const getAllCustomViews = createSelector(
    getCustomViewsState,
    (state: CustomViewsState) => {
        return selectAll(state);
    }
);

export const getCustomViewsEntities = createSelector(
    getCustomViewsState,
    (state: CustomViewsState) => selectEntities(state)
);

export const getCustomViews = createSelector(
    getCustomViewsState,
    (state: CustomViewsState) => state.entities
);

export const getSelectedId = createSelector(
    getCustomViewsState,
    (state: CustomViewsState) => state.selectedId
);

export const getSelected = createSelector(
    getCustomViewsEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);
