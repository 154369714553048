import { Component, OnInit, Input } from '@angular/core';
import { Portals } from '@qwyk/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { QuotationsService } from '../../services/quotations.service';

@Component({
    selector: 'qwyk-request-for-quotation-offer',
    templateUrl: './request-for-quotation-offer.component.html',
    styleUrls: ['./request-for-quotation-offer.component.scss'],
})
export class RequestForQuotationOfferComponent implements OnInit {
    @Input() quotation: Portals.QuotationEntity;

    form: FormGroup;
    sending: boolean;
    error: boolean;
    sent: boolean;

    reasons = [
        'I need a better rate',
        'I would like a different option',
        'I did not find any rates',
        'Other',
    ];
    // tslint:disable-next-line: variable-name
    private _noRatesReason: boolean;

    @Input() public set noRatesReason(noRatesReason: boolean) {
        this._noRatesReason = noRatesReason;
    }

    public get noRatesReason(): boolean {
        return this._noRatesReason;
    }

    constructor(private fb: FormBuilder, private service: QuotationsService) {}

    ngOnInit() {
        this.form = this.fb.group({
            reason: [
                this._noRatesReason
                    ? { value: 'I did not find any rates', disabled: true }
                    : null,
                [Validators.required],
            ],
            remarks: [null, [Validators.maxLength(255)]],
        });
    }

    submit() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        this.sending = true;
        this.form.disable();
        this.service
            .createQuotationRateRequest(this.quotation.id, this.form.value)
            .subscribe(
                () => {
                    this.form.enable();
                    this.sending = false;
                    this.sent = true;
                    this.form.reset();
                },
                () => {
                    this.form.enable();
                    this.sending = false;
                    this.error = true;
                }
            );
    }
}
