import { Component } from '@angular/core';

import { SchedulesSearchFacade } from '../../state/schedules-search.facade';

@Component({
    selector: 'qwyk-schedules-search-container',
    templateUrl: './schedules-search-container.component.html',
    styleUrls: ['./schedules-search-container.component.scss'],
})
export class SchedulesSearchContainerComponent {
    public schedules$ = this.schedulesSearch.schedules$;

    constructor(private readonly schedulesSearch: SchedulesSearchFacade) {}
}
