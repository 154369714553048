import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@qwyk/portals/environment';

import { WelcomeModalComponent } from './../components/welcome-modal/welcome-modal.component';

@Injectable({
    providedIn: 'root',
})
export class OnboardingService {
    private tours = {
        introTour: {
            tourId: 'intro-tour',
            steps: [
                {
                    title: 'Welcome on our guided tour',
                    content:
                        // tslint:disable-next-line: max-line-length
                        'My name Jeff, and I will take you through some of our most important application features. Click `Next` to get started.',
                },
                {
                    content:
                        'You are now on our homepage. Here you can start by searching for schedules, or get straight into your portal.',
                },
                {
                    content:
                        'Our public schedules can be searched using this widget',
                    selector: '#schedulesWidget',
                    orientation: 'bottom',
                },
                {
                    content:
                        'And when you are logged in, you can use any of these menu items to get straight into your portal',
                    selector: '#loggedInMenus',
                    orientation: 'bottom',
                    closeAction: () => {
                        this.router.navigate(['/my-portal']);
                    },
                },
                {
                    title: 'This is your private portal',
                    content:
                        'Here you will be able to manage all your interactions with us',
                },
                {
                    content:
                        'Right now we are on your dashboard, this will give you a quick snapshot of all your business',
                    selector: '#staticDashboard',
                    orientation: 'center',
                },
                {
                    content:
                        'This is the main navigation sidebar, you can use it to move around the portal',
                    selector: '#navSidebar',
                    orientation: 'right',
                },
                {
                    content:
                        'This is the quick access bar, it provides access to some of the most important features',
                    selector: '#topNavbar',
                    orientation: 'bottom',
                },
            ],
        },
    };

    constructor(
        private modal: NgbModal,
        private router: Router // private guidedTourService: GuidedTourService
    ) {}

    public startOnboarding() {
        if (!environment.flags.enableOnboarding) {
            return;
        }
        const modalRef = this.modal.open(WelcomeModalComponent, {
            centered: true,
            size: 'lg',
            windowClass: 'shadow-lg border-0 animate-bounce-fade',
        });

        modalRef.result.then(
            result => {
                if (result === 'start-tour') {
                    this.startTour();
                }
            },
            () => {}
        );
    }

    public startTour() {
        this.router.navigate(['/']);
    }
}
