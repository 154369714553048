import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromShipmentAttachments from './+state/shipment-attachments.reducer';
import {ShipmentAttachmentsEffects} from './+state/shipment-attachments.effects';
import {ShipmentAttachmentsFacade} from './+state/shipment-attachments.facade';
import {SharedStoresShipmentAttachmentsConfig} from "./shared-stores-shipment-attachments.config";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromShipmentAttachments.SHIPMENTATTACHMENTS_FEATURE_KEY,
            fromShipmentAttachments.reducer
        ),
        EffectsModule.forFeature([ShipmentAttachmentsEffects]),
    ],
    providers: [ShipmentAttachmentsFacade],
})
export class SharedStoresShipmentAttachmentsModule {

    static forFeature(
        config: SharedStoresShipmentAttachmentsConfig
    ): ModuleWithProviders<SharedStoresShipmentAttachmentsModule> {
        return {
            ngModule: SharedStoresShipmentAttachmentsModule,
            providers: [config.service]
        }
    }
}
