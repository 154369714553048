<div class="row align-items-center">
    <div class="col-4">
        <div class="font-weight-bold">{{ shipmentAttachment.filename }}</div>
        <div>{{ shipmentAttachment.description }}</div>
    </div>
    <div class="col d-flex justify-content-center">
        <fa-icon
            class="text-primary"
            size="3x"
            *ngIf="!canShowThumbnail"
            [icon]="['fas', 'file']">
        </fa-icon>
        <img
            *ngIf="canShowThumbnail && shipmentAttachment?.attachment_link?.data?.url"
            class="rounded thumbnail"
            [src]="shipmentAttachment?.attachment_link?.data?.url"
            width="146"
            height="110"
            [alt]="shipmentAttachment.description"
            [ngbTooltip]="shipmentAttachment.description"
            (click)="openGallery()" />
    </div>
    <div class="col-2">
        <div>{{ shipmentAttachment.created_at | amTimeAgo }}</div>
    </div>
    <div class="col-2 text-right">
        <button
            (click)="onDownloadAttachment()"
            [disabled]="disabled || shouldDisableDownloadButton"
            class="btn btn-link download"
            ngbTooltip="{{ 'portal.common.download-attachment' | translate }}"
            placement="left"
        >
            <fa-icon [icon]="['far', 'cloud-download']"></fa-icon>
        </button>
        <button
            (click)="onDeleteAttachment(content)"
            [disabled]="disabled"
            class="btn btn-link delete"
            ngbTooltip="{{ 'portal.common.delete-attachment' | translate }}"
            placement="left"
            *ngIf="canDelete"
        >
            <fa-icon [icon]="['far', 'trash']" [classes]="['text-danger']"></fa-icon>
        </button>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header border-bottom-0 px-4 pt-4" >
        <h5 class="modal-title" id="modal-basic-title">{{ 'portal.common.delete-attachment' | translate }}</h5>
    </div>
    <div class="modal-body px-4">
        <div class="message">{{ 'portal.common.delete-attachment-alt' | translate : { value: shipmentAttachment.filename } }}</div>
    </div>
    <div class="modal-footer border-top-0 pt-0 px-4 pb-4">
        <button class="confirm btn btn-danger" (click)="modal.close()">
            {{ 'common.yes' | translate }}
        </button>
        <button class="dismiss btn btn-light"  (click)="modal.dismiss()">
            {{ 'common.no' | translate }}
        </button>
    </div>
</ng-template>
