import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as QuotationsActions from './quotations.actions';
import { common, Portals } from '@qwyk/models';

export const QUOTATIONS_FEATURE_KEY = 'quotations';

export interface State extends EntityState<Portals.QuotationEntity> {
    selectedId?: string | number; // which Quotations record has been selected
    loaded: boolean; // has the Quotations list been loaded
    loading: boolean;
    error?: string | null; // last none error (if any)
    pagination?: common.PaginationMeta;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any;
}

export interface QuotationsPartialState {
    readonly [QUOTATIONS_FEATURE_KEY]: State;
}

export const quotationsAdapter: EntityAdapter<Portals.QuotationEntity> =
    createEntityAdapter<Portals.QuotationEntity>();

export const initialState: State = quotationsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
    query: {},
    error: 'Test',
});

const quotationsReducer = createReducer(
    initialState,
    on(QuotationsActions.loadQuotations, (state, { query }) => ({
        ...state,
        loaded: false,
        loading: true,
        error: null,
        query: {
            ...state.query,
            ...query,
        },
    })),
    on(
        QuotationsActions.loadQuotationsSuccess,
        (state, { quotations, pagination }) =>
            quotationsAdapter.setAll(quotations, {
                ...state,
                loaded: true,
                loading: false,
                pagination,
            })
    ),
    on(
        QuotationsActions.loadQuotationsFailure,
        QuotationsActions.downloadQuotationFailure,
        (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })
    ),
    on(QuotationsActions.loadQuotationSuccess, (state, { quotation }) =>
        quotationsAdapter.setOne(quotation, state)
    ),
    on(QuotationsActions.selectQuotation, (state, { id }) => ({
        ...state,
        selectedId: id,
        error: null,
    })),
    on(QuotationsActions.unselectQuotation, state => ({
        ...state,
        selectedId: null,
    })),
    on(QuotationsActions.quotationChanged, (state, { quotation }) =>
        quotationsAdapter.updateOne(quotation, state)
    ),
    on(QuotationsActions.resetQuery, state => ({
        ...state,
        query: {},
        pagination: null,
    })),
    on(QuotationsActions.dismissError, state => ({
        ...state,
        error: null,
    })),
    on(QuotationsActions.downloadQuotation, state => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(QuotationsActions.downloadQuotationSuccess, state => ({
        ...state,
        loading: false,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return quotationsReducer(state, action);
}
