import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Portals } from '@qwyk/models';
import { Observable, of } from 'rxjs';
import { environment } from '@qwyk/portals/environment';
import { map, first, switchMap } from 'rxjs/operators';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';

@Injectable({
    providedIn: 'root',
})
export class QuotationsService {
    constructor(
        private http: HttpClient,
        private siteConfig: SiteConfigFacade
    ) {}

    public getQuotation(
        id: string,
        isDemo: boolean,
        tempToken: string
    ): Observable<Portals.QuotationEntity> {
        const requestHeaders = {};
        if (isDemo) {
            requestHeaders['Authorization'] = `Bearer ${tempToken}`;
        }

        return this.http
            .get<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/portals/quotations/${id}`,
                { headers: requestHeaders }
            )
            .pipe(
                first(),
                map(response => response.data)
            );
    }

    public getQuotationOffers(
        id: string,
        isDemo = false,
        tempToken: string = null
    ): Observable<Portals.QuotationOffer[]> {
        return this.getQuotation(id, isDemo, tempToken).pipe(
            map(response => response.results as Portals.QuotationOffer[])
        );
    }

    public createQuotation(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: any,
        isDemo = false
    ): Observable<Portals.QuotationEntity> {
        const quotationRequest = injectHeaders => {
            return this.http
                .post<{ data: Portals.QuotationEntity }>(
                    `${environment.backendServer}/api/portals/quotations${
                        isDemo ? '?demo=true' : ''
                    }`,
                    payload,
                    { headers: injectHeaders }
                )
                .pipe(
                    first(),
                    map(response => response.data)
                );
        };

        return of(isDemo).pipe(
            switchMap(demo => {
                if (!demo) {
                    return quotationRequest({});
                } else {
                    return this.siteConfig.organization$.pipe(
                        switchMap(organization =>
                            this.http
                                .post<string>(
                                    `${environment.backendServer}/api/portals/auth/demo-user-token`,
                                    organization
                                )
                                .pipe(
                                    switchMap(token =>
                                        quotationRequest({
                                            Authorization: `Bearer ${token}`,
                                        }).pipe(
                                            map(quotation => {
                                                quotation.temp_token = token;
                                                return quotation;
                                            })
                                        )
                                    )
                                )
                        )
                    );
                }
            })
        );
    }
}
