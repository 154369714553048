import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@qwyk/portals/environment';
import { Portals } from '@qwyk/models';
import { Observable } from 'rxjs';
import { first, shareReplay, map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class SiteConfigService {
    constructor(private http: HttpClient) {}

    getSiteConfig(siteIdentifier: {
        identifier: string;
        type: 'endpoint' | 'cname';
    }): Observable<Portals.SiteConfig> {
        return this.http
            .get<{ data: Portals.SiteConfig }>(
                `${environment.backendServer}/api/public/siteconfig/${siteIdentifier.type}/${siteIdentifier.identifier}`
            )
            .pipe(
                first(),
                shareReplay(),
                map(response => response.data)
            );
    }
}
