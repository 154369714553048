import { Component, Input } from '@angular/core';
import {
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
} from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ObjectToKVPPipe } from '../../pipes/object-to-kvp/object-to-kvp.pipe';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        ObjectToKVPPipe,
        TranslateModule,
        FontAwesomeModule,
    ],
    selector: 'qwyk-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() error: any;
    constructor() {}
}
