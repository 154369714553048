<div class="min-vh-100 bg-light py-3">
    <div
        [ngClass]="{'container-fluid': view === 'table', 'container-lg': view === 'list'}"
        class="table-transition">

        <qwyk-quotations-index-header
            [allowCreateQuotations]="(permissions$ | async).includes(customerPermissionsEnum.CREATE_QUOTATIONS)"
        ></qwyk-quotations-index-header>

        <div *ngIf="error$ | async; let error">
            <div class="pt-3">
                <ngb-alert (closed)="onDismissError()" type="danger">
                    {{'common.generic-error-alt' | translate}}
                </ngb-alert>
            </div>
        </div>

        <qwyk-quotations-index-search
            class="mb-3 d-block"
            (search)="onSearch($event)"
            [(view)]="view"
            [loading]="loading$ | async"
            [query]="query$ | async"
        ></qwyk-quotations-index-search>

        <qwyk-quotations-index-list
            *ngIf="view === 'list'"
            [loading]="loading$ | async"
            [quotations]="quotations$ | async">
        </qwyk-quotations-index-list>

        <qwyk-quotations-index-table
            *ngIf="view === 'table'"
            [loading]="loading$ | async"
            [quotations]="quotations$ | async">
        </qwyk-quotations-index-table>

        <qwyk-pagination
            (pageChanged)="onPageChange($event)"
            *ngIf="pagination$ | async; let pagination"
            [loading]="loading$ | async"
            [pagination]="pagination"
            resource="{{ 'common.quotations.quotations' | translate | lowercase }}"
        ></qwyk-pagination>
    </div>
</div>
