import { ShipmentsService } from './../../../portal/services/shipments.service';
import { DashboardService } from './../../services/dashboard.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import * as dashboardActions from '../actions/dashboard.actions';

@Injectable()
export class DashboardEffects {
    /**
     *
     */
    constructor(
        private actions$: Actions,
        private service: DashboardService,
        private shipmentsService: ShipmentsService
    ) {}

    loadShipmentsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardActions.loadOpsData),
            switchMap(() =>
                this.service
                    .getShipmentCounts()
                    .pipe(
                        map(result =>
                            dashboardActions.shipmentsCountLoaded({ result })
                        )
                    )
            )
        )
    );

    loadSchedulesCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardActions.loadDashboardData),
            switchMap(() =>
                this.service
                    .getScheduleCounts()
                    .pipe(
                        map(result =>
                            dashboardActions.schedulesCountLoaded({ result })
                        )
                    )
            )
        )
    );

    loadShipmentsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dashboardActions.loadOpsData),
            switchMap(() =>
                this.service
                    .getShipments()
                    .pipe(
                        map(result =>
                            dashboardActions.shipmentsListLoaded({ result })
                        )
                    )
            )
        )
    );
}
