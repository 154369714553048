import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ShipmentAttachment } from '@qwyk/shared-stores/shipment-attachments';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-attachments-list-item]',
    templateUrl: './shipment-attachments-list-item.component.html',
    styleUrls: ['./shipment-attachments-list-item.component.scss'],
})
export class ShipmentAttachmentsListItemComponent {
    @Input() shipmentAttachment: ShipmentAttachment;
    @Input() disabled = false;
    @Input() canDelete = false;

    @Output()
    download: EventEmitter<ShipmentAttachment> = new EventEmitter<ShipmentAttachment>();
    @Output()
    delete: EventEmitter<ShipmentAttachment> = new EventEmitter<ShipmentAttachment>();
    @Output() preview: EventEmitter<ShipmentAttachment> =
        new EventEmitter<ShipmentAttachment>();
    @Output() openCarousel: EventEmitter<string> = new EventEmitter<string>();

    private readonly PREVIEWABLE_EXTENSIONS = [
        'jpeg',
        'jpg',
        'gif',
        'png',
        'pdf',
    ];

    private readonly THUMBNAIL_EXTENSIONS = ['jpeg', 'jpg', 'gif', 'png'];

    constructor(private modalService: NgbModal) {}

    public onDownloadAttachment() {
        this.download.emit(this.shipmentAttachment);
    }

    public onDeleteAttachment(content) {
        this.modalService.open(content, { centered: true }).result.then(
            () => {
                this.delete.emit(this.shipmentAttachment);
            },
            reason => {
                return reason;
            }
        );
    }

    public get shouldDisableDownloadButton(): boolean {
        if (
            Object.prototype.hasOwnProperty.call(
                this.shipmentAttachment, 'attachment_link'
            )
        ) {
            return !this.shipmentAttachment.attachment_link?.data?.url;
        }
        return false;
    }

    public get canShowPreview(): boolean {
        return this.PREVIEWABLE_EXTENSIONS.includes(
            this.shipmentAttachment.extension.toLowerCase()
        );
    }

    public get canShowThumbnail(): boolean {
        return this.THUMBNAIL_EXTENSIONS.includes(
            this.shipmentAttachment.extension.toLowerCase()
        );
    }

    public onPreviewAttachment(): void {
        this.preview.emit(this.shipmentAttachment);
    }

    public openGallery(): void {
        this.openCarousel.emit(`${this.shipmentAttachment.id}`);
    }
}
