<div #rootElement class="card" [ngClass]="{'lg': size === 'lg'}" [@fadeInOut]>
    <div class="card-body p-3 flex-shrink-0">
        <fa-icon [size]="size === 'lg' ? 'lg' : null" [icon]="['far', isSearching ? 'spinner' : 'map-pin']"
            [pulse]="isSearching" class="text-primary" [fixedWidth]="true"></fa-icon>
        <div class="input-group">
            <input #searchInput type="search" class="form-control" [placeholder]="inputPlaceholder"
                [ngClass]="{'form-control-lg': size === 'lg'}" [value]="selectedItemLabel"
                (keydown.Tab)="onConfirmKeys($event)" (keyup.enter)="onConfirmKeys($event)"
                (input)="autocompleteInputChanged($event)" />
            <ng-select *ngIf="false" [items]="segments" class="border-left-0" style="width: 80px;">
            </ng-select>
        </div>
    </div>

    <ul class="lta-suggestions-list list-group list-group-flush">
        <ng-container *ngIf="suggestions; else: placeholder">
            <a (click)="itemSelected(suggestion)"
                class="lta-suggestion-item list-group-item list-group-item-action flex-column align-items-start"
                *ngFor="let suggestion of suggestions">

                <ng-template [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                    [ngTemplateOutletContext]="{item: suggestion}"></ng-template>
            </a>
            <a *ngIf="suggestions.length === 0"
                class="lta-not-found-item list-group-item list-group-item-action list-group-item-warning flex-column align-items-start">
                <ng-template [ngTemplateOutlet]="notFoundTemplate || defaultNotFoundTemplate"></ng-template>
            </a>
        </ng-container>
    </ul>
</div>

<ng-template #placeholder>
    <a *ngIf="selectedItem" (click)="itemSelected(selectedItem)"
        class="lta-suggestion-placeholder list-group-item list-group-item-action flex-column align-items-start">
        <ng-template [ngTemplateOutlet]="placeholderTemplate || defaultItemTemplate"
            [ngTemplateOutletContext]="{item: selectedItem}"></ng-template>
    </a>
    <a
        class="lta-suggestion-hint list-group-item list-group-item-action flex-column align-items-start active text-light">
        <ng-template [ngTemplateOutlet]="hintTemplate || defaultHintTemplate"></ng-template>
    </a>
</ng-template>

<ng-template #defaultItemTemplate let-item="item">
    <div>{{ item }}</div>
</ng-template>

<ng-template #defaultHintTemplate>
    <div>{{ 'common.location-typeahead-hint.line1' | translate }}</div>
    <small>{{ 'common.location-typeahead-hint.line2' | translate }}</small>
</ng-template>

<ng-template #defaultNotFoundTemplate>
    <div>{{ 'common.location-typeahead-not-found.line1' | translate }}</div>
    <small>{{ 'common.location-typeahead-not-found.line2' | translate }}</small>
</ng-template>
