import { Component } from '@angular/core';
import { CommonModule, NgIf, NgSwitch } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    standalone: true,
    imports: [NgIf, NgSwitch, CommonModule, TranslateModule],
    selector: 'qwyk-leave-magaya-app-modal',
    templateUrl: './leave-magaya-app-modal.component.html',
    styleUrls: ['./leave-magaya-app-modal.component.scss'],
})
export class LeaveMagayaAppModalComponent {
    public dismissAction = 'common.cancel';
    public confirmAction = 'common.continue';
    public confirmStyle = 'btn-primary';
    public dismissStyle = null;
    public url: string;

    constructor(private modal: NgbActiveModal) {}

    public confirm() {
        window.open(this.url, '_blank');
        this.modal.close();
    }

    public dismiss() {
        this.modal.dismiss();
    }
}
