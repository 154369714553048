import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'qwyk-purchase-order-shipments',
    standalone: true,
    imports: [CommonModule, RouterLink, TranslateModule],
    templateUrl: './purchase-order-shipments.component.html',
    styleUrls: ['./purchase-order-shipments.component.css'],
})
export class PurchaseOrderShipmentsComponent {
    @Input() shipments: any[];
}
