import { Component, Input } from '@angular/core';
import { OnboardingStatusEnum } from '../../onboarding-status.enum';
import { OnboardingResponse } from '../../onboarding.response';

@Component({
    selector: 'qwyk-onboarding-banner',
    templateUrl: './onboarding-banner.component.html',
    styleUrls: ['./onboarding-banner.component.scss'],
})
export class OnboardingBannerComponent {
    @Input() onboardingStatus: OnboardingResponse | undefined;
    onboardingStatusEnum = OnboardingStatusEnum;

    constructor() {}
}
