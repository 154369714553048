/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { PortalsAuth } from '@qwyk/models';
import { CustomerPermissions } from '@qwyk/core';

export const initializeAuthenticationState = createAction(
    '[Portals/Authentication] Initialize Authentication State'
);
export const initializeAuthenticationStateWithToken = createAction(
    '[Portals/Authentication] Initialize Auth State With Token',
    props<{ token: string }>()
);
export const initializeAuthenticationStateWithoutToken = createAction(
    '[Portals/Authentication] Initialize Auth State Without Token'
);
export const newTokenReceived = createAction(
    '[Portals/Authentication] New Token Received',
    props<{ token: PortalsAuth.Token }>()
);

export const loadUser = createAction('[Portals/Authentication] Load User');
export const loadUserSuccess = createAction(
    '[Portals/Authentication] Load User Success',
    props<{ user: PortalsAuth.User }>()
);
export const loadUserFailure = createAction(
    '[Portals/Auhentication] Load User Failure',
    props<{ error: any }>()
);

export const refreshToken = createAction(
    '[Portals/Authentication] Refresh Token'
);
export const refreshTokenFailure = createAction(
    '[Portals/Authentication] Refresh Token Failure',
    props<{ error: any }>()
);

export const loginWithCredentials = createAction(
    '[Portals/Authentication] Log In with Credentials',
    props<{ credentials: PortalsAuth.Credentials }>()
);
export const loginWithCredentialsSuccess = createAction(
    '[Portals/Authentication] Log In with Credentials Success',
    props<{ token: PortalsAuth.Token }>()
);
export const loginWithCredentialsFailure = createAction(
    '[Portals/Authentication] Log In with Credentials Failure',
    props<{ error: any }>()
);

export const logout = createAction('[Portals/Authentication] Logout');
export const logoutSuccess = createAction(
    '[Portals/Authentication] Logout Success'
);

export const reset = createAction('[Portals/Authentication] Reset');
export const setShouldRedirectOnLogout = createAction(
    '[Portals/Authentication] Set Should Redirect on Logout',
    props<{ redirectOnLogout: boolean }>()
);

export const loadPermissions = createAction(
    '[Portals/Permissions] Load Permissions'
);

export const loadPermissionsSuccess = createAction(
    '[Portals/Permissions] Load Permissions Success',
    props<{ permissions: CustomerPermissions[] }>()
);

export const loadPermissionsFailure = createAction(
    '[Portals/Permissions] Load Permissions Failure',
    props<{ error: any }>()
);
