import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { common } from '@qwyk/models';
import { environment } from '@qwyk/portals/environment';

@Injectable({
    providedIn: 'root',
})
export class CustomViewsService {
    constructor(private http: HttpClient) {}

    private _getResourceUrl(resource: string): string {
        switch (resource) {
            case 'shipment':
                return 'shipments/v2';
            case 'warehouse-receipt':
                return 'warehouse/warehouse-receipts';
            case 'cargo-item':
                return 'warehouse/cargo-items';
        }
        return '';
    }

    public createCustomView(
        parent: string,
        request: Partial<common.CustomView>
    ): Observable<common.CustomView> {
        return this.http
            .post<common.CustomView>(
                `${
                    environment.backendServer
                }/api/portals/${this._getResourceUrl(parent)}/custom-views`,
                { ...request, parent }
            )
            .pipe(take(1));
    }

    public updateCustomView(
        parent: string,
        request: Partial<common.CustomView>
    ): Observable<common.CustomView> {
        return this.http
            .patch<common.CustomView>(
                `${
                    environment.backendServer
                }/api/portals/${this._getResourceUrl(parent)}/custom-views/${
                    request.uid
                }`,
                { ...request, parent }
            )
            .pipe(take(1));
    }

    public deleteCustomView(
        parent: string,
        request: Partial<common.CustomView>
    ): Observable<void> {
        return this.http
            .delete<void>(
                `${
                    environment.backendServer
                }/api/portals/${this._getResourceUrl(parent)}/custom-views/${
                    request.uid
                }`
            )
            .pipe(take(1));
    }
}
