import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationFacade } from '../state/authentication.facade';

@Injectable({
    providedIn: 'root',
})
export class CheckTrialExpiredGuard implements CanActivate, CanActivateChild {
    constructor(
        private authentication: AuthenticationFacade,
        private router: Router
    ) {}

    canActivate(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.authentication.user$.pipe(
            map(u => u.is_trial_expired),
            tap(isTrialExpired => {
                if (isTrialExpired) {
                    return this.router.navigate(['/trial-expired']);
                }
            }),
            map(trialExpired => !trialExpired)
        );
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }
}
