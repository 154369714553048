import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromQuotations from './state/quotations.reducer';
import { QuotationsEffects } from './state/quotations.effects';
import { QuotationsFacade } from './state/quotations.facade';
import { EffectsModule } from '@ngrx/effects';
import { QuotationExistsGuard } from './guards/quotation-exists.guard';

export interface SharedStoresQuotationsConfig {
    service: Provider;
}

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromQuotations.QUOTATIONS_FEATURE_KEY,
            fromQuotations.reducer
        ),
        EffectsModule.forFeature([QuotationsEffects]),
    ],
    providers: [QuotationsFacade, QuotationExistsGuard],
})
export class SharedStoresQuotationsModule {
    static forFeature(
        config: SharedStoresQuotationsConfig
    ): ModuleWithProviders<SharedStoresQuotationsModule> {
        return {
            ngModule: SharedStoresQuotationsModule,
            providers: [config.service],
        };
    }
}
