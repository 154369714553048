import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import * as fromShipmentTrackingEvents from './+state/shipment-tracking-events.reducer';
import {ShipmentTrackingEventsEffects} from './+state/shipment-tracking-events.effects';
import {ShipmentTrackingEventsFacade} from './+state/shipment-tracking-events.facade';
import {EffectsModule} from "@ngrx/effects";

export interface SharedStoresShipmentTrackingEventsConfig {
    service: Provider
}

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromShipmentTrackingEvents.SHIPMENTTRACKINGEVENTS_FEATURE_KEY,
            fromShipmentTrackingEvents.reducer
        ),
        EffectsModule.forFeature([ShipmentTrackingEventsEffects])
    ],
    providers: [
        ShipmentTrackingEventsFacade
    ]
})
export class SharedStoresShipmentTrackingEventsModule {

    static forFeature(
        config: SharedStoresShipmentTrackingEventsConfig
    ): ModuleWithProviders<SharedStoresShipmentTrackingEventsModule> {
        return {
            ngModule: SharedStoresShipmentTrackingEventsModule,
            providers: [config.service]
        }
    }


}
