import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PortalsQuotationsWithoutRoutingModule} from './portals-quotations-without-routing.module';
import {
    QuotationExistsGuard,
    QuotationsServiceBase,
    SharedStoresQuotationsModule
} from '@qwyk/shared-stores/quotations';
import {QuotationsService} from './services/quotations.service';
import {HttpClient} from '@angular/common/http';
import {VerifyAllowedPermissions} from '@qwyk/portals/authentication';
import {CustomerPermissions} from '@qwyk/core';
import {QuotationDetailPageComponent, QuotationsIndexPageComponent} from './pages';

export function quotationsServiceFactory(
    http: HttpClient
): QuotationsServiceBase {
    return new QuotationsService(http);
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                component: QuotationsIndexPageComponent,
                pathMatch: 'full',
                canActivate: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [
                        CustomerPermissions.VIEW_QUOTATIONS
                    ],
                },
            },
            {
                path: ':id',
                component: QuotationDetailPageComponent,
                canActivate: [QuotationExistsGuard, VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [
                        CustomerPermissions.VIEW_QUOTATIONS,
                    ],
                },
            },
        ]),
        SharedStoresQuotationsModule.forFeature({
            service: {
                provide: QuotationsServiceBase,
                useFactory: quotationsServiceFactory,
                deps: [HttpClient],
            },
        }),
        PortalsQuotationsWithoutRoutingModule,
    ],
    declarations: [],
    providers: [QuotationsService],
    exports: [],
})
export class PortalsQuotationsModule {}
