import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {common} from '@qwyk/models';

import * as ActivitiesActions from './activities.actions';
import {ActivitiesEntity, ActivitiesIndexQuery, HasActivities} from './activities.models';

export const ACTIVITIES_FEATURE_KEY = 'activities';

export interface State extends EntityState<ActivitiesEntity> {
    selectedId?: string | number; // which Activities record has been selected
    loading: boolean; // has the Activities list been loaded
    saving: boolean; // has the Activities list been loaded
    error?: string | null; // last known error (if any)
    pagination?: common.CursorPaginationMeta;
    query?: ActivitiesIndexQuery;
    parent?: HasActivities;
}

export interface ActivitiesPartialState {
    readonly [ACTIVITIES_FEATURE_KEY]: State;
}

export const activitiesAdapter: EntityAdapter<ActivitiesEntity> =
    createEntityAdapter<ActivitiesEntity>({
        sortComparer: (a: ActivitiesEntity, b: ActivitiesEntity) => b.id.localeCompare(a.id)
    });

export const initialState: State = activitiesAdapter.getInitialState({
    // set initial required properties
    loading: false,
    saving: false
});

const activitiesReducer = createReducer(
    initialState,
    on(ActivitiesActions.loadActivities, (state, {parent, query}) => ({
        ...state,
        loading: true,
        error: null,
        query,
        parent
    })),
    on(ActivitiesActions.createComment, ActivitiesActions.updateComment, (state) => ({
        ...state,
        saving: true,
        error: null,
    })),
    on(
        ActivitiesActions.loadMoreActivities,
        ActivitiesActions.refreshActivities,
        ActivitiesActions.deleteComment,
        (state) => ({
            ...state,
            loading: true,
            error: null,
        })
    ),
    on(ActivitiesActions.loadActivitiesSuccess, (state, {activities, pagination}) =>
        activitiesAdapter.setAll(activities, {...state, loading: false, pagination})
    ),
    on(ActivitiesActions.loadMoreActivitiesSuccess, (state, {activities, pagination}) =>
        activitiesAdapter.addMany(activities, {...state, loading: false, pagination})
    ),
    on(ActivitiesActions.createCommentSuccess, (state, {activity}) =>
        activitiesAdapter.addOne(activity, {...state, saving: false})
    ),
    on(ActivitiesActions.updateCommentSuccess, (state, {activity}) =>
        activitiesAdapter.setOne(activity, {...state, saving: false})
    ),
    on(ActivitiesActions.deleteCommentSuccess, (state, {activity}) =>
        activitiesAdapter.removeOne(activity.id, {...state, loading: false})
    ),
    on(
        ActivitiesActions.loadActivitiesFailure,
        ActivitiesActions.createCommentFailure,
        ActivitiesActions.updateCommentFailure,
        ActivitiesActions.deleteCommentFailure,
        (state, {error}) => ({
            ...state,
            loading: false,
            saving: false,
            error,
        })
    ),
    on(ActivitiesActions.resetActivities, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
    return activitiesReducer(state, action);
}
