import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, catchError, switchMap, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromCustomViews from './custom-views.reducer';
import { CustomViewsActions } from './custom-views.actions';
import { CustomViewsService } from '../custom-views-service';

@Injectable()
export class CustomViewsEffects {
    createCustomView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomViewsActions.createCustomView),
            switchMap(action =>
                this.service
                    .createCustomView(action.parent, action.request)
                    .pipe(
                        map(customView =>
                            CustomViewsActions.createCustomViewSuccess({
                                custom_view: customView,
                            })
                        )
                    )
            ),
            catchError(error => {
                return of(
                    CustomViewsActions.createCustomViewFailure({ error })
                );
            })
        )
    );

    updateCustomView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomViewsActions.updateCustomView),
            exhaustMap(action =>
                this.service
                    .updateCustomView(action.parent, action.request)
                    .pipe(
                        map(customView =>
                            CustomViewsActions.updateCustomViewSuccess({
                                custom_view: customView,
                            })
                        )
                    )
            ),
            catchError(error => {
                return of(
                    CustomViewsActions.updateCustomViewFailure({ error })
                );
            })
        )
    );

    deleteCustomView$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomViewsActions.deleteCustomView),
            switchMap(action =>
                this.service
                    .deleteCustomView(action.parent, action.request)
                    .pipe(
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        map(_ => {
                            return CustomViewsActions.deleteCustomViewSuccess({
                                custom_view: action.request,
                            });
                        })
                    )
            ),
            catchError(error => {
                return of(
                    CustomViewsActions.deleteCustomViewFailure({ error })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private service: CustomViewsService,
        private store: Store<fromCustomViews.CustomViewsState>
    ) {}
}
