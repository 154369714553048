import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SHIPMENTTRACKINGEVENTS_FEATURE_KEY,
    State,
    ShipmentTrackingEventsPartialState,
    shipmentTrackingEventsAdapter,
} from './shipment-tracking-events.reducer';

// Lookup the 'ShipmentTrackingEvents' feature state managed by NgRx
export const getShipmentTrackingEventsState = createFeatureSelector<
    ShipmentTrackingEventsPartialState,
    State
>(SHIPMENTTRACKINGEVENTS_FEATURE_KEY);

const {
    selectAll,
    selectEntities,
} = shipmentTrackingEventsAdapter.getSelectors();

export const getShipmentTrackingEventsLoading = createSelector(
    getShipmentTrackingEventsState,
    (state: State) => state.loading
);

export const getShipment = createSelector(
    getShipmentTrackingEventsState,
    (state: State) => state.shipment
);

export const getShipmentTrackingEventsError = createSelector(
    getShipmentTrackingEventsState,
    (state: State) => state.error
);

export const getAllShipmentTrackingEvents = createSelector(
    getShipmentTrackingEventsState,
    (state: State) => selectAll(state)
);

export const getShipmentTrackingEventsEntities = createSelector(
    getShipmentTrackingEventsState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getShipmentTrackingEventsState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getShipmentTrackingEventsEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);
