<ng-container *ngIf="expanded">
    <li class="list-group-item text-center text-muted" *ngIf="loadingSchedules === true">
        <fa-icon [icon]="['far', 'spinner']" [pulse]="true" class="mr-1"></fa-icon>
        Checking with the carrier for the next available options.
    </li>
    <ng-container *ngIf="schedules$ | async; let schedules">
        <li class="list-group-item text-center" [@fadeIn] *ngIf="schedules.length === 0 && allowBooking">
            <div>
                We couldn't find schedules for this offer at this time.
            </div>
            <div>
                You can continue your booking without a schedule.
            </div>
            <button class="btn btn-primary btn-sm mt-2" (click)="onCreateBooking()"
                [disabled]="indexBeingBooked !== null">
                Book without schedule
                <fa-icon [icon]="['far', 'arrow-right']" class="ml-1"></fa-icon>
            </button>
        </li>
        <li class="list-group-item text-center" [@fadeIn] *ngIf="!allowBooking">
            <div>
                This is a demo quote. You must create a quote while logged in to view schedules and create bookings.
            </div>
        </li>
        <li class="list-group-item small font-weight-bold pb-1 border-0 border-bottom" *ngIf="schedules.length > 0">
            <div class="form-row">
                <div>
                    <fa-icon [icon]="['far', 'search']" style="opacity: 0;" [fixedWidth]="true" class="text-muted mr-1">
                    </fa-icon>
                </div>
                <div class="col-4">Vessel / Voyage</div>
                <div class="col">Closing</div>
                <div class="col">E.T.D.</div>
                <div class="col">E.T.A.</div>
                <div class="col"></div>
            </div>
        </li>
        <li class="list-group-item small border-0"
            *ngFor="let schedule of schedules  | slice: (schedulesPage-1) * schedulesPageSize : (schedulesPage-1) * schedulesPageSize + schedulesPageSize; let idx = index;">
            <div class="form-row justify-content-between align-items-center">
                <div>
                    <fa-icon [icon]="['far', 'search']" [fixedWidth]="true" class="text-muted mr-1"></fa-icon>
                </div>
                <div class="col-4">{{ schedule.vessel }} {{ schedule.voyage }}</div>
                <div class="col">{{ schedule.closing | amDateFormat: 'L'  }}</div>
                <div class="col">{{ schedule.etd | amDateFormat: 'L' }}</div>
                <div class="col">{{ schedule.eta | amDateFormat: 'L'  }}</div>
                <div class="col text-right">
                    <button class="btn btn-secondary btn-sm py-0" (click)="onCreateBooking(idx, schedule)"
                        [disabled]="indexBeingBooked !== null">
                        Book
                        <fa-icon [icon]="['far', indexBeingBooked !== idx ? 'angle-right' : 'spinner']"
                            [fixedWidth]="true" [pulse]="indexBeingBooked===idx" class="ml-1"></fa-icon>
                    </button>
                </div>
            </div>
        </li>
        <li class="list-group-item border-top-0" *ngIf="schedules.length > schedulesPageSize">
            <ngb-pagination [(page)]="schedulesPage" [pageSize]="schedulesPageSize" size="sm" [maxSize]="5"
                [disabled]="indexBeingBooked !== null" [rotate]="true" [ellipses]="false"
                [collectionSize]="schedules.length" class="d-flex justify-content-center">
            </ngb-pagination>
        </li>
    </ng-container>
</ng-container>
