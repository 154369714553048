const colorsWithDefault = {
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#dc3545',
    light: '#f8f9fa',
    dark: '#343a40',
};

export const CUSTOMIZABLE_COLORS_ARRAY = Object.keys(colorsWithDefault);
export const CUSTOMIZABLE_COLORS_DEFAULTS = colorsWithDefault;

export const AVAILABLE_FONTS = [
    'Roboto',
    'Arial',
    'Helvetica',
    'Times New Roman',
    'Times',
    'Courier New',
    'Courier',
    'Verdana',
    'Georgia',
    'Palatino',
    'Garamond',
    'Bookman',
    'Trebuchet MS',
    'Arial Black',
    'Impact',
    'Open Sans',
].sort((a, b) => (a > b ? 1 : -1));

export const FONT_WEIGHTS = [
    { title: '100', value: 100 },
    { title: '200', value: 200 },
    { title: '300', value: 300 },
    { title: '400 (normal)', value: 400 },
    { title: '500', value: 500 },
    { title: '600', value: 600 },
    { title: '700 (bold)', value: 700 },
    { title: '800', value: 800 },
    { title: '900', value: 900 },
];

export const LOCATION_TYPES = [
    { label: 'common.port-airport-terminal', value: 'port' },
    { label: 'common.door', value: 'place' },
];

export const ADDITIONALS = [
    {
        value: 'require_origin_charges',
        label: 'hub.administration.sites.additionals.origin-charges-required',
    },
    {
        value: 'require_destination_charges',
        label: 'hub.administration.sites.additionals.desination-charges-required',
    },
    {
        value: 'customs_clearance_origin',
        label: 'hub.administration.sites.additionals.origin-customs-clearance',
    },
    {
        value: 'customs_clearance_destination',
        label: 'hub.administration.sites.additionals.destination-customs-clearance',
    },
    {
        value: 'cargo_insurance',
        label: 'hub.administration.sites.additionals.insurance',
    },
    {
        value: 'hazardous_cargo',
        label: 'hub.administration.sites.additionals.hazardous',
    },
    {
        value: 'liftgate_pickup',
        label: 'hub.administration.sites.additionals.pickup-liftgate',
    },
    {
        value: 'refrigerated',
        label: 'hub.administration.sites.additionals.refrigerated',
    },
    {
        value: 'white_glove',
        label: 'hub.administration.sites.additionals.white-glove',
    },
    {
        value: 'residential_delivery',
        label: 'hub.administration.sites.additionals.delivery-residential',
    },
    {
        value: 'residential_pickup',
        label: 'hub.administration.sites.additionals.pickup-residential',
    },
    {
        value: 'liftgate_delivery',
        label: 'hub.administration.sites.additionals.delivery-liftgate',
    },
    {
        value: 'call_before_delivery',
        label: 'hub.administration.sites.additionals.delivery-call-before',
    },
    {
        value: 'delivery_appointment_required',
        label: 'hub.administration.sites.additionals.delivery-appointment',
    },
    {
        value: 'flatbed_required',
        label: 'hub.administration.sites.additionals.flatbed-required',
    },
    {
        value: 'limited_access_delivery',
        label: 'hub.administration.sites.additionals.delivery-limited-access',
    },
    {
        value: 'limited_access_pickup',
        label: 'hub.administration.sites.additionals.pickup-limited-access',
    },
    {
        value: 'stackable',
        label: 'hub.administration.sites.additionals.stackable',
    },
];
