import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { OnboardingNoticeComponent } from './components/onboarding-notice/onboarding-notice.component';
import { OnboardingBannerComponent } from './components/onboarding-banner/onboarding-banner.component';
import { FileTransferAnimationComponent } from './components/file-transfer-animation/file-transfer-animation.component';

@NgModule({
    imports: [CommonModule, NgbAlertModule, TranslateModule, FontAwesomeModule],
    declarations: [
        OnboardingNoticeComponent,
        OnboardingBannerComponent,
        FileTransferAnimationComponent,
    ],
    exports: [OnboardingNoticeComponent, OnboardingBannerComponent],
})
export class PortalsOnboardingModule {}
