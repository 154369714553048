<div class="p-4 text-center">
    <img src="https://cdn.qwyk.io/portals/assets/welcomegraphic.png" alt="Welcome!" class="w-75">
</div>
<div class="modal-header px-4 pt-0 d-flex flex-column align-items-center justify-content-center border-bottom-0">
    <p class="lead text-muted">Here are some ways to get started with your very own portal</p>
</div>
<div class="modal-body px-4 py-4 text-center">
    <div class="row">
        <div class="col">
            <div class="card border-0 hover-card-all" (click)="startTour()">
                <div class="card-body">
                    <fa-icon [icon]="['fad', 'bus-alt']" class="text-primary" size="4x" fixedWidth="true"></fa-icon>
                    <h5 class="card-title mb-0 mt-3 text-dark">Guided tour</h5>
                    <p class="card-text text-muted">We'll take you through the app</p>
                </div>
            </div>
        </div>

        <div class="col">
            <a class="card border-0 hover-card-all" href="https://docs.qwykportals.com/getting-started" target="_blank">
                <div class="card-body">
                    <fa-icon [icon]="['fad', 'books']" class="text-success" size="4x" fixedWidth="true"></fa-icon>
                    <h5 class="card-title mb-0 mt-3 text-dark">Online guide</h5>
                    <p class="card-text text-muted">Read our tips on getting started</p>
                </div>
            </a>
        </div>

        <div class="col">
            <a class="card border-0 hover-card-all" href="https://docs.qwykportals.com/features" target="_blank">
                <div class="card-body">
                    <fa-icon [icon]="['fad', 'rocket']"  class="text-danger" size="4x" fixedWidth="true"></fa-icon>
                    <h5 class="card-title mb-0 mt-3 text-dark">Features</h5>
                    <p class="card-text text-muted">Browse through our amazing features</p>
                </div>
            </a>
        </div>
    </div>
</div>
<div class="modal-footer px-4 pb-4 border-top-0 d-flex flex-column align-items-center justify-content-center">
    <button class="btn btn-link" (click)="modal.dismiss()">
        No thanks, just let me dive right in<fa-icon [icon]="['far', 'swimmer']" class="ml-2"></fa-icon>
    </button>
    <span class="small text-muted">
        You can come back to this menu at any time by clicking the Welcome option in Help
    </span>
</div>
