import { Injectable } from '@angular/core';
import { Observable, of, Subscription, timer } from 'rxjs';
import {
    delay,
    first,
    map,
    shareReplay,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PortalsAuth } from '@qwyk/models';
import { environment } from '@qwyk/portals/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationFacade } from '../state/authentication.facade';
import * as moment from 'moment';

export const LOCALSTORAGE_TOKEN_KEY = 'id_token';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private jwtHelper = new JwtHelperService();
    private refreshTokenSubscription: Subscription;

    constructor(
        private http: HttpClient,
        private authentication: AuthenticationFacade
    ) {}

    // Token Management

    storeTokenInLocalStorage(token: PortalsAuth.Token): Observable<void> {
        return of(token).pipe(
            first(),
            tap(() => {
                localStorage.setItem(
                    LOCALSTORAGE_TOKEN_KEY,
                    token.access_token
                );
            }),
            map(() => null)
        );
    }

    fetchTokenFromLocalStorage(
        hookTokenRefresh = false
    ): Observable<string | null> {
        return of(null).pipe(
            first(),
            map(() => {
                const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);

                // No token found.
                if (!token) {
                    return null;
                }

                if (this.jwtHelper.isTokenExpired(token)) {
                    // Token exists but has expired
                    // localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
                    // !!! DON"T REMOVE THE TOKEN OR THE SESSION INVALIDATION WONT WORK ANYMORE
                    return null;
                }

                if (hookTokenRefresh) {
                    this.hookTokenRefresh();
                }

                return token;
            })
        );
    }

    removeTokenFromLocalStorage(): Observable<void> {
        return of(null).pipe(
            map(() => {
                localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
            })
        );
    }

    // tslint:disable: no-console
    hookTokenRefresh(immediately = false) {
        const checkInterval = 4 * 6e4;
        const expirationLeeway = 4.5 * 60; // 4.5

        console.log('[Portals/Authentication] New token refresh hook created');
        this.refreshTokenSubscription = timer(immediately ? 1 : checkInterval)
            .pipe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                switchMap(_ => this.fetchTokenFromLocalStorage(false)),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                tap(_ => console.log('[Portals/Authentication] Check token')),
                map(token => {
                    if (!token) {
                        return null;
                    }
                    const expiresAt = moment(
                        this.jwtHelper.getTokenExpirationDate(token)
                    );
                    console.log(
                        '[Portals/Authentication] Token expires',
                        expiresAt.fromNow(),
                        'at',
                        expiresAt.toISOString()
                    );
                    return expiresAt.isSameOrBefore(
                        moment().add(expirationLeeway, 'seconds')
                    );
                }),
                take(1)
            )
            .subscribe(
                expiresSoon => {
                    if (expiresSoon === null) {
                        return;
                    }
                    if (expiresSoon) {
                        // Token will expire soon, refresh it.
                        console.log(
                            '[Portals/Authentication] Token expires within the next window or soon after, we will refresh it'
                        );
                        this.authentication.refreshToken();
                    } else {
                        // Token is good, check for another cycle
                        console.log(
                            '[Portals/Authentication] Token is fine, it does not expire soon or within the next window.'
                        );
                        // this.refreshTokenSubscription.unsubscribe();
                        this.hookTokenRefresh();
                        return;
                    }
                },
                e => {
                    console.error(e);
                },
                () => {
                    console.log(
                        '[Portals/Authentication] Previous token refresh hook completed'
                    );
                }
            );
    }

    unhookTokenRefresh() {
        if (this.refreshTokenSubscription) {
            this.refreshTokenSubscription.unsubscribe();
        }
    }

    // Server calls
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getUser(): Observable<any> {
        return this.http
            .get<PortalsAuth.User>(
                `${environment.backendServer}/api/portals/auth/me`
            )
            .pipe(first());
    }

    refreshToken(): Observable<PortalsAuth.Token> {
        return this.http
            .post<PortalsAuth.Token>(
                `${environment.backendServer}/api/portals/auth/refresh`,
                null
            )
            .pipe(first());
    }

    logoutFromServer(): Observable<void> {
        return this.http
            .post<void>(
                `${environment.backendServer}/api/portals/auth/logout`,
                null
            )
            .pipe(first());
    }

    loginWithCredentials(
        credentials: PortalsAuth.Credentials
    ): Observable<PortalsAuth.Token> {
        return of(delay(1000)).pipe(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap(_ =>
                this.http
                    .post<PortalsAuth.Token>(
                        `${environment.backendServer}/api/portals/auth/login`,
                        credentials,
                        {
                            observe: 'response',
                        }
                    )
                    .pipe(
                        map(response => response.body),
                        shareReplay()
                    )
            )
        );
    }
}
