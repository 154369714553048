import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Validators } from 'angular-reactive-validation';

@Component({
    selector: 'qwyk-shipment-activities-comment-form',
    templateUrl: './shipment-activities-comment-form.component.html',
    styleUrls: ['./shipment-activities-comment-form.component.scss'],
})
export class ShipmentActivitiesCommentFormComponent {
    @Output() createComment: EventEmitter<{
        comment: string;
    }> = new EventEmitter<{ comment: string }>();
    public form: FormGroup;

    constructor(fb: FormBuilder, private translate: TranslateService) {
        this.form = fb.group({
            comment: [
                null,
                [
                    Validators.required(
                        this.translate.instant(
                            'portal.shipment.page.activities.enter-your-comment'
                        )
                    ),
                    Validators.maxLength(500, maxLength =>
                        this.translate.instant('common.validation.maxLength', {
                            maxLength,
                        })
                    ),
                ],
            ],
        });
    }

    private _disabled: boolean;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
        if (value) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.invalid) return;
        this.createComment.emit(this.form.value);
        this.form.reset();
    }
}
