import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UiModule } from '@qwyk/ui';

import { LinebreaksPipe } from './pipes/linebreaks.pipe';
import { DecimalToDMSPipe } from './pipes/decimal-to-dms.pipe';
import { ObjectToDatePipe } from './pipes/object-to-date.pipe';

import { PartyAddressComponent } from './components/party-address/party-address.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';

@NgModule({
    declarations: [
        DecimalToDMSPipe,
        ObjectToDatePipe,
        LinebreaksPipe,
        LoadingIndicatorComponent,
        PartyAddressComponent,
    ],
    imports: [
        UiModule,
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
    ],
    exports: [
        DecimalToDMSPipe,
        ObjectToDatePipe,
        LinebreaksPipe,
        LoadingIndicatorComponent,
        PartyAddressComponent,
    ],
})
export class SharedModule {}
