import { Component } from '@angular/core';
import { NotificationsFacade } from '../../state/notifications.facade';
import { NotificationsEntity } from '../../state/notifications.models';

@Component({
    selector: 'qwyk-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
    public notifications$ = this.storeFacade.allNotifications$;
    public loaded$ = this.storeFacade.loaded$;
    public selectedNotification$ = this.storeFacade.selectedNotifications$;
    public canLoadPage$ = this.storeFacade.canLoadMoreNotifications$;
    public paginationInfo$ = this.storeFacade.paginationInfo$;
    public loadingPage$ = this.storeFacade.isLoadingPage$;
    public unread$ = this.storeFacade.unreadCount$;

    constructor(private storeFacade: NotificationsFacade) {
        storeFacade.loadNotifications();
    }

    onView(notification: NotificationsEntity) {
        this.storeFacade.toggleNotificationSelected(notification);
    }

    onDelete(notification: NotificationsEntity) {
        this.storeFacade.deleteNotification(notification);
    }

    onToggleRead(notification: NotificationsEntity) {
        this.storeFacade.toggleNotificationRead(notification);
    }

    onLoadMore() {
        this.storeFacade.loadMoreNotifications();
    }
}
