<div class="vh-100 p-5">
    <div class="container">
        <div class="lead border-bottom pb-3 text-muted">
            <fa-icon [icon]="['fad', 'exclamation-triangle']" class="mr-3 text-warning" size="lg"></fa-icon>Page not
            found
        </div>
        <div class="inner border-bottom">
            <div class="my-5">
                <h5>What went wrong?</h5>
                <p class="">We couldn't find the page you were looking for.</p>
            </div>
            <div class="pb-5">
                <h5>What can I do?</h5>
                <ul class="text-muted" style="padding-inline-start: 1em;">
                    <li>Check the address in your browser's addressbar.</li>
                    <li>Press the back button on your browser.</li>
                    <li><a href="javascript:history.back();">Return to the previous page.</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>