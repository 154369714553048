import { createReducer, on, Action } from '@ngrx/store';

import * as SiteConfigActions from './site-config.actions';
import { Portals } from '@qwyk/models';

export const SITECONFIG_FEATURE_KEY = 'siteConfig';

export interface State {
    config: Portals.SiteConfig;
    loaded: boolean; // has the SiteConfig list been loaded
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any; // last none error (if any)
}

export interface SiteConfigPartialState {
    readonly [SITECONFIG_FEATURE_KEY]: State;
}

export const initialState: State = {
    config: null,
    loaded: false,
    error: null,
};

const siteConfigReducer = createReducer(
    initialState,
    on(SiteConfigActions.loadSiteConfig, state => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(SiteConfigActions.loadSiteConfigSuccess, (state, { siteConfig }) => ({
        ...state,
        loaded: true,
        error: null,
        config: siteConfig,
    })),
    on(SiteConfigActions.loadSiteConfigFailure, (state, { error }) => ({
        ...state,
        error,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return siteConfigReducer(state, action);
}
