<div class="card shadow-sm">
    <div class="card-header bg-white p-lg-3 border-0">
        <h5 class="font-weight-normal m-0">
            {{'portal.purchase-orders.booked-shipments' | translate}}
        </h5>
    </div>
    <ul class="list-group list-group-flush">
        <li class="list-group-item p-lg-3">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <label class="font-weight-bold m-0">{{'common.entities.shipment' | translate}}</label>
                </div>
                <div class="col-12 col-lg-4">
                    <label class="font-weight-bold m-0">{{'common.status' | translate}}</label>
                </div>
            </div>
        </li>
        <li class="list-group-item p-lg-3" *ngFor="let shipment of shipments">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <a [routerLink]="['/shipments', shipment.id]">
                        {{ shipment.name }}
                    </a>
                </div>
                <div class="col-12 col-lg-4">
                    {{ shipment.status }}
                </div>
            </div>
        </li>
    </ul>
</div>
