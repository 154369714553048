import { Injectable } from '@angular/core';

import { Action, select, Store } from '@ngrx/store';

import { PortalsAuth } from '@qwyk/models';

import * as fromAuthentication from './authentication.reducer';
import * as AuthenticationActions from './authentication.actions';
import * as AuthenticationSelectors from './authentication.selectors';

@Injectable()
export class AuthenticationFacade {
    public token$ = this.store.pipe(select(AuthenticationSelectors.getToken));
    public userAndToken$ = this.store.pipe(
        select(AuthenticationSelectors.getUserAndToken)
    );
    public authenticated$ = this.store.pipe(
        select(AuthenticationSelectors.getIsAuthenticated)
    );
    public user$ = this.store.pipe(select(AuthenticationSelectors.getUser));
    public error$ = this.store.pipe(select(AuthenticationSelectors.getError));
    public authenticating$ = this.store.pipe(
        select(AuthenticationSelectors.getIsAuthenticating)
    );
    public redirectOnLogout$ = this.store.pipe(
        select(AuthenticationSelectors.getShouldRedirectOnLogout)
    );
    public permissionsLoaded$ = this.store.pipe(
        select(AuthenticationSelectors.getPermissionsLoaded)
    );

    public permissions$ = this.store.pipe(
        select(AuthenticationSelectors.getPermissions)
    );

    constructor(
        private store: Store<fromAuthentication.AuthenticationPartialState>
    ) {}

    public initializeAuthenticationState() {
        this.dispatch(AuthenticationActions.initializeAuthenticationState());
    }

    public refreshToken() {
        this.dispatch(AuthenticationActions.refreshToken());
    }

    public tokenReceived(token: PortalsAuth.Token) {
        this.dispatch(AuthenticationActions.newTokenReceived({ token }));
    }

    public login(creds: { email: string; password: string }) {
        const credentials = {
            ...creds,
            organization_id: null,
            site_id: null,
        };
        this.dispatch(
            AuthenticationActions.loginWithCredentials({ credentials })
        );
    }

    public logout() {
        this.dispatch(AuthenticationActions.logout());
    }

    public reset() {
        this.dispatch(AuthenticationActions.reset());
    }

    public setShouldRedirectOnLogout(redirectOnLogout: boolean) {
        this.dispatch(
            AuthenticationActions.setShouldRedirectOnLogout({
                redirectOnLogout,
            })
        );
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
