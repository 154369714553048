import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromNotifications from './notifications.reducer';
import * as NotificationsSelectors from './notifications.selectors';
import * as NotificationsActions from './notifications.actions';
import { NotificationsEntity } from './notifications.models';
import { first } from 'rxjs/operators';

@Injectable()
export class NotificationsFacade {
    loaded$ = this.store.pipe(
        select(NotificationsSelectors.getNotificationsLoaded)
    );
    allNotifications$ = this.store.pipe(
        select(NotificationsSelectors.getAllNotifications)
    );
    selectedNotifications$ = this.store.pipe(
        select(NotificationsSelectors.getSelected)
    );
    paginationInfo$ = this.store.pipe(
        select(NotificationsSelectors.getPaginationInfo)
    );
    canLoadMoreNotifications$ = this.store.pipe(
        select(NotificationsSelectors.canLoadPage)
    );

    isLoadingPage$ = this.store.pipe(
        select(NotificationsSelectors.loadingPage)
    );

    unreadCount$ = this.store.pipe(
        select(NotificationsSelectors.getUnreadCount)
    );

    constructor(
        private store: Store<fromNotifications.NotificationsPartialState>
    ) {}

    public loadNotifications(): void {
        this.dispatch(NotificationsActions.loadNotifications());
    }

    public loadUnreadCount(): void {
        this.dispatch(NotificationsActions.loadUnreadNotifications());
    }

    public deleteNotification(notification: NotificationsEntity) {
        this.dispatch(
            NotificationsActions.deleteNotification({ notification })
        );
    }

    public toggleNotificationRead(notification: NotificationsEntity) {
        this.dispatch(
            NotificationsActions.toggleNotificationRead({
                notification: {
                    id: notification.id,
                    changes: { read: !notification.read },
                },
            })
        );
    }

    public toggleNotificationSelected(notification: NotificationsEntity) {
        this.selectedNotifications$
            .pipe(first())
            .subscribe(selectedNotification => {
                let action = null;
                if (
                    selectedNotification &&
                    selectedNotification.id === notification.id
                ) {
                    action = NotificationsActions.unselectNotification();
                } else {
                    action = NotificationsActions.selectNotification({
                        notification,
                    });
                }

                this.dispatch(action);
            });
    }

    public loadMoreNotifications() {
        this.dispatch(NotificationsActions.loadMoreNotifications());
    }

    public initializeEchoListener(token: string, userId: string) {
        this.store.dispatch(
            NotificationsActions.initializeEchoListener({ token, userId })
        );
    }

    public reinitializeEchoListener() {
        this.store
            .pipe(select(NotificationsSelectors.getEchoConfig), first())
            .subscribe(echoConfig => {
                this.dispatch(
                    NotificationsActions.initializeEchoListener(echoConfig)
                );
            });
    }

    public deinitializeEchoListener() {
        this.dispatch(NotificationsActions.deinitializeEchoListener());
    }

    dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
