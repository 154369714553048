import { on, createReducer, createFeature } from '@ngrx/store';
import {
    IdSelector,
    EntityState,
    EntityAdapter,
    createEntityAdapter,
} from '@ngrx/entity';

import { common } from '@qwyk/models';

import { CustomViewsActions } from './custom-views.actions';

export const DEFAULT_VIEW_ID = '1';
export const CUSTOM_VIEWS_FEATURE_KEY = 'customViews';

export interface CustomViewsState extends EntityState<common.CustomView> {
    selectedId: string | null;
    loading: boolean;
    error: string | null; // last known error (if any)
}

const selectId: IdSelector<common.CustomView> = ({ uid }) => uid;

export const customViewsAdapter: EntityAdapter<common.CustomView> =
    createEntityAdapter<common.CustomView>({ selectId });

export const initialState: CustomViewsState =
    customViewsAdapter.getInitialState({
        loading: false,
        selectedId: null,
        error: null,
    });

const reducer = createReducer(
    initialState,
    on(
        CustomViewsActions.loadCustomViewsSuccess,
        (state, { parent, custom_views, selectedId }) => {
            const views = custom_views.map(cv => ({ ...cv, parent }));
            return customViewsAdapter.setAll(views, {
                ...state,
                loading: false,
                selectedId,
            });
        }
    ),
    on(CustomViewsActions.createCustomView, state => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(CustomViewsActions.createCustomViewSuccess, (state, { custom_view }) =>
        customViewsAdapter.setOne(custom_view, {
            ...state,
            loading: false,
            selectedId: custom_view.uid,
        })
    ),
    on(CustomViewsActions.updateCustomView, state => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(CustomViewsActions.updateCustomViewSuccess, (state, { custom_view }) => {
        if (custom_view.default_view) {
            for (const [, value] of Object.entries(state.entities)) {
                if (value) {
                    value.default_view = false;
                }
            }
        }

        return customViewsAdapter.updateOne(
            {
                id: custom_view.uid,
                changes: custom_view,
            },
            {
                ...state,
                loading: false,
                selectedId: custom_view.uid,
            }
        );
    }),
    on(CustomViewsActions.deleteCustomView, state => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(CustomViewsActions.deleteCustomViewSuccess, (state, { custom_view }) => {
        return customViewsAdapter.removeOne(custom_view.uid, {
            ...state,
            loading: false,
        });
    }),
    on(CustomViewsActions.setSelectedView, (state, { uid }) => ({
        ...state,
        error: null,
        loading: false,
        selectedId: uid,
    })),
    on(
        CustomViewsActions.loadCustomViewsFailure,
        CustomViewsActions.createCustomViewFailure,
        CustomViewsActions.updateCustomViewFailure,
        CustomViewsActions.deleteCustomViewFailure,
        (state, { error }) => ({
            ...state,
            error,
            loading: false,
        })
    ),
    on(CustomViewsActions.reset, () => initialState)
);

export const customViewsFeature = createFeature({
    name: CUSTOM_VIEWS_FEATURE_KEY,
    reducer,
});
