import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    State,
    AUTHENTICATION_FEATURE_KEY,
    AuthenticationPartialState,
} from './authentication.reducer';

// Lookup the 'Authentication' feature state managed by NgRx
export const getAuthenticationState = createFeatureSelector<
    AuthenticationPartialState,
    State
>(AUTHENTICATION_FEATURE_KEY);

export const getToken = createSelector(
    getAuthenticationState,
    state => state.token
);

export const getUserAndToken = createSelector(
    getAuthenticationState,
    state => ({ user: state.user, token: state.token })
);

export const getIsAuthenticated = createSelector(
    getAuthenticationState,
    state => state.authenticated
);

export const getUser = createSelector(
    getAuthenticationState,
    state => state.user
);

export const getError = createSelector(
    getAuthenticationState,
    state => state.error
);

export const getIsAuthenticating = createSelector(
    getAuthenticationState,
    state => state.authenticating
);
export const getShouldRedirectOnLogout = createSelector(
    getAuthenticationState,
    state => state.redirectOnLogout
);

export const getPermissions = createSelector(
    getAuthenticationState,
    state => state.permissions
);

export const getPermissionsLoaded = createSelector(
    getAuthenticationState,
    state => state.permissionsLoaded
);
