<ng-container [ngSwitch]="notification.notification_type">
    <div *ngSwitchCase="'shipment-status-update'" class="card border-0 bg-light">
        <div class="card-body text-center">
            <fa-icon [icon]="['far', notification.metadata?.icon]" size="2x"></fa-icon>
            <p class="lead m-0" *ngIf="notification.metadata?.label">
                {{
                    'portal.shipment.state.' + notification.notification_sub_type | translate
                }}
            </p>
            <div>{{ notification.metadata?.actual_timestamp | amLocal | amDateFormat: 'LLL' }}</div>
            <hr>
            <div>{{ 'portal.common.shipment' | translate }}:
                <strong>
                    <a [routerLink]="['/my-portal/shipments/', notification.entity?.id]">
                        {{ notification.entity?.title }}
                    </a>
                </strong>
            </div>

            <div *ngIf="notification.metadata?.reference">{{ 'common.reference' | translate }}:
                <strong>{{ notification?.metadata?.reference }}</strong>
            </div>

            <div *ngIf="notification.metadata?.notes">{{ 'common.notes' | translate }}:
                <strong>{{ notification?.metadata?.notes }}</strong>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'quotation-rate-posted'" class="card border-0 bg-light">
        <div class="card-body text-center">
            <fa-icon [icon]="['far', 'hand-holding-usd']" size="2x"></fa-icon>
            <hr>
            <div>{{ 'common.quotations.quotation' | translate | titlecase }}:
                <strong>
                    <a [routerLink]="['/my-portal/quotations/', notification.entity?.id]">
                        {{ notification.entity?.title }}
                    </a>
                </strong>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'quotation-published'" class="card border-0 bg-light">
        <div class="card-body text-center">
            <fa-icon [icon]="['far', 'hands-usd']" size="2x"></fa-icon>
            <hr>
            <div>{{ 'common.quotations.quotation' | translate | titlecase }}:
                <strong>
                    <a [routerLink]="['/my-portal/quotations/', notification.entity?.id]">
                        {{ notification.entity?.title }}
                    </a>
                </strong>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'shipment-activity'" class="card border-0 bg-light">
        <div class="card-body text-center">
            <fa-icon
                [icon]="['far', notification.notification_type | notificationIcon: notification.notification_sub_type]"
                size="2x" class="text-muted" [fixedWidth]="true"></fa-icon>
            <hr>
            <div>{{ 'portal.common.shipment' | translate }}:
                <strong>
                    <a [routerLink]="['/my-portal/shipments/', notification.entity?.id]">
                        {{ notification.entity?.title }}
                    </a>
                </strong>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'entity-shared'" class="card border-0 bg-light">
        <div class="card-body text-center">
            <fa-icon
                [icon]="['far', notification.notification_type | notificationIcon: notification.notification_sub_type]"
                size="2x" class="text-muted" [fixedWidth]="true"></fa-icon>
            <hr>
            <div>{{ notification.subject}}:</div>
            <div *ngIf="notification.metadata?.message" class="py-3">
                "{{ notification.metadata?.message }}"
            </div>
            <div>{{ notification.notification_sub_type | titlecase }}:
                <strong>
                    <ng-container [ngSwitch]="notification.entity.type">
                        <a *ngSwitchCase="'App\\Models\\Portals\\Shipments\\Shipment'"
                           [routerLink]="['/my-portal/shipments/', notification.entity.id]">{{ notification.entity.title }}</a>
                        <a *ngSwitchCase="'App\\Domain\\Shipment\\Models\\Shipment'"
                           [routerLink]="['/my-portal/shipments/', notification.entity.id]">{{ notification.entity.title }}</a>
                        <a *ngSwitchCase="'App\\Models\\Portals\\Quotations\\Quotation'"
                           [routerLink]="['/my-portal/quotations/', notification.entity.id]">{{ notification.entity.title }}</a>
                        <a *ngSwitchCase="'App\\Models\\Portals\\Schedules\\SavedSchedule'"
                           [routerLink]="['/my-portal/schedules/saved/', notification.entity.id]">{{ notification.entity.title }}</a>
                    </ng-container>
                </strong>
            </div>
        </div>
    </div>
</ng-container>
