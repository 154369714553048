import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';
import {
    QUOTATION_STATUS_COLORS,
    QUOTATION_STATUS_ICONS,
    QUOTATION_STATUS_LABELS,
    safeGet,
} from '../../data';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'qwyk-quotation-status-badge',
    templateUrl: './quotation-status-badge.component.html',
    styleUrls: ['./quotation-status-badge.component.scss'],
})
export class QuotationStatusBadgeComponent {
    @Input() public quotation: Portals.QuotationEntity;
    private readonly icons = QUOTATION_STATUS_ICONS;
    private readonly labels = QUOTATION_STATUS_LABELS;
    private readonly colors = QUOTATION_STATUS_COLORS;

    public get icon(): IconProp {
        return safeGet(this.icons, this.quotation.status) as IconProp;
    }

    public get label(): string {
        return safeGet(this.labels, this.quotation.status);
    }

    public get color(): string {
        return safeGet(this.colors, this.quotation.status);
    }

    private static safeGet(map, key, def = null): string | null {
        // eslint-disable-next-line no-prototype-builtins
        if (map.hasOwnProperty(key)) {
            return map[key];
        }
        return def;
    }
}
