import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivateChild,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationFacade } from '../state/authentication.facade';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IsMasterAdminGuard implements CanActivate, CanActivateChild {
    constructor(
        private authentication: AuthenticationFacade,
        private router: Router
    ) {}

    canActivate(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        next: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.authentication.user$.pipe(
            map(user => (!user ? false : user.is_master_admin)),
            tap(canActivate => {
                if (!canActivate) {
                    return this.router.navigate(['/']);
                }
            })
        );
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }
}
