import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    NOTIFICATIONS_FEATURE_KEY,
    State,
    NotificationsPartialState,
    notificationsAdapter,
} from './notifications.reducer';

// Lookup the 'Notifications' feature state managed by NgRx
export const getNotificationsState = createFeatureSelector<
    NotificationsPartialState,
    State
>(NOTIFICATIONS_FEATURE_KEY);

const { selectAll, selectEntities } = notificationsAdapter.getSelectors();

export const getNotificationsLoaded = createSelector(
    getNotificationsState,
    (state: State) => state.loaded
);

export const getNotificationsError = createSelector(
    getNotificationsState,
    (state: State) => state.error
);

export const getAllNotifications = createSelector(
    getNotificationsState,
    (state: State) => selectAll(state)
);

export const getNotificationsEntities = createSelector(
    getNotificationsState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getNotificationsState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getNotificationsEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);

export const getPaginationInfo = createSelector(
    getNotificationsState,
    (state: State) => ({
        total: state.total,
        current: Math.min(state.perPage * state.loadedPage, state.total),
    })
);

export const canLoadPage = createSelector(
    getNotificationsState,
    (state: State) => state.loadedPage < state.lastPage
);

export const getLoadedPage = createSelector(
    getNotificationsState,
    (state: State) => state.loadedPage || 0
);

export const loadingPage = createSelector(
    getNotificationsState,
    (state: State) => state.loadingPage
);

export const getUnreadCount = createSelector(
    getNotificationsState,
    (state: State) => state.unread
);

export const getEchoConfig = createSelector(
    getNotificationsState,
    (state: State) => state.echoConfig
);
