import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromBookingWizardOffers from './booking-wizard-offers.reducer';
import * as BookingWizardOffersSelectors from './booking-wizard-offers.selectors';
import * as BookingWizardOffersActions from './booking-wizard-offers.actions';

@Injectable()
export class BookingWizardOffersFacade {
    loaded$ = this.store.pipe(
        select(BookingWizardOffersSelectors.getBookingWizardOffersLoaded)
    );
    polling$ = this.store.pipe(
        select(BookingWizardOffersSelectors.getBookingWizardOffersPolling)
    );
    allOffers$ = this.store.pipe(
        select(BookingWizardOffersSelectors.getAllBookingWizardOffers)
    );
    quotationRequest$ = this.store.pipe(
        select(BookingWizardOffersSelectors.getQuotationRequest)
    );

    selectedOffers$ = this.store.pipe(
        select(BookingWizardOffersSelectors.getSelected)
    );

    constructor(
        private store: Store<
            fromBookingWizardOffers.BookingWizardOffersPartialState
        >
    ) {}

    public loadQuotation(id: string) {
        this.dispatch(BookingWizardOffersActions.loadQuotation({ id }));
    }

    public abortPolling() {
        this.dispatch(BookingWizardOffersActions.abortPollingForOffers());
    }

    public reset() {
        this.dispatch(BookingWizardOffersActions.reset());
    }

    dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
