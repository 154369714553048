import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-conversation-message',
    templateUrl: './conversation-message.component.html',
    styleUrls: ['./conversation-message.component.scss'],
})
export class ConversationMessageComponent {
    @Input() message: Portals.ConversationMessage;
    @Input() showHeader = true;
    @Input() isCurrentUser: boolean;
    @Input() index: number;
    @Input() downloadingMessageId: string | null;

    @Output() downloadAttachment: EventEmitter<{
        messageId: string;
    }> = new EventEmitter();

    constructor() {}

    onDownloadAttachment() {
        this.downloadAttachment.emit({ messageId: this.message._id });
    }
}
