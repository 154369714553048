import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomerPermissions } from '@qwyk/core';
import { VerifyFeaturesGuard } from '@qwyk/portals/authentication';
import { VerifyAllowedPermissions } from '@qwyk/portals/authentication';
import { portalsNotificationsRoutes } from '@qwyk/portals/notifications';

import { EnabledGuard } from '../../guards/enabled.guard';
import { PortalIndexComponent } from './components/portal-index/portal-index.component';
import { ResourceErrorComponent } from './components/resource-error/resource-error.component';

const routes: Routes = [
    {
        path: '',
        component: PortalIndexComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./../dashboard/dashboard.module').then(
                        m => m.DashboardModule
                    ),
            },
            {
                path: 'error/:errorCode',
                component: ResourceErrorComponent,
            },
            {
                path: 'orders',
                loadChildren: () =>
                    import('@qwyk/portals/purchase-orders').then(
                        M => M.PortalsPurchaseOrdersModule
                    ),

                canActivate: [EnabledGuard, VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [
                        CustomerPermissions.VIEW_PURCHASEORDERS,
                    ],
                },
                canActivateChild: [EnabledGuard],
            },
            {
                path: 'schedules',
                children: [
                    {
                        path: '',
                        redirectTo: 'saved',
                        pathMatch: 'full',
                    },
                    {
                        path: 'search',
                        loadChildren: () =>
                            import('@qwyk/portals/schedules-search').then(
                                M => M.PortalsSchedulesSearchModule
                            ),
                    },
                    {
                        path: 'saved',
                        loadChildren: () =>
                            import('@qwyk/portals/saved-schedules').then(
                                M => M.PortalsSavedSchedulesModule
                            ),
                    },
                ],
            },
            {
                path: 'shipments',
                loadChildren: () =>
                    import('@qwyk/portals/shipments').then(
                        M => M.PortalsShipmentsModule
                    ),
                canActivate: [VerifyAllowedPermissions, VerifyFeaturesGuard],
                data: {
                    requiredPermissions: [CustomerPermissions.VIEW_SHIPMENTS],
                    requiredFeature: ['disable_booking_tracking'],
                },
            },
            {
                path: 'booking',
                loadChildren: () =>
                    import('./../booking/booking.module').then(
                        m => m.BookingModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                canActivateChild: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [CustomerPermissions.VIEW_BOOKINGS],
                },
            },
            {
                path: 'invoices',
                loadChildren: () =>
                    import('@qwyk/portals/invoices-v2').then(
                        m => m.PortalsInvoicesV2Module
                    ),
                canActivate: [VerifyAllowedPermissions],
                canActivateChild: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [
                        CustomerPermissions.VIEW_INVOICES,
                        CustomerPermissions.VIEW_PAYMENTS,
                    ],
                },
            },
            {
                path: 'payments',
                loadChildren: () =>
                    import('@qwyk/portals/payments').then(
                        m => m.PortalsPaymentsModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [CustomerPermissions.VIEW_PAYMENTS],
                },
            },
            {
                path: 'help',
                loadChildren: () =>
                    import('./../help/help.module').then(m => m.HelpModule),
            },
            {
                path: 'reports',
                loadChildren: () =>
                    import('./../reports/reports.module').then(
                        m => m.ReportsModule
                    ),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('@qwyk/portals/settings').then(
                        M => M.PortalsSettingsModule
                    ),
            },
            {
                path: 'booking-wizard',
                loadChildren: () =>
                    import('@qwyk/portals/booking-wizard').then(
                        M => M.PortalsBookingWizardModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                canActivateChild: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [CustomerPermissions.CREATE_BOOKINGS],
                },
            },
            {
                path: 'quotations',
                loadChildren: () =>
                    import('@qwyk/portals/quotations').then(
                        M => M.PortalsQuotationsModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [CustomerPermissions.VIEW_QUOTATIONS],
                },
            },
            {
                path: 'conversations',
                loadChildren: () =>
                    import('@qwyk/portals/conversations').then(
                        M => M.PortalsConversationsModule
                    ),
                canActivate: [VerifyFeaturesGuard],
                data: {
                    requiredFeature: ['disable_global_chat'],
                },
            },
            {
                path: 'warehouse',
                redirectTo: 'warehouse-receipts',
            },
            {
                path: 'warehouse-receipts',
                loadChildren: () =>
                    import('@qwyk/portals/warehouse-receipts').then(
                        M => M.PortalsWarehouseReceiptsModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [
                        CustomerPermissions.VIEW_WAREHOUSERECEIPTS,
                    ],
                },
            },
            {
                path: 'cargo-releases',
                loadChildren: () =>
                    import('@qwyk/portals/cargo-releases').then(
                        M => M.PortalsCargoReleasesModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [
                        CustomerPermissions.VIEW_CARGORELEASES,
                    ],
                },
            },
            {
                path: 'pickup-orders',
                loadChildren: () =>
                    import('@qwyk/portals/pickup-orders').then(
                        M => M.PortalsPickupOrdersModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [
                        CustomerPermissions.VIEW_PICKUPORDERS,
                    ],
                },
            },
            {
                path: 'warehouse-inventory',
                loadChildren: () =>
                    import('@qwyk/portals/warehouse-inventory').then(
                        M => M.PortalsWarehouseInventoryModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [CustomerPermissions.VIEW_INVENTORY],
                },
            },
            {
                path: 'cargo-detail',
                loadChildren: () =>
                    import('@qwyk/portals/cargo-detail').then(
                        M => M.PortalsCargoDetailModule
                    ),
                canActivate: [VerifyAllowedPermissions],
                data: {
                    requiredPermissions: [CustomerPermissions.VIEW_COMMODITIES],
                },
            },
            {
                path: 'notifications',
                children: portalsNotificationsRoutes,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PortalRoutingModule {}
