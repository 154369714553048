import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    imports: [TranslateModule],
    selector: 'qwyk-controlled-by-magaya-warning',
    templateUrl: './controlled-by-magaya-warning.component.html',
    styleUrls: ['./controlled-by-magaya-warning.component.scss'],
})
export class ControlledByMagayaWarningComponent {
    constructor() {}
}
