import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveValidationModule } from 'angular-reactive-validation';

import {
    LineBreaksPipe,
    ObjectToKVPPipe,
    AddressFormComponent,
    UserInfoTooltipComponent,
    ControlledByMagayaWarningComponent,
} from '@qwyk/ui-standalone';
import { TranslateModule } from '@ngx-translate/core';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { GalleriaModule } from 'primeng/galleria';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextareaModule } from 'primeng/inputtextarea';

import {
    ArrayFilterPipe,
    BootstrapObjectToDatePipe,
    BypassSecurityTrustResourceUrlPipe,
    FileExtensionIconPipe,
    IsArrayPipe,
    JoinArrayWithMorePipe,
    LoadTypeIconPipe,
    MD5Pipe,
    MultiUnitPipe,
    NotificationIconPipe,
    ObjectToArrayPipe,
    PortIconPipe,
    PrettyBytesPipe,
    ProductColorPipe,
    ProductIconPipe,
    ProductTitlePipe,
    PurchaseOrderStatusIconPipe,
    SimplishipObjectToDatePipe,
    SlugToTitlePipe,
    SplitPipe,
    TitleCaseToSentencePipe,
    TransportModeIconPipe,
    TransportModeTransformPipe,
    TraversePipe,
    UrlEncodePipe,
    ValueMapPipe,
    ValueMaskPipe,
} from './pipes';

import {
    FileDragAndDropDirective,
    DisplaysValidationDirective,
    GooglePlacesAutocompleteDirective,
} from './directives';

import {
    AddressComponent,
    BreakPointHelperComponent,
    CargoItemsStatusBadgeComponent,
    ContainedIconComponent,
    ConversationDetailComponent,
    ConversationListItemComponent,
    ConversationMessageComponent,
    DetailCardBodyDirective,
    DetailCardComponent,
    DetailCardHeaderDirective,
    ExpandCollapseButtonComponent,
    FullScreenButtonComponent,
    InfoTooltipComponent,
    LocationTypeaheadComponent,
    LocationTypeaheadHintDirective,
    LocationTypeaheadItemDirective,
    LocationTypeaheadNotFoundDirective,
    LocationTypeaheadPanelComponent,
    LocationTypeaheadPlaceholderDirective,
    OnHoldIndicatorComponent,
    QuotationRequestSummaryComponent,
    RelativeTimeDisplayComponent,
    ShipmentActivitiesCommentComponent,
    ShipmentActivitiesCommentFormComponent,
    ShipmentActivitiesDocumentComponent,
    ShipmentActivitiesListComponent,
    ShipmentActivitiesMilestoneChangeComponent,
    ShipmentActivitiesScheduleChangeComponent,
    ShipmentActivitiesStatusUpdateComponent,
    ShipmentAttachmentsListComponent,
    ShipmentAttachmentsListItemComponent,
    ShipmentContainersListComponent,
    ShipmentContainersListItemComponent,
    ShipmentQuotationItemComponent,
    ShipmentTrackingEventsListComponent,
    ShipmentTrackingEventsListItemComponent,
    ToggleButtonComponent,
    AttachmentsCarouselComponent,
} from './components';

import { ShipmentDataPackageComponent } from './components/shipment-data-package/shipment-data-package.component';

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        FontAwesomeModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MomentModule,
        ReactiveValidationModule,
        RouterModule,
        InputNumberModule,
        InputTextareaModule,
        PaginatorModule,
        GalleriaModule,
        InputTextModule,
        SelectButtonModule,
        SidebarModule,
        TabViewModule,
        TableModule,
        ButtonModule,
        LineBreaksPipe,
        TranslateModule,
        ObjectToKVPPipe,
        AddressFormComponent,
        UserInfoTooltipComponent,
        ControlledByMagayaWarningComponent,
    ],
    declarations: [
        FileExtensionIconPipe,
        ObjectToArrayPipe,
        SimplishipObjectToDatePipe,
        IsArrayPipe,
        ValueMaskPipe,
        SlugToTitlePipe,
        TitleCaseToSentencePipe,
        TransportModeIconPipe,
        ProductTitlePipe,
        ProductColorPipe,
        ProductIconPipe,
        BypassSecurityTrustResourceUrlPipe,
        JoinArrayWithMorePipe,
        FileDragAndDropDirective,
        NotificationIconPipe,
        PurchaseOrderStatusIconPipe,
        BootstrapObjectToDatePipe,
        AddressComponent,
        LocationTypeaheadComponent,
        LocationTypeaheadPanelComponent,
        LocationTypeaheadItemDirective,
        LocationTypeaheadPlaceholderDirective,
        LocationTypeaheadHintDirective,
        LocationTypeaheadNotFoundDirective,
        LoadTypeIconPipe,
        QuotationRequestSummaryComponent,
        GooglePlacesAutocompleteDirective,
        InfoTooltipComponent,
        MD5Pipe,
        ConversationListItemComponent,
        ConversationDetailComponent,
        ConversationMessageComponent,
        PrettyBytesPipe,
        UrlEncodePipe,
        TraversePipe,
        DisplaysValidationDirective,
        BreakPointHelperComponent,
        ArrayFilterPipe,
        SplitPipe,
        ValueMapPipe,
        ShipmentTrackingEventsListItemComponent,
        ShipmentTrackingEventsListComponent,
        ShipmentContainersListComponent,
        ShipmentContainersListItemComponent,
        ContainedIconComponent,
        PortIconPipe,
        ShipmentActivitiesCommentComponent,
        ShipmentActivitiesCommentFormComponent,
        ShipmentActivitiesDocumentComponent,
        ShipmentActivitiesListComponent,
        ShipmentActivitiesMilestoneChangeComponent,
        ShipmentActivitiesScheduleChangeComponent,
        ShipmentActivitiesStatusUpdateComponent,
        ShipmentAttachmentsListComponent,
        ShipmentAttachmentsListItemComponent,
        ShipmentQuotationItemComponent,
        ToggleButtonComponent,
        DetailCardComponent,
        DetailCardHeaderDirective,
        DetailCardBodyDirective,
        OnHoldIndicatorComponent,
        FullScreenButtonComponent,
        TransportModeTransformPipe,
        MultiUnitPipe,
        CargoItemsStatusBadgeComponent,
        ExpandCollapseButtonComponent,
        RelativeTimeDisplayComponent,
        ShipmentDataPackageComponent,
        AttachmentsCarouselComponent,
    ],
    exports: [
        MD5Pipe,
        SplitPipe,
        TraversePipe,
        ValueMapPipe,
        PortIconPipe,
        IsArrayPipe,
        UrlEncodePipe,
        MultiUnitPipe,
        ValueMaskPipe,
        SlugToTitlePipe,
        ProductIconPipe,
        PrettyBytesPipe,
        ArrayFilterPipe,
        LoadTypeIconPipe,
        ProductTitlePipe,
        ProductColorPipe,
        ObjectToArrayPipe,
        NotificationIconPipe,
        FileExtensionIconPipe,
        TransportModeIconPipe,
        JoinArrayWithMorePipe,
        TitleCaseToSentencePipe,
        BootstrapObjectToDatePipe,
        TransportModeTransformPipe,
        SimplishipObjectToDatePipe,
        PurchaseOrderStatusIconPipe,
        BypassSecurityTrustResourceUrlPipe,
        FileDragAndDropDirective,
        AddressComponent,
        LocationTypeaheadComponent,
        LocationTypeaheadItemDirective,
        LocationTypeaheadPlaceholderDirective,
        LocationTypeaheadHintDirective,
        LocationTypeaheadNotFoundDirective,
        QuotationRequestSummaryComponent,
        GooglePlacesAutocompleteDirective,
        InfoTooltipComponent,
        ConversationListItemComponent,
        ConversationDetailComponent,
        DisplaysValidationDirective,
        BreakPointHelperComponent,
        ArrayFilterPipe,
        SplitPipe,
        ValueMapPipe,
        ShipmentTrackingEventsListItemComponent,
        ShipmentTrackingEventsListComponent,
        ShipmentContainersListComponent,
        ShipmentContainersListItemComponent,
        ContainedIconComponent,
        ShipmentActivitiesCommentComponent,
        ShipmentActivitiesCommentFormComponent,
        ShipmentActivitiesDocumentComponent,
        ShipmentActivitiesListComponent,
        ShipmentActivitiesMilestoneChangeComponent,
        ShipmentActivitiesScheduleChangeComponent,
        ShipmentActivitiesStatusUpdateComponent,
        ShipmentAttachmentsListComponent,
        ShipmentAttachmentsListItemComponent,
        ShipmentDataPackageComponent,
        ShipmentQuotationItemComponent,
        ToggleButtonComponent,
        DetailCardComponent,
        DetailCardHeaderDirective,
        DetailCardBodyDirective,
        OnHoldIndicatorComponent,
        FullScreenButtonComponent,
        RelativeTimeDisplayComponent,
        AttachmentsCarouselComponent,
        ExpandCollapseButtonComponent,
        CargoItemsStatusBadgeComponent,
    ],
})
export class UiModule {}
