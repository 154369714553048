import {Injectable} from '@angular/core';

import {Action, select, Store} from '@ngrx/store';

import * as fromQuotations from './quotations.reducer';
import * as QuotationsSelectors from './quotations.selectors';
import * as QuotationsActions from './quotations.actions';
import {Portals} from '@qwyk/models';
import {Update} from '@ngrx/entity';

@Injectable()
export class QuotationsFacade {
    loaded$ = this.store.pipe(select(QuotationsSelectors.getQuotationsLoaded));
    loading$ = this.store.pipe(
        select(QuotationsSelectors.getQuotationsLoading)
    );
    error$ = this.store.pipe(select(QuotationsSelectors.getQuotationsError));
    allQuotations$ = this.store.pipe(
        select(QuotationsSelectors.getAllQuotations)
    );
    selectedQuotations$ = this.store.pipe(
        select(QuotationsSelectors.getSelected)
    );
    pagination$ = this.store.pipe(
        select(QuotationsSelectors.getQuotationsPagination)
    );
    query$ = this.store.pipe(select(QuotationsSelectors.getQuotationsQuery));

    constructor(private store: Store<fromQuotations.QuotationsPartialState>) {}

    public loadQuotations(query) {
        this.dispatch(QuotationsActions.loadQuotations({ query }));
    }

    public loadQuotation(id) {
        this.dispatch(QuotationsActions.loadQuotation({ id }));
    }

    public selectQuotation(id: string) {
        this.dispatch(QuotationsActions.selectQuotation({ id }));
    }

    public resetSelectedQuotation() {
        this.dispatch(QuotationsActions.unselectQuotation());
    }

    public quotationUpdated(quotation: Portals.QuotationEntity): void {
        const update: Update<Portals.QuotationEntity> = {
            id: quotation.id,
            changes: quotation,
        };
        this.dispatch(
            QuotationsActions.quotationChanged({quotation: update})
        );
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    public resetQuery() {
        this.dispatch(QuotationsActions.resetQuery());
    }

    public dismissError() {
        this.dispatch(QuotationsActions.dismissError());
    }

    public downloadQuotation(quotation: Portals.QuotationEntity): void {
        this.dispatch(QuotationsActions.downloadQuotation({quotation}))
    }
}
