import * as shipmentActions from './../actions/shipments.actions';
import { createReducer, Action, on } from '@ngrx/store';
import { ShipmentsState } from '../portal.state';
import { ShipmentActivity } from '../models/shipments.models';

const initialState: ShipmentsState = {
    action: null,
    done: false,
    error: null,
    shipment: null,
    activity: null,
};

const reducer = createReducer(
    initialState,
    on(shipmentActions.reset, state => ({
        ...state,
        ...initialState,
    })),
    on(shipmentActions.loadShipment, state => ({
        ...state,
        action: shipmentActions.loadShipment.type,
        done: false,
        error: null,
    })),
    on(shipmentActions.loadShipmentSuccess, (state, { shipment }) => ({
        ...state,
        done: true,
        shipment,
        error: null,
    })),
    on(shipmentActions.loadShipmentFailure, (state, { error }) => ({
        ...state,
        done: true,
        shipment: null,
        error,
    })),
    on(shipmentActions.loadShipmentActivity, state => ({
        ...state,
        activity: null,
        action: shipmentActions.loadShipmentActivity.type,
        done: false,
        error: null,
    })),
    on(shipmentActions.loadShipmentActivitySuccess, (state, { activity }) => ({
        ...state,
        done: true,
        error: null,
        activity,
    })),
    on(shipmentActions.loadShipmentActivityFailure, state => ({
        ...state,
        activity: null,
        done: true,
        error: true,
    })),
    on(shipmentActions.loadMoreShipmentActivity, state => ({
        ...state,
        action: shipmentActions.loadMoreShipmentActivity.type,
        done: false,
        error: null,
    })),
    on(
        shipmentActions.loadMoreShipmentActivitySuccess,
        (state, { activity }) => {
            let data: ShipmentActivity[] = [];
            const activityData = state.activity?.data;
            if (activityData?.length) {
                data = [...activityData];
            }
            data = [...data, ...activity.data];
            return {
                ...state,
                done: true,
                error: null,
                activity: {
                    data,
                    meta: activity.meta,
                },
            };
        }
    ),
    on(shipmentActions.loadMoreShipmentActivityFailure, state => ({
        ...state,
        done: true,
        error: true,
    })),
    on(shipmentActions.postComment, state => ({
        ...state,
        action: shipmentActions.postComment.type,
        done: false,
        error: null,
    })),
    on(shipmentActions.postCommentSuccess, (state, { activity }) => {
        let data: ShipmentActivity[] = [];
        const activityData = state.activity?.data;
        if (activityData?.length) {
            data = [...activityData];
        }
        data = [...data, ...activity.data];

        let meta;

        if (state?.activity?.meta) {
            meta = state.activity.meta;
        }

        return {
            ...state,
            done: true,
            error: null,
            activity: {
                data,
                meta,
            },
        };
    }),
    on(shipmentActions.postCommentFailure, state => ({
        ...state,
        done: true,
        error: true,
    })),
    on(shipmentActions.deleteComment, state => ({
        ...state,
        done: false,
        error: null,
        action: shipmentActions.deleteComment.type,
    })),
    on(shipmentActions.deleteCommentSuccess, (state, { activity }) => {
        let data: ShipmentActivity[] = [];
        const activityData = state.activity?.data;
        if (activityData?.length) {
            data = [...activityData];
        }

        const idx = data.findIndex(a => a.id === activity.id);
        if (idx >= -1) {
            data.splice(idx, 1);
        }

        let meta;
        if (state?.activity?.meta) {
            meta = state.activity.meta;
        }

        return {
            ...state,
            activity: {
                meta,
                data,
            },
            done: true,
            error: null,
        };
    }),
    on(shipmentActions.deleteCommentFailure, state => ({
        ...state,
        done: true,
        error: true,
    }))
);

export function shipmentsReducer(
    state: ShipmentsState | undefined,
    action: Action
) {
    return reducer(state, action);
}
