<ng-container *ngIf="error" [ngSwitch]="error?.status">
    <div *ngSwitchCase="403" class="alert alert-danger d-flex align-items-center justify-content-start">
        <div class="flex-grow-0 mr-3">
            <fa-icon [icon]="['far', 'ban']" size="lg"></fa-icon>
        </div>
        <div class="flex-grow-1">
            <div class="font-weight-bold">{{ 'portal.form-error.access-denied' | translate }}</div>
            <div>{{ 'portal.form-error.you-dont-have-permissions' | translate }}.</div>
        </div>
    </div>

    <div *ngSwitchCase="409" class="alert alert-danger d-flex align-items-center justify-content-start">
        <div class="flex-grow-0 mr-3">
            <fa-icon [icon]="['far', 'exclamation-circle']" size="lg"></fa-icon>
        </div>
        <div class="flex-grow-1">
            <div class="font-weight-bold">{{ 'portal.form-error.conflict' | translate }}</div>
            <div>{{ error?.error?.message || ('portal.form-error.record-might-already-exist' | translate) }}</div>
        </div>
    </div>

    <div *ngSwitchCase="422" class="alert alert-warning d-flex align-items-center justify-content-start">
        <div class="flex-grow-0 mr-3">
            <fa-icon [icon]="['far', 'exclamation-triangle']" size="lg"></fa-icon>
        </div>
        <div class="flex-grow-1">
            <div class="font-weight-bold">{{ 'portal.form-error.there-was-a-problem-with-your-input' | translate }}</div>
            <ul class="m-0">
                <ng-container *ngFor="let error of error.error.errors | objectToKVP: true">
                    <li *ngFor="let message of error.value">{{ message }}</li>
                </ng-container>
            </ul>
            <div class="mt-2">{{ 'portal.form-error.correct-errors-and-try-again' | translate }}</div>
        </div>
    </div>

    <div *ngSwitchDefault class="alert alert-danger d-flex align-items-center justify-content-start">
        <div class="flex-grow-0 mr-3">
            <fa-icon [icon]="['far', 'exclamation-circle']" size="lg"></fa-icon>
        </div>
        <div class="flex-grow-1">
            <div class="font-weight-bold">{{ 'common.something_went_wrong' | translate }}</div>
            <div>{{ 'common.try_again' | translate }}</div>
        </div>
    </div>
</ng-container>
