/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { common, Portals } from '@qwyk/models';
import { Update } from '@ngrx/entity';

export const loadQuotations = createAction(
    '[Portals/Quotations] Load Quotations',
    props<{ query: any }>()
);

export const loadQuotationsSuccess = createAction(
    '[Portals/Quotations] Load Quotations Success',
    props<{
        quotations: Portals.QuotationEntity[];
        pagination: common.PaginationMeta;
    }>()
);

export const loadQuotationsFailure = createAction(
    '[Portals/Quotations] Load Quotations Failure',
    props<{ error: any }>()
);

export const loadQuotation = createAction(
    '[Portals/Quotations] Load Quotation',
    props<{ id: string }>()
);
export const loadQuotationSuccess = createAction(
    '[Portals/Quotations] Load Quotation Success',
    props<{ quotation: Portals.QuotationEntity }>()
);
export const loadQuotationFailure = createAction(
    '[Portals/Quotations] Load Quotation Failure',
    props<{ error: any }>()
);
export const selectQuotation = createAction(
    '[Portals/Quotations] Select Quotation',
    props<{ id: string }>()
);
export const unselectQuotation = createAction(
    '[Portals/Quotations] Unselect Quotation'
);

export const quotationChanged = createAction(
    '[Portals/Quotations] Quotation Changed',
    props<{ quotation: Update<Portals.QuotationEntity> }>()
);

export const resetQuery = createAction('[Portals/Quotations] Reset Query');

export const dismissError = createAction('[Portals/Quotations] Dismiss Error');

export const downloadQuotation = createAction(
    '[Portals/Quotations] Download Quotation',
    props<{ quotation: Portals.QuotationEntity }>()
);
export const downloadQuotationSuccess = createAction(
    '[Portals/Quotations] Download Quotation Success',
    props<{ quotation: Portals.QuotationEntity; blob: Blob }>()
);
export const downloadQuotationFailure = createAction(
    '[Portals/Quotations] Download Quotation Failure',
    props<{ error: any }>()
);
