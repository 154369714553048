import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-shipment-containers-list',
    templateUrl: './shipment-containers-list.component.html',
    styleUrls: ['./shipment-containers-list.component.scss'],
})
export class ShipmentContainersListComponent {
    @Input() loading = false;
    @Input() shipmentContainers: Portals.ShipmentDataContainer[];
    @Input() cardClass = null;
    @Input() headingClass: string;
    @Input() headerClass: string;
}
