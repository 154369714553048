import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { map, shareReplay } from 'rxjs/operators';
import { SegmentService } from 'ngx-segment-analytics';

import { SegmentEvent } from '@qwyk/core';
import { SideBarService } from '@qwyk/portals/ui';
import { OnboardingService } from '@qwyk/portals/onboarding';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

import { AppState } from './../../../../store/app.state';

@Component({
    selector: 'qw-improved-dashboard',
    templateUrl: './improved-dashboard.component.html',
    styleUrls: ['./improved-dashboard.component.scss'],
})
export class ImprovedDashboardComponent implements OnInit, OnDestroy {
    queryParams$ = this.route.queryParams;
    user$ = this.authentication.user$;
    view$ = this.queryParams$.pipe(
        map(p => {
            if (!p.view) {
                this.router.navigate([], { queryParams: { view: 'ops' } });
                return null;
            }
            return p.view;
        })
    );
    onboardingStatus$ = this.onboardingService
        .getOnboardingStatus()
        .pipe(shareReplay(1));

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<AppState>,
        private sidebar: SideBarService,
        private authentication: AuthenticationFacade,
        private onboardingService: OnboardingService,
        private segment: SegmentService
    ) {}

    ngOnInit() {
        setTimeout(() => {
            this.sidebar.background = 'bg-white';
        }, 300);
        this.segment.track(SegmentEvent.DASHBOARD_VIEW);
    }

    ngOnDestroy(): void {
        this.sidebar.background = 'bg-light';
    }

    get greeting(): string {
        const greetings = [
            [0, 4, 'portal.common.greetings.good-night'],
            [5, 11, 'portal.common.greetings.good-morning'], // Store messages in an array
            [12, 17, 'portal.common.greetings.good-afternoon'],
            [18, 24, 'portal.common.greetings.good-night'],
        ];
        const hr = new Date().getHours();

        for (const greeting of greetings) {
            if (hr >= greeting[0] && hr <= greeting[1]) {
                return greeting[2] as string;
            }
        }
        return 'common.hello';
    }
}
