import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as InvoicesActions from './invoices.actions';
import { common, Portals } from '@qwyk/models';

export const INVOICES_FEATURE_KEY = 'invoices';

export interface State extends EntityState<Portals.Invoice> {
    selectedId?: string | number; // which Invoices record has been selected
    loaded: boolean; // has the Invoices list been loaded
    loading: boolean;
    error?: string | null; // last none error (if any)
    pagination?: common.PaginationMeta;
}

export interface InvoicesPartialState {
    readonly [INVOICES_FEATURE_KEY]: State;
}

export const invoicesAdapter: EntityAdapter<Portals.Invoice> =
    createEntityAdapter<Portals.Invoice>();

export const initialState: State = invoicesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
});

const invoicesReducer = createReducer(
    initialState,
    on(InvoicesActions.loadInvoices, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null,
    })),
    on(InvoicesActions.loadInvoicesSuccess, (state, { invoices, pagination }) =>
        invoicesAdapter.setAll(invoices, {
            ...state,
            loaded: true,
            loading: false,
            pagination,
        })
    ),
    on(InvoicesActions.loadInvoicesFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),
    on(InvoicesActions.loadInvoiceSuccess, (state, { invoice }) =>
        invoicesAdapter.addOne(invoice, state)
    ),
    on(InvoicesActions.selectInvoice, (state, { id }) => ({
        ...state,
        selectedId: id,
        error: null,
    })),
    on(InvoicesActions.unselectInvoice, state => ({
        ...state,
        selectedId: null,
    })),
    // eslint-disable-next-line no-empty-pattern
    on(InvoicesActions.updateInvoice, (state, {}) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(InvoicesActions.updateInvoiceSuccess, (state, { invoice }) =>
        invoicesAdapter.updateOne(
            { id: invoice.id, changes: invoice },
            {
                ...state,
                loading: false,
            }
        )
    ),
    on(InvoicesActions.updateInvoiceFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    })),
    // eslint-disable-next-line no-empty-pattern
    on(InvoicesActions.deleteInvoice, (state, {}) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(InvoicesActions.deleteInvoiceSuccess, (state, { invoice }) =>
        invoicesAdapter.removeOne(invoice.id, {
            ...state,
            loading: false,
        })
    ),
    on(InvoicesActions.deleteInvoiceFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return invoicesReducer(state, action);
}
