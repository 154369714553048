import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as BookingWizardOffersActions from './booking-wizard-offers.actions';
import { Portals } from '@qwyk/models';

export const BOOKINGWIZARDOFFERS_FEATURE_KEY = 'bookingWizardOffers';

export interface State extends EntityState<Portals.QuotationOffer> {
    selectedId?: string | number; // which BookingWizardOffers record has been selected
    quotation: Portals.QuotationEntity;
    loaded: boolean; // has the BookingWizardOffers list been loaded
    error?: string | null; // last none error (if any)
    polling: boolean;
    polCounter: number;
    polInterval: number;
    polMaximum: number;
    abortPolling: boolean;
    isDemo: boolean;
    tempToken: string;
}

export interface BookingWizardOffersPartialState {
    readonly [BOOKINGWIZARDOFFERS_FEATURE_KEY]: State;
}

export const bookingWizardOffersAdapter: EntityAdapter<Portals.QuotationOffer> =
    createEntityAdapter<Portals.QuotationOffer>();

export const initialState: State = bookingWizardOffersAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    quotation: null,
    polling: false,
    abortPolling: false,
    polCounter: 0,
    polInterval: 3000,
    polMaximum: 15,
    isDemo: false,
    tempToken: null,
});

const bookingWizardOffersReducer = createReducer(
    initialState,

    on(BookingWizardOffersActions.loadQuotation, state => ({
        ...state,
        loaded: false,
        error: null,
        isDemo: false,
        tempToken: null,
    })),
    on(
        BookingWizardOffersActions.loadQuotationSuccess,
        (state, { quotation, isDemo, tempToken }) => {
            return bookingWizardOffersAdapter.setAll(
                quotation.results as Portals.QuotationOffer[],
                {
                    ...state,
                    quotation,
                    loaded: true,
                    isDemo,
                    tempToken,
                }
            );
        }
    ),
    on(BookingWizardOffersActions.loadQuotationOffers, state => ({
        ...state,
        polCounter: state.polCounter + 1,
    })),
    on(
        BookingWizardOffersActions.loadQuotationOffersSuccess,
        (state, { offers }) => bookingWizardOffersAdapter.addMany(offers, state)
    ),
    on(BookingWizardOffersActions.polForOffers, state => ({
        ...state,
        polling: true,
    })),
    on(BookingWizardOffersActions.abortPollingForOffers, state => ({
        ...state,
        abortPolling: true,
    })),
    on(
        BookingWizardOffersActions.abortedPollingForOffers,
        BookingWizardOffersActions.polForOffersCompleted,
        state => ({
            ...state,
            polling: false,
            polCounter: 0,
            abortPolling: false,
        })
    ),
    on(BookingWizardOffersActions.reset, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
    return bookingWizardOffersReducer(state, action);
}
