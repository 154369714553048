import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-quotations-list-item]',
    templateUrl: './quotations-list-item.component.html',
    styleUrls: ['./quotations-list-item.component.scss'],
})
export class QuotationsListItemComponent {
    @Input() quotation: Portals.QuotationEntity;
}
