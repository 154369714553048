import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-detail-navs',
    templateUrl: './quotation-detail-navs.component.html',
    styleUrls: ['./quotation-detail-navs.component.scss'],
})
export class QuotationDetailNavsComponent {
    @Input() currentView: string;
    @Input() quotation: Portals.QuotationEntity;

    public readonly NAVS = [
        {
            title: 'portal.warehouse-receipts.page.details.navs.summary',
            viewTag: 'summary',
            icon: 'rss',
            enabled: true,
        },
        {
            title: 'common.quotations.page.navs.offers',
            viewTag: 'offers',
            icon: 'hand-holding-usd',
            enabled: true,
            badgeValue: () => this.offerCount(),
        },
        {
            title: 'portal.landing-page.quotation-request.page2.header-cargo',
            viewTag: 'cargo',
            icon: 'list',
            enabled: true,
        },
        {
            title: 'common.quotations.page.navs.rate-requests',
            viewTag: 'requests',
            icon: 'question-circle',
            badgeValue: () => this.quotation.open_rate_requests_count,
            enabled: true,
        },
    ];

    private offerCount() {
        return this.quotation.offers_count;
    }
}
