import { LowerCasePipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import {
    faFlag,
    faPhone,
    faMapPin,
    faBriefcase,
} from '@fortawesome/pro-regular-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UserSummary } from '@qwyk/models';

@Component({
    standalone: true,
    imports: [NgIf, LowerCasePipe, TranslateModule, FontAwesomeModule],
    selector: 'qwyk-user-info-tooltip',
    templateUrl: './user-info-tooltip.component.html',
    styleUrls: ['./user-info-tooltip.component.scss'],
})
export class UserInfoTooltipComponent {
    @Input() user: UserSummary;

    faBriefcase = faBriefcase;
    faMapPin = faMapPin;
    faPhone = faPhone;
    faFlag = faFlag;
}
