/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { common, Portals } from '@qwyk/models';

export const loadInvoices = createAction(
    '[Portals/Invoices] Load Invoices',
    props<{ query: any }>()
);

export const loadInvoicesSuccess = createAction(
    '[Portals/Invoices] Load Invoices Success',
    props<{ invoices: Portals.Invoice[]; pagination: common.PaginationMeta }>()
);

export const loadInvoicesFailure = createAction(
    '[Portals/Invoices] Load Invoices Failure',
    props<{ error: any }>()
);

export const loadInvoice = createAction(
    '[Portals/Invoices] Load Invoice',
    props<{ id: string }>()
);
export const loadInvoiceSuccess = createAction(
    '[Portals/Invoices] Load Invoice Success',
    props<{ invoice: Portals.Invoice }>()
);
export const loadInvoiceFailure = createAction(
    '[Portals/Invoices] Load Invoice Failure',
    props<{ error: any }>()
);

export const updateInvoice = createAction(
    '[Portals/Invoices] Update Invoice',
    props<{ invoice: Portals.Invoice }>()
);
export const updateInvoiceSuccess = createAction(
    '[Portals/Invoices] Update Invoice Success',
    props<{ invoice: Portals.Invoice }>()
);
export const updateInvoiceFailure = createAction(
    '[Portals/Invoices] Update Invoice Failure',
    props<{ error: any }>()
);

export const deleteInvoice = createAction(
    '[Portals/Invoices] Delete Invoice',
    props<{ invoice: Portals.Invoice }>()
);
export const deleteInvoiceSuccess = createAction(
    '[Portals/Invoices] Delete Invoice Success',
    props<{ invoice: Portals.Invoice }>()
);
export const deleteInvoiceFailure = createAction(
    '[Portals/Invoices] Delete Invoice Failure',
    props<{ error: any }>()
);

export const selectInvoice = createAction(
    '[Portals/Invoices] Select Invoice',
    props<{ id: string }>()
);
export const unselectInvoice = createAction(
    '[Portals/Invoices] Unselect Invoice'
);
