import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'unitConvert',
})
export class UnitConvertPipe implements PipeTransform {
    private static convertMass(
        value: number,
        from: 'imperial' | 'metric',
        to: 'imperial' | 'metric'
    ): number {
        if (from === 'imperial' && to === 'metric') {
            return value / 2.20462;
        } else if (from === 'metric' && to === 'imperial') {
            return value / 0.45359291;
        }
        return value;
    }

    private static convertLength(
        value: number,
        from: 'imperial' | 'metric',
        to: 'imperial' | 'metric'
    ): number {
        if (from === 'imperial' && to === 'metric') {
            return value * 2.54;
        } else if (from === 'metric' && to === 'imperial') {
            return value / 2.54;
        }

        return value;
    }

    private static convertVolume(
        value: number,
        from: 'imperial' | 'metric',
        to: 'imperial' | 'metric'
    ): number {
        if (from === 'imperial' && to === 'metric') {
            return value / 35.3147;
        } else if (from === 'metric' && to === 'imperial') {
            return value / 0.02831682;
        }

        return value;
    }

    transform(
        value: number | null,
        type: 'mass' | 'volume' | 'length',
        from: 'imperial' | 'metric',
        to: 'imperial' | 'metric'
    ): number | null {
        if (value === null || value === undefined) {
            return null;
        }

        if (from === to) {
            return value;
        }

        let convertFunc;

        if (type === 'mass') {
            convertFunc = UnitConvertPipe.convertMass;
        } else if (type === 'volume') {
            convertFunc = UnitConvertPipe.convertVolume;
        } else if (type === 'length') {
            convertFunc = UnitConvertPipe.convertLength;
        }

        if (convertFunc) {
            return convertFunc(value, from, to);
        }

        return value;
    }
}
