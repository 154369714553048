import { versions } from './../../../environments/versions';
import { Component } from '@angular/core';

@Component({
    selector: 'qw-debug-page',
    templateUrl: './debug-page.component.html',
    styleUrls: ['./debug-page.component.scss'],
})
export class DebugPageComponent {
    versionInfo = versions;

    constructor() {}

    throwException() {
        const randomNumber = Math.random() * 10000;
        throw Error(`Test error: ${new Date()} ${randomNumber}`);
    }
}
