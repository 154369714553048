import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'transportModeTransform' })
export class TransportModeTransformPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    transform(loadType: string, transportMode: string, ...args): any {
        if (transportMode === 'TRUCK' && loadType === 'lcl') {
            return 'ltl';
        }
        if (transportMode === 'TRUCK' && loadType === 'fcl') {
            return 'ftl';
        }

        if (transportMode === 'AIR') {
            return null;
        }

        return loadType;
    }
}
