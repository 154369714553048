import {Component, Input} from '@angular/core';
import {Portals} from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-rate-request-list',
    templateUrl: './quotation-rate-request-list.component.html',
    styleUrls: ['./quotation-rate-request-list.component.scss']
})
export class QuotationRateRequestListComponent {
    @Input() quotation: Portals.QuotationEntity;
}
