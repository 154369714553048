import { Component, Input } from '@angular/core';
import { Portals } from '@qwyk/models';

@Component({
    selector: 'qwyk-quotation-offer-list-item-charges',
    templateUrl: './quotation-offer-list-item-charges.component.html',
    styleUrls: ['./quotation-offer-list-item-charges.component.scss'],
})
export class QuotationOfferListItemChargesComponent {
    @Input() offer: Portals.QuotationOffer;
    groupExpansion = {};

    constructor() {}
}
