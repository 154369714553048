<div class="d-flex">
    <div class="flex-grow-0 mr-3 icon-container">
        <div class="icon-circle border rounded-circle text-muted bg-white">
            <fa-icon
                [fixedWidth]="true"
                [icon]="['far', 'check']"
                size="lg"
            ></fa-icon>
        </div>
    </div>

    <div class="flex-grow-1" style="margin-top: 0.4em">
        <p class="m-0">
            <b [ngbTooltip]="shipmentActivity.user?.email">{{
                shipmentActivity.user?.name || '@system'
            }}</b>
            {{ 'portal.shipment.page.activities.changed-the-status-to' | translate }}
            <a [queryParams]="{ view: 'milestones' }" [routerLink]="[]">{{
                shipmentActivity.data.new_status
            }}</a>
            <span class="text-muted">
                {{ shipmentActivity.created_at | amTimeAgo }}</span
            >
        </p>
        <div *ngIf="shipmentActivity.data.notes" class="small text-muted">
            <p class="m-0">{{ shipmentActivity.data.notes }}</p>
        </div>
    </div>
</div>
