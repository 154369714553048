import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromInvoices from './invoices.reducer';
import * as InvoicesSelectors from './invoices.selectors';
import * as InvoicesActions from './invoices.actions';
import { Portals } from '@qwyk/models';

@Injectable()
export class InvoicesFacade {
    loaded$ = this.store.pipe(select(InvoicesSelectors.getInvoicesLoaded));
    loading$ = this.store.pipe(select(InvoicesSelectors.getInvoicesLoading));
    error$ = this.store.pipe(select(InvoicesSelectors.getInvoicesError));
    allInvoices$ = this.store.pipe(select(InvoicesSelectors.getAllInvoices));
    selectedInvoices$ = this.store.pipe(select(InvoicesSelectors.getSelected));
    pagination$ = this.store.pipe(
        select(InvoicesSelectors.getInvoicesPagination)
    );

    constructor(private store: Store<fromInvoices.InvoicesPartialState>) {}

    public loadInvoices(query) {
        this.dispatch(InvoicesActions.loadInvoices({ query }));
    }

    public selectInvoice(id: string) {
        this.dispatch(InvoicesActions.selectInvoice({ id }));
    }

    public resetSelectedInvoice() {
        this.dispatch(InvoicesActions.unselectInvoice());
    }

    updateInvoice(invoice: Portals.Invoice) {
        this.dispatch(InvoicesActions.updateInvoice({ invoice }));
    }

    public deleteInvoice(invoice: Portals.Invoice) {
        this.dispatch(InvoicesActions.deleteInvoice({ invoice }));
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
