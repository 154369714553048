<form [formGroup]="form" *ngIf="form; else loading" (submit)="next()">
    <div class="row justify-content-center mx-0">
        <div class="col-12 col-lg-9 col-xl-8 col-xxl-6">

            <h2 class="font-weight-normal">Get started</h2>
            <h6 class="font-weight-light">Navigate through the wizard to get a quotation, schedule, or start a booking
                instantly</h6>
            <hr>

            <div class="card border-0 shadow text-dark"
                [ngStyle]="{'background-image': 'url(https://cdn.qwyk.io/portals/assets/' + (form.get('header.transport_mode').value | lowercase) + '.png)'}"
                style="background-position: center; background-size: cover; background-color: rgba(255,255,255,0.8);
            background-blend-mode: lighten;">
                <div class="card-body">
                    <div class="row align-items-center ">
                        <div class="col-auto">
                            <fa-icon [icon]="['far', form.get('header.transport_mode').value | transportModeIcon]"
                                [fixedWidth]="true" size="3x">
                            </fa-icon>
                        </div>
                        <div class="col">
                            <h4 class="mb-0 font-weight-normal">
                                {{ form.get('header.transport_mode').value | titlecase }}
                                {{ form.get('header.load_type').value | uppercase }}
                            </h4>
                            <p class="mb-0">{{ form.get('header.origin').value.name }} to
                                {{ form.get('header.destination').value.name }}</p>
                        </div>
                        <div class="col-12 text-left col-sm-auto">
                            <button class="btn btn-secondary" [routerLink]="['../routing']" type="button"
                                [disabled]="form.disabled">
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div formGroupName="header" class="card mt-4 border-0 shadow text-dark">
                <div class="card-body">
                    <h4 class="font-weight-normal">{{ form.get('header.wantsQuote').value ? 'Quote' : 'Booking' }}
                        information</h4>
                    <div class="row mt-3">
                        <div class="form-group col">
                            <div class="custom-control custom-switch mt-2">
                                <input type="checkbox" class="custom-control-input" formControlName="wantsQuote"
                                    id="wants_quote" aria-describedby="wantsQuoteHelp">
                                <label class="custom-control-label" for="wants_quote">I want a quote</label>

                                <small id="wantsQuoteHelp" class="form-text text-muted">
                                    {{ form.get('header.wantsQuote').value ? 'Deselect this if you want to continue
                                    straight to a Booking' : 'Select this if you want us to quote a rate' }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-sm-10 col-md-7">
                            <label>Commodity</label>
                            <ng-select [items]="commodities$ | async" bindLabel="description" [clearable]="false"
                                formControlName="commodity" groupBy="group"
                                placeholder="Select a commodity from the list"
                                [ngClass]="{'is-invalid': form.get('header.commodity').invalid && form.get('header.commodity').touched}">
                                <ng-template ng-optgroup-tmp let-item="item">
                                    <div class="border-bottom"></div>
                                </ng-template>

                            </ng-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-12 col-sm-6 col-md-4">
                            <label>Cargo ready on</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
                                    autocomplete="off" #cargoReadyDate="ngbDatepicker" (click)="cargoReadyDate.toggle()"
                                    formControlName="cargo_ready" [minDate]="minCargoReadyDate"
                                    [ngClass]="{'is-invalid': form.get('header.cargo_ready').invalid && form.get('header.cargo_ready').touched}">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary calendar" (click)="cargoReadyDate.toggle()"
                                        type="button">
                                        <fa-icon [icon]="['far', 'calendar']"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <a href="javascript:void(0)" class="pl-2 mt-1" (click)="setCargoReadyToday()">Set to
                                today</a>
                        </div>
                    </div>
                    <ng-container *ngIf="form.get('header.wantsQuote').value">
                        <div class="row">
                            <div class="form-group col-12 col-sm-6 col-md-4" *ngIf="currencies$ | async; let curr">
                                <label>Preferred currency</label>
                                <ng-select [items]="curr" bindLabel="code" bindValue="code" groupBy="group"
                                    placeholder="Currency" formControlName="currency" [clearable]="false"
                                    [ngClass]="{'is-invalid': form.get('header.currency').invalid && form.get('header.currency').touched}">
                                    <ng-template ng-optgroup-tmp let-item="item">
                                        <div class="border-bottom"></div>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                        {{ item.code }} - {{ item.name}}
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        {{ item.code }} - {{ item.name }}
                                    </ng-template>
                                </ng-select>
                            </div>

                        </div>
                        <div class="row">
                            <div class="form-group col-12 col-sm-10 col-md-7">
                                <label>Your quote reference (optional)</label>
                                <input type="text" class="form-control" formControlName="owner_reference"
                                    placeholder="Your reference" maxlength="255"
                                    [ngClass]="{'is-invalid': form.get('header.owner_reference').invalid && form.get('header.owner_reference').touched}" />
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!form.get('header.wantsQuote').value">
                        <div class="form-group">
                            <label>Pricing reference (optional)</label>
                            <input type="text" class="form-control" formControlName="owner_reference"
                                [ngClass]="{'is-invalid': form.get('header.currency').invalid && form.get('header.currency').touched}" />
                        </div>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="form.get('header').valid || busy">
                <div class="card mt-4 border-0 shadow text-dark" [@fadeIn]>
                    <div class="card-body">
                        <h4 class="font-weight-normal">Cargo</h4>
                        <div class="mt-3" *ngFor="let cargoRow of getCargoFormArray.controls; let idx = index;"
                            [ngClass]="{'border-bottom': idx < getCargoFormArray.controls.length - 1}"
                            formArrayName="packages">
                            <div [formGroupName]="idx">
                                <ng-container *ngIf="form.get('header.load_type').value === 'fcl'">
                                    <div class="row">
                                        <div class="form-group col-4 col-sm-3 col-md-2">
                                            <label>Quantity</label>
                                            <input type="number" class="form-control" formControlName="quantity"
                                                placeholder="Quantity"
                                                [ngClass]="{'is-invalid': cargoRow.get('quantity').invalid && cargoRow.get('quantity').touched}" />
                                        </div>
                                        <div class="form-group col-8 col-sm-4 col-md">
                                            <label>Container Type</label>
                                            <ng-select [items]="packagings$ | async" bindLabel="description"
                                                [clearable]="false" placeholder="Container Type"
                                                formControlName="packaging"
                                                [ngClass]="{'is-invalid': cargoRow.get('packaging').invalid && cargoRow.get('packaging').touched}">
                                            </ng-select>
                                        </div>
                                        <div class="form-group col-12 col-sm-5 col-md">
                                            <label>Unit weight (optional)</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control" placeholder="Weight"
                                                    [ngClass]="{'is-invalid': cargoRow.get('unit_weight').invalid && cargoRow.get('unit_weight').touched}"
                                                    formControlName="unit_weight" />
                                                <div class="input-group-append">
                                                    <ng-select style="min-width: 90px;" [searchable]="false"
                                                        [ngClass]="{'is-invalid': cargoRow.get('uom').invalid && cargoRow.get('uom').touched}"
                                                        placeholder="Unit" (change)="onUOMSet(idx, $event)"
                                                        [items]="[{key: 'metric', label: 'Kgs'}, {key: 'imperial', label: 'Lbs'}]"
                                                        bindValue="key" bindLabel="label" formControlName="uom">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="form.get('header.load_type').value === 'lcl'">
                                    <div class="row">
                                        <div class="form-group col-4 col-sm-4 col-md-2">
                                            <label>Quantity</label>
                                            <input type="number" class="form-control" formControlName="quantity" min="1"
                                                placeholder="Quantity"
                                                [ngClass]="{'is-invalid': cargoRow.get('quantity').invalid && cargoRow.get('quantity').touched}" />
                                        </div>
                                        <div class="form-group col">
                                            <label>Packaging</label>
                                            <ng-select [items]="packagings$ | async" bindLabel="description"
                                                [clearable]="false" placeholder="Packaging" formControlName="packaging"
                                                [ngClass]="{'is-invalid': cargoRow.get('packaging').invalid && cargoRow.get('packaging').touched}">
                                            </ng-select>
                                        </div>
                                        <div class="col-1" *ngIf="getCargoFormArray.controls.length > 1"
                                            style="min-width: 70px;">
                                            <button class="btn btn-outline-danger btn-sm border-0"
                                                [disabled]="form.disabled" style="margin-top: 32px;"
                                                (click)="removeCargoRow(idx)" type="button">
                                                <fa-icon [icon]="['far', 'trash']" class="mr-2"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-12 col-sm-10 col-md-6">
                                            <label>Unit weight</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control" formControlName="unit_weight"
                                                    min="0.1" placeholder="Weight"
                                                    [ngClass]="{'is-invalid': cargoRow.get('unit_weight').invalid && cargoRow.get('unit_weight').touched}" />
                                                <div class="input-group-append">
                                                    <ng-select style="min-width: 90px;" [searchable]="false"
                                                        [ngClass]="{'is-invalid': cargoRow.get('uom').invalid && cargoRow.get('uom').touched}"
                                                        [clearable]="false" (change)="onUOMSet(idx, $event)"
                                                        [items]="[{key: 'metric', label: 'Kgs'}, {key: 'imperial', label: 'Lbs'}]"
                                                        bindValue="key" bindLabel="label" formControlName="uom">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-12 col-sm-10 col-md-6">
                                            <label>Unit {{ !dimensionsInput ? 'volume' : 'dimensions' }}</label>
                                            <div class="input-group" *ngIf="dimensionsInput">
                                                <input type="number" class="form-control" formControlName="unit_length"
                                                    [ngClass]="{'is-invalid': cargoRow.get('unit_length').invalid && cargoRow.get('unit_length').touched}"
                                                    placeholder="Length" />
                                                <input type="number" class="form-control" formControlName="unit_width"
                                                    [ngClass]="{'is-invalid': cargoRow.get('unit_width').invalid && cargoRow.get('unit_width').touched}"
                                                    placeholder="Width" />
                                                <input type="number" class="form-control" formControlName="unit_height"
                                                    [ngClass]="{'is-invalid': cargoRow.get('unit_height').invalid && cargoRow.get('unit_height').touched}"
                                                    placeholder="Height" />
                                                <div class="input-group-append">
                                                    <ng-select style="min-width: 90px;" [searchable]="false"
                                                        [clearable]="false" (change)="onUOMSet(idx, $event)"
                                                        [items]="[{key: 'metric', label: 'Cm'}, {key: 'imperial', label: 'In'}]"
                                                        bindValue="key" bindLabel="label" formControlName="uom"
                                                        [ngClass]="{'is-invalid': cargoRow.get('uom').invalid && cargoRow.get('uom').touched}">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="input-group" *ngIf="!dimensionsInput">
                                                <input type="number" class="form-control" formControlName="unit_volume"
                                                    placeholder="Volume" (change)="onVolumeChange(idx, $event)"
                                                    [ngClass]="{'is-invalid': cargoRow.get('unit_height').invalid && cargoRow.get('unit_height').touched}" />
                                                <div class="input-group-append">
                                                    <ng-select style="min-width: 90px;" [searchable]="false"
                                                        [ngClass]="{'is-invalid': cargoRow.get('uom').invalid && cargoRow.get('uom').touched}"
                                                        [clearable]="false" (change)="onUOMSet(idx, $event)"
                                                        [items]="[{key: 'metric', label: 'Cbm'}, {key: 'imperial', label: 'Cft'}]"
                                                        bindValue="key" bindLabel="label" formControlName="uom">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <small *ngIf="idx === 0" id="wantsQuoteHelp" class="form-text">
                                                <a href="javascript:void(0);"
                                                    (click)="dimensionsInput = !dimensionsInput">
                                                    Enter {{ dimensionsInput ? 'volume' : 'dimensions' }}
                                                </a>
                                            </small>
                                        </div>

                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <ng-container *ngIf="form.get('header.load_type').value === 'lcl'">
                            <button class="btn btn-outline-secondary btn-sm border-0" (click)="addCargoRow()"
                                [disabled]="form.disabled" *ngIf="form.valid" [@fadeIn] type="button">
                                <fa-icon [icon]="['far', 'plus']" class="mr-2"></fa-icon>Add cargo
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div class="card mt-4 border-0 shadow text-dark" *ngIf="form.valid || busy" [@fadeIn]>
                    <div class="card-body" formGroupName="additionals">
                        <div class="d-flex mb-2 align-items-center justify-content-between">
                            <h4 class="font-weight-normal m-0">Additionals</h4>
                            <div>
                                <fa-icon [icon]="['far', 'question-circle']" class="text-muted"
                                    ngbTooltip="Request for additional services to be included in your quote. Services are as-available and not guaranteed to be included.">
                                </fa-icon>
                            </div>
                        </div>
                        <div class="row small">
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="customs_clearance_origin" id="customs_clearance_origin"
                                        name="customs_clearance_origin">
                                    <label class="custom-control-label" for="customs_clearance_origin">
                                        Origin customs clearance
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="customs_clearance_destination"
                                        id="customs_clearance_destination" name="customs_clearance_destination">
                                    <label class="custom-control-label" for="customs_clearance_destination">
                                        Destination customs clearance
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4 d-none">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="cargo_insurance" id="cargo_insurance" name="cargo_insurance">
                                    <label class="custom-control-label" for="cargo_insurance">Cargo insurance</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="hazardous_cargo" id="hazardous_cargo" name="hazardous_cargo">
                                    <label class="custom-control-label" for="hazardous_cargo">Hazardous cargo</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input" formControlName="refrigerated"
                                        id="refrigerated" name="refrigerated">
                                    <label class="custom-control-label" for="refrigerated">Refrigerated</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4" *ngIf="form.get('header.load_type').value === 'lcl'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input" formControlName="stackable"
                                        id="stackable" name="stackable">
                                    <label class="custom-control-label" for="stackable">Stackable</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4"
                                *ngIf="form.get('header.origin').value?.type === 'place'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="residential_pickup" id="residential_pickup"
                                        name="residential_pickup">
                                    <label class="custom-control-label" for="residential_pickup">Residential
                                        pickup</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4"
                                *ngIf="form.get('header.origin').value?.type === 'place'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="liftgate_pickup" id="liftgate_pickup" name="liftgate_pickup">
                                    <label class="custom-control-label" for="liftgate_pickup">Liftgate pickup</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4"
                                *ngIf="form.get('header.origin').value?.type === 'place'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="limited_access_pickup" id="limited_access_pickup"
                                        name="limited_access_pickup">
                                    <label class="custom-control-label" for="limited_access_pickup">Limited access
                                        pickup</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4"
                                *ngIf="form.get('header.destination').value?.type === 'place'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="residential_delivery" id="residential_delivery"
                                        name="residential_delivery">
                                    <label class="custom-control-label" for="residential_delivery">Residential
                                        delivery</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4"
                                *ngIf="form.get('header.destination').value?.type === 'place'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="liftgate_delivery" id="liftgate_delivery"
                                        name="liftgate_delivery">
                                    <label class="custom-control-label" for="liftgate_delivery">{{ 'liftgate_delivery' |
                                        slugToTitle }}</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4"
                                *ngIf="form.get('header.destination').value?.type === 'place'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="delivery_appointment_required"
                                        id="delivery_appointment_required" name="delivery_appointment_required">
                                    <label class="custom-control-label" for="delivery_appointment_required">Delivery
                                        appointment rqrd</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-4"
                                *ngIf="form.get('header.destination').value?.type === 'place'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="limited_access_delivery" id="limited_access_delivery"
                                        name="limited_access_delivery">
                                    <label class="custom-control-label" for="limited_access_delivery">Limited access
                                        delivery</label>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-xl-4"
                                *ngIf="(form.get('header.destination').value?.type === 'place' || form.get('header.origin').value?.type === 'place') && form.get('header.load_type').value !== 'lcl'">
                                <div class="custom-control custom-switch mt-2">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="flatbed_required" id="flatbed_required"
                                        name="flatbed_required">
                                    <label class="custom-control-label" for="flatbed_required">Flatbed required</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="mt-4 text-center" *ngIf="form.valid || busy" [@fadeIn]>
                    <div *ngIf="error" [@fadeIn] class="alert alert-danger mt-4 shadow">
                        <div class="font-weight-bold">We are sorry, something went wrong.</div>
                        <div>Please try again or contact support if the problem persists.</div>
                    </div>
                    <div>
                        <button class="btn btn-secondary btn-lg btn-block" [disabled]="form.disabled">
                            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" class="mr-2" *ngIf="busy"></fa-icon>
                            {{ form.get('header.wantsQuote').value ? 'Get quote' : 'Create booking'}}
                            <fa-icon [icon]="['far', 'arrow-right']" class="ml-2" *ngIf="!busy"></fa-icon>
                        </button>
                    </div>
                </div>

            </ng-container>
        </div>
    </div>
</form>
<ng-template #loading>
    <div class="my-5">
        <qwyk-loading-indicator></qwyk-loading-indicator>
    </div>
</ng-template>
