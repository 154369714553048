import { Component, Input } from '@angular/core';

@Component({
    selector: 'qwyk-relative-time-display',
    templateUrl: './relative-time-display.component.html',
    styleUrls: ['./relative-time-display.component.scss'],
})
export class RelativeTimeDisplayComponent {
    @Input() value?: string;
}
