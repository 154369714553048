/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
    INDEX_PRODUCT_OPTIONS,
    INDEX_SORT_OPTIONS,
    INDEX_STATUS_OPTIONS,
} from '../../data';

@Component({
    selector: 'qwyk-quotations-index-search',
    templateUrl: './quotations-index-search.component.html',
    styleUrls: ['./quotations-index-search.component.scss'],
})
export class QuotationsIndexSearchComponent implements OnInit {
    searchForm: FormGroup;
    @Input() query: any;
    @Input() queryParams: any;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();
    @Output() public viewChange: EventEmitter<'list' | 'table'> =
        new EventEmitter<'list' | 'table'>();
    @Input() public showMore = false;

    public readonly sortOptions = INDEX_SORT_OPTIONS;
    public readonly productOptions = INDEX_PRODUCT_OPTIONS;
    public readonly statusOptions = INDEX_STATUS_OPTIONS;

    private formInitialValues = {
        sort: 'created_at',
        direction: 'desc',
    };

    constructor(private fb: FormBuilder) {}

    private _view: 'list' | 'table';

    public get view(): 'list' | 'table' {
        return this._view;
    }

    @Input()
    public set view(view: 'list' | 'table') {
        this._view = view;
        this.viewChange.emit(view);
    }

    private _loading: boolean;

    public get loading(): boolean {
        return this._loading;
    }

    @Input()
    public set loading(loading: boolean) {
        this._loading = loading;
        if (this.searchForm) {
            if (loading) {
                this.searchForm.disable();
            } else {
                this.searchForm.enable();
            }
        }
    }

    public get orderByDirection(): string {
        return this.searchForm.get('direction').value;
    }

    ngOnInit() {
        this.searchForm = this.fb.group({
            friendly_id: [],
            status: [],
            product: [],
            origin_name: [],
            destination_name: [],
            sort: [],
            direction: [],
        });

        const query = { ...this.query };
        this.searchForm.patchValue({ ...this.formInitialValues, ...query });
    }

    public onSearch(page = 1): void {
        this.search.emit({ ...this.searchForm.value, page });
    }

    toggleOrderDirection() {
        this.searchForm
            .get('direction')
            .setValue(this.orderByDirection === 'asc' ? 'desc' : 'asc');
    }

    public onReset() {
        this.searchForm.reset(this.formInitialValues);
        this.onSearch();
    }
}
