import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '@qwyk/core';

@Pipe({
    standalone: true,
    name: 'purchaseOrderStatusName',
})
export class PurchaseOrderStatusNamePipe implements PipeTransform {
    private _statuses: { [index: string]: string } =
        Constants.PURCHASEORDER_STATUS_NAMES;

    constructor(private readonly _translate: TranslateService) {}

    transform(value: string): string {
        const result = this._statuses[value];
        if (result) {
            return this._translate.instant(result);
        } else {
            return value;
        }
    }
}
