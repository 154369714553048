import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@qwyk/portals/environment';
import { catchError, map, take } from 'rxjs/operators';
import { OnboardingResponse } from './onboarding.response';

@Injectable({
    providedIn: 'root',
})
export class OnboardingService {
    constructor(private http: HttpClient) {}

    public getOnboardingStatus(): Observable<OnboardingResponse | null> {
        return this.http
            .patch<OnboardingResponse>(
                `${environment.backendServer}/api/portals/auth/onboarding`,
                {},
                { observe: 'response' }
            )
            .pipe(
                take(1),
                map(response => response.body),
                catchError(() => of(null))
            );
    }
}
