import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'qwyk-auth0-callback',
    templateUrl: './auth0-callback.component.html',
    styleUrls: ['./auth0-callback.component.scss'],
})
export class Auth0CallbackComponent implements OnInit {
    params$ = this.route.queryParams;
    showRedirectButton = false;
    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        setTimeout(() => {
            this.showRedirectButton = true;
            this.router.navigate(['/']);
        }, 500);
        if (!this.route.snapshot.queryParams['error']) {
            // this.auth.handleAuthCallback();
            // this.auth.handleAuthCallback();
        }
    }
}
