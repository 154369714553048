import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromSchedulesSearch from './schedules-search.reducer';
import * as SchedulesSearchSelectors from './schedules-search.selectors';
import { Portals } from '@qwyk/models';
import * as SchedulesSearchActions from './schedules-search.actions';

@Injectable()
export class SchedulesSearchFacade {
    loaded$ = this.store.pipe(
        select(SchedulesSearchSelectors.getSchedulesSearchLoaded)
    );

    searching$ = this.store.pipe(
        select(SchedulesSearchSelectors.getSchedulesSearchSearching)
    );
    error$ = this.store.pipe(
        select(SchedulesSearchSelectors.getSchedulesSearchError)
    );
    query$ = this.store.pipe(
        select(SchedulesSearchSelectors.getSchedulesSearchQuery)
    );
    schedules$ = this.store.pipe(
        select(SchedulesSearchSelectors.getAllSchedulesSearch)
    );
    selectedSchedulesSearch$ = this.store.pipe(
        select(SchedulesSearchSelectors.getSelected)
    );

    constructor(
        private store: Store<fromSchedulesSearch.SchedulesSearchPartialState>
    ) {}

    public search(query: Portals.SchedulesSearchQuery) {
        this.dispatch(SchedulesSearchActions.searchSchedules({ query }));
    }

    public reset() {
        this.dispatch(SchedulesSearchActions.resetSearchResults());
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
