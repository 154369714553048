<div class="d-flex">
    <div class="flex-grow-0 mr-3 icon-container">
        <div class="icon-circle border rounded-circle text-muted bg-white">
            <fa-icon
                [fixedWidth]="true"
                [icon]="['far', 'comment']"
                size="lg"
            ></fa-icon>
        </div>
    </div>
    <div class="flex-grow-1" style="margin-top: 0.4em">
        <p class="m-0">
            <b [ngbTooltip]="shipmentActivity.user?.email">
                {{ shipmentActivity.user?.name || '@system' }}
            </b>
            {{'portal.shipment.page.activities.commented' | translate}}
            <span class="text-muted">
                {{ shipmentActivity.created_at | amTimeAgo }}
            </span>
        </p>
        <p class="m-0">
            <fa-icon
                [icon]="['far', 'quote-left']"
                class="mr-1 text-muted"
                style="opacity: 0.5"
            ></fa-icon>
            <span
                [innerHTML]="shipmentActivity.data.comment | lineBreaks"
            ></span>
            <fa-icon
                [icon]="['far', 'quote-right']"
                class="ml-1 text-muted"
                style="opacity: 0.5"
            ></fa-icon>
        </p>
    </div>
</div>
