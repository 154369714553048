<div class="d-inline-block  ml-2" ngbDropdown *ngIf="downloadable">
    <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle type="button">
        <fa-icon
            [icon]="['far', !loading ? 'file-pdf' : 'spinner']"
            [pulse]="loading" class="mr-2"></fa-icon>
        PDF
    </button>
    <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
        <button
            ngbDropdownItem
            (click)="onDownload()"
        >
            <fa-icon
                [icon]="['far', !loading ? 'file-download' : 'spinner']"
                [pulse]="loading" class="mr-2"></fa-icon>
            {{ 'common.download' | translate }} PDF
        </button>
        <button
            ngbDropdownItem
            (click)="onEmail()"
        >
            <fa-icon
                [icon]="['far', !loading ? 'envelope' : 'spinner']"
                [pulse]="loading" class="mr-2"></fa-icon>
                {{ 'portal.common.email_file' | translate : { file: 'PDF' } }}
        </button>
    </div>
</div>
