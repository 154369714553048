// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// CONFIG ENV

import Pusher from 'pusher-js';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment = {
    name: 'production',
    badge: null,
    production: true,
    backendServer: 'https://api.qwykportals.com',
    schedulesServer: 'https://api.qwykschedules.com',
    microservicesServer: 'https://microservices.api.qwykportals.com',
    hostnameMask: '^([A-Za-z0-9\\.\\-\\_]*).qwykportals.com$',
    inboundEmailsDomain: 'incoming.qwykportals.com',
    auth: {
        bearerTokenConfig: {
            allowedDomains: ['api.next.qwykportals.com', 'api.qwykportals.com'],
            ignoreSubdomains: true,
            ignorePaths: ['^/api/siteconfig/', '^/api/portals/auth/login$'],
        },
    },
    echoConfig: {
        userModel: 'App.Models.Portals.Auth.User',
        notificationNamespace: 'App\\Notifications',
        options: {
            broadcaster: 'pusher',
            key: '1af98dbec4d0647880bb',
            cluster: 'eu',
            forceTLS: true,

            client: new Pusher('1af98dbec4d0647880bb', {
                cluster: 'eu',
                enabledTransports: ['ws', 'wss'],
                authEndpoint:
                    'https://api.qwykportals.com/api/portals/broadcasting/auth',
            }),
        },
    },
    flags: {
        enableOnboarding: false,
        enablePurchaseOrders: true,
        enableHelp: false,
    },
    utils: {
        Algolia: {
            applicationId: '3G8S1ON0FI',
            adminApiKey: 'c397d91713a36b2e696eb4454f22ec40',
        },
        LogRocket: {
            enable: true,
            appId: 'wozolw/portals-client-prod',
        },
        Sentry: {
            enabled: true,
            dsn: 'https://bc9cb816ce4e4e3280ae7811b9d51b56@sentry.io/4815443',
            traceOrigins: ['https://api.qwykportals.com'],
            tracesSampleRate: 0.1,
        },
        Veem: {
            environment: 'production',
            clientId: 'MagayaDigitalFreightPortal-nJO3BW8zqQ'
        },
        Amplitude: {
            enabled: true,
            apiKey: 'c56f437e325b29066c927fba8c3873f5'
        },
        Segment: {
            apiKey: 'G4i7Qbgws6CABI0XCfPTZyeqzaH84kP7',
            debug: false,
            loadOnInitialization: true
        }
    },
    translation: {
        enabled: true,
        defaultLanguage: 'en',
        showFlags: false,
        languages: [
            {key: "en", name: "English", flag: 'gb', enabled: true},
            {key: "es", name: "Español", flag: 'es', enabled: true},
            {key: "nl", name: "Nederlands", flag: 'nl', enabled: true},
            {key: "de", name: "Deutsch", flag: 'de', enabled: true},
            {key: "fr", name: "Français", flag: 'fr', enabled: true},
        ]
    }
};

