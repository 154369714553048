import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Portals } from '@qwyk/models';
import { of, Observable } from 'rxjs';
import {
    delay,
    tap,
    switchMap,
    map,
    catchError,
    shareReplay,
} from 'rxjs/operators';
import { Animations } from '@qwyk/ui';
import { QuotationsService } from '../../services/quotations.service';

@Component({
    selector: 'qwyk-quotation-offer-list-item-schedules',
    templateUrl: './quotation-offer-list-item-schedules.component.html',
    styleUrls: ['./quotation-offer-list-item-schedules.component.scss'],
    animations: [Animations.fadeInAnimation],
})
export class QuotationOfferListItemSchedulesComponent implements OnInit {
    @Input() offer: Portals.QuotationOffer;
    @Input() expanded = false;
    @Input() indexBeingBooked = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() createBooking: EventEmitter<any> = new EventEmitter();
    @Input() allowBooking = true;

    loadingSchedules = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schedules$: Observable<any[]>;
    schedulesPage = 1;
    schedulesPageSize = 5;
    constructor(private service: QuotationsService) {}

    ngOnInit() {
        this.schedules$ = of(0).pipe(
            delay(1),
            tap(() => {
                this.loadingSchedules = true;
            }),
            switchMap(() => {
                if (!this.allowBooking) {
                    this.loadingSchedules = false;
                    return of([]);
                }
                return this.service.getOfferSchedules(this.offer).pipe(
                    map(schedules =>
                        schedules.filter(
                            e =>
                                // eslint-disable-next-line no-prototype-builtins
                                !e.hasOwnProperty('service_mode') ||
                                e['service_mode'] !== 'DOOR/DOOR'
                        )
                    ),
                    catchError(() => {
                        this.loadingSchedules = false;
                        return of([]);
                    }),
                    tap(() => {
                        this.loadingSchedules = false;
                        this.schedulesPage = 1;
                    })
                );
            }),
            shareReplay()
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onCreateBooking(index: number = null, schedule: any = null) {
        this.createBooking.emit(schedule ? { index, schedule } : null);
    }
}
