import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [NgClass, FontAwesomeModule, TitleCasePipe, TranslateModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-list-item-loader]',
    templateUrl: './list-item-loader.component.html',
    styleUrls: ['./list-item-loader.component.scss'],
})
export class ListItemLoaderComponent {
    @Input() resource = 'Items';
    @Input() dense = false;
    private normalClass = 'list-group-item p-4 text-center loader';
    private denseClass = 'list-group-item text-center loader';

    @HostBinding('class')
    public get class(): string {
        return this.dense ? this.denseClass : this.normalClass;
    }
}
