import { createAction, props } from '@ngrx/store';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Shipment } from '@qwyk/shared-stores/shipments';

import {
    CreateShipmentAttachmentRequest,
    ShipmentAttachment,
} from './shipment-attachments.models';

export const init = createAction(
    '[Portals/Shipments/Attachments] Init',
    props<{ shipment: Shipment }>()
);

export const loadShipmentAttachmentsSuccess = createAction(
    '[Portals/Shipments/Attachments] Load ShipmentAttachments Success',
    props<{
        shipmentAttachments: ShipmentAttachment[];
    }>()
);

export const loadShipmentAttachmentsFailure = createAction(
    '[Portals/Shipments/Attachments] Load ShipmentAttachments Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const loadShipmentAttachmentsOnly = createAction(
    '[Portals/Shipments/Attachments] Load ShipmentAttachments Only',
    props<{ shipment: Shipment }>()
);

export const loadShipmentAttachmentsOnlySuccess = createAction(
    '[Portals/Shipments/Attachments] Load ShipmentAttachments Only Success',
    props<{
        attachmentLinks: ShipmentAttachment[];
    }>()
);

export const loadShipmentAttachmentsOnlyFailure = createAction(
    '[Portals/Shipments/Attachments] Load ShipmentAttachments Only Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const generateAttachmentLink = createAction(
    '[Portals/Shipments/Attachments] Generate Attachment Link',
    props<{ shipment: Shipment; attachment: ShipmentAttachment }>()
);
export const generateAttachmentLinkSuccess = createAction(
    '[Portals/Shipments/Attachments] Generate Attachment Link Success',
    props<{ attachmentLink: string }>()
);
export const generateAttachmentLinkFailure = createAction(
    '[Portals/Shipments/Attachments] Generate Attachment Link Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const createShipmentAttachment = createAction(
    '[Portals/Shipments/Attachments] Create ShipmentAttachment',
    props<{ shipment: Shipment; request: CreateShipmentAttachmentRequest }>()
);

export const createShipmentAttachmentSuccess = createAction(
    '[Portals/Shipments/Attachments] Create ShipmentAttachment Success',
    props<{ shipmentAttachment: ShipmentAttachment }>()
);

export const createShipmentAttachmentFailure = createAction(
    '[Portals/Shipments/Attachments] Create ShipmentAttachment Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const deleteShipmentAttachment = createAction(
    '[Portals/Shipments/Attachments] Delete ShipmentAttachment',
    props<{ shipmentId: string; shipmentAttachmentId: number }>()
);

export const deleteShipmentAttachmentSuccess = createAction(
    '[Portals/Shipments/Attachments] Delete ShipmentAttachment Success',
    props<{ shipmentAttachmentId: number }>()
);

export const deleteShipmentAttachmentFailure = createAction(
    '[Portals/Shipments/Attachments] Delete ShipmentAttachment Failure',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props<{ error: any }>()
);

export const reset = createAction('[Portals/Shipments/Attachments] Reset');

export const dismissError = createAction(
    '[Portals/Shipments/Attachments] Dismiss Error'
);
