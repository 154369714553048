import { Component } from '@angular/core';

@Component({
    selector: 'qwyk-file-transfer-animation',
    templateUrl: './file-transfer-animation.component.html',
    styleUrls: ['./file-transfer-animation.component.scss'],
})
export class FileTransferAnimationComponent {
    fileCount = 8;
}
