import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Portals } from '@qwyk/models';

@Component({
    standalone: true,
    imports: [NgIf, NgFor, TranslateModule, FontAwesomeModule, MomentModule],
    selector: 'qwyk-schedule-timeline',
    templateUrl: './schedule-timeline.component.html',
    styleUrls: ['./schedule-timeline.component.scss'],
})
export class ScheduleTimelineComponent {
    @Input() schedule: Portals.SavedSchedule | Portals.Schedule;
}
