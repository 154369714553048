import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { LocalStorageService } from '@qwyk/core';
import { environment } from '@qwyk/portals/environment';

import { Language } from './language.model';

@Injectable({ providedIn: 'root' })
export class PortalsTranslateService {
    private readonly _availableLanguages: Language[];

    constructor(
        private _config: PrimeNGConfig,
        private _translate: TranslateService,
        private _localStorage: LocalStorageService
    ) {
        this._availableLanguages = environment.translation.languages.filter(
            e => e.enabled
        );
    }

    public get currentLanguage(): string {
        return this._translate.currentLang;
    }

    public get availableLanguages(): Language[] {
        return this._availableLanguages;
    }

    public get isTranslationsEnabled(): boolean {
        return environment.translation?.enabled === true;
    }

    private _translatePrimeNg(): void {
        this._translate
            .get('common.primeng')
            .pipe(take(1))
            .subscribe(res => this._config.setTranslation(res));
    }

    public init(useLanguage: string | null = null) {
        if (!this.isTranslationsEnabled) {
            return;
        }

        this._translate.addLangs(this._availableLanguages.map(e => e.key));

        let chosenLanguage = null;

        if (useLanguage) {
            chosenLanguage = useLanguage;
        } else if (
            this._localStorage.getString('lang') &&
            this._translate.langs.includes(this._localStorage.getString('lang'))
        ) {
            chosenLanguage = this._localStorage.getString('lang');
        } else if (
            this._translate.getBrowserLang() &&
            this._translate.langs.includes(this._translate.getBrowserLang())
        ) {
            chosenLanguage = this._translate.getBrowserLang();
        }

        if (!chosenLanguage) {
            chosenLanguage = environment.translation.defaultLanguage ?? 'en';
        }

        this._translate.use(chosenLanguage);
        moment.locale(chosenLanguage);

        this._translatePrimeNg();
    }

    public useLanguage(languageKey: string): void {
        if (!this.isTranslationsEnabled) {
            return;
        }

        this._translate.use(languageKey);
        this._localStorage.setString('lang', languageKey);
        moment.locale(languageKey);
        this._translatePrimeNg();
    }
}
