<div class="card shadow mb-5 border-0 text-dark">
    <div class="card-special-header d-flex">
        <fa-icon class="header-icon text-secondary" [ngClass]="{'text-muted': hasExpired}"
            [icon]="['fas', offer.transport_mode | transportModeIcon]" size="3x" [fixedWidth]="true">
        </fa-icon>
        <h6 class="bg-white flex-fill rounded px-3 py-2 m-0 ml-2 ">
            {{ offer.pol.name }} to {{ offer.pod.name }}
        </h6>
    </div>
    <div class="card-body mt-4">
        <div class="row">
            <div class="col">
                <div class="font-weight-normal text-muted m-0 small">CARRIER</div>
                {{ offer.carrier_name || '-' }}
            </div>
            <div class="col">
                <div class="font-weight-normal text-muted m-0 small">TRANSIT TIME</div>
                <span *ngIf="offer.transit_time">{{ offer.transit_time }} day(s)</span>
                <span *ngIf="!offer.transit_time">-</span>
            </div>
            <div class="col">
                <div class="font-weight-normal text-muted m-0 small">EXPIRES</div>
                <span *ngIf="offer.valid_until" [ngClass]="{'text-danger': hasExpired}">
                    {{ offer.valid_until | amUtc | amDateFormat: 'LL'}}
                </span>
                <span *ngIf="!offer.valid_until">-</span>
            </div>
        </div>
    </div>

    <ul class="list-group list-group-flush">

        <li (click)="expandCharges = !expandCharges" (mouseenter)="hoverHeader = true"
            (mouseleave)="hoverHeader = false"
            [ngClass]="{'list-group-item-action': offer.breakdown.length >= 0, 'bg-light': expandCharges}"
            class="list-group-item d-flex justify-content-between py-3 align-items-center border-bottom-0 border-top text-primary">
            <h6 class="m-0" [ngClass]="{'font-weight-normal': !expandCharges}">
                <fa-icon
                    [icon]="['far', !hoverHeader || offer.breakdown.length === 0 ? 'list' : expandCharges ? 'angle-up' : 'angle-down']"
                    [fixedWidth]="true" class="mr-2"></fa-icon>Charges
            </h6>
            <h5 class="m-0">{{ offer.total_amount | currency:offer.currency }}</h5>
        </li>
        <qwyk-quotation-offer-list-item-charges [offer]="offer" *ngIf="expandCharges">
        </qwyk-quotation-offer-list-item-charges>
        <ng-container *ngIf="offer.transport_mode !== 'TRUCK' && !hasExpired">
            <li (click)="expandSchedules = !expandSchedules" (mouseenter)="hoverHeader = true"
                (mouseleave)="hoverHeader = false" [ngClass]="{'bg-light': expandSchedules}"
                class="list-group-item list-group-item-action d-flex py-3 justify-content-between align-items-center border-bottom-0 border-top text-primary">
                <h6 class="m-0" [ngClass]="{'font-weight-normal': !expandSchedules}">
                    <fa-icon [icon]="['far', !hoverHeader ? 'calendar' : expandSchedules ? 'angle-up' : 'angle-down']"
                        [fixedWidth]="true" class="mr-2"></fa-icon>
                    Schedules
                </h6>
            </li>
            <qwyk-quotation-offer-list-item-schedules [expanded]="expandSchedules" [offer]="offer"
                [indexBeingBooked]="bookingSchedulesIndex" (createBooking)="onCreateBooking($event)"
                [allowBooking]="allowBooking">
            </qwyk-quotation-offer-list-item-schedules>
        </ng-container>
        <li (click)="expandRemarks = !expandRemarks" *ngIf="offer.remarks" (mouseenter)="hoverHeader = true"
            (mouseleave)="hoverHeader = false" [ngClass]="{'bg-light': expandRemarks}"
            class="list-group-item list-group-item-action d-flex py-3 justify-content-between align-items-center  border-bottom-0 border-top text-primary">
            <h6 class="m-0" [ngClass]="{'font-weight-normal': !expandRemarks}">
                <fa-icon [icon]="['far', !hoverHeader ? 'comment' : expandRemarks ? 'angle-up' : 'angle-down']"
                    [fixedWidth]="true" class="mr-2"></fa-icon>Remarks
            </h6>
        </li>
        <li class="list-group-item border-bottom-0" *ngIf="expandRemarks && offer.remarks">
            <p [innerHtml]="offer.remarks | lineBreaks" class="small"></p>
        </li>
        <li (click)="expandMap = !expandMap" (mouseenter)="hoverHeader = true" (mouseleave)="hoverHeader = false"
            [ngClass]="{'bg-light': expandMap}"
            class="list-group-item list-group-item-action d-flex py-3 justify-content-between align-items-center border-bottom-0 border-top text-primary">
            <h6 class="m-0" [ngClass]="{'font-weight-normal': !expandMap}">
                <fa-icon [icon]="['far', !hoverHeader ? 'map' : expandMap ? 'angle-up' : 'angle-down']"
                    [fixedWidth]="true" class="mr-2"></fa-icon>Map
            </h6>
        </li>
        <qwyk-quotation-offer-list-item-map [offer]="offer" [quotation]="quotation" [expanded]="expandMap">
        </qwyk-quotation-offer-list-item-map>
        <li class="list-group-item text-center border-top">
            <button class="btn btn-secondary" [ngClass]="{'btn-sm': offer.transport_mode !== 'TRUCK'}"
                (click)="onCreateBooking()" [disabled]="busy || !allowBooking || hasExpired">
                Create booking
                <fa-icon [icon]="['far', !busy?'arrow-right':'spinner']" [pulse]="busy" class="ml-1"></fa-icon>
            </button>
            <div class="text-danger small" *ngIf="hasExpired">This offer has expired and cannot be booked on.</div>
            <div [@fadeIn] *ngIf="!expandSchedules && offer.transport_mode !== 'TRUCK' && allowBooking  && !hasExpired">
                <a href="javascript:void(0)" class="small" (click)="expandSchedules = true">Select a schedule</a>
            </div>
            <div class="small text-muted" *ngIf="!allowBooking">
                This is a demo quote. You must create a quote while logged in to view schedules and create bookings.
            </div>
        </li>
    </ul>
</div>
