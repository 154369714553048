import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as fromBookingWizardOffers from './booking-wizard-offers.reducer';
import * as BookingWizardOffersActions from './booking-wizard-offers.actions';
import { QuotationsService } from '../services/quotations.service';
import { of, timer } from 'rxjs';
import {
    switchMap,
    map,
    catchError,
    withLatestFrom,
    tap,
    takeUntil,
    filter,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as BookingWizardOffersSelectors from './booking-wizard-offers.selectors';

@Injectable()
export class BookingWizardOffersEffects {
    loadQuotation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingWizardOffersActions.loadQuotation),
            switchMap(action =>
                this.quotationsService
                    .getQuotation(action.id, false, null)
                    .pipe(
                        map(quotation =>
                            BookingWizardOffersActions.loadQuotationSuccess({
                                quotation,
                                isDemo: false,
                                tempToken: null,
                            })
                        ),
                        catchError(error =>
                            of(
                                BookingWizardOffersActions.loadQuotationFailure(
                                    {
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    loadQuotationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingWizardOffersActions.loadQuotationSuccess),
            switchMap(action => {
                return of(
                    BookingWizardOffersActions.polForOffers({
                        quotation: action.quotation,
                        isDemo: action.isDemo,
                        tempToken: action.tempToken,
                    })
                );
            })
        )
    );

    polForOffers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingWizardOffersActions.polForOffers),
            withLatestFrom(
                this.store.select(BookingWizardOffersSelectors.getPollingState)
            ),
            switchMap(args =>
                timer(args[1].interval, args[1].interval).pipe(
                    map(() =>
                        BookingWizardOffersActions.loadQuotationOffers({
                            quotation: args[0].quotation,
                            isDemo: args[0].isDemo,
                            tempToken: args[0].tempToken,
                        })
                    ),
                    takeUntil(
                        this.store
                            .select(
                                BookingWizardOffersSelectors.getPollingState
                            )
                            .pipe(
                                filter(
                                    state =>
                                        state.counter >= state.maximum ||
                                        state.abort
                                ),
                                tap(() => {
                                    this.store.dispatch(
                                        BookingWizardOffersActions.polForOffersCompleted()
                                    );
                                })
                            )
                    )
                )
            )
        )
    );

    loadQuotationOffers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingWizardOffersActions.loadQuotationOffers),
            switchMap(action =>
                this.quotationsService
                    .getQuotationOffers(
                        action.quotation.id,
                        action.isDemo,
                        action.tempToken
                    )
                    .pipe(
                        map(offers =>
                            BookingWizardOffersActions.loadQuotationOffersSuccess(
                                { offers }
                            )
                        ),
                        catchError(error =>
                            of(
                                BookingWizardOffersActions.loadQuotationOffersFailure(
                                    { error }
                                )
                            )
                        )
                    )
            )
        )
    );

    abortPollingForOffers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingWizardOffersActions.abortPollingForOffers),
            switchMap(() => {
                return of(BookingWizardOffersActions.abortedPollingForOffers());
            })
        )
    );

    constructor(
        private actions$: Actions,
        private quotationsService: QuotationsService,
        private store: Store<fromBookingWizardOffers.BookingWizardOffersPartialState>
    ) {}
}
