import {Component, Input} from '@angular/core';
import {Portals} from '@qwyk/models';
import {QUOTATION_STATUS_ICONS, QUOTATION_STATUS_LABELS} from '../../data';

@Component({
    selector: 'qwyk-quotations-index-table',
    templateUrl: './quotations-index-table.component.html',
    styleUrls: ['./quotations-index-table.component.scss']
})
export class QuotationsIndexTableComponent {
    @Input() quotations: Portals.QuotationEntity[];
    @Input() loading: boolean;

    public readonly icons = QUOTATION_STATUS_ICONS;
    public readonly labels = QUOTATION_STATUS_LABELS;


}
