import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
// noinspection ES6PreferShortImport
import { AuthenticationFacade } from '../state/authentication.facade';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class VerifyMagayaControlledGuard implements CanActivate {
    constructor(
        private authentication: AuthenticationFacade,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.authentication.user$.pipe(
            map(user => user && !user.is_organization_magaya_network),
            switchMap(allowed => {
                if (!allowed) {
                    return of(
                        this.router.createUrlTree(['/error/403'], {
                            queryParams: {
                                source:
                                    '/' +
                                    route.pathFromRoot
                                        .map(e => e.url.toString())
                                        .filter(e => e)
                                        .join('/'),
                            },
                        })
                    );
                }
                return of(allowed);
            })
        );
    }
}
