import { Component } from '@angular/core';

@Component({
    selector: 'qw-schedules-dashboard',
    templateUrl: './schedules-dashboard.component.html',
    styleUrls: ['./schedules-dashboard.component.scss'],
})
export class SchedulesDashboardComponent {
    constructor() {}
}
